EmPower.View.UserRow = (function($, undefined) {
    
    return EmPower.View.AbstractView.extend({
	viewName: "Account Row",
	
	_events: function() {
	},
	
	_initialize: function() {
	    this._userModel = this._getOption("userModel", true);
	},
	
	_setupListeners: function() {
	    
	    var self = this;
	    
	    self.listenTo(self.getUserModel(), 'change', function() {
		self.renderDebounced();
	    });
	},
	
	getUserModel: function() {
	    return this._userModel;
	},
	
	_htmlTemplate: function() {
	    return EmPower.Templates['transformer/user/user_row'];
	},
	
	_htmlData: function() {
	    
	    var self = this;
	    
	    var data = {};
	    
	    var userModel = self.getUserModel();
	    if (userModel) {
		
		data.firstName = userModel.firstName() || "[First Name]";
		data.lastName = userModel.lastName() || "[Last Name]";
		data.username = userModel.username() || "";
		data.isEnabled = true; //userModel.isEnabled() === 1 ? true : false;
	    }
	    
	    return data;
	},
	
	_render: function() {
	},
	
	_show: function() {
	},
	
	_hide: function() {
	},
	
	_remove: function() {
	}
	
    });
    
})(jQuery);
