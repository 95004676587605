$(document).ready(function() {
    
    Dropzone.autoDiscover = false; // disable autodiscover for all dropzone elements by default

    videojs.options.flash.swf = './swf/video-js.swf';

    EmPower.Util.AjaxSetup.initialize();

    EmPower.app = new EmPower.Route.Router('', {
	container: $('#empower-content-container')
    });
    
    Synexxus.Auth.checkSession().always(function() {
	Backbone.history.start();
    });
});
