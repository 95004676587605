EmPower.Util.UniqueCollection = (function($, undefined) {

    var UniqueCollection = function() {
	this._collection = [];
    };
    
    UniqueCollection.prototype.comparator = function(comparator) {
	
	if (comparator) {
	    this._comparator = comparator;
	}
	
	return this._comparator;
    };
    
    UniqueCollection.prototype.beforeDelete = function(beforeDelete) {
	
	if (beforeDelete) {
	    this._beforeDelete = beforeDelete;
	}
	
	return this._beforeDelete;
    };
    
    UniqueCollection.prototype.getCollection = function() {
	return this._collection.slice();
    };
    
    UniqueCollection.prototype.find = function(object, remove) {
	
	var self = this;
	
	var args = arguments;
	
	var found;
	_.forEach(self._collection.slice().reverse(), function(element, index, list) {
	    
	    if (_.isFunction(self._comparator) ? self._comparator(element, object) : element === object) {
		
		found = element;
		
		if (remove) {
		    
		    var canDelete = true;
		    if (_.isFunction(self._beforeDelete)) {
			
			args = Array.prototype.slice.call(args, 2);
			args.unshift(found);
			
			canDelete = self._beforeDelete.apply(this, args);
		    }
		    
		    if (canDelete) {
			self._collection.splice(list.length - 1 - index, 1);
		    }
		}
	    }
	    
	});
	
	return found;
    };
    
    UniqueCollection.prototype.add = function(object) {

	if (!this.find(object)) {
	    this._collection.push(object);
	}
    };
    
    UniqueCollection.prototype.remove = function(object) {
	
	var args = Array.prototype.slice.call(arguments, 1);
	args.unshift(true);
	args.unshift(object);
	
	this.find.apply(this, args);
    };
    
    UniqueCollection.prototype.isEmpty = function() {
	
	return this._collection.length === 0;
    };
    
    UniqueCollection.prototype.length = function() {
	
	return this._collection.length;
    };
    
    return UniqueCollection;
    
})(jQuery);