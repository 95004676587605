EmPower.Model.User = (function($, undefined) {
    
    return EmPower.Model.AbstractUserInformation.extend({
	
	idAttribute: 'userId',
	    
	modelName: 'User',
	
	urlRoot: EmPower.Util.Url.apiBuilder('user'),

	_initialize: function() {
	},

	_parse: function(response, options) {

	    return response;
	},
	
	_userInformationRoot: 'userInformation',
	
	userId: function(value) {
	    return this._field('userId', value);
	},

	username: function(value) {
	    return this._field('username', value);
	},
	
	roleName: function(value) {
	    return this._field('roleName', value);
	},

	password: function(value) {
	    return this._field('password', value);
	},

	oldPassword: function(value) {
	    return this._field('oldPassword', value);
	},

	isEnabled: function(value) {
	    return this._field('enabled', value);
	},
        
        isHidden: function(value) {
	    return this._field('hidden', value);
	},

	getCanChangePermission: function() {
	    return this._field('canChangePermission');
	},

	getCanDisable: function() {
	    return this._field('canDisable');
	},

	getCanEdit: function() {
	    return this._subModel('canEdit');
	}
    });
    
})(jQuery);
