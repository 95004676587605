/* globals self, global, define, require, exports */

/**
 * Define the Synexxus Common Authentication Module
 *
 * Pattern modeled after backbone.js to allow for inclusion in as wide a variety of 
 * projects as possible
 *
 * Requiring only jQuery for authentication if possble
 */
(function(factory) {
    // Establish the root object, `window` (`self`) in the browser, or `global` on the server.
    // We use `self` instead of `window` for `WebWorker` support.
    var root = (typeof self === 'object' && self.self === self && self) ||
	      (typeof global === 'object' && global.global === global && global);

    // Set up Synexxus appropriately for the environment. Start with AMD.
    if (typeof define === 'function' && define.amd) {
	define(['jquery', 'exports'], function($, exports) {
	    // Export global even in AMD case in case this script is loaded with
	    // others that may still expect a global Backbone.
	    root.Synexxus = factory(root, exports, $);
	});

    // Next for Node.js or CommonJS. jQuery may not be needed as a module.
    } else if (typeof exports !== 'undefined') {
	
	var $;
	try {
            $ = require('jquery');
        } catch (e) {
            // empty catch
        }
        
	factory(root, exports, $);

    // Finally, as a browser global.
    } else {
	
	root.Synexxus = factory(root, {}, (root.jQuery || root.Zepto || root.ender || root.$));
    }
})

(function(root, Synexxus, $) {

    //Save previous version
    var previousSynexxus = root.Synexxus;

    //set version number
    Synexxus.version = '1.0';

    //set local jquery version
    Synexxus.$ = $;

    //return Synexxus to previous version and return a reference to this one.
    Synexxus.noConflict = function() {
        root.Synexxus = previousSynexxus;
        return this;
    };

    var api_version = 1;

    Synexxus.Auth = {
        uris: {
            auth: ['./auth/', api_version, '/session'].join(''),

            /** Access points other than auth/login MUST require authentication **/
            /* Account creation endpoint */
            account: ['./auth/', api_version, '/account'].join(''),

            /* Password validation endpoint */
            password: ['./auth/', api_version, '/pwd'].join('')
        },
    
        login: function(username, password) {

            var ret = Synexxus.$.Deferred();

            var apiCall = Synexxus.$.ajax(Synexxus.Auth.uris.auth, {
                data: JSON.stringify({
                      username: username,
                      password: password
                }),
                method: "POST",
                dataType: 'json',
                contentType: "application/json; charset=utf-8"
            });

            apiCall.done(function(data, txtStatus, jqXHR) {

                Synexxus.Auth.getCurrentUser().done(function(jqXHR, txt2Status, jq2XHR) {

                    Synexxus.Auth.session = jqXHR.data;

                    ret.resolve({
                        data: jqXHR.data,
                        status: txt2Status,
                        jqXHR: jqXHR
                    });
                }).fail(function(jqXHR, txtStatus, err) {
                    ret.reject({
                        jqXHR: jqXHR,
                        status: txtStatus,
                        error: err
                    });
                });
            });

            apiCall.fail(function(jqXHR, txtStatus, err) {

                ret.reject({
                    jqXHR: jqXHR,
                    status: txtStatus,
                    error: err
                });
            }); 

            return ret.promise();
        },

        logout: function() {

            var ret = Synexxus.$.Deferred();

            var apiCall = Synexxus.$.ajax(Synexxus.Auth.uris.auth, {
                method: "DELETE",
                dataType: 'json'
            });

            apiCall.done(function(data, status, jqXHR) {
                
                Synexxus.Auth.session = null;
                
                ret.resolve({
                    data: data,
                    status: status,
                    jqXHR: jqXHR
                });
            });

            apiCall.fail(function(jqXHR, status, error) {

                if (status === 401) {
                    Synexxus.Auth.session = null;
                }

                ret.reject({
                    jqXHR: jqXHR,
                    status: status,
                    error: error
                });
            });
            
            return ret.promise();
        },

        getCurrentUser: function() {

            var ret = Synexxus.$.Deferred();

            var apiCall = Synexxus.$.ajax(Synexxus.Auth.uris.auth, {        
                method: "GET",
                dataType: 'json'
            });

            apiCall.done(function(data,txtStatus,jqXHR) {

                ret.resolve({
                    data: data,
                    status: txtStatus,
                    jqXHR: jqXHR
                });
            });

            apiCall.fail(function(jqXHR, txtStatus, err) {

                ret.reject({
                    jqXHR: jqXHR,
                    status: txtStatus,
                    error: err
                });
            });

            return ret.promise();
        },

        createAccount: function(dataObj) {

            var ret = Synexxus.$.Deferred();

            var validated = this._validateAccountPayload(dataObj);

            validated.done(function() {
                
                var apiCall = Synexxus.$.ajax(Synexxus.Auth.uris.account, {        
                    method: "POST",
                    data: JSON.stringify(dataObj),
                    contentType: "application/json; charset=utf-8",
                    dataType: 'json'
                });
                
                apiCall.done(function(data,txtStatus,jqXHR) {
                    
                    ret.resolve({
                        data: data,
                        status: txtStatus,
                        jqXHR: jqXHR
                    });
                });
                
                apiCall.fail(function(jqXHR, txtStatus, err) {
                    
                    ret.reject({
                        jqXHR: jqXHR,
                        status: txtStatus,
                        error: err
                    });
                });
            }); 

            validated.fail(function(){
                
                ret.reject({
                    jqXHR: null,
                    status: "Invalid input",
                    error: "Username and password are both required."
                });
            });

            return ret.promise();
        },
        
        _validateAccountPayload: function(dataObj) {
            
            var ret = Synexxus.$.Deferred();
            
            if(!dataObj || !dataObj.username || !dataObj.password) {
                ret.reject("Both username and password are required.");
            }
            ret.resolve();
            
            return ret.promise();
        },
        
        deactivateAccount: function(accountId) {
            
            var ret = Synexxus.$.Deferred();
            var url = [Synexxus.Auth.uris.auth, accountId].join("/");

            var apiCall = Synexxus.$.ajax(url, {        
                method: "DELETE",
                dataType: 'json'
            });
            
            apiCall.done(function(data, txtStatus, jqXHR) {
                
                ret.resolve({
                    data: data,
                    status: txtStatus,
                    jqXHR: jqXHR
                });
            });
            
            apiCall.fail(function(jqXHR, txtStatus, err) {

                ret.reject({
                    jqXHR: jqXHR,
                    status: txtStatus,
                    error: err
                });
            }); 
            return ret.promise();
        },
        
        accountExists: function(accountId) {
            
            var ret = Synexxus.$.Deferred();
            var url = [Synexxus.Auth.uris.auth, accountId, 'exists'].join("/");

            var apiCall = Synexxus.$.ajax(url, {        
                method: "GET",
                dataType: 'json'
            });
            
            apiCall.done(function(data,txtStatus,jqXHR){
                
                ret.resolve({
                    data: data,
                    status: txtStatus,
                    jqXHR: jqXHR
                });
            });
            
            apiCall.fail(function(jqXHR, txtStatus, err) {

                ret.reject({
                    jqXHR: jqXHR,
                    status: txtStatus,
                    error: err
                });
            });
            
            return ret.promise();
        },

        checkSession: function() {

            var apiCall = Synexxus.Auth.getCurrentUser();

            apiCall.done(function(data) {
                Synexxus.Auth.session = data;
            });

            return apiCall;
        },

        assureSession: function(callback) {

            if(!Synexxus.Auth.session || Synexxus.$.isEmptyObject(Synexxus.Auth.session)) {

                if(Synexxus.$.isFunction(callback)) {
                    callback.call();
                }

                return false;
            }

            return true;
        },

        session: null
    };  

    return Synexxus; 
});
