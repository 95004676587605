EmPower.View.PressureList = (function($, undefined) {

    return EmPower.View.AbstractCollectionView.extend({
	viewName: "Pressure List",
	
	_events: function() {
	},
	
	_initialize: function() {
	    this._applicationRouter = this._session.applicationRouter();
	},
	
	_setupListeners: function() {
	},
	
	_htmlTemplate: function() {
	    return EmPower.Templates['transformer/pressure/pressure_list'];
	},
	
	_htmlData: function() {
	},
	
	_getCollectionContainer: function($html) {
	    return $html.filter("div[data-section='pressure-list']");
	},
	
	_createModelView: function(model) {
	    return new EmPower.View.PressureRow({
		session: this._session,
		pressureModel: model
	    });
	},
	
	_getLoadingCollectionMessage: function() {
	    return "Loading pressures, please wait...";
	},
	
	_getEmptyCollectionMessage: function() {
	    return "Sorry, no pressures have been configured.";
	},
	
	_render: function() {
	},
	
	_show: function() {
	},
	
	_hide: function() {
	},
	
	_remove: function() {
	}
    });
    
})(jQuery);
