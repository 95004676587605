(function (Transformer, $, undefined) {

    var that = {};

    that.presetHtml = [
        '<form onsubmit="return false;">',
        '  <div data-action="errorMessage"></div>',
        '  <div class="form-group">',
        '    <label>Preset Name</label>',
        '    <input required name="presetName" class="form-control" id="name-input" placeholder="Enter a preset name" value="<%-presetName%>"/>',
        '  </div>',
        '</form>'
    ];

    that.presetDialog = function (session, transformerId, dialog, deviceId, presetNumber, presetName, actionType) {

        if (actionType === 'edit') {
            dialog = dialog || new BootstrapDialog({
                title: 'Update Existing Preset'
            });
        } else if (actionType === 'create') {
            dialog = dialog || new BootstrapDialog({
                title: 'Create New Preset'
            });
        }

        if (!that.presetHtmlCompiled) {
            that.presetHtmlCompiled = _.template(that.presetHtml.join(''));
        }

        var $html = $(that.presetHtmlCompiled({
            presetName: presetName
        }));

        dialog.setMessage($html);

        var onCancel = function (dialog) {
            dialog.close();
        };

        var cancelButton = {
            label: 'Cancel',
            cssClass: 'btn-default left',
            action: onCancel
        };

        var onSubmit = function (dialog) {
            var inputtedName = $('#name-input').val();
            var url = EmPower.Util.Url.apiBuilder("transformers", transformerId, "camera", deviceId, "create-update-preset", presetNumber, "/" + inputtedName);
            $.ajax({
                url: url,
                type: "POST",
                success: function (data) {
                    console.log("success");
                },
                error: function (err) {
                    console.log(err);
                }
            });

            dialog.close();
        };

        var submitButton = {
            label: 'Submit',
            cssClass: 'btn-default right',
            action: onSubmit
        };

        dialog.setButtons([cancelButton, submitButton]);

        return dialog.open();
    };

    Transformer.updateCreatePreset = function (session, transformerId, deviceId, presetNumber, presetName, actionType) {

        if (transformerId !== undefined && transformerId !== null) {

            that.presetDialog(session, transformerId, null, deviceId, presetNumber, presetName || '', actionType);

        }
    };







    that.deletePresetHtml = [
        '<form onsubmit="return false;">',
        '  <div data-action="errorMessage"></div>',
        '  <div class="form-group">',
        '    <label>Are you sure you want to delete this preset?</label>',
        '    <p><%-presetName%></p>',
        '  </div>',
        '</form>'
    ];

    that.deletePresetDialog = function (session, transformerId, dialog, deviceId, presetNumber, presetName) {

        dialog = dialog || new BootstrapDialog({
            title: 'Delete Preset',
            cssClass: 'deletePresetDialog'
        });

        if (!that.deletePresetHtmlCompiled) {
            that.deletePresetHtmlCompiled = _.template(that.deletePresetHtml.join(''));
        }

        var $html = $(that.deletePresetHtmlCompiled({
            presetName: presetName
        }));

        dialog.setMessage($html);

        var onCancel = function (dialog) {
            dialog.close();
        };

        var cancelButton = {
            label: 'Cancel',
            cssClass: 'btn-default left',
            action: onCancel
        };

        var onSubmit = function (dialog) {
            var url = EmPower.Util.Url.apiBuilder("transformers", transformerId, "camera", deviceId, "delete-preset", presetNumber);
            $.ajax({
                url: url,
                type: "POST",
                success: function (data) {
                    console.log("success");
                },
                error: function (err) {
                    console.log(err);
                }
            });

            dialog.close();
        };

        var submitButton = {
            label: 'Delete',
            cssClass: 'btn-default btn-danger right',
            action: onSubmit
        };

        dialog.setButtons([cancelButton, submitButton]);

        return dialog.open();
    };

    Transformer.deletePreset = function (session, transformerId, deviceId, presetNumber, presetName) {

        if (transformerId !== undefined && transformerId !== null) {

            that.deletePresetDialog(session, transformerId, null, deviceId, presetNumber, presetName);

        }
    };

})(EmPower.Dialog.Preset = EmPower.Dialog.Preset || {}, jQuery);