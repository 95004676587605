EmPower.Model.Opm = (function($, undefined) {
    
    return EmPower.Model.AbstractModel.extend({
        
        idAttribute: 'id',
        modelName: 'Opm',
        
        _initialize: function() {
            
        },
        
        _parse: function() {
        }
    });

})(jQuery);