EmPower.Model.Ltc = (function($, undefined) {
    
    return EmPower.Model.AbstractDeviceEventModel.extend({
	    
	modelName: 'LTC',

	_initialize: function() {
	},

	_parse: function(response, options) {
	    return response;
	},
	
	value: function(value) {
	    return this._field('value', value);
	},
	
	valueDisplay: function(value) {
	    return (this.value() !== undefined && this.value() !== null) ? this.value(): "No Current Position";
	},
	
	totalPosChanges: function(value) {
	    return this._field('totalPosChanges', value);
	},
	
	totalPosChangesDisplay: function(value) {
	    return this.totalPosChanges() ? this.totalPosChanges(): "0";
	},
	
	currentDraw: function(value) {
	    return this._field('currentDraw', value);
	},
	
	currentDrawDisplay: function(value) {
	    return (this.currentDraw() !== undefined && this.currentDraw() !== null) ? this.currentDraw() + " mA" : "No Current";
	},
        
        hasTapPosition: function(value) {
            return this._field('hasTapPosition', value);
        }
    });
    
})(jQuery);
