EmPower.View.TransformerInformationTable = (function ($, undefined) {

    return EmPower.View.AbstractView.extend({
        viewName: "Transformer Information Table",

        _events: function () {
        },

        _initialize: function () {
            this._selectedTransformer = this._session.transformerSession().selectedTransformer();
            this._coolingModeCollection = this._session.transformerSession().coolingModeCollection();
            this._dynamicLoadingCollection = this._session.transformerSession().dynamicLoadingCollection();
            this._pastConnectionCollection = this._session.transformerSession().pastConnectionCollection();
            this._transformerModel(this._selectedTransformer.selected());
            this._coolingLatestCollection = new EmPower.Collection.CoolingLatest(null, {
                session: this._session,
                selectedObject: this._selectedTransformer
            });
        },

        _setupListeners: function () {

            var self = this;

            self.listenTo(self._selectedTransformer, 'change', function () {
                self._transformerModel(self._selectedTransformer.selected());
                self.renderDebounced();
            });

            self.listenTo(self._coolingModeCollection, 'update', function () {
                self.renderDebounced();
            });

            self.listenTo(self._dynamicLoadingCollection, 'update', function () {
                self.renderDebounced();
            });

            self.listenTo(self._pastConnectionCollection, 'update', function () {
                self.renderDebounced();
            });

            self.listenTo(self._coolingLatestCollection, 'update', function () {
                self.renderDebounced();
            });
        },

        _transformerModel: function (selectedTransformerModel) {

            var self = this;

            if (selectedTransformerModel !== undefined) {

                if (self._selectedTransformerModel !== selectedTransformerModel) {

                    if (self._selectedTransformerModel) {
                        self.stopListening(self._selectedTransformerModel);
                    }

                    if (selectedTransformerModel) {
                        self.listenTo(selectedTransformerModel, 'change', function () {
                            self.renderDebounced();
                        });
                    }
                }

                self._selectedTransformerModel = selectedTransformerModel;
            }

            return self._selectedTransformerModel;
        },

        _htmlTemplate: function () {
            return EmPower.Templates['transformer/transformer-management/transformer_information_table'];
        },

        _htmlData: function () {

            var self = this;

            var transformerModel = self._transformerModel();
            var data = {
                transformerModel: transformerModel
            };

            var coolingModes = function () {

                var coolingModes = "";
                
                if (self._coolingLatestCollection.length) {
                    self._coolingLatestCollection.models.forEach(function (model) {
                        if (coolingModes) {
                            coolingModes += ", ";
                        }
                        coolingModes += model.attributes.coolingType;
                    });
                }

                return coolingModes;
            };

            var dynamicLoadingLoad = function () {

                var load = "";
                if (self._dynamicLoadingCollection.length) {

                    _.forEach(self._dynamicLoadingCollection.models, function (model) {
                        if (load) {
                            load += ", ";
                        }
                        if (model.load() !== null) {
                            load += model.load();
                        }
                    });

                    if (load) {
                        load += " PU";
                    }
                }

                return load;
            };
            var dynamicLoadingTot = function () {

                var tot = "";
                if (self._dynamicLoadingCollection.length) {

                    _.forEach(self._dynamicLoadingCollection.models, function (model) {
                        if (tot) {
                            tot += ", ";
                        }
                        if (model.topOilTempInit() !== null) {
                            tot += model.topOilTempInit();
                        }
                    });

                    if (tot) {
                        tot += " &#8451;";
                    }
                }

                return tot;
            };
            var dynamicLoadingHs = function () {

                var hs = "";
                if (self._dynamicLoadingCollection.length) {

                    _.forEach(self._dynamicLoadingCollection.models, function (model) {
                        if (hs) {
                            hs += ", ";
                        }
                        if (model.windingHsTempInit() !== null) {
                            hs += model.windingHsTempInit();
                        }
                    });

                    if (hs) {
                        hs += " &#8451;";
                    }
                }

                return hs;
            };
            var timeToOverTemp = function () {

                var overTemp = "";
                if (self._dynamicLoadingCollection.length) {

                    _.forEach(self._dynamicLoadingCollection.models, function (model) {
                        if (overTemp) {
                            overTemp += ", ";
                        }
                        if (model.timeOverTempDisplay() !== null) {
                            overTemp += model.timeOverTempDisplay();
                        }
                    });

                    if (overTemp) {
                        overTemp += " minutes";
                    }
                }

                return overTemp;
            };
            var lossOfLife = function () {

                var lossOfLife = "";
                if (self._dynamicLoadingCollection.length) {

                    _.forEach(self._dynamicLoadingCollection.models, function (model) {
                        if (lossOfLife) {
                            lossOfLife += ", ";
                        }
                        if (model.timeOverTempDisplay() !== null) {
                            lossOfLife += model.lifeLoss();
                        }
                    });

                    if (lossOfLife) {
                        lossOfLife += " percent";
                    }
                }

                return lossOfLife;
            };
            
            var insulationType = function() {
                var insulationType = "Unknown";
                if (self._dynamicLoadingCollection.length && self._dynamicLoadingCollection.models[0].insulationType()) {
                    insulationType = self._dynamicLoadingCollection.models[0].insulationType().toString();
                }
                return insulationType;
            };

            var pastStartConnTime = function () {
                var pastConnTime = "";
                if (self._pastConnectionCollection.length) {
                    _.forEach(self._pastConnectionCollection.models, function (model) {
                        if (pastConnTime) {
                            pastConnTime += ", ";
                        }
                        if (model.connectionStartTime() !== null) {
                            pastConnTime += model.connectionStartTime();
                        }
                    });
                }

                return pastConnTime;
            };

            var pastEndConnTime = function () {
                var pastConnTime = "";
                if (self._pastConnectionCollection.length) {
                    _.forEach(self._pastConnectionCollection.models, function (model) {
                        if (pastConnTime) {
                            pastConnTime += ", ";
                        }
                        if (model.connectionEndTime() !== null) {
                            pastConnTime += model.connectionEndTime();
                        }
                    });
                }

                return pastConnTime;
            };

            var pastEventsNotSynced = function () {
                var events = "";
                if (self._pastConnectionCollection.length) {
                    _.forEach(self._pastConnectionCollection.models, function (model) {
                        if (events) {
                            events += ", ";
                        }
                        if (model.eventsNotSynced() !== null) {
                            events += model.eventsNotSynced();
                        }
                    });
                }

                return events;
            };

            var pastEventsSynced = function () {
                var events = "";
                if (self._pastConnectionCollection.length) {
                    _.forEach(self._pastConnectionCollection.models, function (model) {
                        if (events) {
                            events += ", ";
                        }
                        if (model.eventsSynced() !== null) {
                            events += model.eventsSynced();
                        }
                    });
                }

                return events;
            };


            // Split data into Transformer Information, Connection Information, Loading Information.

            if (transformerModel) {

                data.tables = {
                    transformerInformation: {
                        title: "Transformer Information",
                        rows: [
                            {key: "Name", value: transformerModel.transformerName() || "[No Data]"},
                            {key: "ID", value: transformerModel.transformerId() || "[No Data]"},
                            {key: "Serial Number", value: transformerModel.serialNumber() || "[No Data]"},
                            {key: "Transformer Type", value: transformerModel.type() || "[No Data]"},
                            {key: "Name Plate Rating", value: transformerModel.namePlateRating() + " kVA" || "[No Data]"},
                            {key: "Insulation Type", value: insulationType() || "[No Data]"},
                            {key: "Cooling Modes", value: coolingModes() || "[No Data]"},
                            {key: "Substation Name", value: transformerModel.substationName() || "[No Data]"},
                            {key: "Install Date", value: transformerModel.installDate() || "[No Data]"},
                            {key: "Location", value: transformerModel.locationDisplay("[No Data]")}
                        ]
                    },

                    loadingInformation: {
                        title: "Loading Information",
                        rows: [
                            {key: "Load", value: parseFloat(dynamicLoadingLoad()).toFixed(1) + " PU" || "[No Data]"},
                            {key: "Time to Over Temperature", value: timeToOverTemp() || "[No Data]"},
                            {key: "Loss of Life", value: parseFloat(lossOfLife()).toFixed(2) || "[No Data]"},
                            {key: "Top Oil Temperature", value: dynamicLoadingTot() || "[No Data]"},
                            {key: "Winding Hotspot Temperature", value: dynamicLoadingHs() || "[No Data]"}
                        ]
                    },

                    connectionInformation: {
                        title: "Connection Information",
                        rows: [
                            {key: "Active Connection", value: transformerModel.connectedDisplay("[No Data]")},
                            {key: "Last Sync", value: transformerModel.lastCommsTimeDisplay("[No Data]")},
                            {key: "Date Connected", value: transformerModel.dateConnectedDisplay("[No Data]")},
                            {key: "Connection Duration", value: transformerModel.connectionDuration("[No Data]")},
                            {key: "Events Synced", value: transformerModel.eventsSyncedDisplay("[No Data]")},
                            {key: "Events Not Synced", value: transformerModel.eventsNotSyncedDisplay("[No Data]")}
                        ]
                    },

                    prevConnectionInformation: {
                        title: "Past Connection Information",
                        rows: [
                            {key: "Connection Start Time", value: pastStartConnTime() || "[No Data]"},
                            {key: "Connection End Time", value: pastEndConnTime() || "[No Data]"},
                            {key: "Events Synced", value: pastEventsSynced() || "[No Data]"},
                            {key: "Events Not Synced", value: pastEventsNotSynced() || "[No Data]"}
                        ]
                    }

                };
            }

            return data;
        },

        _render: function ($html) {
            if (!this._coolingModeCollection.isFetched()) {
                this._coolingModeCollection.fetch();
            }
        },

        _show: function () {
        },

        _hide: function () {
        },

        _remove: function () {
        }
    });

})(jQuery);
