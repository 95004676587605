EmPower.View.Battery = (function ($, undefined) {

    return EmPower.View.AbstractView.extend({
        viewName: "Transformer Battery",

        _events: function () {
            var self = this;
            return {
                "click [data-action=acknowledge-sticky]": function (event) {
                    event.preventDefault();
                    var id = event.currentTarget.dataset.annuncid;
                    self.acknowledgeAnnunciator(id);
                }
            };
        },

        _initialize: function () {
            this._selectedTransformer = this._session.transformerSession().selectedTransformer();
            this._transformerCollection = this._session.transformerSession().transformerCollection();
            this._batteryCollection = new EmPower.Collection.BatteryEvents(null, {
                session: this._session,
                selectedObject: this._selectedTransformer,
                dateRange: this._session.dateRange()
            });
        },

        _setupListeners: function () {
            var self = this;

            self.listenTo(self._batteryCollection, 'update reset', function () {
                this.renderDebounced();
            });
            self.listenTo(self._session, 'reset', function () {
                this._batteryCollection.reset();
            });
        },

        _htmlTemplate: function () {
            return EmPower.Templates['transformer/battery/transformer_battery'];
        },

        acknowledgeAnnunciator: function (annunciatorId) {
            var url = EmPower.Util.Url.apiBuilder("transformers", this._selectedTransformer.selectedId(), "annunciator", annunciatorId, "acknowledge");
            $.ajax({
                type: "POST",
                url: url,
                success: function () {
                    $.notify({
                        message: "<strong>Success:</strong>  Annunciator " + annunciatorId + " acknowledged. Status will update pending RCTU response."
                    }, {
                        type: 'success',
                        placement: {
                            from: 'top',
                            align: 'center'
                        }
                    });
                },
                error: function (err) {
                    $.notify({
                        message: "<strong>Failure:</strong> Could not acknowledge annunciator " + annunciatorId
                    }, {
                        type: 'danger',
                        placement: {
                            from: 'top',
                            align: 'center'
                        }
                    });
                }
            });

        },

        _htmlData: function () {
            
            var batteryLatest = null;
            if (this._batteryCollection && this._batteryCollection.models.length) {
                batteryLatest = this._batteryCollection.models[0].attributes;
            }
            
            console.log(batteryLatest);
            
            
            return {
                transformerId: this._selectedTransformer.selectedId(),
                battery: batteryLatest
            };
        },

        _render: function ($html) {

            var url = EmPower.Util.Url.apiBuilder("transformers", this._selectedTransformer.selectedId(), "battery");
            $.ajax({
                url: url,
                type: 'GET',
                success: function (data) {
                    data = data.results[0];

                    if (data) {
                        data.chargeMode = data.chargeMode ? data.chargeMode : 'No data';
                        var $chargeMode = $html.find("span[data-section='chmode']");
                        $chargeMode.text(data.chargeMode);

                        data.chargeVoltage = data.chargeVoltage ? data.chargeVoltage.toFixed(1) + " V" : 'No data';
                        var $chargeVoltage = $html.find("span[data-section='chvolt']");
                        $chargeVoltage.text(data.chargeVoltage);

                        data.chargeCurrent = data.chargeCurrent ? data.chargeCurrent.toFixed(1) + " A" : 'No data';
                        var $chargeCurrent = $html.find("span[data-section='chcur']");
                        $chargeCurrent.text(data.chargeCurrent);

                        data.batteryTemp = data.batteryTemp ? data.batteryTemp.toFixed(1) + " C" : 'No data';
                        var $batteryTemp = $html.find("span[data-section='battemp']");
                        $batteryTemp.text(data.batteryTemp);

                        data.compartmentTemp = data.compartmentTemp ? data.compartmentTemp.toFixed(1) + " C" : 'No data';
                        var $compartmentTemp = $html.find("span[data-section='comptemp']");
                        $compartmentTemp.text(data.compartmentTemp);
                    }

                },
                error: function () {

                }
            });

            var annuncUrl = EmPower.Util.Url.apiBuilder("transformers", this._selectedTransformer.selectedId(), "/annunciator/battery");
            var $annunciatorList = $html.find("#annunciator-list");

            $.ajax({
                type: "GET",
                url: annuncUrl,
                success: function (data) {
                    $annunciatorList.empty();

                    data.forEach(function (annunciator) {

                        var timestamp = annunciator.timestamp ? annunciator.timestamp : 'No Date';
                        var stateClass;
                        switch (annunciator.value) {
                            case 2: // annunciator needs acknowledged, flashing orange background
                                stateClass = "label-warning flashing acknowledgeable";
                                break;
                            case 1: // annunciator is firing, orange background
                                stateClass = "label-warning not-acknowledgeable";
                                break;
                            case 0: // annunciator is not firing, gray background
                                stateClass = "label-default not-acknowledgeable";
                                break;
                            default: // no events, grey background with grey text
                                stateClass = "label-default label-disabled not-acknowledgeable";
                                break;
                        }

                        $annunciatorList.append("<div class='annunciator-card " + stateClass + "' data-annuncId='" + annunciator.transformerAnnunciatorId + "' data-action='acknowledge-sticky'><p>" + annunciator.description + "</p><span>" + timestamp + "</div>");
                    });

                },
                error: function (err) {
                    console.log(err);
                }
            });



        },

        _show: function () {
//	    if (this._annunciatorList) {
//		this._annunciatorList.show();
//	    }
        },

        _hide: function () {
//	    if (this._annunciatorList) {
//		this._annunciatorList.hide();
//	    }
        },

        _remove: function () {
//	    if (this._annunciatorList) {
//		this._annunciatorList.remove();
//	    }
        }
    });

})(jQuery);



