EmPower.View.TransformerMenu = (function ($, undefined) {

    return EmPower.View.AbstractView.extend({
        viewName: "Transformer Menu",

        _events: function () {
            return {
                'click div.toggle-menu > a': function (event) {
                    this.toggleMenu(event);
                },
                "click #transformer-menu a": function (event) {

                    if (event) {

                        event.preventDefault();

                        var dataAction = $(event.currentTarget).attr('data-action');

                        switch (dataAction) {
                            case this._transformerRouter.paths.health():
                                this._transformerRouter.showHealth();
                                break;
                            case this._transformerRouter.paths.dynamicLoading():
                                this._transformerRouter.showDynamicLoading();
                                break;
                            case this._transformerRouter.paths.bushing():
                                this._transformerRouter.showBushing();
                                break;
                            case this._transformerRouter.paths.temperature():
                                this._transformerRouter.showTemperature();
                                break;
                            case this._transformerRouter.paths.pressure():
                                this._transformerRouter.showPressure();
                                break;
                            case this._transformerRouter.paths.powerMetering():
                                this._transformerRouter.showPowerMetering();
                                break;
                            case this._transformerRouter.paths.annunciator():
                                this._transformerRouter.showAnnunciator();
                                break;
                            case this._transformerRouter.paths.cooling():
                                this._transformerRouter.showCooling();
                                break;
                            case this._transformerRouter.paths.ltc():
                                this._transformerRouter.showLtc();
                                break;
                            case this._transformerRouter.paths.dga():
                                this._transformerRouter.showDga();
                                break;
                            case this._transformerRouter.paths.moisture():
                                this._transformerRouter.showMoisture();
                                break;
                            case this._transformerRouter.paths.documents():
                                this._transformerRouter.showDocuments();
                                break;
                            case this._transformerRouter.paths.security():
                                this._transformerRouter.showSecurity();
                                break;
                            case this._transformerRouter.paths.security():
                                this._transformerRouter.showSecurity();
                                break;
                            case this._transformerRouter.paths.video():
                                this._transformerRouter.showVideo();
                                break;
                            case this._transformerRouter.paths.users():
                                this._transformerRouter.showUsers();
                                break;
                            case this._transformerRouter.paths.rctu():
                                this._transformerRouter.showRctu();
                                break;
                            case this._transformerRouter.paths.rctuUpdate():
                                this._transformerRouter.showRctuUpdate();
                                break;
                            case this._transformerRouter.paths.opm():
                                this._transformerRouter.showOpm();
                                break;
                            case this._transformerRouter.paths.recorded():
                                this._transformerRouter.showRecorded();
                                break;
                            case this._transformerRouter.paths.battery():
                                this._transformerRouter.showBattery();
                                break;
                            case this._transformerRouter.paths.systemHealth():
                                this._transformerRouter.showSystemHealth();
                                break;
                            case this._transformerRouter.paths.metrology():
                                this._transformerRouter.showMetrology();
                                break;
                            case this._transformerRouter.paths.firmware():
                                this._transformerRouter.showFirmware();
                                break;
                            default:
                                this._transformerRouter.showPageNotFound();
                                break;
                        }
                    }
                }
            };
        },

        _initialize: function () {

            this._transformerRouter = this._session.applicationRouter().getTransformersModule();

            this._selectedTransformerSubview = this._session.transformerSession().selectedTransformerSubview();

            this._selectedTransformer = this._session.transformerSession().selectedTransformer();

            this._$previousSelection = null;

        },

        _setupListeners: function () {

            this.listenTo(this._selectedTransformerSubview, 'change', function () {
                // need to change to render debounced...
                this._renderSelected();
            });

            this.listenTo(this._selectedTransformer, 'change', function () {
                if (this._selectedTransformer.getPreviouslySelected() !== undefined) {
                    this.renderDebounced();
                }
            });
        },

        _htmlTemplate: function () {
            return EmPower.Templates['transformer/menu/transformer_menu'];
        },

        _htmlData: function () {

            var data = {
                health: this._transformerRouter.paths.health(),
                dynamicLoading: this._transformerRouter.paths.dynamicLoading(),
                bushing: this._transformerRouter.paths.bushing(),
                temperature: this._transformerRouter.paths.temperature(),
                pressure: this._transformerRouter.paths.pressure(),
                powerMetering: this._transformerRouter.paths.powerMetering(),
                annunciator: this._transformerRouter.paths.annunciator(),
                cooling: this._transformerRouter.paths.cooling(),
                ltc: this._transformerRouter.paths.ltc(),
                dga: this._transformerRouter.paths.dga(),
                moisture: this._transformerRouter.paths.moisture(),
                documents: this._transformerRouter.paths.documents(),
                security: this._transformerRouter.paths.security(),
                video: this._transformerRouter.paths.video(),
                users: this._transformerRouter.paths.users(),
                rctu: this._transformerRouter.paths.rctu(),
                rctuUpdate: this._transformerRouter.paths.rctuUpdate(),
                opm: this._transformerRouter.paths.opm(),
                recorded: this._transformerRouter.paths.recorded(),
                battery: this._transformerRouter.paths.battery(),
                systemHealth: this._transformerRouter.paths.systemHealth(),
                metrology: this._transformerRouter.paths.metrology(),
                firmware: this._transformerRouter.paths.firmware()
            };

            return data;
        },

        _render: function () {
            this._renderSelected();

            var url = EmPower.Util.Url.apiBuilder("transformers", this._selectedTransformer.selectedId(), "dynamic-routes");

            if (this._selectedTransformer.selectedId() !== undefined && this._selectedTransformer.selectedId() !== null) {
                $.ajax({
                    url: url,
                    type: "GET",
                    success: function (data) {
                        if (data.Bushing === 0) {
                            $('#showBushing').css('display', 'none');
                        }
                        if (data.Temperature === 0) {
                            $('#showTemp').css('display', 'none');
                        }
                        if (data.Pressure === 0) {
                            $('#showPress').css('display', 'none');
                        }
                        if (data.Annunciator === 0) {
                            $('#showAnnunc').css('display', 'none');
                        }
                        if (data.Cooling === 0 && data.Fans === 0) {
                            $('#showCool').css('display', 'none');
                        }
                        if (data.LTC === 0) {
                            $('#showLTC').css('display', 'none');
                        }
                        if (data.DGA === 0) {
                            $('#showMoisture').css('display', 'none');
                        }
                        if (data.DGA === 0) {
                            $('#showDGA').css('display', 'none');
                        }
                        if (data.Battery === 0) {
                            $('#showBatt').css('display', 'none');
                        }
                        if (data.Camera === 0) {
                            $('#showCam').css('display', 'none');
                            $('#showRec').css('display', 'none');
                            $('#showFirm').css('display', 'none');
                        }
                        
                    },
                    error: function (err) {
                        console.log("Failed to retrieve dynamic routes");
                    }
                });
            }

        },

        _renderSelected: function () {

            var self = this;

            var $collapsePrevious;

            // scan up to parent ul ... if data-content=sub-menu ... expand
            // on remove... scan up to parent ul... if data-content=sub-menu ... collapse
            var $previous = self.$html.find('#transformer-menu').find('li.active');
            if ($previous.length > 0) {

                $previous.removeClass('active');
                var previousSelector = 'ul[data-sub-menu="true"]';
                var $previousContainer = $previous.closest(previousSelector);
                if ($previousContainer.length === 0) {
                    $previousContainer = $previous.children(previousSelector);
                }

                if ($previousContainer && $previousContainer.length > 0) {
                    $collapsePrevious = $previousContainer;
                }
            }

            var $container;
            if (self._selectedTransformerSubview.selected() && _.isFunction(self._selectedTransformerSubview.selected().path)) {

                var menuDataAction = self._selectedTransformerSubview.selected().path();

                if (menuDataAction) {

                    var $active = self.$html.find('#transformer-menu').find("a[data-action='" + menuDataAction + "']").closest("li");
                    if ($active && $active.length > 0) {

                        $active.addClass("active");

                        var selector = 'ul[data-sub-menu="true"]';
                        $container = $active.closest(selector);
                        if ($container.length === 0) {
                            $container = $active.children(selector);
                        }

                        if ($container && $container.length !== 0) {
                            setTimeout(function () {
                                $container.slideDown(500);
                            }, 1);
                            $container.attr("aria-expanded", "true");
                        }
                    }
                }
            }

            if ($collapsePrevious && !$collapsePrevious.is($container)) {
                setTimeout(function () {
                    $collapsePrevious.slideUp(500);
                }, 1);
                $collapsePrevious.attr("aria-expanded", "false");
            }
        },

        toggleMenu: function (event) {

            var $button = $(event.target).closest("a");

            if ($button.attr('data-action') === "collapse") {
                this.$el.addClass("menu-collapsed");
            } else {
                this.$el.removeClass("menu-collapsed");
            }

            setTimeout(function () {
                $(document).trigger("transformerViewResize");
            }, 250);
        },

        _show: function () {
        },

        _hide: function () {
        },

        _remove: function () {
        }
    });

})(jQuery);
