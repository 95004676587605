(function(Graph, $, undefined) {
    
    // private namespace
    var that = {};

    Graph.open = function(session, View, title) {
	
        var view = null;
        
        if (View) {
            view = new View({
                session: session
            });
        }
        
        var $html = $(EmPower.Templates['templates/dialog_graph_container']());
        $html.css('min-height', ($(window).height() * 0.7));
        $html.css('max-height', ($(window).height() * 0.8));
        
	var dialog = new BootstrapDialog({
	    title: 'Graph for  <strong>' + title + "</strong>",
            onshown: function() {
                if (view) {
                    view.render($html);
                }
            },
            onhidden: function() {
                if (view) {
                    view.remove();
                }
            },
            size: BootstrapDialog.SIZE_WIDE,
            draggable: true
	});
        
	dialog.setMessage($html);
	
	var onClose = function(dialog) {
	    dialog.close();
	};
	
	var cancelButton = {
	    label: 'Close',
	    cssClass: 'btn-default left',
	    action: onClose
	};
	
	dialog.setButtons([cancelButton]);
        
	return dialog.open();
    };

})(EmPower.Dialog.Graph = EmPower.Dialog.Graph || {}, jQuery);
