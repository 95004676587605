(function(SimpleGauge, $, undefined) {
    
    // private namespace
    var that = {};
    
    that.defaultOptions = {
        angle: 0,                       // The span of the gauge arc
        lineWidth: 0.2,                // The line thickness
        radiusScale: 0.9,              // Relative radius
        animationSpeed: 32,
        pointer: {
          length: 0.5,                  // Relative to gauge radius
          strokeWidth: 0.045,           // The thickness
          color: '#7c7c7c'              // Fill color
        },
        limitMax: true,                // If false, max value increases automatically if value > maxValue
        limitMin: true,                // If true, the min value of the gauge will be fixed
        generateGradient: true,
        highDpiSupport: true,           // High resolution support
        staticLabels: {
            color: "#7c7c7c",           // Optional: Label text color
            fractionDigits: 1           // Optional: Numerical precision. 0=round off.
        }
    };
    
    that.largeDefaultOptions = {
        staticLabels: {
            font: "14px sans-serif"     // Specifies font
        }
    };
    SimpleGauge.largeGauge = function($el, options) {
        
        if ($el && !($el instanceof jQuery)) {
            options = $el || {};
        } else {
            options = options || {};
        }
        
        options = _.merge({}, that.defaultOptions, that.largeDefaultOptions, options);
        
        var gauge = new Gauge($el.get(0));
        gauge.setOptions(options);
        gauge.maxValue = options.maxValue;
        gauge.setMinValue(options.minValue);
        gauge.animationSpeed = options.animationSpeed;
        
        return gauge;
    };
    
    that.smallDefaultOptions = {
        staticLabels: {
            font: "10px sans-serif"      // Specifies font
        }
    };
    SimpleGauge.smallGauge = function($el, options) {
        
        if ($el && !($el instanceof jQuery)) {
            options = $el || {};
        } else {
            options = options || {};
        }
        
        options = _.merge({}, that.defaultOptions, that.smallDefaultOptions, options);
        
        var gauge = new Gauge($el.get(0));
        gauge.setOptions(options);
        gauge.maxValue = options.maxValue;
        gauge.setMinValue(options.minValue);
        gauge.animationSpeed = options.animationSpeed;
        
        return gauge;
    };
    
    SimpleGauge.redraw = function(element) {
        var context = element.getContext('2d');
        context.save();
        context.setTransform(1, 0, 0, 1, 0, 0);
        context.clearRect(0, 0, context.canvas.width, context.canvas.height);
        context.restore();
    };

})(EmPower.Util.SimpleGauge = EmPower.Util.SimpleGauge || {}, jQuery);
