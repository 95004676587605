EmPower.Model.MetrologyDetectedEvent = (function($, undefined) {
    
    return EmPower.Model.AbstractDeviceEventModel.extend({
	
	idAttribute: 'id',
	    
	modelName: 'Metrology Detected Event',

	_initialize: function() {
	},

	_parse: function(response, options) {
            return response;
	}
    });
    
})(jQuery);
