EmPower.View.UserManagement = (function ($, undefined) {

    return EmPower.View.AbstractView.extend({

        viewName: "User Management",

        _events: function () {
            var self = this;
            return {
                'click button[data-action="saveAccessPanels"]': function () {
                    var panelsDto = $.map($('.accesspanel'), function (el) {
                        return {accessPanelId: $(el).data('accesspaneid'), userId: $(el).data('userid'), hasAccess: el.checked};
                    });

                    var url = EmPower.Util.Url.apiBuilder("useraccess");
                    $.ajax({
                        type: "POST",
                        url: url,
                        data: JSON.stringify(panelsDto),
                        contentType: "application/json; charset=utf-8",
                        success: function (data) {
                            $.notify({
                                message: "Successfully saved user access data"
                            }, {
                                type: 'success',
                                placement: {
                                    from: 'top',
                                    align: 'center'
                                },
                                z_index: 9999
                            });

                            self._collectionFetcher.fetchAll(true);
                        },
                        error: function (err) {
                            $.notify({
                                message: "Failed to save user access data"
                            }, {
                                type: 'danger',
                                placement: {
                                    from: 'top',
                                    align: 'center'
                                },
                                z_index: 9999
                            });
                        }
                    });
                },

                'click .accessLevelSelect': function (ev) {
                    var accessLevel = $(ev.currentTarget).data('value');
                    $('.accesspanel').each(function () {
                        if ($(this).data('level') === accessLevel) {
                            $(this).prop('checked', true);
                        } else {
                            $(this).prop('checked', false);
                        }
                    });
                },
                'click [data-action=deselect-all]': function () {
                    $('.accesspanel').each(function () {
                        $(this).prop('checked', false);
                    });

                },
                'click [data-action=add-user-to-list]': function (evt) {
                    // when a user is selected to be added, form the id of the hidden card, unhide the card, then remove the option from the dropdown
                    var userId = '#user' + $(evt.currentTarget).data('attribute');
                    $(userId).css('display', 'block');
                    $(evt.currentTarget).remove();
                }
            };
        },

        _initialize: function () {
            this._selectedTransformer = this._session.transformerSession().selectedTransformer();
            this._userCollection = this._session.transformerSession().userInformationCollection();
            this._selectedUser = this._session.transformerSession().selectedUserInformation();
            this._accessPanels = new EmPower.Collection.UserAccess(null, {
                session: this._session,
                selectedObject: this._selectedTransformer
            });
        },

        _setupListeners: function () {
            var self = this;
            self.listenTo(self._transformerCollection, 'update change', function () {
                this.renderDebounced();
            });
            self.listenTo(self._selectedTransformer, 'change', function () {
                this.renderDebounced();
            });
            self.listenTo(self._accessPanels, 'sync change', function () {
//                self._accessPanels.fetch();
                this.renderDebounced();
            });
        },

        _htmlTemplate: function () {
            return EmPower.Templates['transformer/user/user_management'];
        },

        _htmlData: function () {
            var data = {};
            data.models = this._accessPanels.models;

            return data;
        },

        _render: function ($html) {
            

        },

        _show: function () {
            if (this._accountInformation) {
                this._accountInformation.show();
            }
            if (this._accountList) {
                this._accountList.show();
            }
        },

        _hide: function () {
            if (this._accountInformation) {
                this._accountInformation.hide();
            }
            if (this._accountList) {
                this._accountList.hide();
            }
        },

        _remove: function () {
            if (this._accountInformation) {
                this._accountInformation.remove();
            }
            if (this._accountList) {
                this._accountList.remove();
            }
        }
    });

})(jQuery);
