EmPower.View.AccountList = (function($, undefined) {
    
    return EmPower.View.AbstractCollectionView.extend({
	viewName: "Account List",
	
	_events: function() {
	    return {
                'click button[data-action="user-filter"]': function(e) {
                    var $filterText = $(e.currentTarget).find("span");
                    if (this.getCollectionProxy().hasFilter('Enabled')) {
                        this.getCollectionProxy().resetFilters();
                        $filterText.html = "Hide Disabled";
                    } else {
                        this.getCollectionProxy().filterBy('Enabled', function(model) {
                            return model.isEnabled() ? true : false;
                        });
                        $filterText.html = "Show Disabled";
                    }
		},
		'click button[data-action="user-add"]': function() {
		    EmPower.Dialog.User.information(this._session);
		}
	    };
	},
	
	_initialize: function() {
	},
	
	_setupListeners: function() {
	},
	
	_htmlTemplate: function() {
	    return EmPower.Templates['settings/account-management/account_list'];
	},
	
	_htmlData: function() {
	    
	    var data = {
		canCreateUser: false,
                hasProxy: this.hasProxy(),
                filterText: this.getCollectionProxy().hasFilter('Enabled') ? "Show Disabled" : "Hide Disabled"
	    };
	    
	    if (this.getCollection()) {
		data.canCreateUser = this.getCollection().getCanCreateUser();
	    }
	    
	    return data;
	},
	
	_getCollectionContainer: function($html) {
	    return $html.find("div[data-section='user-list']");
	},
	
	_createModelView: function(model) {
	    return new EmPower.View.AccountRow({
		session: this._session,
		userModel: model
	    });
	},
	
	_render: function() {
	},
	
	_show: function() {
	},
	
	_hide: function() {
	},
	
	_remove: function() {
	}
    });
    
})(jQuery);
