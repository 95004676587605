EmPower.Collection.CoolingTransformer = (function($, undefined) {
    
    return EmPower.Collection.AbstractDeviceCollection.extend({
	collectionName: 'Cooling Transformer',
	
	model: EmPower.Model.Cooling,
	
	url: function() {
	    return EmPower.Util.Url.apiBuilder('transformers', this._selectedObject.selectedId(), 'cooling', 'transformer-cooling');
	},
	
	_initialize: function() {
	},
	
	_parse: function() {
	}
    });
    
})(jQuery);
