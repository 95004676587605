EmPower.Model.AccessGate = (function($, undefined) {
    
    return EmPower.Model.AbstractDeviceEventModel.extend({
	    
	modelName: 'Access Gate',

	_initialize: function() {
	},

	_parse: function(response, options) {
	    return response;
	},
	
	value: function(value) {
	    return this._field('value', value);
	},
        
        valueDisplay: function() {
            
            var value = "No value";
            if (this.value() !== null) {
                if (this.value() === 1 || this.value() === "opened") {
                    value = "Opened";
                } else {
                    value = "Closed";
                }
            }
            
            return value;
        }
    });
    
})(jQuery);
