/* jshint bitwise: false */

EmPower.Model.TransformerSession = (function($, undefined) {
    
    return EmPower.Model.AbstractSubSession.extend({
	    whatAmI: function(){return "EmPower.Model.TransformerSession - Also see modelName";},
	modelName: 'Transformer Session',

	_initialize: function() {
	    
            this.selectedTransformerSubview(EmPower.Model.SelectedObject);
	    
	    this.transformerCollection(EmPower.Collection.Transformer);
            
            this.transformerCollectionProxy(Backbone.Obscura);
	    
	    this.selectedTransformer(EmPower.Model.SelectedCollectionObject);
            
            
            this.conditionIndexCollection(EmPower.Collection.ConditionIndex);
            this.conditionIndexCollectionProxy(Backbone.Obscura);
            this.conditionIndexEventCollection(EmPower.Collection.ConditionIndexEvent);
            
            this.coolingModeCollection(EmPower.Collection.CoolingMode);
            this.coolingEventCollection(EmPower.Collection.CoolingEvent);
            this.coolingTransformerCollection(EmPower.Collection.CoolingTransformer);
            
            this.dynamicLoadingCollection(EmPower.Collection.DynamicLoading);
            this.dynamicLoadingCollectionProxy(Backbone.Obscura);
            this.dynamicLoadingEventCollection(EmPower.Collection.DynamicLoadingEvent);
            
	    this.temperatureCollection(EmPower.Collection.Temperature);
            this.temperatureCollectionProxy(Backbone.Obscura);
	    this.temperatureEventCollection(EmPower.Collection.TemperatureEvent);
            
            this.pressureCollection(EmPower.Collection.Pressure);
            this.pressureCollectionProxy(Backbone.Obscura);
	    this.pressureEventCollection(EmPower.Collection.PressureEvent);
            
            this.metrologyCollection(EmPower.Collection.Metrology);
            this.metrologyCollectionProxy(Backbone.Obscura);
	    this.metrologyEventCollection(EmPower.Collection.MetrologyEvent);
            this.metrologyDetectedEventCollection(EmPower.Collection.MetrologyDetectedEvent);
            
            this.annunciatorCollection(EmPower.Collection.Annunciator);
            this.annunciatorCollectionProxy(Backbone.Obscura);
	    this.annunciatorEventCollection(EmPower.Collection.AnnunciatorEvent);
            
            this.bushingCollection(EmPower.Collection.Bushing);
            this.bushingCollectionProxy(Backbone.Obscura);
	    this.bushingEventCollection(EmPower.Collection.BushingEvent);
            
            this.dgaCollection(EmPower.Collection.Dga);
            this.dgaCollectionProxy(Backbone.Obscura);
	    this.dgaEventCollection(EmPower.Collection.DgaEvent);
            
//            this.ltcCollection(EmPower.Collection.Ltc);
//            this.ltcCollectionProxy(Backbone.Obscura);
	    this.ltcEventCollection(EmPower.Collection.LtcEvent);
            this.ltcMotorCurrentsEventCollection(EmPower.Collection.LtcMotorCurrentsEvent);
            
            this.userInformationCollection(EmPower.Collection.UserInformation);
	    this.selectedUserInformation(EmPower.Model.SelectedCollectionObject);
            
            this.bubblingTempEventCollection(EmPower.Collection.BubblingTemperatureEvent);

            this.pastConnectionCollection(EmPower.Collection.PastConnections);

            this.userAccessCollection(EmPower.Collection.UserAccess);
        },
        
        _reset: function(options) {
            
            this.selectedTransformerSubview().selected(options.selectedTransformerSectionView ? options.selectedTransformerSectionView : null);
            
	    this.selectedTransformer().selected(options.selectedTransformer ? options.selectedTransformer : null);
            
            this.selectedUserInformation().selected(null);
        },
	
        selectedTransformerSubview: function(value) {
	    return this._instanceFactoryField('selectedTransformerSubview', value);
	},
	transformerCollection: function(value) {
	    return this._instanceFactoryField('transformerCollection', value, [null, {session: this._session()}]);
	},
        transformerCollectionProxy: function(value) {
            var args;
            if (value === undefined) {
                args = [this.transformerCollection()];
            }
            var proxy = this._instanceFactoryField('transformerCollectionProxy', value, args);
            if (_.isFunction(proxy.filterBy) && !this._transformerCollectionProxyFilter) {
                proxy.filterBy('Enabled', function(model) {
                    return model.isEnabled() ? true : false;
                });
                this._transformerCollectionProxyFilter = true;
            }
            return proxy;
        },
	selectedTransformer: function(value) {
	    
            var self = this;
            
	    var args;
	    if (value === undefined) {
		args = [{
		    session: this._session(),
		    collection: this.transformerCollectionProxy(),
		    attributeParser: function(selectedId) {
			return isNaN(selectedId) ? undefined : ~~selectedId;
		    },
		    comparableAttribute: function(model) {
			return model ? ~~model.transformerId() : undefined;
		    },
                    notFoundRedirect: function(selectedId) {
                        var message = "Sorry, <strong>Transformer ID " + selectedId + "</strong> was not found.";
                        if (selectedId === undefined || selectedId === null) {
                            message = "Sorry, Transformer ID must be a number.";
                        }
                        
                        $.notify({
                            message: message
                        }, {
                            type: 'danger',
                            placement: {
                                from: 'top',
                                align: 'center'
                            }
                        });
                        self._session().applicationRouter().getTransformersModule().showTransformerManagement();
                    }
		}];
	    }
	    
	    return this._instanceFactoryField('selectedTransformer', value, args);
	},
        
        conditionIndexCollection: function(value) {
	    return this._instanceFactoryField('conditionIndexCollection', value, [null, {
                session: this._session,
		selectedObject: this.selectedTransformer()
            }]);
	},
        conditionIndexCollectionProxy: function(value) {
            var args;
            if (value === undefined) {
                args = [this.conditionIndexCollection()];
            }
            var proxy = this._instanceFactoryField('conditionIndexCollectionProxy', value, args);
            if (_.isFunction(proxy.setSort) && !this._conditionIndexCollectionProxySort) {
                proxy.setSort(function(model) {
                    var ciTypeName = model.ciTypeName();
                    if (ciTypeName.toLowerCase() === "health") {
                        ciTypeName = "";
                    }
                    return ciTypeName;
                }, 'asc');
                this._conditionIndexCollectionProxySort = true;
            }
            return proxy;
        },
        conditionIndexEventCollection: function(value) {
	    return this._instanceFactoryField('conditionIndexEventCollection', value, [null, {
                session: this._session(),
		selectedObject: this.selectedTransformer(),
		dateRange: this._session().dateRange()
            }]);
	},
        
        coolingModeCollection: function(value) {
	    return this._instanceFactoryField('coolingModeCollection', value, [null, {
                session: this._session,
		selectedObject: this.selectedTransformer()
            }]);
	},
        coolingEventCollection: function(value) {
	    return this._instanceFactoryField('coolingEventCollection', value, [null, {
                session: this._session(),
                selectedObject: this.selectedTransformer(),
		dateRange: this._session().dateRange()
            }]);
	},
        coolingTransformerCollection: function(value) {
	    return this._instanceFactoryField('coolingTransformerCollection', value, [null, {
                session: this._session(),
                selectedObject: this.selectedTransformer()
            }]);
	},
        
        dynamicLoadingCollection: function(value) {
	    return this._instanceFactoryField('dynamicLoadingCollection', value, [null, {
                session: this._session(),
		selectedObject: this.selectedTransformer()
            }]);
	},
        dynamicLoadingCollectionProxy: function(value) {
            var args;
            if (value === undefined) {
                args = [this.dynamicLoadingCollection()];
            }
            var proxy = this._instanceFactoryField('dynamicLoadingCollectionProxy', value, args);
            if (_.isFunction(proxy.filterBy) && !this._dynamicLoadingCollectionProxyFilter) {
                proxy.filterBy('Enabled', function(model) {
                    return model.isEnabled() ? true : false;
                });
                this._dynamicLoadingCollectionProxyFilter = true;
            }
            return proxy;
        },
        dynamicLoadingEventCollection: function(value) {
	    return this._instanceFactoryField('dynamicLoadingEventCollection', value, [null, {
                session: this._session(),
                selectedObject: this.selectedTransformer(),
		dateRange: this._session().dateRange()
            }]);
	},
        
        
        bushingCollection: function(value) {
            return this._instanceFactoryField('bushingCollection', value, [null, {
                session: this._session,
		selectedObject: this.selectedTransformer()
            }]);
        },
        bushingCollectionProxy: function(value) {
            var args;
            if (value === undefined) {
                args = [this.bushingCollection()];
            }
            var proxy = this._instanceFactoryField('bushingCollectionProxy', value, args);
            if (_.isFunction(proxy.filterBy) && !this._bushingCollectionProxyFilter) {
                proxy.filterBy('Enabled', function(model) {
                    return model.isEnabled() ? true : false;
                });
                this._bushingCollectionProxyFilter = true;
            }
            if (_.isFunction(proxy.setSort) && !this._bushingCollectionProxySort) {
                proxy.setSort(function(model) {
                    return model.phase();
                }, 'asc');
                this._bushingCollectionProxySort = true;
            }
            return proxy;
        },
        bushingEventCollection: function(value) {
	    return this._instanceFactoryField('bushingEventCollection', value, [null, {
                session: this._session,
		selectedObject: this.selectedTransformer(),
		dateRange: this._session().dateRange()
            }]);
	},
        
        
	temperatureCollection: function(value) {
	    return this._instanceFactoryField('temperatureCollection', value, [null, {
                session: this._session,
		selectedObject: this.selectedTransformer()
            }]);
	},
        temperatureCollectionProxy: function(value) {
            var args;
            if (value === undefined) {
                args = [this.temperatureCollection()];
            }
            var proxy = this._instanceFactoryField('temperatureCollectionProxy', value, args);
            if (_.isFunction(proxy.filterBy) && !this._temperatureCollectionProxyFilter) {
                proxy.filterBy('Enabled', function(model) {
                    return model.isEnabled() ? true : false;
                });
                this._temperatureCollectionProxyFilter = true;
            }
            return proxy;
        },
	temperatureEventCollection: function(value) {
	    return this._instanceFactoryField('temperatureEventCollection', value, [null, {
                session: this._session,
		selectedObject: this.selectedTransformer(),
		dateRange: this._session().dateRange()
            }]);
	},
        
        pressureCollection: function(value) {
            return this._instanceFactoryField('pressureCollection', value, [null, {
                session: this._session,
		selectedObject: this.selectedTransformer()
            }]);
        },
        pressureCollectionProxy: function(value) {
            var args;
            if (value === undefined) {
                args = [this.pressureCollection()];
            }
            var proxy = this._instanceFactoryField('pressureCollectionProxy', value, args);
            if (_.isFunction(proxy.filterBy) && !this._pressureCollectionProxyFilter) {
                proxy.filterBy('Enabled', function(model) {
                    return model.isEnabled() ? true : false;
                });
                this._pressureCollectionProxyFilter = true;
            }
            return proxy;
        },
        pressureEventCollection: function(value) {
	    return this._instanceFactoryField('pressureEventCollection', value, [null, {
                session: this._session,
		selectedObject: this.selectedTransformer(),
		dateRange: this._session().dateRange()
            }]);
	},
        
        
        metrologyCollection: function(value) {
            return this._instanceFactoryField('metrologyCollection', value, [null, {
                session: this._session,
		selectedObject: this.selectedTransformer()
            }]);
        },
        metrologyCollectionProxy: function(value) {
            var args;
            if (value === undefined) {
                args = [this.metrologyCollection()];
            }
            var proxy = this._instanceFactoryField('metrologyCollectionProxy', value, args);
            if (_.isFunction(proxy.filterBy) && !this._metrologyCollectionProxyFilter) {
                proxy.filterBy('Enabled', function(model) {
                    return model.isEnabled() ? true : false;
                });
                this._metrologyCollectionProxyFilter = true;
            }
            return proxy;
        },
        metrologyEventCollection: function(value) {
	    return this._instanceFactoryField('metrologyEventCollection', value, [null, {
                session: this._session,
		selectedObject: this.selectedTransformer(),
		dateRange: this._session().dateRange()
            }]);
	},
        
        metrologyDetectedEventCollection: function(value) {
            return this._instanceFactoryField('metrologyDetectedEventCollection', value, [null, {
                    session: this._session,
                    selectedObject: this.selectedTransformer(),
                    dateRange: this._session().dateRange()
            }]);
        },
        
        
        annunciatorCollection: function(value) {
            return this._instanceFactoryField('annunciatorCollection', value, [null, {
                session: this._session,
		selectedObject: this.selectedTransformer()
            }]);
        },
        annunciatorCollectionProxy: function(value) {
            var args;
            if (value === undefined) {
                args = [this.annunciatorCollection()];
            }
            var proxy = this._instanceFactoryField('annunciatorCollectionProxy', value, args);
            if (_.isFunction(proxy.filterBy) && !this._annunciatorCollectionProxyFilter) {
                proxy.filterBy('Enabled', function(model) {
                    return model.isEnabled() ? true : false;
                });
                this._annunciatorCollectionProxyFilter = true;
            }
            return proxy;
        },
        annunciatorEventCollection: function(value) {
	    return this._instanceFactoryField('annunciatorEventCollection', value, [null, {
                session: this._session,
		selectedObject: this.selectedTransformer(),
		dateRange: this._session().dateRange()
            }]);
	},
        
        
        dgaCollection: function(value) {
            return this._instanceFactoryField('dgaCollection', value, [null, {
                session: this._session,
		selectedObject: this.selectedTransformer()
            }]);
        },
        dgaCollectionProxy: function(value) {
            var args;
            if (value === undefined) {
                args = [this.dgaCollection()];
            }
            var proxy = this._instanceFactoryField('dgaCollectionProxy', value, args);
            if (_.isFunction(proxy.filterBy) && !this._dgaCollectionProxyFilter) {
                proxy.filterBy('Enabled', function(model) {
                    return model.isEnabled() ? true : false;
                });
                this._dgaCollectionProxyFilter = true;
            }
            return proxy;
        },
        dgaEventCollection: function(value) {
	    return this._instanceFactoryField('dgaEventCollection', value, [null, {
                session: this._session,
		selectedObject: this.selectedTransformer(),
		dateRange: this._session().dateRange()
            }]);
	},
        
        bubblingTempEventCollection: function(value) {
	    return this._instanceFactoryField('bubblingTempEventCollection', value, [null, {
                session: this._session,
		selectedObject: this.selectedTransformer(),
		dateRange: this._session().dateRange()
            }]);
	},
        
//        ltcCollection: function(value) {
//	    return this._instanceFactoryField('dynamicLoadingCollection', value, [null, {
//                session: this._session(),
//		selectedObject: this.selectedTransformer()
//            }]);
//	},
//        ltcCollectionProxy: function(value) {
//            var args;
//            if (value === undefined) {
//                args = [this.dynamicLoadingCollection()];
//            }
//            var proxy = this._instanceFactoryField('dynamicLoadingCollectionProxy', value, args);
//            if (_.isFunction(proxy.filterBy) && !this._dynamicLoadingCollectionProxyFilter) {
//                proxy.filterBy('Enabled', function(model) {
//                    return model.isEnabled() ? true : false;
//                });
//                this._dynamicLoadingCollectionProxyFilter = true;
//            }
//            return proxy;
//        },
        ltcEventCollection: function(value) {
	    return this._instanceFactoryField('ltcEventCollection', value, [null, {
                session: this._session(),
                selectedObject: this.selectedTransformer(),
                dateRange: this._session().dateRange()
            }]);
	},
        ltcMotorCurrentsEventCollection: function(value) {
	    return this._instanceFactoryField('ltcMotorCurrentsEventCollection', value, [null, {
                session: this._session(),
                selectedObject: this.selectedTransformer(),
		dateRange: this._session().dateRange()
            }]);
    },
    
    pastConnectionCollection: function(value) {
	    return this._instanceFactoryField('pastConnectionCollection', value, [null, {
                session: this._session(),
                selectedObject: this.selectedTransformer()}]);
	},  
        
        userInformationCollection: function(value) {
	    return this._instanceFactoryField('userInformationCollection', value, [null, {session: this}]);
    },
    
    userAccessCollection: function(value) {
        return this._instanceFactoryField('userAccessCollection', value, [null, {
            session: this._session,
            selectedObject: this.selectedTransformer()
        }]);
    },

	selectedUserInformation: function(value) {
	    
	    var args;
	    if (value === undefined) {
		args = [{
		    session: this._session,
		    collection: this.userInformationCollection(),
		    attributeParser: function(selectedId) {
			return isNaN(selectedId) ? undefined : ~~selectedId;
		    },
		    comparableAttribute: function(model) {
			return model ? ~~model.userInformationId() : undefined;
		    }
		}];
	    }
	    
	    return this._instanceFactoryField('selectedUserInformation', value, args);
	}
    });
    
})(jQuery);
