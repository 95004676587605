EmPower.Collection.AbstractSelectedObjectCollection = (function(Collection, $, undefined) {
    
    return Collection.extend({
	
	_onSelectedObjectChange: function() {
	    // ignore change if it's the object initializing with first object.
	    if (this._selectedObject.getPreviouslySelected() !== undefined) {
		this.reset();
	    }
	},
	
	initialize: function() {
	    
	    var self = this;
	    
	    self._selectedObject = self._getOption('selectedObject', true);
	    
	    self.listenTo(self._selectedObject, 'change', function() {
		self._onSelectedObjectChange();
	    });
	    
	    Collection.prototype.initialize.apply(self, arguments);
	},
        
	sync: function() {
		this._logger.debugLog(this);
		if (this._selectedObject.selectedId() !== undefined && this._selectedObject.selectedId() !== null) {
			return Collection.prototype.sync.apply(this, arguments);
		} else {
			return $.when(null);
		}
	}
    });
    
})(EmPower.Collection.AbstractCollection, jQuery);