EmPower.Model.Transformer = (function($, undefined) {
    
    return EmPower.Model.AbstractModel.extend({
		whatAmI: function(){return "EmPower.Model.Transformer - Also see modelName";},
	
	idAttribute: 'transformerId',
	    
	modelName: 'Transformer',
	
	urlRoot: EmPower.Util.Url.apiBuilder('transformers'),

	_initialize: function() {
	},

	_parse: function(response, options) {
	    return response;
	},
	
	transformerId: function(value) {
	    return this._field('transformerId', value);
	},
        
        oilTypeId: function(value) {
	    return this._field('oilTypeId', value);
	},
        
        oilTypeName: function(value) {
	    return this._field('oilTypeName', value);
	},
        
        oilTypeAbbrev: function(value) {
	    return this._field('oilTypeAbbrev', value);
	},
        
        serialNumber: function(value) {
            return this._field('serialNumber', value);
        },

	latitude: function(value) {
	    return this._field('latitude', value);
	},
	
	longitude: function(value) {
	    return this._field('longitude', value);
	},
        
        location: function(value) {
            return this._field('location', value);
        },
        
        locationDisplay: function(defaultValue) {
            
            if (this.latitude() !== undefined && this.latitude() !== null
                    && this.longitude() !== undefined && this.longitude() !== null) {
                return this.latitude() + ", " + this.longitude();
            }
            return defaultValue || "No Data";
        },
        
        type: function(value) {
            return this._field('type', value);
        },
        
        substationName: function(value) {
            return this._field('substationName', value);
        },
	
	installDate: function(value) {
	    return this._field('installDate', value);
	},
        
        installDateDisplay: function() {
            return this.installDate() || "No date";
        },
        
        phoneHome: function() {
            return this._field('phoneHome');
        },
        
        phoneNumber: function() {
            return this._field('phoneNumber');
        },
        
        isOutOfSync: function() {
            return this._field('isOutOfSync');
        },
        
	lastCommsTime: function() {
	    return this._field('lastCommsTime');
	},
        
        lastCommsTimeMoment: function() {
            return this.lastCommsTime() ? moment(this.lastCommsTime()) : null;
        },
        
        lastCommsTimeDisplay: function(defaultValue) {
            
            defaultValue = defaultValue || "No Date";
            
            var moment = this.lastCommsTimeMoment();
	    return moment ? moment.format() : defaultValue;
        },

	city: function(value) {
	    return this._field('city', value);
	},

	state: function(value) {
	    return this._field('state', value);
	},
	
	namePlateRating: function(value) {
	    return this._field('namePlateRating', value);
	},
	
	weightOfCore: function(value) {
	    return this._field('weightOfCore', value);
	},
	
	weightOfTank: function(value) {
	    return this._field('weightOfTank', value);
	},
	
	oilVolume: function(value) {
	    return this._field('oilVolume', value);
	},
	
	plannedLife: function(value) {
	    return this._field('plannedLife', value);
	},
	
	loadLoss: function(value) {
	    return this._field('loadLoss', value);
	},
	
	noLoadLoss: function(value) {
	    return this._field('noLoadLoss', value);
	},
	
	torRated: function(value) {
	    return this._field('torRated', value);
	},
	
	hsrRated: function(value) {
	    return this._field('hsrRated', value);
	},
	
	nConst: function(value) {
	    return this._field('nConst', value);
	},
	
	mConst: function(value) {
	    return this._field('mConst', value);
	},
	
	oilTimeconstant: function(value) {
	    return this._field('oilTimeconstant', value);
	},
	
	loadRated: function(value) {
	    return this._field('loadRated', value);
	},
	
	transformerName: function(value) {
	    return this._field('transformerName', value);
	},
	
	connected: function() {
	    return this._field('connected');
	},
        
        connectedDisplay: function(defaultValue) {
            defaultValue = defaultValue || "No Data";
            
            if (this.connected() !== undefined && this.connected() !== null) {
                return this.connected() ? "Connected" : "Not Connected";
            }
            return defaultValue;
        },
	
	dateConnected: function() {
	    return this._field('dateConnected');
	},
        
        dateConnectedMoment: function() {
            return this.dateConnected() ? moment(this.dateConnected()) : null;
        },
        
        dateConnectedDisplay: function(defaultValue) {
            defaultValue = defaultValue || "No Date";
            
            var moment = this.dateConnectedMoment();
	    return moment ? moment.format() : defaultValue;
        },
        
	eventsSynced: function() {
	    return this._field('eventsSynced');
	},
        
        eventsSyncedDisplay: function(defaultValue) {
            defaultValue = defaultValue || "No Data";
            
            if (this.eventsSynced() !== undefined && this.eventsSynced() !== null) {
                return this.eventsSynced();
            }
            return defaultValue;
        },
	
	eventsNotSynced: function() {
	    return this._field('eventsNotSynced');
	},
        
        eventsNotSyncedDisplay: function(defaultValue) {
            defaultValue = defaultValue || "No Data";
            
            if (this.eventsNotSynced() !== undefined && this.eventsNotSynced() !== null) {
                return this.eventsNotSynced();
            }
            return defaultValue;
        },
        
        connectionDuration: function(defaultValue) {
            defaultValue = defaultValue || "No Data";
            
            if (this.connected() && this.dateConnectedMoment()){
                return moment.preciseDiff(this.dateConnectedMoment(), moment());
            }
            return defaultValue;
        },
        
        isEnabled: function(value) {
            return this._field('isEnabled', value);
        },
        
	getCanDisable: function() {
	    return this._field('canDisable');
	},

	getCanEdit: function() {
	    return this._subModel('canEdit');
	}
    });
    
})(jQuery);
