EmPower.View.BushingList = (function($, undefined) {

    return EmPower.View.AbstractCollectionView.extend({
	viewName: "Bushing List",
	
	_events: function() {
            this._getOption("bushingPhase", false);
	},
	
	_initialize: function() {
	    this._applicationRouter = this._session.applicationRouter();
	},
	
	_setupListeners: function() {
	},
	
	_htmlTemplate: function() {
	    return EmPower.Templates['transformer/bushing/bushing_list'];
	},
	
	_htmlData: function() {
            
            var hasPrimary, hasSecondary, hasTertiary;
            _.forEach(this.getModels(), function(model) {
                if (model && model.winding()) {
                    switch(model.winding().toLowerCase()) {
                        case "primary":
                            hasPrimary = true;
                            break;
                        case "secondary":
                            hasSecondary = true;
                            break;
                        case "tertiary":
                            hasTertiary = true;
                            break;
                    }
                }
            });

            return {
                hasPrimary: hasPrimary ? true : false,
                hasSecondary: hasSecondary ? true : false,
                hasTertiary: hasTertiary ? true : false
            };
	},
	
	_getCollectionContainer: function($html) {
	    return $html.find("div[data-section='bushing-list']");
	},
	
        _getModelSubsetContainer: function($collectionContainer, model) {
            
            var subsection;
            if (model && model.winding()) {
                subsection = model.winding().toLowerCase();
            }
            
            return $collectionContainer.find("div[data-subsection=bushing-" + subsection + "-list]");
        },
        
	_createModelView: function(model) {
	    return new EmPower.View.BushingRow({
		session: this._session,
		bushingModel: model
	    });
	},
	
	_getLoadingCollectionMessage: function() {
	    return "Loading bushings, please wait...";
	},
	
	_getEmptyCollectionMessage: function() {
	    return "Sorry, no bushings have been configured.";
	},
	
	_render: function() {
	},
	
	_show: function() {
	},
	
	_hide: function() {
	},
	
	_remove: function() {
	}
    });
    
})(jQuery);
