EmPower.View.Pressure = (function($, undefined) {

    return EmPower.View.AbstractView.extend({
	viewName: "Transformer Pressure",
	
	_events: function() {
	},
	
	_initialize: function() {
	    this._selectedTransformer = this._session.transformerSession().selectedTransformer();
	    
	    this._pressureCollection = this._session.transformerSession().pressureCollectionProxy();
	},
	
	_setupListeners: function() {
	},
	
	_htmlTemplate: function() {
	    return EmPower.Templates['transformer/pressure/transformer_pressure'];
	},
	
	_htmlData: function() {
	    return {
		selectedTransformer: this._selectedTransformer.selected()
	    };
	},
	
	_render: function($html) {
	    
	    var $pressureList = $html.filter("div[data-section='transformer-pressure-list']");
	    if (!this._pressureList) {

		this._pressureList = new EmPower.View.PressureList({
		    session: this._session,
		    collection: this._pressureCollection,
		    selectedModel: null
		});
		this._pressureList.render($pressureList);
	    } else {
		$pressureList.replaceWith(this._pressureList.$el);
	    }
	},
	
	_show: function() {
	    if (this._pressureList) {
		this._pressureList.show();
	    }
	},
	
	_hide: function() {
	    if (this._pressureList) {
		this._pressureList.hide();
	    }
	},
	
	_remove: function() {
	    if (this._pressureList) {
		this._pressureList.remove();
	    }
	}
    });
    
})(jQuery);
