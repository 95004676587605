EmPower.Model.Camera = (function($, undefined) {
    
    return EmPower.Model.AbstractModel.extend({
        idAttribute: 'id',
        
	modelName: 'Camera',

	_initialize: function() {
	},

	_parse: function(response, options) {
	    return response;
	},
	
	cameraId: function(value) {
	    return this._field('id', value);
	},
	
	transformerDeviceId: function(value) {
	    return this._field('transformerDeviceId', value);
	},
	
	deviceId: function(value) {
	    return this._field('deviceId', value);
	},
	
	transformerId: function(value) {
	    return this._field('transformerId', value);
	},
	
	cameraIpAddress: function(value) {
	    return this._field('cameraIpAddress', value);
	},
	
	port: function(value) {
	    return this._field('port', value);
	},
	
	model: function(value) {
	    return this._field('model', value);
	},
	
	description: function(value) {
	    return this._field('description', value);
	},
	
	serialNumber: function(value) {
	    return this._field('serialNumber', value);
	},
	
	type: function(value) {
	    return this._field('type', value);
	},
	
	presets: function(value) {
	    return this._field('presets', value);
	},
        
        streamingInformation: function(value) {
            return this._field('streamingInformation', value);
        }
    });
    
})(jQuery);
