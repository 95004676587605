(function(TransformerDocument, $, undefined) {
    
    // private namespace
    var that = {};
    
    that.logger = new EmPower.Util.Logger("Document Wizards", true);
    
    that.documentDeleteDialog = {};
    
    that.documentDeleteDialog.html = [
	'<div>',
	'  Are you sure you wish to delete <strong><%-description()%></strong>?',
	'<div>'
    ];
    
    that.documentDeleteDialog.open = function(documentModel, dialog) {
	
	dialog = dialog || new BootstrapDialog({
	    title: 'Delete <strong>' + documentModel.description() + "</strong>",
	    size: BootstrapDialog.SIZE_SMALL
	});
	
	if (!that.documentDeleteDialog.htmlCompiled) {
	    that.documentDeleteDialog.htmlCompiled = _.template(that.documentDeleteDialog.html.join(''));
	}
	    
	var $html = $(that.documentDeleteDialog.htmlCompiled(documentModel));
	
	dialog.setMessage($html);
	
	var onNextStep = function(dialog) {
	    
	    EmPower.Dialog.SimpleDialog.Loading.open('Deleting Document...');
	    
	    var apiDocument = documentModel.destroy({wait: true});
		
	    apiDocument.done(function(apiData) {

		EmPower.Dialog.SimpleDialog.Loading.close();

		that.documentDeleteDialog.showSuccess(documentModel, dialog);
	    });
	};
	
	var nextButton = {
	    label: 'Delete',
	    cssClass: 'btn-danger',
	    action: onNextStep
	};
	
	var onCancel = function(dialog) {
	    dialog.close();
	};
	
	var cancelButton = {
	    label: 'Cancel',
	    cssClass: 'btn-default left',
	    action: onCancel
	};
	
	dialog.setButtons([cancelButton, nextButton]);
	dialog.setType(BootstrapDialog.TYPE_DANGER);
	dialog.setSize(BootstrapDialog.SIZE_SMALL);
	
	return dialog.open();
    };
    
    that.documentDeleteDialog.showSuccess = function(documentModel, dialog) {
	
	$.notify({
	    message: "Document <strong>" + documentModel.description() + "</strong> was deleted."
	}, {
	    type: 'success',
	    placement: {
		from: 'top',
		align: 'center'
	    }
	});
	
	dialog.close();

	return dialog;
    };
    
    TransformerDocument.delete = function(documentModel) {
	
	if (documentModel && documentModel.id) {
	    
	    that.documentDeleteDialog.open(documentModel);
	}
    };
    
})(EmPower.Dialog.TransformerDocument = EmPower.Dialog.TransformerDocument || {}, jQuery);
