EmPower.View.Metrology = (function($, undefined) {

    return EmPower.View.AbstractView.extend({
	viewName: "Transformer Metrology",
	
	_events: function() {
	},
	
	_initialize: function() {
	    this._selectedTransformer = this._session.transformerSession().selectedTransformer();
	    
	    this._metrologyCollection = this._session.transformerSession().metrologyCollectionProxy();
	},
	
	_setupListeners: function() {
	},
	
	_htmlTemplate: function() {
	    return EmPower.Templates['transformer/power-metering/transformer_metrology'];
	},
	
	_htmlData: function() {
	},
	
	_render: function($html) {
	    
	    var $metrologyList = $html.filter("div[data-section='transformer-metrology-list']");
	    if (!this._metrologyList) {

		this._metrologyList = new EmPower.View.MetrologyList({
		    session: this._session,
		    collection: this._metrologyCollection,
		    selectedModel: null
		});
		this._metrologyList.render($metrologyList);
	    } else {
		$metrologyList.replaceWith(this._metrologyList.$el);
	    }
	},
	
	_show: function() {
	    if (this._metrologyList) {
		this._metrologyList.show();
	    }
	},
	
	_hide: function() {
	    if (this._metrologyList) {
		this._metrologyList.hide();
	    }
	},
	
	_remove: function() {
	    if (this._metrologyList) {
		this._metrologyList.remove();
	    }
	}
    });
    
})(jQuery);
