EmPower.View.TransformerContentFrame = (function($, undefined) {
    
    return EmPower.View.AbstractView.extend({
	viewName: "Transformer Content Frame",
	
	_events: function() {
            return {
                "click ul[data-section='transformer-content-nav-tabs'] a": function(event) {
		    
		    if (event) {
			
			event.preventDefault();
			
			var dataAction = $(event.currentTarget).attr('data-action');
			
			switch(dataAction) {
                            case "transformer-current":
                                this._selectedGraph.selected(false);
				break;
                            case "transformer-graph":
                                this._selectedGraph.selected(true);
                                break;
                            default:
                                this._logger.info("Content navigation action not found: ", dataAction);
                                break;
                        }
                    }
                }
            };
	},
	
	_initialize: function() {
	    
	    this._applicationRouter = this._session.applicationRouter();
	    
	    this._transformerCollection = this._session.transformerSession().transformerCollection();
	    
	    this._selectedTransformerSubview = this._session.transformerSession().selectedTransformerSubview();
            
            // Default setting for graph
            this._selectedGraph = new EmPower.Model.SelectedObject();
            this._selectedGraph.selected(false);
            
            // Default setting for compainion view
            this._hasCompanion = false;
            
            // Default setting for has graph collection
            this._hasGraphCollection = false;
	},
	
        _updateSubview: function() {
            var subView = this._selectedTransformerSubview.selected();
                
            this._hasCompanion = subView && _.isFunction(subView.hasCompanionView) ?
                    subView.hasCompanionView() : false;

            this._hasGraphCollection = subView && _.isFunction(subView.hasGraphCollection) ?
                    subView.hasGraphCollection() : false;

            this._selectedGraph.selected(false);
        },
        
	_setupListeners: function() {
	    
	    var self = this;
	    
	    this.listenTo(self._transformerCollection, 'update', function() {
		// Needs to listen to transformer collection to make sure transformer models stay updated.
		    // Should also show page not found if transformer is deleted while selected.
	    });
	    
            this.listenTo(self._session, 'reset', function() {
                self._selectedGraph.selected(false);
            });
            
            this.listenTo(self._selectedGraph, 'change', function() {
                self.renderDebounced();
            });
            
	    this.listenTo(self._selectedTransformerSubview, 'change', function() {
                self._updateSubview();
		self.renderDebounced();
	    });
            
            // check current state
            self._updateSubview();
	},
	
	_htmlTemplate: function() {
	    return EmPower.Templates['transformer/transformer_content_frame'];
	},
	
	_htmlData: function() {
	},
	
	_render: function($html) {
	    
            var self = this;
            
            // create submenu
	    var $subMenu = $html.find('div[data-section="transformer-menu"]');
	    if (!this._subMenu) {
		this._subMenu = new EmPower.View.TransformerMenu({
		    session: this._session
		});
		this._subMenu.render($subMenu);
	    } else {
		$subMenu.replaceWith(this._subMenu.$el);
	    }
	    
            // hide submenu if on transformer management screen
	    var transformerSubview = this._selectedTransformerSubview.selected();
	    var selectedView = transformerSubview;
	    if (transformerSubview && _.isFunction(transformerSubview.view)) {
		selectedView = transformerSubview.view();
	    }
	    var isManagement = selectedView instanceof EmPower.View.TransformerManagement;
	    if (!isManagement) {
		this._subMenu.show();
	    } else {
		this._subMenu.hide();
	    }
	    
            // create transformer header
	    var $transformerHeader = $html.find('div[data-section="transformer-header"]');
	    if (!this._transformerHeader) {
		this._transformerHeader = new EmPower.View.TransformerHeader({
		    session: this._session
		});
		this._transformerHeader.render($transformerHeader);
	    } else {
		$transformerHeader.replaceWith(this._transformerHeader.$el);
	    }
            
            // create companion view
	    var $companionView = $html.find("div[data-section='transformer-companion-view-container']");
	    if (!this._companionView && this._hasCompanion) {
		this._companionView = new EmPower.View.TransformerCompainionView({
		    session: this._session,
            selectedGraph: this._selectedGraph
		});
		this._companionView.render($companionView);
	    } else if (this._companionView) {
		$companionView.replaceWith(this._companionView.$el);
            }
            
            // hide or show companion view
	    if (this._hasCompanion) {
                if (this._companionView) {
                    this._companionView.show();
                } else {
                    $companionView.show();
                }
	    } else {
                if (this._companionView) {
                    this._companionView.hide();
                } else {
                    $companionView.hide();
                }
	    }
            
            // hide or show graph tab
            var $transformerContentNavTab = $html.find("ul[data-section='transformer-content-nav-tabs']");
            if (this._hasGraphCollection) {
                $transformerContentNavTab.show();
            } else {
                $transformerContentNavTab.hide();
            }
            
            // create graph view if needed.
            var $graphView = $html.find("div#empower-transformer-graph-content");
            if (!this._graphView && this._hasGraphCollection && this._selectedGraph.selected()) {
                this._graphView = new EmPower.View.CollectionGraph({
                   session: this._session
                });
                this._graphView.render($graphView);
            } else if (this._graphView) {
                $graphView.replaceWith(this._graphView.$el);
            }
            
            // hide or show graph view
            $transformerContentNavTab.find("li.active").removeClass("active");
            if (this._hasGraphCollection && this._selectedGraph.selected()) {
                
                $transformerContentNavTab.find("a[data-action='transformer-graph']").parent('li').addClass("active");
                this._logger.info("Showing graph view.");
                
                if (self._graphView) {
                        self._graphView.show();
                } else {
                    $graphView.show();
                }

            } else {
                
                $transformerContentNavTab.find("a[data-action='transformer-current']").parent('li').addClass("active");
                this._logger.info("Hiding graph view.");
                
                if (self._graphView) {
                        self._graphView.hide();
                } else {
                    $graphView.hide();
                }
            }
	    
	    this._renderSubView(selectedView, transformerSubview, this._hasGraphCollection);
	},
        
	_renderSubView: function(selectedView, transformerSubview, hasGraphCollection) {
	    
	    var previousTransformerSubview = this._previousTransformerSubview;
	    var previouslySelectedView = previousTransformerSubview;
	    if (previousTransformerSubview && _.isFunction(previousTransformerSubview.view)) {
		previouslySelectedView = previousTransformerSubview.view();
	    }

	    this._logger.warn("Rendering selected section view and hiding previous view.", selectedView, previouslySelectedView);
	    
	    if (selectedView !== previouslySelectedView) {
		if (previouslySelectedView && previouslySelectedView.hasRendered() && previouslySelectedView.isVisible()) {
		    previouslySelectedView.hide();
		}
	    }
	    
	    if (selectedView) {

                if (hasGraphCollection && this._selectedGraph.selected()) {
                    if (selectedView.hasRendered()) {
                        selectedView.hide();
                    }
                } else {
                    if (selectedView.hasRendered()) {
                        selectedView.show();
                    }
                }

		var $contentContainer = this.$html.find("div#empower-transformer-content");
		if (this.$el.find("div#empower-transformer-content").length !== 0) {
		    $contentContainer.replaceWith(this.$el.find("div#empower-transformer-content"));
		}
		$contentContainer = this.$html.find("div#empower-transformer-content");
		
		if (!selectedView.hasRendered() && !this._selectedGraph.selected()) {
		    var $container = $("<div></div>", {
			'id': selectedView.cid,
			'class': 'section-content-container',
                        'style': 'display:flex;'
		    });

		    selectedView.render($container);
		}
		
		if ($contentContainer.find("div#" + selectedView.cid).length === 0) {
		    this._logger.info("Appending selected view.");
		    $contentContainer.append(selectedView.$el);
		}
		
		if (hasGraphCollection && this._selectedGraph.selected()) {
                    this._logger.info("Hiding selected view.");
                    $contentContainer.hide();
                    if (selectedView.hasRendered()) {
                        selectedView.hide();
                    }
                } else {
                    this._logger.info("Showing selected view.");
                    if (selectedView.hasRendered()) {
                        selectedView.show();
                    }
                    $contentContainer.show();
                }
		
		this._previousTransformerSubview = transformerSubview;
	    }
	},
	
	_show: function() {
	    if (this._transformerMenu) {
		this._transformerMenu.show();
	    }
	    if (this._transformerHeader) {
		this._transformerHeader.show();
	    }
	    if (this._companionView && this._hasCompanion) {
		this._companionView.show();
	    }
            if (this._graphView && this._selectedGraph.selected()) {
                this._graphView.show();
            }
	    if (this._previousTransformerSubview
		    && _.isFunction(this._previousTransformerSubview.view) && !this._selectedGraph.selected()) {
		this._previousTransformerSubview.view().show();
	    }
	},
	
	_hide: function() {
	    if (this._transformerMenu) {
		this._transformerMenu.hide();
	    }
	    if (this._transformerHeader) {
		this._transformerHeader.hide();
	    }
	    if (this._companionView) {
		this._companionView.hide();
	    }
            if (this._graphView) {
                this._graphView.hide();
            }
	    if (this._previousTransformerSubview
		    && _.isFunction(this._previousTransformerSubview.view)) {
		this._previousTransformerSubview.view().hide();
	    }
	},
	
	_remove: function() {
	    if (this._transformerMenu) {
		this._transformerMenu.remove();
	    }
	    if (this._transformerHeader) {
		this._transformerHeader.remove();
	    }
	    if (this._companionView) {
		this._companionView.remove();
	    }
            if (this._graphView) {
                this._graphView.remove();
            }
	    if (this._previousTransformerSubview
		    && _.isFunction(this._previousTransformerSubview.view)) {
		this._previousTransformerSubview.view().remove();
	    }
	}
    });

})(jQuery);
