EmPower.View.TransformerCompainionView = (function($, undefined) {
    
    return EmPower.View.AbstractView.extend({
	viewName: "Transformer Compainion View",
	
	_events: function() {
	    return {
		'click li[data-action="table-toggle"] > a': function(event) {
		    
		    event.preventDefault();
		    var $button = $(event.target);
		    $button = $button.closest("a");

		    var $companionView = this.$html.find('div[data-content="data-table-container"]');
		    var $companionViewContainer = this.$html.filter("div[data-section='companion-view']");
		    if ($button.attr('data-action') === "expand-table") {
			
			if (_.isFunction(this._selectedCompanionView)) {
			    
			    var selectedCompanionView = this._selectedCompanionView();
			    if (selectedCompanionView) {
				
				if (!selectedCompanionView.hasRendered()) {
				    selectedCompanionView.render($companionView);
				    selectedCompanionView.hide();
				} else {
				    $companionView.replaceWith(selectedCompanionView.$el);
				}
				
				this._renderedCompanionView = selectedCompanionView;
				
				$companionViewContainer.addClass("table-opened");
                                $companionViewContainer.find("ul.data-table-right").removeClass("hide");
				if (!selectedCompanionView.isVisible()) {
				    selectedCompanionView.show("blind", {
					direction: "down"
				    }, 250);
				}
			    }
			}
		    } else {
			
			if (this._renderedCompanionView.isVisible()) {
			    
			    this._renderedCompanionView.hide("blind", {
				direction: "down"
			    }, 250);
			    $companionViewContainer.removeClass("table-opened");
                            if (!this._selectedGraph.selected()) {
                                $companionViewContainer.find("ul.data-table-right").addClass("hide");
                            }
			}
		    }
                    setTimeout(function() {
                        $(document).trigger("transformerViewResize");
                    }, 250);
		},
		'click a[data-action="date-select"]': function(event) {
		    EmPower.Dialog.DateRange.open(this._dateRange);
		},
		'click a[data-action="download"]': function(event) {
		    event.preventDefault();
		    if (_.isFunction(this._selectedCompanionView)) {
			var selectedCompanionView = this._selectedCompanionView();
			if (selectedCompanionView && _.isFunction(selectedCompanionView.getDownloadUrl)) {
                            EmPower.Util.Download.hiddenDownload(selectedCompanionView.getDownloadUrl());
			}
		    }
		}
	    };
	},
	
	_initialize: function() {
	    
	    this._selectedTransformer = this._session.transformerSession().selectedTransformer();
	    
	    this._selectedTransformerSubview = this._session.transformerSession().selectedTransformerSubview();
	    
	    this._dateRange = this._session.dateRange();
            
        this._selectedGraph = this._getOption('selectedGraph');
	},
	
	_setupListeners: function() {
	    
	    var self = this;
	    
	    self.listenTo(self._selectedTransformerSubview, 'change', function() {
		this.renderDebounced();
	    });
	    
	    self.listenTo(self._selectedTransformer, 'change', function() {
		this.renderDebounced();
	    });
            
		self.listenTo(self._selectedGraph, 'change', function() {
			if (self.hasRendered()) {
				if (self._selectedGraph.selected()) {
					self.$html.find("ul.data-table-right").removeClass("hide");
				} else if (!self.$html.filter("div[data-section='companion-view']").hasClass("table-opened")) {
					self.$html.find("ul.data-table-right").addClass("hide");
				}
			}
		});
	    
	    self.listenTo(self._dateRange, 'change', function() {
                if (self.hasRendered()) {
                    var dateRange = " " + self._dateRange.fromDateFormattedLocal() + " to " + self._dateRange.toDateFormattedLocal();

                    this.$html.find("span[data-section='date-range']").html(dateRange);
				}
				
			//this._selectedGraph.fetch();
		});
		

	},
	
	_htmlTemplate: function() {
	    return EmPower.Templates['transformer/companion-view/transformer_companion_view'];
	},
	
	_htmlData: function() {
	    return {
		fromDateFormatted: this._dateRange.fromDateFormattedLocal(),
		toDateFormatted: this._dateRange.toDateFormattedLocal()
	    };
	},
	
	_render: function($html) {
	    
	    if (this._selectedTransformerSubview.selected() && _.isFunction(this._selectedTransformerSubview.selected().companionView)) {
		this._selectedCompanionView = function() {
		    return this._selectedTransformerSubview.selected().companionView();
		};
	    } else {
		this._selectedCompanionView = null;
	    }
	    
	    if (this._renderedCompanionView) {
		this._renderedCompanionView.hide();
	    }
	},
	
	_show: function() {
	    if (this._renderedCompanionView && this.$html.filter("div[data-section='companion-view']").hasClass("table-opened")) {
		this._renderedCompanionView.show();
	    }
	},
	
	_hide: function() {
	    if (this._renderedCompanionView) {
		this._renderedCompanionView.hide();
	    }
	},
	
	_remove: function() {
	    if (this._renderedCompanionView) {
		this._renderedCompanionView.remove();
	    }
	}
    });
    
})(jQuery);
