(function(SimpleChartAxis, $, undefined) {
    
    // private namespace
    var that = {};
    
    // Base default axis
    that.default = {
        scaleLabel: {
            display: true
        },
        gridLines: {
            color: "#e9e8e8",
            display:true,
            drawTicks: true,
            drawBorder: true,
            zeroLineColor:"#e9e8e8"
        },
        ticks: {
            fontColor: "#424242"
        }
    };
    SimpleChartAxis.default = function(options) {
        return _.merge({}, that.default, options || {});
    };
    
    // Time axis
    that.time = {
        type: 'time',
        time: {
            tooltipFormat: "YYYY-MM-DD T HH:mm:ssZ",
            displayFormats: {
                millisecond: 'lll',
                second: 'lll',
                minute: 'lll',
                hour: 'lll',
                day: 'll'
            }/*,
            unit: 'week'*/
        },
        scaleLabel: {
            labelString: 'Timestamp'
        },
        ticks: {
            maxRotation: 45,
            minRotation: 20
        }
    };
    SimpleChartAxis.time = function(options) {
        return SimpleChartAxis.default(_.merge({}, that.time, options || {}));
    };
    
    // Value axis
    that.value = {
        type: "linear",
        position: "left",
        scaleLabel: {
            labelString: 'Value'
        }
    };
    SimpleChartAxis.value = function(options) {
        return SimpleChartAxis.default(_.merge({}, that.value, options || {}));
    };
    
    that.conditionIndex = {
        scaleLabel: {
            labelString: 'Condition Index (0 to 100)'
        },
        ticks: {
            suggestedMin: 0,
            suggestedMax: 100
        }
    };
    SimpleChartAxis.conditionIndex = function(options) {
        return SimpleChartAxis.value(_.merge({}, that.conditionIndex, options || {}));
    };
    
    that.temperature = {
        scaleLabel: {
            labelString: 'Temperature (Celsius)'
        }
    };
    SimpleChartAxis.temperature = function(options) {
        return SimpleChartAxis.value(_.merge({}, that.temperature, options || {}));
    };
    
    that.bushing = {
        scaleLabel: {
            labelString: 'Condition (One)'
        },
        ticks: {
            suggestedMin: 0,
            suggestedMax: 110
        }
    };
    SimpleChartAxis.bushing = function(options) {
        return SimpleChartAxis.value(_.merge({}, that.bushing, options || {}));
    };
    
    that.concentration = {
        scaleLabel: {
            labelString: 'Concentration (Parts-per-million)'
        }
    };
    SimpleChartAxis.concentration = function(options) {
        return SimpleChartAxis.value(_.merge({}, that.concentration, options || {}));
    };
    
    that.relativeHumidity = {
        scaleLabel: {
            labelString: 'Moisture (Percent)'
        },
        ticks: {
            suggestedMin: 0,
            suggestedMax: 100
        }
    };
    SimpleChartAxis.relativeHumidity = function(options) {
        return SimpleChartAxis.value(_.merge({}, that.relativeHumidity, options || {}));
    };
    
    that.moisture = {
        scaleLabel: {
            labelString: 'Moisture (One)'
        },
        ticks: {
            suggestedMin: 0,
            suggestedMax: 1
        }
    };
    SimpleChartAxis.moisture = function(options) {
        return SimpleChartAxis.value(_.merge({}, that.moisture, options || {}));
    };
    
    that.load = {
        scaleLabel: {
            labelString: 'Load (PU)'
        },
        ticks: {
            suggestedMin: 0,
            suggestedMax: 2
        }
    };
    SimpleChartAxis.load = function(options) {
        return SimpleChartAxis.value(_.merge({}, that.load, options || {}));
    };
    
    that.life = {
        scaleLabel: {
            labelString: 'Life (Percentage)'
        },
        ticks: {
            suggestedMin: 0,
            suggestedMax: 100
        }
    };
    SimpleChartAxis.life = function(options) {
        return SimpleChartAxis.value(_.merge({}, that.life, options || {}));
    };
    
    that.voltage = {
        scaleLabel: {
            labelString: 'Voltage (Volts)'
        }
    };
    SimpleChartAxis.voltage = function(options) {
        return SimpleChartAxis.value(_.merge({}, that.voltage, options || {}));
    };
    
    that.voltagekV = {
        scaleLabel: {
            labelString: 'Voltage (kV)'
        }
    };
    SimpleChartAxis.voltagekV = function(options) {
        return SimpleChartAxis.value(_.merge({}, that.voltagekV, options || {}));
    };
    
    that.current = {
        scaleLabel: {
            labelString: 'Current (Amps)'
        }
    };
    SimpleChartAxis.current = function(options) {
        return SimpleChartAxis.value(_.merge({}, that.current, options || {}));
    };
    
    that.pressure = {
        scaleLabel: {
            labelString: 'Pressure (psi)'
        }
    };
    SimpleChartAxis.pressure = function(options) {
        return SimpleChartAxis.value(_.merge({}, that.pressure, options || {}));
    };
    
    that.lifeLossDay = {
        scaleLabel: {
            labelString: 'Life (Percentage)'
        },
        ticks: {
            suggestedMin: 0,
            suggestedMax: 200
        }
    };
    SimpleChartAxis.lifeLossDay = function(options) {
        return SimpleChartAxis.value(_.merge({}, that.lifeLossDay, options || {}));
    };
    
    that.faa = {
        scaleLabel: {
            labelString: 'Aging Factor'
        },
        ticks: {
            suggestedMin: 0,
            suggestedMax: 2
        }
    };
    SimpleChartAxis.faa = function(options) {
        return SimpleChartAxis.value(_.merge({}, that.faa, options || {}));
    };

})(EmPower.Util.SimpleChartAxis = EmPower.Util.SimpleChartAxis || {}, jQuery);
