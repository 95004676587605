EmPower.View.TransformerList = (function($, undefined) {

    return EmPower.View.AbstractCollectionView.extend({
	viewName: "Transformer List",
	
	_events: function() {
	    return {
		"click [data-action='transformer-add']": function() {
		    EmPower.Dialog.Transformer.uploadConfiguration(this._session);
		},
		"dblclick li": function(event) {
		    
		    if (event) {
			
			var cid = $(event.currentTarget).attr('id');
			var transformerModel = this.getCollectionProxy().get({cid: cid});
			this.getSelectedModel().selected(transformerModel);
			this._applicationRouter.getTransformersModule().showHealth();
		    }
		},
                "click button[data-action='view-transformer']": function(e) {
                    var $element = $(e.currentTarget);
                    var cid = $element.attr("data-transformer-cid");
                    if (cid) {
                        this.getSelectedModel().selected(this.getCollectionProxy().get({cid: cid}));
                        this._applicationRouter.getTransformersModule().showHealth();
                    }
                },
                'click button[data-action="disable-transformer"]': function(e) {
                    var $element = $(e.currentTarget);
                    var cid = $element.attr("data-transformer-cid");
                    if (cid) {
                        var transformer = this.getCollectionProxy().get({cid: cid});
                        this.getSelectedModel().selected(transformer);
                        EmPower.Dialog.Transformer.disable(transformer);
                    }
		},
		'click button[data-action="enable-transformer"]': function(e) {
                    var $element = $(e.currentTarget);
                    var cid = $element.attr("data-transformer-cid");
                    if (cid) {
                        var transformer = this.getCollectionProxy().get({cid: cid});
                        this.getSelectedModel().selected(transformer);
                        EmPower.Dialog.Transformer.enable(transformer);
                    }
		},
                'click button[data-action="transformer-filter"]': function(e) {
                    var $filterText = $(e.currentTarget).find("span");
                    if (this.getCollectionProxy().hasFilter('Enabled')) {
                        this.getCollectionProxy().resetFilters();
                        $filterText.html = "Hide Disabled";
                    } else {
                        this.getCollectionProxy().filterBy('Enabled', function(model) {
                            return model.isEnabled() ? true : false;
                        });
                        $filterText.html = "Show Disabled";
                    }
		}
	    };
	},
	
	_initialize: function() {
	    this._applicationRouter = this._session.applicationRouter();
	},
	
	_setupListeners: function() {
	},
	
	_htmlTemplate: function() {
	    return EmPower.Templates['transformer/transformer-management/transformer_list'];
	},
	
	_htmlData: function() {
            
	    var data = {
		canCreateTransformer: false,
                hasProxy: this.hasProxy(),
                filterText: this.getCollectionProxy().hasFilter('Enabled') ? "Show Disabled" : "Hide Disabled"
	    };
	    
	    if (this.getCollection()) {
		data.canCreateTransformer = this.getCollection().canCreateTransformer();
	    }
	    
	    return data;
	},
	
	_getCollectionContainer: function($html) {
	    return $html.find("div[data-section='transformer-list']");
	},
	
	_createModelView: function(model) {
	    return new EmPower.View.TransformerRow({
		session: this._session,
		transformerModel: model
	    });
	},
	
	_getLoadingCollectionMessage: function() {
	    return "Loading transformers, please wait...";
	},
	
	_getEmptyCollectionMessage: function() {
	    return "Sorry, no transformers have been configured.";
	},
	
	_render: function() {
	},
	
	_show: function() {
	},
	
	_hide: function() {
	},
	
	_remove: function() {
	}
    });
    
})(jQuery);
