EmPower.View.AccountInformation = (function ($, undefined) {

    return EmPower.View.AbstractView.extend({

        viewName: "Account Information",

        _events: function () {
            var self = this;
            return {
                'click button[data-action="editUser"]': function () {
                    EmPower.Dialog.User.information(this._session, this._userModel());
                },

                'click button[data-action="changePassword"]': function () {
                    EmPower.Dialog.User.password(this._userModel());
                },

                'click button[data-action="hideUser"]': function () {
                    EmPower.Dialog.User.hide(this._userModel());
                },

                'click button[data-action="showUser"]': function () {
                    EmPower.Dialog.User.show(this._userModel());
                },

                'click button[data-action="disableUser"]': function () {
                    EmPower.Dialog.User.disable(this._userModel());
                },

                'click button[data-action="enableUser"]': function () {
                    EmPower.Dialog.User.enable(this._userModel());
                },

                'click button[data-action="saveAccessPanels"]': function () {
                    var panelsDto = $.map($('.accesspanel'), function (el) {
                        return {accessPanelId: $(el).data('accesspaneid'), userId: $(el).data('userid'), hasAccess: el.checked};
                    });

                    var url = EmPower.Util.Url.apiBuilder("useraccess");
                    $.ajax({
                        type: "POST",
                        url: url,
                        data: JSON.stringify(panelsDto),
                        contentType: "application/json; charset=utf-8",
                        success: function (data) {
                            $.notify({
                                message: "Successfully saved user access data"
                            }, {
                                type: 'success',
                                placement: {
                                    from: 'top',
                                    align: 'center'
                                },
                                z_index: 9999
                            });
                            
                            self._collectionFetcher.fetchAll(true);
                        },
                        error: function (err) {
                            console.log(err);
                            $.notify({
                                message: "Failed to save user access data"
                            }, {
                                type: 'danger',
                                placement: {
                                    from: 'top',
                                    align: 'center'
                                },
                                z_index: 9999
                            });
                        }
                    });
                },

                'click .accessLevelSelect': function (ev) {
                    var accessLevel = $(ev.currentTarget).data('value');
                    $('.accesspanel').each(function () {
                        if ($(this).data('level') === accessLevel) {
                            $(this).prop('checked', true);
                        } else {
                            $(this).prop('checked', false);
                        }
                    });
                },
                'click [data-action=deselect-all]': function () {
                    $('.accesspanel').each(function () {
                        $(this).prop('checked', false);
                    });
                    
                }
            };
        },

        _initialize: function () {
            this._selectedUser = this._session.settingSession().selectedUser();
            this._userModel(this._selectedUser.selected());
        },

        _setupListeners: function () {

            var self = this;

            self.listenTo(self._selectedUser, 'change', function () {
                self._userModel(self._selectedUser.selected());
                self.renderDebounced();
            });
        },

        _userModel: function (selectedUserModel) {

            var self = this;

            if (selectedUserModel !== undefined) {

                if (self._selectedUserModel !== selectedUserModel) {

                    if (self._selectedUserModel) {
                        self.stopListening(self._selectedUserModel);
                    }

                    if (selectedUserModel) {
                        self.listenTo(selectedUserModel, 'change', function () {
                            self.renderDebounced();
                        });
                    }
                }

                self._selectedUserModel = selectedUserModel;
            }

            return self._selectedUserModel;
        },

        _htmlTemplate: function () {
            return EmPower.Templates['settings/account-management/account_information'];
        },

        _htmlData: function () {

            var userModel = this._userModel();
            var data = {
                user: userModel,
                foundOnFilteredCollection: this._selectedUser.foundOnFilteredCollection()
            };

            if (userModel) {
                data.userId = userModel.userId();
                data.firstName = userModel.firstName() ? userModel.firstName() : "[First Name]";
                data.lastName = userModel.lastName() ? userModel.lastName() : "[Last Name]";
                data.isEnabled = userModel.isEnabled() === 1 ? true : false;
                data.isHidden = userModel.isHidden() === 1 ? true : false;

                data.canChangePermission = userModel.getCanChangePermission();
                data.canDisable = userModel.getCanDisable();
                data.canEdit = userModel.getCanEdit();
                data.accessPanels = userModel.accessPanels();

                data.rows = [
                    {key: "Company", class: "", value: userModel.company()},
                    {key: "Title", class: "", value: userModel.title()},
                    {key: "Phone Number", class: "", value: userModel.phoneNumber()},
                    {key: "E-Mail", class: "", value: userModel.email()},
                    {key: "Access Card Number", class: "", value: userModel.accessCardNumber()},
                    {key: "Access Card ID", class: "", value: userModel.accessCardId()},
                    {key: "Access Card Facility ID", class: "", value: userModel.accessCardFacilityId()},
                    {key: "Role Type", class: "", value: userModel.roleName()}
                ];
            }

            return data;
        },

        _render: function () {
        },

        _show: function () {
        },

        _hide: function () {
        },

        _remove: function () {
        }
    });

})(jQuery);
