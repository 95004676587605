(function(TransformerConnection, $, undefined) {
    
    TransformerConnection.disconnect = function(transformer) {
        
        if (transformer && transformer.id) {
            
            EmPower.Dialog.SimpleDialog.Loading.open('Disconnecting transformer...');
            
            var url = EmPower.Util.Url.apiBuilder("transformers", transformer.id, "disconnect");
            
            $.ajax({
		url: url,
		type: 'GET',
		success: function(data) {
		    EmPower.Dialog.SimpleDialog.Loading.close();
                    $.notify({
                        message: "Disconnected <strong> Transformer ID " + transformer.id + "</strong>."
                    }, {
                        type: 'success',
                        placement: {
                            from: 'top',
                            align: 'center'
                        }
                    });
		},
		error: function() {
		    EmPower.Dialog.SimpleDialog.Loading.close();
		}
	    });
        }
    };
    
})(EmPower.Dialog.TransformerConnection = EmPower.Dialog.TransformerConnection || {}, jQuery);
