EmPower.Model.DaemonError = (function($, undefined) {
    
    return EmPower.Model.AbstractModel.extend({
	
	idAttribute: 'id',
	    
	modelName: 'Daemon Error',
	
	urlRoot: EmPower.Util.Url.apiBuilder('serverinfo', 'daemon', 'errors'),

	_initialize: function() {
	},

	_parse: function() {
	}
    });
    
})(jQuery);
