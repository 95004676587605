EmPower.View.Cooling = (function ($, undefined) {

    return EmPower.View.AbstractView.extend({
        viewName: "Transformer Cooling",

        _events: function () {
            var self = this;
            return {
                "click [data-action=acknowledge-sticky]": function (event) {
                    event.preventDefault();
                    var id = event.currentTarget.dataset.annuncid;
                    self.acknowledgeAnnunciator(id);
                }
            };
        },

        _initialize: function () {
            this._selectedTransformer = this._session.transformerSession().selectedTransformer();

            this._coolingLatestCollection = new EmPower.Collection.CoolingLatest(null, {
                session: this._session,
                selectedObject: this._selectedTransformer
            });

            this._pumpLatestCollection = new EmPower.Collection.PumpLatest(null, {
                session: this._session,
                selectedObject: this._selectedTransformer
            });

            this._fanbankLatestCollection = new EmPower.Collection.FanBankLatest(null, {
                session: this._session,
                selectedObject: this._selectedTransformer
            });

            this._coolingTransformerCollection = new EmPower.Collection.CoolingTransformer(null, {
                session: this._session,
                selectedObject: this._selectedTransformer
            });
        },

        _setupListeners: function () {
            var self = this;

            self.listenTo(self._transformerCollection, 'update reset', function () {
                this.renderDebounced();
            });
            self.listenTo(self._selectedTransformer, 'change', function () {
                this.renderDebounced();
            });
            self.listenTo(self._coolingLatestCollection, 'update reset', function () {
                this.renderDebounced();
            });
            self.listenTo(self._pumpLatestCollection, 'update reset', function () {
                this.renderDebounced();
            });
            self.listenTo(self._fanbankLatestCollection, 'update reset', function () {
                this.renderDebounced();
            });
            self.listenTo(self._coolingTransformerCollection, 'update reset', function () {
                this.renderDebounced();
            });

        },

        _htmlTemplate: function () {
            return EmPower.Templates['transformer/cooling/transformer_cooling'];
        },

        acknowledgeAnnunciator: function (annunciatorId) {
            var url = EmPower.Util.Url.apiBuilder("transformers", this._selectedTransformer.selectedId(), "annunciator", annunciatorId, "acknowledge");
            $.ajax({
                type: "POST",
                url: url,
                success: function () {
                    $.notify({
                        message: "<strong>Success:</strong>  Annunciator " + annunciatorId + " acknowledged. Status will update pending RCTU response."
                    }, {
                        type: 'success',
                        placement: {
                            from: 'top',
                            align: 'center'
                        }
                    });
                },
                error: function (err) {
                    $.notify({
                        message: "<strong>Failure:</strong> Could not acknowledge annunciator " + annunciatorId
                    }, {
                        type: 'danger',
                        placement: {
                            from: 'top',
                            align: 'center'
                        }
                    });
                }
            });

        },

        _htmlData: function () {
            var self = this;

            var coolingData = self._coolingLatestCollection.models;
            coolingData.forEach(function (system) {
                var fanId = system.attributes.fanbankFanBankId;
                var pumpId = system.attributes.pumpPumpId;

                system.pumpData = self._pumpLatestCollection.models.filter(function (pump) {
                    return pump.attributes.transformerPumpId === pumpId;
                });

                system.fanData = self._fanbankLatestCollection.models.filter(function (fanbank) {
                    return fanbank.attributes.transformerFanBankID === fanId;
                });

                var isOn = system.attributes.FlowStatus;
                switch (system.attributes.coolingType) {

                    case 'ODAF':
                        if (isOn) {
                            system.imgSrc = './images/Web_ODAF-OFAF_ON.png';
                        } else {
                            system.imgSrc = './images/Web_ODAF-OFAF_OFF.png';
                        }
                        break;
                    case 'ODWF':
                        if (isOn) {
                            system.imgSrc = './images/Web_ODAF-OFAF_ON.png';
                        } else {
                            system.imgSrc = './images/Web_ODAF-OFAF_OFF.png';
                        }
                        break;
                    case 'OFAF':
                        if (isOn) {
                            system.imgSrc = './images/Web_ODAF-OFAF_ON.png';
                        } else {
                            system.imgSrc = './images/Web_ODAF-OFAF_OFF.png';
                        }
                        break;
                    case 'OFWF':
                        if (isOn) {
                            system.imgSrc = './images/Web_ODAF-OFAF_ON.png';
                        } else {
                            system.imgSrc = './images/Web_ODAF-OFAF_OFF.png';
                        }
                        break;
                    case 'ONAF':
                        if (isOn) {
                            system.imgSrc = './images/Web_ONAF_ON.png';
                        } else {
                            system.imgSrc = './images/Web_ONAF_OFF.png';
                        }
                        break;
                    case 'ONAN':
                        system.imgSrc = './images/Web_ONAN.png';
                        break;
                    default:
                        system.imgSrc = './images/Web_ONAN.png';
                        break;
                }
            });

            var statusMap = {
                1: 'Unknown',
                2: 'Good',
                3: 'Bad',
                4: 'Degraded'
            };

            var coolingTransformerData = self._coolingTransformerCollection.models;

            return {
                coolingData: coolingData,
                statusMap: statusMap,
                transformerCooling: coolingTransformerData
            };
        },

        _render: function ($html) {

            var coolingData = this._coolingLatestCollection.models;
            if (coolingData.length === 0) {
                $html.find("#no-events-message").replaceWith(EmPower.Templates['templates/page_warning']({
                    message: "No events within date range"
                }));
            }
            else {
                $html.find("#no-events-wrapper").remove();
            }

            var annuncUrl = EmPower.Util.Url.apiBuilder("transformers", this._selectedTransformer.selectedId(), "/annunciator/cooling");
            var $annunciatorList = $html.find("#annunciator-list");

            $.ajax({
                type: "GET",
                url: annuncUrl,
                success: function (data) {
                    $annunciatorList.empty();

                    data.forEach(function (annunciator) {

                        var timestamp = annunciator.timestamp ? annunciator.timestamp : 'No Date';
                        var stateClass;
                        switch (annunciator.value) {
                            case 2: // annunciator needs acknowledged, flashing orange background
                                stateClass = "label-warning flashing acknowledgeable";
                                break;
                            case 1: // annunciator is firing, orange background
                                stateClass = "label-warning not-acknowledgeable";
                                break;
                            case 0: // annunciator is not firing, gray background
                                stateClass = "label-default not-acknowledgeable";
                                break;
                            default: // no events, grey background with grey text
                                stateClass = "label-default label-disabled not-acknowledgeable";
                                break;
                        }

                        $annunciatorList.append("<div class='annunciator-card " + stateClass + "' data-annuncId='" + annunciator.transformerAnnunciatorId + "' data-action='acknowledge-sticky'><p>" + annunciator.description + "</p><span>" + timestamp + "</div>");
                    });

                },
                error: function (err) {
                    console.log(err);
                }
            });

        },

        _show: function () {
        },

        _hide: function () {
        },

        _remove: function () {
        }
    });

})(jQuery);
