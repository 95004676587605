EmPower.Collection.Recorded = (function($, undefined) {
    
    return EmPower.Collection.AbstractSelectedObjectCollection.extend({
	collectionName: 'Recorded',

	model: EmPower.Model.Recorded,
	
	url: function() {
	    return EmPower.Util.Url.apiBuilder('transformers', this._selectedObject.selectedId(), 'videos');
	},
	
	_initialize: function() {
	},
	
	_parse: function() {
	}
        
//        downloadUrl: function() {
//            return this.url() + "/download";
//        }
    });
    
})(jQuery);