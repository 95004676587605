EmPower.Collection.PressureEvent = (function($, undefined) {
    
    return EmPower.Collection.AbstractDeviceEventCollection.extend({
	collectionName: 'Pressure Event',
	
	model: EmPower.Model.Pressure,
	
	_url: function() {
	    return EmPower.Util.Url.apiBuilder('transformers', this._selectedObject.selectedId(), 'pressure', 'events');
	},
	
	_initialize: function() {
	},
	
	_parse: function() {
	}
    });
    
})(jQuery);
