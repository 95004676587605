EmPower.View.AbstractGraphView = (function(View, $, undefined) {
    
    return EmPower.View.AbstractView.extend({
        
        _events: function() {
            
            return {
                'click span[data-action=graph-legend]': function(event) {

                    if (event && this._chart) {
                        event.preventDefault();
                        
                        var datasetId = $(event.currentTarget).attr('data-dataset-id');
                        
                        var dataset = this._chart.data.datasets[datasetId];
                        dataset.hidden = dataset.hidden ? null : true;
                        
                        var meta = this._chart.getDatasetMeta(datasetId);
                        meta.hidden = meta.hidden ? null : true;
                        
                        var $elements = this.$html.find("div[data-section=graph-legend]").find("span[data-dataset-id=" + datasetId + "]");
                        if (meta.hidden) {
                            $elements.addClass("inactive");
                        } else {
                            $elements.removeClass("inactive");
                        }
                        
                        this._chart.update();
                    }
                },
                'click button[data-action=download-graph]': function(event) {
                    if (event && this._chart) {
                        event.preventDefault();
                        
                        EmPower.Dialog.SimpleDialog.Loading.open('Generating graph image...');
                        
                        var transformerName = $("#transformer-header-text").text().split(" - ")[0].trim();
                        
                        var title = this._chart.options.title.text ? transformerName + ": " + this._chart.options.title.text : transformerName + this.viewName;
                        var options = _.merge({}, this.graphOptions().options, {
                            animation: false,
                            responsive: false,
                            maintainAspectRatio: true,
                            title: {
                                display: true,
                                text: title + " - " + this._session.dateRange().fromDateFormattedLocal() + " to " + 
                                        this._session.dateRange().toDateFormattedLocal()
                            },
                            legend: {
                                display: true,
                                position: 'right',
                            }
                        });

                        var $canvas = $('<canvas/>',{'class':'hide'})
                            .width(1920)
                            .height(1080)
                            .appendTo('body');
                        var chart = EmPower.Util.SimpleChart.lineChart($canvas, options);
                        chart.data.datasets = this._chart.data.datasets;
                        chart.update();
                        
                        var fileName = title ? _.snakeCase(title) : "chart";
                        fileName += ".png";
                        if (navigator && navigator.msSaveBlob) { // IE 10+ 
                            var blob = chart.canvas.msToBlob();
                            navigator.msSaveBlob(new Blob([blob], { type: 'image/png;' }), fileName); 
                        } else {
                            var url = chart.canvas.toDataURL("image/png");
                            var a = document.createElement('a');
                            a.href = url;
                            a.download = fileName;
                            a.style.display = 'none';
                            document.body.appendChild(a);
                            a.click();
                            document.body.removeChild(a);
                        }
                        
                        chart.destroy();
                        $canvas.remove();
                        EmPower.Dialog.SimpleDialog.Loading.close();
                    }
                }
            };
	},
        
        initialize: function() {
            
            this._graphOptions = {};
            
            if (!_.isFunction(this._getCollection)) {
                this.collections(this._getOption("collections", false));
            }
            
            if (!_.isFunction(this._getGraphOptions)) {
                this.graphOptions(this._getOption("graphOptions", false));
            }
	    
	    View.prototype.initialize.apply(this, arguments);
            
            if (!this.collections() && _.isFunction(this._getCollection)) {
                this.collections(this._getCollection());
            }
            
            if (!this.graphOptions() && _.isFunction(this._getGraphOptions)) {
                this.graphOptions(this._getGraphOptions());
            }
	},

        collections: function(collections) {
            if (collections !== undefined) {
                if (!_.isArray(collections)) {
                    collections = [collections];
                }
                this._collections = collections;
            }
            return this._collections;
        },
        
        graphOptions: function(graphOptions) {
            if (graphOptions !== undefined) {
                this._graphOptions = graphOptions || {};
            }
            return this._graphOptions;
        },
        
        _setupGraphListeners: function(collections) {
            
            var self = this;
            
            if (collections) {
                _.forEach(collections, function(collection) {
                    if (collection) {
                        
                        self.listenTo(collection, 'update reset', function() {
                            self.renderDebounced();
                        });

                        self.listenTo(collection, 'error', function() {
                            if (!self._hasError) {
                                self.renderDebounced();
                            }
                        });

                        self.listenTo(collection, 'sync', function() {
                            if (self._initialSync || self._hasError) {
                                self.renderDebounced();
                            }
                        });

                    } else {

                        self._logger.warn("Graph View was not extended with a specific collection. No listeners setup.");
                    }
                });
            }
        },
        
	_setupListeners: function(collections) {
            
            var self = this;
            
            if (collections !== undefined) {
                
                if (!_.isArray(collections)) {
                    collections = [collections];
                }
                
                if (!_.isEmpty(self.collections()) && !_.isEmpty(_.xor(self.collections(), collections))) {
                    _.forEach(self.collections(), function(collection) {
                        if (collection) {
                            self.stopListening(collection);
                        }
                    });
		}
                
                self._setupGraphListeners(collections);
            } else {
                
                collections = self.collections();
                
                self._setupGraphListeners(collections);
            }
            
	},
        
	_htmlTemplate: function() {
	    return EmPower.Templates['templates/graph_container'];
	},
	
        _htmlData: function() {
	},
        
	_render: function($html) {
            
            var self = this;
            
            $html.find("div[data-section=graph-legend]").hide();
            var $graphContainer = $html.find('canvas[data-section="graph-canvas"]');
            if (self.collections()) {
                
                var completedSync = 0;
                self._initialSync = false;
                self._hasError = false;
                self._hasModels = null;
                _.forEach(self.collections(), function(collection) {
                    
                    if (collection) {
                        if (!collection.isFetched()) {
                            self._initialSync = true;
                        } else {
                            completedSync++;
                        }
                        if (collection.hasError()) {
                            self._hasError = true;
                        }
                        /*if (collection.length && self._hasModels !== false) {
                            self._hasModels = true;
                        } else {
                            self._hasModels = false;
                        }*/
                        if (collection.length) {
                            self._hasModels = true;
                        }
                    }
                });

                if (!self._initialSync && !self._hasError && self.collections() && self._hasModels) {

                    $html.find("div.alert-info").removeClass("hide");

                    setTimeout(function() {
                        
                        if (!self._chart) {
                            self._chart = EmPower.Util.SimpleChart.lineChart($graphContainer, self.graphOptions().options);
                        } else {
                            self._chart.unbindEvents();
                            $graphContainer.replaceWith(self._chart.chart.canvas);
                            self._chart.bindEvents();
                        }
                        self._logger.info("Chart: ", self._chart);

                        if (self._chart && self._hasModels) {
                            
                            self._chart.data.datasets = EmPower.Util.SimpleChart.datasetGenerator(self.collections(), self.graphOptions().datasetConfig);
                            
                            $html.find("div[data-section=graph-legend]").show();
                            $html.find("div[data-section=graph-legend]").html(self._chart.generateLegend());
                            
                            self._chart.update();
                        }

                        $html.find("div.alert-info").addClass("hide");
                    }, 1);
                } else {

                    var $template;
                    if (this._initialSync && !this._hasError) {

                        $template = $(EmPower.Templates['templates/page_loading']({
                            message: "Loading events (" + completedSync + " of " + this._collections.length + " collections loaded), please wait..."
                        }));

                    } else if (this._hasError) {

                        $template = $(EmPower.Templates['templates/page_error']({
                            message: "Sorry, could not load events."
                        }));

                    } else {

                        $template = $(EmPower.Templates['templates/page_warning']({
                            message: "Sorry, no events within selected date range."
                        }));
                    }

                    if ($template) {
                        $graphContainer.replaceWith($template);
                    }
                }
                
            } else {
                    
                $graphContainer.replaceWith(EmPower.Templates['templates/page_error']({
                    message: "Sorry, graph does not have a dataset."
                }));
            }
	},
	
	_show: function() {
	},
	
        _afterShow: function() {
            if (this._chart) {
                this._chart.bindEvents();
            }
        },
        
        _beforeHide: function() {
            if (this._chart) {
                this._chart.unbindEvents();
                this._chart.destroy();
                this._chart = undefined;
                this._reRender = true;
            }
        },
        
	_hide: function() {
	},
	
	_remove: function() {
            if (this._chart) {
                this._chart.destroy();
            }
	},
        
        resizeChart: function() {
            if (this.isVisible() && this._chart) {
                this._chart.resize();
            }
        }
    });
    
})(EmPower.View.AbstractView, jQuery);
