EmPower.View.PageNotFound = (function($, undefined) {

    return EmPower.View.AbstractView.extend({
	viewName: "Page Not Found",
	
	_events: function() {
	},
	
	_initialize: function() {
	},
	
	_setupListeners: function() {
	},
	
	_htmlTemplate: function() {
	    return EmPower.Templates['page-not-found/page_not_found'];
	},
	
	_htmlData: function() {
	},
	
	_render: function() {
	},
	
	_show: function() {
	},
	
	_hide: function() {
	},
	
	_remove: function() {
	}
	
    });
    
})(jQuery);
