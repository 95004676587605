EmPower.Model.Battery = (function($, undefined) {
    
    return EmPower.Model.AbstractDeviceEventModel.extend({
	
	idAttribute: 'fileName',
	    
	modelName: 'Battery',

	_initialize: function() {
	},

	_parse: function() {
	},
	
	description: function(value) {
	    return this._field('description', value);
	},

	fileName: function(value) {
	    return this._field('fileName', value);
	}
        
    });
    
})(jQuery);
