EmPower.Collection.User = (function($, undefined) {
    
    return EmPower.Collection.AbstractCollection.extend({
	
	url: EmPower.Util.Url.apiBuilder('user'),

	model: EmPower.Model.User,
	
	collectionName: 'User',
	
	_initialize: function() {
	    this._canCreateUser = false;
	},
	
	_parse: function(response) {
	    
	    this._canCreateUser = response.canCreate ? true : false;
	},
	
	comparator: function(userOne, userTwo) {
	    var sort = 0;
	    if (userOne.lastName()) {
		sort = userOne.lastName().localeCompare(userTwo.lastName());
	    }
	    if (sort === 0) {
		if (userOne.firstName()) {
		    sort = userOne.firstName().localeCompare(userTwo.firstName());
		}
		if (sort === 0) {
		    if (userOne.username()) {
			sort = userOne.username().localeCompare(userTwo.username());
		    }
		}
	    }
	    
	    return sort;
	},
	
	getCanCreateUser: function() {
	    return this._canCreateUser ? true : false;
	}
    });
    
})(jQuery);
