EmPower.View.Temperature = (function ($, undefined) {

    return EmPower.View.AbstractView.extend({
        viewName: "Transformer Temperature",

        _events: function () {
            return {
                "click [id=tempunit-switch]": function (event) {
                    event.preventDefault();
                    $("#tempunit-switch").toggleClass('active');
                    this._temperatureUnit = !this._temperatureUnit;
                    this._updateTemperatureList();
                }
            };
        },

        _initialize: function () {
            this._selectedTransformer = this._session.transformerSession().selectedTransformer();

            this._temperatureCollection = this._session.transformerSession().temperatureCollectionProxy();

            this._temperatureUnit = false;
        },

        _setupListeners: function () {
        },

        _getTemperatureUnit: function () {
            if (this._temperatureUnit) {
                return "F";
            }
            return "C";
        },

        _updateTemperatureList: function () {
            var $html = $("#transformer-temperature");
            var $temperatureList = $html.find("div[data-section='transformer-temperature-list']");

            this._temperatureList = new EmPower.View.TemperatureList({
                session: this._session,
                collection: this._temperatureCollection,
                selectedModel: null,
                temperatureUnit: this._getTemperatureUnit()
            });
            this._temperatureList.render($temperatureList);

        },

        _htmlTemplate: function () {
            return EmPower.Templates['transformer/temperature/transformer_temperature'];
        },

        _htmlData: function () {
            return {
                selectedTransformer: this._selectedTransformer.selected()
            };
        },

        _render: function ($html) {
            var $weather = $html.find("div[data-section='transformer-temperature-weather']");
            if (!this._weather) {

                this._weather = new EmPower.View.Weather({
                    session: this._session
                });
                this._weather.render($weather);
            } else {
                $weather.replaceWith(this._weather.$el);
            }

            var $temperatureList = $html.find("div[data-section='transformer-temperature-list']");
            if (!this._temperatureList) {

                this._temperatureList = new EmPower.View.TemperatureList({
                    session: this._session,
                    collection: this._temperatureCollection,
                    selectedModel: null,
                    temperatureUnit: this._getTemperatureUnit()
                });
                this._temperatureList.render($temperatureList);
            } else {
                $temperatureList.replaceWith(this._temperatureList.$el);
            }
        },

        _show: function () {
            if (this._weather) {
                this._weather.show();
            }
            if (this._temperatureList) {
                this._temperatureList.show();
            }
        },

        _hide: function () {
            if (this._weather) {
                this._weather.hide();
            }
            if (this._temperatureList) {
                this._temperatureList.hide();
            }
        },

        _remove: function () {
            if (this._weather) {
                this._weather.remove();
            }
            if (this._temperatureList) {
                this._temperatureList.remove();
            }
        }
    });

})(jQuery);
