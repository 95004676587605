EmPower.View.Weather = (function ($, undefined) {

    return EmPower.View.AbstractView.extend({
        viewName: "Transformer Weather",

        _events: function () {
            return {
                "click [data-action='refresh-weather']": function () {
                    this.renderDebounced();
                }
            };
        },

        _initialize: function () {
            this._transformerCollection = this._session.transformerSession().transformerCollection();
            this._selectedTransformer = this._session.transformerSession().selectedTransformer();
            this._transformerModel(this._selectedTransformer.selected());
        },

        _setupListeners: function () {

            var self = this;

            self.listenTo(self._selectedTransformer, 'change', function () {
                self._transformerModel(self._selectedTransformer.selected());
                self.renderDebounced();
            });
        },

        _transformerModel: function (selectedTransformerModel) {

            var self = this;

            if (selectedTransformerModel !== undefined) {

                if (self._selectedTransformerModel !== selectedTransformerModel) {

                    if (self._selectedTransformerModel) {
                        self.stopListening(self._selectedTransformerModel);
                    }

                    if (selectedTransformerModel) {
                        self.listenTo(selectedTransformerModel, 'change:latitude change:longitude', function () {
                            self.renderDebounced();
                        });
                    }
                }

                self._selectedTransformerModel = selectedTransformerModel;
            }

            return self._selectedTransformerModel;
        },

        _htmlTemplate: function () {
            return EmPower.Templates['transformer/weather/transformer_weather'];
        },

        _htmlData: function () {
            return {
                isTransformersFetched: this._transformerCollection.isFetched(),
                hasSelectedTransformer: this._transformerModel() ? true : false
            };
        },

        _render: function ($html, data) {

            if (data.hasSelectedTransformer) {

                var self = this;
                var $container = $html.filter("div[data-section='weather']");

//                var url = 'https://cors-anywhere.herokuapp.com/https://api.darksky.net/forecast/ae56a1e38f0bac4f9189e5240ac8b31a/' + self._transformerModel().latitude() + ',' + self._transformerModel().longitude() + '?units=si';
                var url = 'https://cors-anywhere.herokuapp.com/https://api.openweathermap.org/data/2.5/onecall?lat=' + self._transformerModel().latitude() + '&lon=' + self._transformerModel().longitude() + '&appid=1dc8a7b82a9192e084dc4f88baa5b777&units=metric';

                $.ajax({
                    url: url,
                    type: 'GET',
                    crossDomain: true,
                    success: function (data) {
//                        data.daily.data[0].sunriseTime = moment(data.daily.data[0].sunriseTime*1000).format("LT");
//                        data.daily.data[0].sunsetTime = moment(data.daily.data[0].sunsetTime*1000).format("LT");
//                        data.currently.time = moment(data.currently.time*1000).format();
//                        
//                        _.forEach(data.daily.data, function(forecast) {
//                            forecast.day = moment(forecast.time*1000).format("ddd");
//                            forecast.date = moment(forecast.time*1000).format("Do MMM YYYY");
//                        });

                        data.current.sunrise = moment(data.current.sunrise * 1000).format("LT");
                        data.current.sunset = moment(data.current.sunset * 1000).format("LT");
                        data.current.dt = moment(data.current.dt * 1000).format();
                        data.current.iconUrl = "http://openweathermap.org/img/wn/" + data.current.weather[0].icon + "@2x.png";
                        data.current.description = data.current.weather.map(function (condition) {
                            return condition.description;
                        }).join(", ");

                        _.forEach(data.daily, function (forecast) {
                            forecast.day = moment(forecast.dt * 1000).format("ddd");
                            forecast.date = moment(forecast.dt * 1000).format("Do MMM YYYY");
                            forecast.iconUrl = "http://openweathermap.org/img/wn/" + forecast.weather[0].icon + ".png";
                            forecast.description = forecast.weather.map(function (condition) {
                                return condition.description;
                            }).join(", ");
                        });

                        if (!self._weatherApiHtml) {
                            self._weatherApiHtml = EmPower.Templates['transformer/weather/weather'];
                        }
                        $container.html(self._weatherApiHtml(data));
                    },
                    error: function (err) {
                        console.log("Could not load weather data: ", err);
                    }
                });

//                var createWeather = function(tries) {
//                    $.simpleWeather({
//                        location: self._transformerModel().latitude() + ", " + self._transformerModel().longitude(),
//                        unit: 'c',
//                        success: function(weather) {
//                            if (!self._weatherApiHtml) {
//                                self._weatherApiHtml = EmPower.Templates['transformer/weather/weather'];
//                            }
//                            $container.html(self._weatherApiHtml(weather));
//                        },
//                        error: function(error) {
//                            if (tries < 10) {
//                                tries++;
//                                createWeather(tries);
//                            } else {
//                                if (!self._weatherApiErrorHtml) {
//                                    self._weatherApiErrorHtml = EmPower.Templates['templates/page_error'];
//                                }
//                                $container.html(self._weatherApiErrorHtml({message: "Sorry, could not load weather information. <a href='javascript:void(null);' data-action='refresh-weather'>Please click here to reload.</a>"}));
//                            }
//                        }
//                    });
//                };
//                createWeather(0);

            }

        },

        _show: function () {
        },

        _hide: function () {
        },

        _remove: function () {
        }
    });

})(jQuery);
