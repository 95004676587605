EmPower.View.MetrologyDetectedEventTable = (function($, undefined) {

    return EmPower.View.AbstractEventTableView.extend({
	viewName: "Transformer Metrology Event Table",
	
	_getEventCollection: function() {
	    return this._session.transformerSession().metrologyDetectedEventCollection();
	},
	
	_createTableView: function($container, eventCollection) {
	    return new bbGrid.View({
		rows: 7,
		container: $container,
		collection: eventCollection,
		colModel: [{
			title: 'Timestamp',
			index: true,
			name: 'timestamp',
			sorttype: 'number'
		    }, {
			title: 'Event Type',
			index: true,
			name: 'eventType',
			sorttype: 'number'
		    }, {
			title: 'Recording ID',
			index: true,
			name: 'recordingId',
			sorttype: 'number'
		    }, {
			title: 'Count',
			index: true,
			name: 'count',
			sorttype: 'number'
		    }, {
			title: 'Value 0',
			index: true,
			name: 'value0',
			sorttype: 'number'
		    }, {
			title: 'Value 1',
			index: true,
			name: 'value1',
			sorttype: 'number'
		    }, {
			title: 'Value 2',
			index: true,
			name: 'value2',
			sorttype: 'number'
		    }]
	    });
	}
    });
    
})(jQuery);
