EmPower.Model.AbstractUserInformation = (function($, undefined) {
    
    return EmPower.Model.AbstractNestedModel.extend({
	
	_userInformationRoot: null,
	
	_getUserInformationRoot: function() {
	    return (this._userInformationRoot ? (this._userInformationRoot + ".") : "");
	},
	
	userInformationId: function(value) {
	    return this._field(this._getUserInformationRoot() + 'userInformationId', value);
	},

	username: function(value) {
	    return this._field(this._getUserInformationRoot() + 'username', value);
	},

	firstName: function(value) {
	    return this._field(this._getUserInformationRoot() + 'firstName', value);
	},

	lastName: function(value) {
	    return this._field(this._getUserInformationRoot() + 'lastName', value);
	},

	phoneNumber: function(value) {
	    return this._field(this._getUserInformationRoot() + 'phoneNumber', value);
	},

	email: function(value) {
	    return this._field(this._getUserInformationRoot() + 'email', value);
	},

	accessCardId: function(value) {
	    return this._field(this._getUserInformationRoot() + 'accessCardId', value);
	},

	accessCardNumber: function(value) {
	    return this._field(this._getUserInformationRoot() + 'accessCardNumber', value);
	},

	accessCardFacilityId: function(value) {
	    return this._field(this._getUserInformationRoot() + 'accessCardFacilityId', value);
	},

	picturePath: function(value) {
	    return this._field(this._getUserInformationRoot() + 'picturePath', value);
	},

	company: function(value) {
	    return this._field(this._getUserInformationRoot() + 'company', value);
	},

	title: function(value) {
	    return this._field(this._getUserInformationRoot() + 'title', value);
	},

	accessPanels: function(value){
		return this._field(this._getUserInformationRoot() + 'userAccessPanels', value);
	}
    });
    
})(jQuery);
