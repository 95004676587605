EmPower.Collection.DatabaseInformation = (function($, undefined) {
    
    return EmPower.Collection.AbstractCollection.extend({
	
	url: EmPower.Util.Url.apiBuilder('serverinfo', 'database'),

	model: EmPower.Model.DatabaseInformation,
	
	collectionName: 'Database Information',
	
	_initialize: function() {
	},
	
	_parse: function() {
	}
    });
    
})(jQuery);
