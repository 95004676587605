EmPower.View.Map = (function($, undefined) {
    
    // private namespace
    var that = {};
    
    that.tileLayers = {
	"Street": L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
	    noWrap: true,
	    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
	}),
	"Satellite": L.tileLayer("https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}", {
	    noWrap: true,
	    attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
	}),
        "Street - Dark Theme": L.tileLayer('https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}.png', {
            noWrap: true,
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> &copy; <a href="https://cartodb.com/attributions">CartoDB</a>',
            subdomains: 'abcd',
        }),
        "Street - Grey Theme": L.tileLayer('https://stamen-tiles-{s}.a.ssl.fastly.net/toner-lite/{z}/{x}/{y}.{ext}', {
            noWrap: true,
            attribution: 'Map tiles by <a href="https://stamen.com">Stamen Design</a>, <a href="https://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
            subdomains: 'abcd',
            ext: 'png'
        })
        /* HTTPS not supported */
//        "Street - Black and White": L.tileLayer('https://{s}.tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png', {
//            noWrap: true,
//            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
//        })
    };
    
    return EmPower.View.AbstractView.extend({
	viewName: "Map",
	
	_events: function() {
	    return {
		'click div[data-action="transformer-information-toggle"] > a': this._toggleTransformerInformation
	    };
	},
	
	_initialize: function() {
            
            this._transformerCollectionProxy = this._session.transformerSession().transformerCollectionProxy();
	    
	    this._selectedTransformer = this._session.transformerSession().selectedTransformer();
	    
	    this._transformerMarkers = {};
	    
	    this._quickInfoVisible = false;
	    
	    var self = this;
	    self.listenTo(self._session, 'reset', function() {
		self._quickInfoVisible = false;
		self.renderDebounced();
	    });
	},
	
	_setupListeners: function() {
	    
	    var self = this;
	    
	    self.listenTo(self._transformerCollectionProxy, 'add remove update reset', function() {
		self.renderDebounced();
	    });
            
            self.listenTo(self._selectedTransformer, 'change', function() {
		self.flyToTransformerLocationDebounced(self._selectedTransformer.selected());
                self.highlightSelectedTransformerMarkerDebounced(self._selectedTransformer);
            });
	},
	
        flyToTransformerLocation: function(transformerModel) {
            if (this._map && transformerModel && this._selectedTransformer.foundOnFilteredCollection()) {
                var zoomLevel = this._map.getZoom();
                zoomLevel = this._map.getZoom() > 10 ?  this._map.getZoom() : 10;
                this._map.flyTo(L.latLng(transformerModel.latitude(), transformerModel.longitude()), zoomLevel, {
                    animate: this.isVisible() ? true : false
                });
            } else if (this._map) {
                this._map.flyTo(this._map.options.center, this._map.options.zoom, {
                    animate: this.isVisible() ? true : false
                });
            }
        },
        flyToTransformerLocationDebounced: function(transformerModel) {
            if (!this._debouncedFlyToTransformerLocation) {
		this._debouncedFlyToTransformerLocation = _.debounce(this.flyToTransformerLocation, 1);
	    }
	    
	    return this._debouncedFlyToTransformerLocation(transformerModel);
        },
        
        highlightSelectedTransformerMarker: function(selectedTransformer) {
            if (this._map && selectedTransformer) {
                var marker;
                if (selectedTransformer.selected() && this._selectedTransformer.foundOnFilteredCollection()) {

                    marker = this._transformerMarkers[selectedTransformer.selected().cid];
                    if (marker) {
                        marker.setIcon(L.AwesomeMarkers.icon({
                            prefix: "fa",
                            markerColor: "blue",
                            icon: "bolt",
                            iconColor: "black"
                        }));
                        marker.update();
                    }
                }
                if (selectedTransformer.getPreviouslySelected() && selectedTransformer.selected() !== selectedTransformer.getPreviouslySelected()) {
                    marker = this._transformerMarkers[selectedTransformer.getPreviouslySelected().cid];

                    if (marker) {
                       marker.setIcon(L.AwesomeMarkers.icon({
                            prefix: "fa",
                            markerColor: "green", // get color based on health and selected?...
                            icon: "bolt",
                            iconColor: "black"
                        })); 
                       marker.update();
                    }
                }
            }
        },
        highlightSelectedTransformerMarkerDebounced: function(transformerModel) {
            if (!this._debouncedHighlightSelectedTransformerMarker) {
		this._debouncedHighlightSelectedTransformerMarker = _.debounce(this.highlightSelectedTransformerMarker, 1);
	    }
	    
	    return this._debouncedHighlightSelectedTransformerMarker(transformerModel);
        },
        
	_htmlTemplate: function() {
	    
	    return EmPower.Templates['map/map'];
	},
	
	_htmlData: function() {
	    return {
		quickInfoVisible: this._quickInfoVisible
	    };
	},
	
	_render: function($html) {
	    
	    var $mapContainer = $html.find('div[data-content="empower-map"]');
	    if (!this._map) {
		
		this._map = L.map($mapContainer.get(0), {
		    layers: that.tileLayers.Street,
		    maxBounds: L.latLngBounds(L.latLng(-90, -180), L.latLng(90, 180)),
		    center: L.latLng(0, 0),
		    zoom: 2,
		    minZoom: 2
		});
		
		var baseLayers = that.tileLayers;
		this._controlLayer = L.control.layers(baseLayers, null);
		this._controlLayer.addTo(this._map);
	    } else {
		$mapContainer.replaceWith(this._map.getContainer());
	    }
	    
	    this._logger.info("Map Instance: ", this._map);
	    
	    this._logger.info("Control Layer: ", this._controlLayer);
	    
	    this._renderOverlays();
	    
	    var self = this;
            var $transformerListResizable = $html.find('div[data-action="transformer-list-resizable"]');
            if (!this._transformerListResizable) {
                this._transformerListResizable = $transformerListResizable.resizable({
                    handles: "n",
                    stop: function() {
                        if (self._map) {
                            self._map.invalidateSize(true);
                        }
                    }
                });
            } else {
                $transformerListResizable.replaceWith(this._transformerListResizable);
            }
	    
	    var $transformerList = $html.find('div[data-section="transformer-list-container"]');
	    if (!this._transformerList) {
		this._transformerList = new EmPower.View.TransformerList({
		    session: this._session,
		    collection: this._transformerCollectionProxy,
		    selectedModel: this._selectedTransformer
		});
		this._transformerList.render($transformerList);
	    } else {
		$transformerList.replaceWith(this._transformerList.$el);
	    }
	    
	    var $transformerInformation = $html.find("div[data-section='transformer-information']");
	    if (!this._transformerInformation) {
		this._transformerInformation = new EmPower.View.TransformerInformation({
		    session: this._session
		});
		this._transformerInformation.render($transformerInformation);
	    } else {
		$transformerInformation.replaceWith(this._transformerInformation.$el);
	    }
	    if (!this._quickInfoVisible) {
		this._transformerInformation.hide();
	    }
            
            if (self._map) {
                setTimeout(function() {
		    self._map.invalidateSize(true);
		}, 1);
            }
	},
	
	_renderOverlays: function() {
	    
	    var self = this;

	    var transformerModels = self._transformerCollectionProxy.models;
	    if (self._map && transformerModels) {
		
		if (!self._transformerMarkerCluster) {

		    self._transformerMarkerCluster = L.markerClusterGroup();
		    self._controlLayer.addOverlay(self._transformerMarkerCluster, "Transformers");
		    self._map.addLayer(self._transformerMarkerCluster);
		    self._logger.info("Transformer Layer Group: ", self._transformerMarkerCluster);
		
                    self._transformerMarkerCluster.on('click', function (markerCluster) {
                        
                        if (markerCluster.layer.cid !== undefined && markerCluster.layer.cid !== null) {
                            var selectedTransformer = self._transformerCollectionProxy.get({cid: markerCluster.layer.cid});
                            if (selectedTransformer) {
                                self._selectedTransformer.selected(selectedTransformer);
                            }
                        }
                    });
                    
                    self._transformerMarkerCluster.on('dblclick', function (markerCluster) {
                        
                        if (markerCluster.layer.cid !== undefined && markerCluster.layer.cid !== null) {
                            var selectedTransformer = self._transformerCollectionProxy.get({cid: markerCluster.layer.cid});
                            if (selectedTransformer) {
                                self._selectedTransformer.selected(selectedTransformer);
                            }
                            
                            self._session.applicationRouter().getTransformersModule().showHealth();
                        }
                    });
                }
		
		var addedTransformerMarkers = [];
		var deletedTransformerMarkers = _.clone(self._transformerMarkers);
		_.forEach(transformerModels, function(transformerModel) {
		    
		    delete deletedTransformerMarkers[transformerModel.cid];
		    var transformerMarker = self._transformerMarkers[transformerModel.cid];
		    var latLng = L.latLng(transformerModel.latitude(), transformerModel.longitude());
		    
		    if (!transformerMarker) {
			
			transformerMarker = L.marker(latLng, {
			    icon: L.AwesomeMarkers.icon({
				prefix: "fa",
				markerColor: "green", // get color based on health and selected?...
				icon: "bolt",
				iconColor: "black"
			    })
			});
			
			//transformerMarker.bindPopup(transformerModel.transformerName());
			
			transformerMarker.bindTooltip(transformerModel.transformerName());
			
                        transformerMarker.cid = transformerModel.cid;
                        
			self._logger.info("Adding marker: ", transformerModel.cid, transformerMarker);
			self._transformerMarkers[transformerModel.cid] = transformerMarker;
			addedTransformerMarkers.push(transformerMarker);
                        
                        self.listenTo(transformerModel, "change", function() {
                            transformerMarker
                                .setLatLng(L.latLng(transformerModel.latitude(), transformerModel.longitude()))
				.setTooltipContent(transformerModel.transformerName())
                                .update();
                        });
		    } else {
			
			// this doesnt get called on model update, need a new listener
			transformerMarker
                            .setLatLng(L.latLng(transformerModel.latitude(), transformerModel.longitude()))
                            .setTooltipContent(transformerModel.transformerName())
                            .update();
		    }
		});

		self._transformerMarkerCluster.addLayers(addedTransformerMarkers);
		self._transformerMarkerCluster.removeLayers(_.values(deletedTransformerMarkers));
                
                self.highlightSelectedTransformerMarker(self._selectedTransformer);
                self.flyToTransformerLocation(self._selectedTransformer.selected());
	    }
	    
	},
	
	_renderOverlaysDebounced: function() {
	    
	    var self = this;
	    
	    var debounce = _.debounce(function() {
		return self._renderOverlays();
	    }, 1);

	    self._renderOverlaysDebounced = debounce;

	    return self._renderOverlaysDebounced();
	},
	
	_toggleTransformerInformation: function(event) {
	    
	    var $button = $(event.currentTarget);

	    if ($button.attr('data-action') === "collapse") {
		$button.parent().addClass("collapsed");
		this._quickInfoVisible = false;
		if (this._transformerInformation) {
		    this._transformerInformation.hide("blind", {
			direction: "right"
		    }, 250);
		}
	    } else {
		$button.parent().removeClass("collapsed");
		this._quickInfoVisible = true;
		if (this._transformerInformation) {
		    this._transformerInformation.show("blind", {
			direction: "right"
		    }, 250);
		}
	    }
	    
	    var self = this;
	    
	    if (self._map) {
		
		// Resize map whenever container goes from hidden to visible state
		setTimeout(function() {
		    self._map.invalidateSize(true);
		}, 250);
	    }
	},
	
	_onVisible: function() {
	    
	    var self = this;
	    
	    if (self._map) {
		
		// Resize map whenever container goes from hidden to visible state
		setTimeout(function() {
		    self._map.invalidateSize(false);
		}, 1);
	    }
	},
	
	_show: function() {
	    if (this._transformerInformation && this._quickInfoVisible) {
		this._transformerInformation.show();
	    }
	    if (this._transformerList) {
		this._transformerList.show();
	    }
	},
	
	_hide: function() {
	    if (this._transformerInformation) {
		this._transformerInformation.hide();
	    }
	    if (this._transformerList) {
		this._transformerList.hide();
	    }
	},
	
	_remove: function() {
	    if (this._transformerInformation) {
		this._transformerInformation.remove();
	    }
	    if (this._transformerList) {
		this._transformerList.remove();
	    }
	}
    });

})(jQuery);
