EmPower.Model.Pressure = (function($, undefined) {
    
    return EmPower.Model.AbstractDeviceEventModel.extend({
	    
	modelName: 'Pressure',

	_initialize: function() {
	},

	_parse: function(response, options) {
	    return response;
	},
	
	value: function(value) {
	    return this._field('value', value);
	},
	
	valueDisplay: function() {
	    return (this.value() !== undefined && this.value() !== null) ? (this.value() * 0.145037738).toFixedNumber(1) + " psi / " + this.value() + " kPa"  : "No Events";
	},
	
	inputNumber: function(value) {
	    return this._field('inputNumber', value);
	},
	
	type: function(value) {
	    return this._field('type', value);
	},
        
        analogType: function(value) {
            return this._field('analogType', value);
        },
        
        units: function(value) {
            return this._field('units', value);
        },
        
        precision: function(value) {
            return this._field('precision', value);
        },
        
        preciseValue: function() {
            var value = this.value();
            var units = this.units();
            
            if (units.localeCompare("PSI") && value) {
                return (value * 0.145037738).toFixed(this.precision()) + " psi / " + value + " kPa";
            }
            
            return value.toFixed(this.precision()) + " " + units;
        }
    });
    
})(jQuery);
