EmPower.Collection.ConditionIndex = (function($, undefined) {
    
    return EmPower.Collection.AbstractSelectedObjectCollection.extend({
	collectionName: 'Condition Index',

	model: EmPower.Model.ConditionIndex,
	
	url: function() {
            return EmPower.Util.Url.apiBuilder('transformers', this._selectedObject.selectedId(), 'opm', 'conditionindex_new');
	},
	
	_initialize: function() {
	},
	
	_parse: function(response) {
	    
	    var models = [];
	    var conditionIndexes = response;
	    
	    if (conditionIndexes && !_.isEmpty(conditionIndexes)) {
		
		_.forEach(_.values(conditionIndexes), function(conditionIndexs) {
		    
		    _.forEach(conditionIndexs, function(conditionIndex) {
			models.push(conditionIndex);
		    });
		});
	    }
	    
	    return models;
	}
    });
    
})(jQuery);
