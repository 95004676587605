EmPower.View.Moisture = (function($, undefined) {

    return EmPower.View.AbstractView.extend({
	viewName: "Transformer Moisture",
	
	_events: function() {
	},
	
	_initialize: function() {
	    this._selectedTransformer = this._session.transformerSession().selectedTransformer();
	    
            // add new filter for water types...
	    this._dgaCollection = new Backbone.Obscura(this._session.transformerSession().dgaCollection());
            this._dgaCollection.filterBy('Enabled', function(model) {
                return model.isEnabled() ? true : false;
            });
            this._dgaCollection.filterBy('Type', function(model) {
                return _.startsWith(model.gasTypeAbbrev(), "H2O");
            });
            
            // add new filter for by moisture...
            this._temperatureCollection = new Backbone.Obscura(this._session.transformerSession().temperatureCollection());
            this._temperatureCollection.filterBy('Enabled', function(model) {
                return model.isEnabled() ? true : false;
            });
            this._temperatureCollection.filterBy('Type', function(model) {
                return _.startsWith(model.temperatureTypeAbbrev(), "OTM");
            });
	},
	
	_setupListeners: function() {
	},
	
	_htmlTemplate: function() {
	    return EmPower.Templates['transformer/moisture/transformer_moisture'];
	},
	
	_htmlData: function() {
	},
	
	_render: function($html) {
	    
            // render the two separate collections on one page...
            
	    var $dgaList = $html.find("div[data-section='transformer-dga-list']");
	    if (!this._dgaList) {

		this._dgaList = new EmPower.View.DgaList({
		    session: this._session,
		    collection: this._dgaCollection,
		    selectedModel: null
		});
		this._dgaList.render($dgaList);
	    } else {
		$dgaList.replaceWith(this._dgaList.$el);
	    }
            
            var $temperatureList = $html.find("div[data-section='transformer-temperature-list']");
	    if (!this._temperatureList) {

		this._temperatureList = new EmPower.View.TemperatureList({
		    session: this._session,
		    collection: this._temperatureCollection,
		    selectedModel: null,
                    temperatureUnit: 'C'
		});
		this._temperatureList.render($temperatureList);
	    } else {
		$temperatureList.replaceWith(this._temperatureList.$el);
	    }
	},
	
	_show: function() {
	    if (this._dgaList) {
		this._dgaList.show();
	    }
            if (this._temperatureList) {
		this._temperatureList.show();
	    }
	},
	
	_hide: function() {
	    if (this._dgaList) {
		this._dgaList.hide();
	    }
            if (this._temperatureList) {
		this._temperatureList.hide();
	    }
	},
	
	_remove: function() {
	    if (this._dgaList) {
		this._dgaList.remove();
	    }
            if (this._temperatureList) {
		this._temperatureList.remove();
	    }
	}
    });
    
})(jQuery);
