EmPower.Model.ConditionIndex = (function($, undefined) {
    
    return EmPower.Model.AbstractEventModel.extend({
	idAttribute: 'id',
        
        modelName: 'Condition Index',

	_initialize: function() {
	},

	_parse: function(response, options) {
	    return response;
	},
	
	conditionIndexId: function(value) {
	    return this._field('id', value);
	},
	
	value: function(value) {
            
            value = this._field('value', value);
            
            if (value) {
                value = value;
            }
            
	    return value;
	},
	
	ciTypeId: function(value) {
	    return this._field('ciTypeId', value);
	},
	
	ciTypeName: function(value) {
	    return this._field('ciTypeName', value);
	},
	
	ciTypeAbbrev: function(value) {
	    return this._field('ciTypeAbbrev', value);
	},
	
	ciTypeWeightFactor: function(value) {
	    return this._field('ciTypeWeightFactor', value);
	}
    });
    
})(jQuery);
