EmPower.View.AbstractEventTableView = (function($, undefined) {

    return EmPower.View.AbstractView.extend({
	
	_events: function() {
	},
	
	_initialize: function() {
	    this._selectedTransformer = this._session.transformerSession().selectedTransformer();
	    
	    this._dateRange = this._session.dateRange();
	    
	    this._eventCollection = this._getEventCollection();
	},
	
	_getEventCollection: function() {
	    this._logger.throwError("Abstract Event Table View was not extended with an event collection.");
	},
	
	getDownloadUrl: function() {
	    return this._eventCollection.getDownloadUrl();
	},
	
	_setupListeners: function() {
	    
			var self = this;

		// if( this.viewName == "Transformer Condition Index Table" ){
		// 	this._logger.debugConsoleLog("Setting up listeners for: " + this.viewName);
		// }
			
			
		// this.listenTo(this._session.dateRange(), 'change', function(){
		// 	this._logger.debugConsoleLog("Responding to change event on SESSION.dateRange: " + this.viewName);
		// } );
            
	    this.listenTo(self._dateRange, 'change', function() {
			this._logger.debugConsoleLog("Responding to change event on dateRange: " + this.viewName);
                setTimeout(function() {
                    if (self.isVisible()) {
                        self._eventCollection.fetchDebounced();
                    }
                }, 1);
	    });
            
            this.listenTo(self._eventCollection, 'update', function() {
                setTimeout(function() {
                    $(document).trigger("transformerViewResize");
                }, 250); 
            });
	},
	
	_htmlTemplate: function() {
	    return EmPower.Templates['templates/data_table'];
	},
	
	_htmlData: function() {
	},
	
	_createTableView: function($container) {
	    this._logger.throwError("Abstract Event Table View was not extended with a table view.");
	},
	
	_render: function($html) {
	     
	    if (this._dataTable && this.hasRendered()) {
		this._dataTable.container.empty();
		this._dataTable.remove();
		this._dataTable = null;
	    }
	    
	    var $table = $html.filter("div[data-section='data-table']");
	    if (!this._dataTable) {
		this._dataTable = this._createTableView($table, this._eventCollection);
	    } else {
		$table.html(this._dataTable.$el);
	    }
	},
	
	_show: function() {
	    if (this.hasRendered()) {
		this.render();
	    }
	},
	
	_hide: function() {
	    if (this._dataTable && this.hasRendered()) {
		this._dataTable.container.empty();
		this._dataTable.remove();
		this._dataTable = null;
	    }
	},
	
	_remove: function() {
	    if (this._dataTable && this.hasRendered()) {
		this._dataTable.container.empty();
		this._dataTable.remove();
		this._dataTable = null;
	    }
	}
    });
    
})(jQuery);
