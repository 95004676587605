EmPower.View.AnnunciatorList = (function($, undefined) {

    return EmPower.View.AbstractCollectionView.extend({
	viewName: "Annunciator List",
	
	_events: function() {
	},
	
	_initialize: function() {
	    this._applicationRouter = this._session.applicationRouter();
	},
	
	_setupListeners: function() {
	},
	
	_htmlTemplate: function() {
	    return EmPower.Templates['transformer/annunciator/annunciator_list'];
	},
	
	_htmlData: function() {
	},
	
	_getCollectionContainer: function($html) {
	    return $html.filter("div[data-section='annunciator-list']");
	},
	
	_createModelView: function(model) {
	    return new EmPower.View.AnnunciatorRow({
		session: this._session,
		annunciatorModel: model
	    });
	},
	
	_getLoadingCollectionMessage: function() {
	    return "Loading annunciators, please wait...";
	},
	
	_getEmptyCollectionMessage: function() {
	    return "Sorry, no annunciators have been configured.";
	},
	
	_render: function() {
	},
	
	_show: function() {
	},
	
	_hide: function() {
	},
	
	_remove: function() {
	}
    });
    
})(jQuery);
