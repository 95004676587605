EmPower.Collection.AbstractEventCollection = (function(Collection, $, undefined) {
    
    return Collection.extend({
	
	_url: function() {
	    this._logger.throwError("Event Collection was not extended with a specific url.");
	},
	
	url: function(append) {
	    return this._addDateRangeQueryParams(this._url(), append);
	},
	
	initialize: function() {
	    
	    var self = this;
	    
	    this._dateRange = this._getOption('dateRange', true);
	    
	    self.listenTo(self._dateRange, "change", function() {
			self.reset();
	    });
	    
	    Collection.prototype.initialize.apply(this, arguments);
	},
	
	modelId: function(attr) {
	    return attr.eventId;
	},
        
        comparator: function(eventTwo, eventOne) {
	    var sort = 0;
	    if (eventOne.timestamp() && eventTwo.timestamp()) {
		sort = eventOne.timestamp().localeCompare(eventTwo.timestamp());
	    }
	    if (sort === 0 && eventOne.eventId() && eventTwo.eventId()) {
		if (eventOne.eventId() >= eventTwo.eventId()) {
		    sort = 1;
		} else if (eventOne.eventId() < eventTwo.eventId()) {
		    sort = -1;
		}
	    }
	    
	    return sort;
	},
	
	_addDateRangeQueryParams: function(UrlString, append) {
	    append = append || "";
	    return  UrlString += UrlString ? append + '?from=' + _.escape(this._dateRange.fromDateFormatted()) + '&to=' + _.escape(this._dateRange.toDateFormatted()) : '';
	},
	
	getDownloadUrl: function() {
	    return _.isFunction(this.url) ? this.url("/download") : this.url + "/download";
	}
    });
    
})(EmPower.Collection.AbstractSelectedObjectCollection, jQuery);