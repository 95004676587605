(function(SimpleDialog, $, undefined) {
    
    // private namespace
    var that = {};
    
    that.loadingDialog = {};
    
    that.loadingDialog.defaultMessage = "Loading...";
    
    that.loadingDialog.maxTimeout = EmPower.Options.timeout + (1000 * 30);
    
    that.loadingDialog.html = [
	    '<div class="alert alert-info" style="margin-bottom:0px;">',
	    '  <i class="fa fa-spinner fa-pulse"></i>',
	    '  <span style="margin-left: 5px;"><%= message %></span>',
	    '</div>'
	];
    
    that.loadingDialog.realize = function(instance, message) {

	if (!that.loadingDialog.htmlCompiled) {
	    that.loadingDialog.htmlCompiled = _.template(that.loadingDialog.html.join(''));
	}
	    
	var data = {};
	data.message = message || that.loadingDialog.defaultMessage;
	    
	var $html = $(that.loadingDialog.htmlCompiled(data));
	
	if (!instance) {
	    
	    instance = new BootstrapDialog({
		    size: BootstrapDialog.SIZE_SMALL,
		    closable: false,
		    autodestroy: false
	    });
	    
	    instance.realize();
	    instance.getModalHeader().hide();
	    instance.getModalFooter().hide();
	}

	instance.setMessage($html);

	return instance;
    };
    
    SimpleDialog.Loading = (function() {
	
	// public namespace;
	var ns = {};
	
	ns.open = function(message, openTimeout, closeTimeout) {
	    
	    that.loadingDialog.instance = that.loadingDialog.realize(that.loadingDialog.instance, message);
	    
	    clearTimeout(that.loadingDialog.openTimer);
	    clearTimeout(that.loadingDialog.closeTimer);
	    
	    openTimeout = openTimeout || 400;
	    that.loadingDialog.openTimer = setTimeout(function() {
		    that.loadingDialog.instance.open();
	    }, openTimeout);
	    
	    closeTimeout = closeTimeout || that.loadingDialog.maxTimeout;
	    that.loadingDialog.closeTimer = setTimeout(function() {
		    that.loadingDialog.instance.close();
	    }, closeTimeout);
	};
	
	ns.close = function() {
	    
	    if (that.loadingDialog.instance) {
		
		clearTimeout(that.loadingDialog.openTimer);
		clearTimeout(that.loadingDialog.closeTimer);
		that.loadingDialog.instance.close();
	    }
	};
	
	return ns;
    })();
    
})(EmPower.Dialog.SimpleDialog = EmPower.Dialog.SimpleDialog || {}, jQuery);
