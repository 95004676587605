EmPower.View.DynamicLoadingAssetAgeGraph = (function(View, $, undefined) {
    
    return View.extend({
        viewName: "Dynamic Loading - Asset Age Graph",
	
	_initialize: function() {
            this.collections(this._session.transformerSession().dynamicLoadingEventCollection());
            this.graphOptions({
                options: {
                    scales: {
                        yAxes: [ 
                            EmPower.Util.SimpleChartAxis.lifeLossDay({
                                id: "y-axis-lifelossday"
                            }),
                            EmPower.Util.SimpleChartAxis.faa({
                                id: "y-axis-faa"
                            })
                        ]
                    }
                },
                datasetConfig: [{
                    datasetId: function(model) {
                        return "dl-lifeLossDay";
                    },
                    label: function(model) {
                        return "Loss of Life Day";
                    },
                    yValue: function(model) {
                        return (model.lifeLossDay() !== undefined && model.lifeLossDay() !== null) ? (model.lifeLossDay()).toFixedNumber(2) : null;
                    },
                    datasetOptions: function(model) {
                        return {
                            yAxisID: "y-axis-lifelossday"
                        };
                    }
                }, {
                    datasetId: function(model) {
                        return "dl-acceleratedAgingFactor";
                    },
                    label: function(model) {
                        return "Accelerated Aging Factor";
                    },
                    yValue: function(model) {
                        return (model.acceleratedAgingFactor() !== undefined && model.acceleratedAgingFactor() !== null) ? (model.acceleratedAgingFactor()).toFixedNumber(2) : null;
                    },
                    datasetOptions: function(model) {
                        return {
                            yAxisID: "y-axis-faa"
                        };
                    }
                }]
            });
	}
    });
    
})(EmPower.View.AbstractGraphView, jQuery);
