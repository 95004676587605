EmPower.Model.CoolingMode = (function($, undefined) {
    
    return EmPower.Model.AbstractEventModel.extend({
        idAttribute: 'transformerInfoByCoolingModeId',
        
	modelName: 'Cooling Mode',

	_initialize: function() {
	},

	_parse: function(response, options) {
	    return response;
	},
	
	coolingModeId: function(value) {
	    return this._field('transformerInfoByCoolingModeId', value);
	},
	
	transformerId: function(value) {
	    return this._field('transformerId', value);
	},
        
        coolingTypeId: function(value) {
	    return this._field('coolingTypeId', value);
	},
        
        coolingTypeName: function(value) {
	    return this._field('coolingTypeName', value);
	},
        
        coolingTypeAbbrev: function(value) {
	    return this._field('coolingTypeAbbrev', value);
	},
        
        ratings: function(value) {
	    return this._field('ratings', value);
	},
	
	tempLoss: function(value) {
	    return this._field('tempLoss', value);
	},
	
	i2rLoss: function(value) {
	    return this._field('i2rLoss', value);
	},
	
	windingEddyLoss: function(value) {
	    return this._field('windingEddyLoss', value);
	},
	
	strayLoss: function(value) {
	    return this._field('strayLoss', value);
	},
	
	coreLoss: function(value) {
	    return this._field('coreLoss', value);
	},
	
	loadLoss: function(value) {
	    return this._field('loadLoss', value);
	},
	
	kvaRating: function(value) {
	    return this._field('kvaRating', value);
	},
	
	ratedWindRise: function(value) {
	    return this._field('ratedWindRise', value);
	},
	
	testWindRise: function(value) {
	    return this._field('testWindRise', value);
	},
	
	hotSpotRise: function(value) {
	    return this._field('hotSpotRise', value);
	},
	
	topOilRise: function(value) {
	    return this._field('topOilRise', value);
	},
	
	bottomOilRise: function(value) {
	    return this._field('bottomOilRise', value);
	},
	
	ratedAmbient: function(value) {
	    return this._field('ratedAmbient', value);
	},
	
	averageOilRise: function(value) {
	    return this._field('averageOilRise', value);
	},
	
	windingCond: function(value) {
	    return this._field('windingCond', value);
	},
	
	eddyLossHotSpot: function(value) {
	    return this._field('eddyLossHotSpot', value);
	},
	
	windTimeConst: function(value) {
	    return this._field('windTimeConst', value);
	},
	
	windHeightHotSpot: function(value) {
	    return this._field('windHeightHotSpot', value);
	},
	
	fluidType: function(value) {
	    return this._field('fluidType', value);
	},
	
	fluidGallons: function(value) {
	    return this._field('fluidGallons', value);
	},
	
	currentPrimaryWind: function(value) {
	    return this._field('currentPrimaryWind', value);
	},
	
	currentSecondaryWind: function(value) {
	    return this._field('currentSecondaryWind', value);
	},
	
	currentTertiaryWind: function(value) {
	    return this._field('currentTertiaryWind', value);
	},
	
	voltPrimaryWind: function(value) {
	    return this._field('voltPrimaryWind', value);
	},
	
	voltSecondaryWind: function(value) {
	    return this._field('voltSecondaryWind', value);
	},
	
	voltTertiaryWind: function(value) {
	    return this._field('voltTertiaryWind', value);
	},
	
	crossRateApparentPowerHx: function(value) {
	    return this._field('crossRateApparentPowerHx', value);
	},
	
	crossRateApparentPowerHt: function(value) {
	    return this._field('crossRateApparentPowerHt', value);
	},
	
	crossRateApparentPowerXt: function(value) {
	    return this._field('crossRateApparentPowerXt', value);
	},
	
	weightOfCore: function(value) {
	    return this._field('weightOfCore', value);
	},
	
	weightOfTank: function(value) {
	    return this._field('weightOfTank', value);
	}
    });
    
})(jQuery);
