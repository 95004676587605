EmPower.Collection.DaemonError = (function($, undefined) {
    
    return EmPower.Collection.AbstractCollection.extend({
	
	url: EmPower.Util.Url.apiBuilder('serverinfo', 'daemon', 'errors'),

	model: EmPower.Model.DaemonError,
	
	collectionName: 'Daemon Error',
	
	_initialize: function() {
	},
	
	_parse: function() {
	}
    });
    
})(jQuery);
