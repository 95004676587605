EmPower.View.DynamicLoadingLoadGraph = (function(View, $, undefined) {
    
    return View.extend({
        viewName: "Dynamic Loading - Load Graph",
	
	_initialize: function() {
            this.collections(this._session.transformerSession().dynamicLoadingEventCollection());
            this.graphOptions({
                options: {
                    scales: {
                        yAxes: [
                            EmPower.Util.SimpleChartAxis.load({
                                id: "y-axis-load"
                            })
                        ]
                    }
                },
                datasetConfig: [{
                    datasetId: function(model) {
                        return "dl-load";
                    },
                    label: function(model) {
                        return "Load";
                    },
                    yValue: function(model) {
                        return (model.load() !== undefined && model.load() !== null) ? (model.load()).toFixedNumber(2) : null;
                    },
                    datasetOptions: function(model) {
                        return {
                            yAxisID: "y-axis-load"
                        };
                    }
                }, {
                    datasetId: function(model) {
                        return "dl-ultLoad";
                    },
                    label: function(model) {
                        return "Available Load";
                    },
                    yValue: function(model) {
                        return (model.ultLoad() !== undefined && model.ultLoad() !== null) ? (model.ultLoad()).toFixedNumber(2) : null;
                    },
                    datasetOptions: function(model) {
                        return {
                            yAxisID: "y-axis-load"
                        };
                    }
                }, {
                    datasetId: function(model) {
                        return "currentLimit";
                    },
                    label: function(model) {
                        return "Current Limit";
                    },
                    yValue: function(model) {
                        return (model.currentLimit() !== undefined && model.currentLimit() !== null) ? (model.currentLimit()).toFixedNumber(2) : null;
                    },
                    datasetOptions: function(model) {
                        return {
                            yAxisID: "y-axis-load"
                        };
                    }
                }]
            });
	}
    });
    
})(EmPower.View.AbstractGraphView, jQuery);
