EmPower.Collection.Bushing = (function($, undefined) {
    
    return EmPower.Collection.AbstractDeviceCollection.extend({
	collectionName: 'Bushing',
	
	model: EmPower.Model.Bushing,
	
	url: function() {
	    return EmPower.Util.Url.apiBuilder('transformers', this._selectedObject.selectedId(), 'bushing');
	},
	
	_initialize: function() {
	},
	
	_parse: function() {
	}
    });
    
})(jQuery);
