EmPower.View.DgaList = (function($, undefined) {

    return EmPower.View.AbstractCollectionView.extend({
	viewName: "Dga List",
	
	_events: function() {
	},
	
	_initialize: function() {
	    this._applicationRouter = this._session.applicationRouter();
	},
	
	_setupListeners: function() {
	},
	
	_htmlTemplate: function() {
	    return EmPower.Templates['transformer/dga/dga_list'];
	},
	
	_htmlData: function() {
	},
	
	_getCollectionContainer: function($html) {
	    return $html.filter("div[data-section='dga-list']");
	},
	
	_createModelView: function(model) {
	    return new EmPower.View.DgaRow({
		session: this._session,
		dgaModel: model
	    });
	},
	
	_getLoadingCollectionMessage: function() {
	    return "Loading dissolved gas analysis, please wait...";
	},
	
	_getEmptyCollectionMessage: function() {
	    return "Sorry, no dissolved gas analysis have been configured.";
	},
	
	_render: function() {
	},
	
	_show: function() {
	},
	
	_hide: function() {
	},
	
	_remove: function() {
	}
    });
    
})(jQuery);
