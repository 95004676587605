EmPower.View.ConditionIndexRow = (function($, undefined) {

    return EmPower.View.AbstractView.extend({
	viewName: "Condition Index Row",
	
	_events: function() {
	},
	
	_initialize: function() {
	    this._conditionIndexModel = this._getOption("conditionIndexModel", true);
            
            this._conditionIndexGraphs = this._getOption("conditionIndexGraphs", true);
	},
	
	_setupListeners: function() {
	    
	    var self = this;
	    
	    self.listenTo(self.getConditionIndexModel(), 'change', function() {
		self.renderDebounced();
	    });
	},
	
	getConditionIndexModel: function() {
	    return this._conditionIndexModel;
	},
	
	_htmlTemplate: function() {
	    return EmPower.Templates['transformer/health/condition_index_row'];
	},
	
	_htmlData: function() {
	    return {
		conditionIndexModel: this.getConditionIndexModel(),
                hasGraph: (this._conditionIndexGraphs) ? _.has(this._conditionIndexGraphs, this.getConditionIndexModel().ciTypeAbbrev()) : false
	    };
	},
	
	_render: function() {
            if (this.getConditionIndexModel() && this.getConditionIndexModel().ciTypeName().toLowerCase() === "health") {
                this.$el.addClass("health-index");
            }
	},
	
	_show: function() {
	},
	
	_hide: function() {
	},
	
	_remove: function() {
	}
	
    });
    
})(jQuery);
