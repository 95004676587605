EmPower.View.Loading = (function($, undefined) {

    return EmPower.View.AbstractView.extend({
	viewName: "Loading",
	
	_events: function() {
	},
	
	_initialize: function() {
	},
	
	_setupListeners: function() {
	},
	
	_htmlTemplate: function() {
	    return EmPower.Templates['loading/loading'];
	},
	
	_htmlData: function() {
	},
	
	_render: function() {
	},
	
	_show: function() {
	},
	
	_hide: function() {
	},
	
	_remove: function() {
	}
	
    });
    
})(jQuery);
