EmPower.Model.CoolingEvent = (function($, undefined) {
    
    return EmPower.Model.AbstractDeviceEventModel.extend({
	    
	modelName: 'Cooling Event',

	_initialize: function() {
	},

	_parse: function(response, options) {
	    return response;
	}
	
//	currentDrawDisplay: function(value) {
//	    return (this.currentDraw() !== undefined && this.currentDraw() !== null) ? (this.currentDraw() / 1000).toFixedNumber(1) + " A" : "No Current";
//	}
	
    });
    
})(jQuery);
