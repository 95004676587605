EmPower.Route.About = (function(SubRoute, $, undefined) {
    
    // private namespace
    var that = {};
    
    that.SubRoute =  SubRoute.extend({
		whatAmI: function(){return "EmPower.Route.About - Also see routeName";},
	
	routeName: "About",
	
	_paths: function() {
	    return {
		about: function() {
		    return EmPower.Util.Url.builder();
		}
	    };
	},
	
	_routes: function(paths) {
	    
	    var routes = {};
	    
	    routes[paths.about()] = this._showAbout;
	    
	    return routes;
	},
	
	_initialize: function() {
	    this._aboutView = new EmPower.View.About({
		session: this._session
	    });
	},
	
	
	_showAbout: function() {
	    this._session.selectedSectionView().selected(this._aboutView);
	},
	showAbout: function() {
	    this.navigate(this.paths.about(), {trigger: true});
	},
	
	_show: function() {
	    this._session.selectedSectionView().selected(this._aboutView);
	}
    });
    
    return that.SubRoute;
    
})(EmPower.Route.AbstractSubRoute, jQuery);
