EmPower.View.Firmware = (function ($, undefined) {

    return EmPower.View.AbstractView.extend({
        viewName: "Transformer Firmware",

        _events: function () {
            return {
                "click [data-action=select-camera]": function (e) {
                    this._selectedCamera = e.currentTarget.dataset.cameraId;
                    $(".cameras>div.active-camera").removeClass("active-camera");
                    $(e.target).addClass("active-camera");
                },
                "click [data-action=select-firmware]": function (e) {
                    this._selectedFirmware = e.currentTarget.dataset.firmwareName;
                    $(".firmware-list>div>a.active-firmware").removeClass("active-firmware");
                    $(e.target).addClass("active-firmware");
                },
                "click [data-action=update-firmware]": function (e) {
                    var user = $("#firmware-user").val();
                    var pw = $("#firmware-pw").val();
                    this._updateCamera(this._selectedCamera, this._selectedFirmware, user, pw);
                },
                "click [data-action=download-firmware]": function (e) {
                    var firmware = e.currentTarget.dataset.firmwareName;
                    EmPower.Util.Download.hiddenDownload("api/1/firmware/camera/" + firmware + "/download");
                },
                "click [data-action=delete-firmware]": function (e) {
                    var firmware = e.currentTarget.dataset.firmwareName;
                    firmware = firmware.startsWith("/") ? firmware : "/" + firmware;
                    var url = EmPower.Util.Url.apiBuilder("/firmware/camera", firmware);
                    $.ajax({
                        type: "DELETE",
                        url: url,
                        success: function () {
                            $.notify({
                                message: "Successfully deleted file"
                            }, {
                                type: 'success',
                                placement: {
                                    from: 'top',
                                    align: 'center'
                                },
                                z_index: 9999
                            });
                        },
                        error: function (err) {
                            console.log(err);
                            $.notify({
                                message: "Failed to delete file"
                            }, {
                                type: 'danger',
                                placement: {
                                    from: 'top',
                                    align: 'center'
                                },
                                z_index: 9999
                            });
                        }
                    });
                }

            };
        },

        _initialize: function () {
            this._selectedTransformer = this._session.transformerSession().selectedTransformer();

            this._selectedCamera = null;
            this._selectedFirmware = null;

            this._cameraCollection = new EmPower.Collection.Camera(null, {
                session: this._session,
                selectedObject: this._selectedTransformer
            });

            this._firmwareCollection = new EmPower.Collection.Firmware(null, {
                session: this._session,
                selectedObject: this._selectedTransformer
            });

        },

        _setupListeners: function () {
            var self = this;

            self.listenTo(self._transformerCollection, 'update reset', function () {
                this.renderDebounced();
            });
            self.listenTo(self._selectedTransformer, 'change', function () {
                this.renderDebounced();
            });
            self.listenTo(self._cameraCollection, 'sync change', function () {
                this.renderDebounced();
            });
            self.listenTo(self._firmwareCollection, 'sync change', function () {
                this.renderDebounced();
            });

        },

        _updateCamera: function (camera, firmware, user, pw) {
            // if either property is null, warn user do nothing
            if (!this._selectedCamera || !this._selectedFirmware) {
                $.notify({
                    message: "Please select both a camera and a firmware file to update"
                }, {
                    type: 'danger',
                    placement: {
                        from: 'top',
                        align: 'center'
                    },
                    z_index: 9999
                });
            } else {
                var firmFile = this._firmwareCollection.models.find(function (file) {
                    return file.attributes.firmwareFileName === firmware;
                });
                
                // add the uname and pw to the data
                firmFile.attributes.username = user;
                firmFile.attributes.password = pw;
                
                var url = EmPower.Util.Url.apiBuilder("transformers", this._selectedTransformer.selectedId(), "camera", camera, "/firmware/upgrade");
                $.ajax({
                    type: "POST",
                    url: url,
                    contentType: "application/json",
                    data: JSON.stringify(firmFile),
                    success: function () {
                        $.notify({
                            message: "Successfully sent firmware to camera to be updated"
                        }, {
                            type: 'success',
                            placement: {
                                from: 'top',
                                align: 'center'
                            },
                            z_index: 9999
                        });
                    },
                    error: function (err) {
                        console.log(err);
                        $.notify({
                            message: "Failed to send firmware to camera"
                        }, {
                            type: 'danger',
                            placement: {
                                from: 'top',
                                align: 'center'
                            },
                            z_index: 9999
                        });
                    }
                });

            }
        },

        _htmlTemplate: function () {
            return EmPower.Templates['transformer/firmware/transformer_firmware'];
        },

        _htmlData: function () {
            return {
                cameras: this._cameraCollection,
                firmwares: this._firmwareCollection
            };
        },

        _render: function ($html) {

            var $dropzone = $html.find('div[data-section="dropzone"]');
            if (!this._dropzone) {

                var self = this;

                this._dropzone = new Dropzone($dropzone.get(0), {
                    url: "/api",
                    addRemoveLinks: true,
                    dictCancelUpload: "cancel upload",
                    dictCancelUploadConfirmation: "upload canceled",
                    dictRemoveFile: "remove file"
                });

                this._dropzone.on("complete", function (file) {
                    this.removeFile(file);
                    self._session.collectionFetcher().fetchAll();
                });

                this._dropzone.on("processing", function () {
                    this.options.url = ["/api/1/", "firmware", "/camera"].join("");
                });

                Dropzone.options.technicalDocumentsDropzone = {
                    paramName: "file",
                    maxFilesize: 20,
                    accept: function (file, done) {
                        done();
                    }
                };

            } else {

                $dropzone.replaceWith(this._dropzone.element);
            }
        },

        _show: function () {
        },

        _hide: function () {
        },

        _remove: function () {
        }
    });

})(jQuery);
