EmPower.View.AbstractCollectionView = (function(View, $, undefined) {
    
    // private namespace
    var that = {};
    
    that.collectionContainerTemplate = EmPower.Templates['templates/collection_container'];
    
    that.modelContainerTemplate = EmPower.Templates['templates/model_container'];
    
    return View.extend({
	
	events: function() {
	    
	    var events = View.prototype.events.apply(this, arguments) || {};
	    
	    return _.extend({
		'click li': function(e) {
		    
                    var model, cid;
                    
                    if (e.currentTarget && (e.ctrlKey || e.metaKey)) {
                        cid = $(e.currentTarget).attr('id');
			model = this.getCollectionProxy().get({cid: cid});
                        EmPower.Dialog.Model.open(model, model ? model.modelName : null);
                    } else if (e.currentTarget && this.getSelectedModel()) {
			cid = $(e.currentTarget).attr('id');
			model = this.getCollectionProxy().get({cid: cid});
			this.getSelectedModel().selected(model);
		    }
                }
	    }, events);
	},
	
	initialize: function() {
	    
	    this._collection = this._getOption("collection", true);
            if (this._collection instanceof Backbone.Obscura) {
                this._collectionProxy = this._collection;
                this._collection = this._collectionProxy.superset();
            }
            
	    this._selectedModel = this._getOption("selectedModel", true);
	    
	    this._collectionContainerTemplate = this._getOption("collectionContainerTemplate") || that.collectionContainerTemplate;
	    
	    this._modelContainerTemplate = this._getOption("modelContainerTemplate") || that.modelContainerTemplate;
            
            this._renderedError = false;
            
            this._initialSync = true;
            
	    // map: {key: model.cid, value: view}
	    this._modelViews = {};
	    
	    View.prototype.initialize.apply(this, arguments);
	},
	
	setupListeners: function() {

	    var self = this;
	    
	    if (!self._isListenersSetup) {
		
		self._logger.warn("Setting up collection view listeners", this);
		
		self.listenTo(self.getCollectionProxy(), 'add remove update reset change', function() {
                    self.renderDebounced();
		});
                
                self.listenTo(self.getCollection(), 'error', function() {
                    if (!self._hasError) {
                        self.renderDebounced();
                    }
		});

                self.listenTo(self.getCollection(), 'sync', function() {
                    if (self._initialSync || self._hasError) {
                        self.renderDebounced();
                    }
		});
		
		if (self.getSelectedModel()) {
		    self.listenTo(self.getSelectedModel(), 'change', function() {

			var previouslySelected = self.getSelectedModel().getPreviouslySelected();
			if (previouslySelected) {
			    self.$html.find('#' + previouslySelected.cid).removeClass("active");
			}

			var selected = self.getSelectedModel().selected();
			if (selected) {
			    self.$html.find('#' + selected.cid).addClass("active");
			}
		    });
		}
	    }
            
            View.prototype.setupListeners.apply(self, arguments);
	},
	
	getCollection: function() {
	    return this._collection;
	},
	
        getCollectionProxy: function() {
            if (this.hasProxy()) {
                return this._collectionProxy;
            }
            return this.getCollection();
        },
        
        getModels: function() {
            return this.getCollectionProxy().models;
        },
        
        hasProxy: function() {
            return this._collectionProxy ? true : false;
        },
        
	getSelectedModel: function() {
	    return this._selectedModel;
	},
	
	_getCollectionContainer: function($html) {
	    this._logger.throwError("Collection View was not extended with specific Collection Container.");
	},
	
	_createModelView: function(model) {
	    this._logger.throwError("Collection View was not extended with specific Create Model View.", model);
	},
	
	_afterHtmlRendered: function($html) { 
	    
	    var self = this;
            
            var subsetListElements = {};
            
	    var $collectionContainer = self._getCollectionContainer($html);
	    if ($collectionContainer.length === 0) {
		self._logger.throwError("Collection View was extended an empty Collection Container.");
	    }
	    
	    var $collectionList = $(self._collectionContainerTemplate());
            var isSubsetCollection = false;

	    var removed = _.clone(self._modelViews);
            
            self._initialSync = self.getCollection().isFetched() ? false : true;
            self._hasError = self.getCollection().hasError() ? true : false;
	    if (self.getModels().length > 0) {

		_.forEach(self.getModels(), function(model) {
                    
                    if (_.isFunction(self._getModelSubsetContainer)) {
                        
                        isSubsetCollection = true;
                        var $subsetContainer = self._getModelSubsetContainer($collectionContainer, model);
                        
                        if ($subsetContainer && $subsetContainer.length !== 0) {
                            var subsetId = $subsetContainer.data("subsection");
                            $collectionList = subsetListElements[subsetId];
                            if (!$collectionList) {
                                $collectionList = subsetListElements[subsetId] = $(self._collectionContainerTemplate());
                                $subsetContainer.html($collectionList);
                            }
                        } else {
                            $collectionList = null;
                        }
                    }
                    
                    if ($collectionList && $collectionList.length) {
                        var modelView = self._modelViews[model.cid];
                        var $modelViewContainer = null;
                        if (modelView) {

                            delete removed[model.cid];
                            $modelViewContainer = modelView.$el;
                        } else {

                            var isSelected = false;
                            if (self.getSelectedModel()) {
                                var selected = self.getSelectedModel().selected();
                                if ((selected && selected.cid) && (model && model.cid)) {
                                    isSelected = selected.cid === model.cid;
                                }
                            }

                            $modelViewContainer = $(self._modelContainerTemplate({
                                model: model,
                                isSelected: isSelected
                            }));

                            modelView = self._createModelView(model);
                            modelView.render($modelViewContainer);
                            self._modelViews[model.cid] = modelView;
                        }

                        $collectionList.append($modelViewContainer);
                    }
                });
	    } else {
		
		var $template;
		var data;
		if (self._initialSync && !self._hasError) {
		    
		    data = {
			message: _.isFunction(self._getLoadingCollectionMessage) && self._getLoadingCollectionMessage()
				? self._getLoadingCollectionMessage() : "Loading data, please wait..."
		    };

		    $template = $(EmPower.Templates['templates/page_loading'](data));
			
		} else if (self._hasError) {
		    
		    data = {
			message: _.isFunction(self._getCollectionErrorMessage) && self._getCollectionErrorMessage()
				? self._getCollectionErrorMessage() : "Sorry, could not load requested data."
		    };

		    $template = $(EmPower.Templates['templates/page_error'](data));
		    
		} else if (_.isFunction(self._getEmptyCollectionMessage) && self._getEmptyCollectionMessage()) {
		    
		    data = {
			message: self._getEmptyCollectionMessage()
		    };
		    
		    $template = $(EmPower.Templates['templates/page_warning'](data));
		}
		
		if ($template) {
                    // replace HTML with template message...
		    $collectionList.html($template);
		}
	    }
            
            if (!isSubsetCollection && $collectionList && !$collectionList.is(':empty')) {
                $collectionContainer.html($collectionList);
            }
	    
	    _.forOwn(removed, function(modelView, cid, modelViews) {
		$collectionList.find('#' + cid).remove();
		modelView.remove();
		delete self._modelViews[cid];
	    });
	},
	
	_toggleDisplay: function(isDisplay) {
	    
	    var isVisible = this.isVisible();
	    
	    var retVal = View.prototype._toggleDisplay.apply(this, arguments);
	    
	    if (isDisplay) {
		if (!isVisible) {
		    _.forOwn(this._modelViews, function(modelView, cid, modelViews) {
			modelView.show();
		    });
		}
	    } else {
		if (isVisible) {
		    _.forOwn(this._modelViews, function(modelView, cid, modelViews) {
			modelView.hide();
		    });
		}
	    }

	    return retVal;
	},
	
	remove: function() {
	    
	    View.prototype.remove.apply(this, arguments);
	    
	    _.forOwn(this._modelViews, function(modelView, cid, modelViews) {
		modelView.remove();
	    });
	}
    });

})(EmPower.View.AbstractView, jQuery);
