EmPower.View.About = (function($, undefined) {
    
    return EmPower.View.AbstractView.extend({
	
	viewName: "About",
	
	_events: function() {
	},
	
	_initialize: function() {
	    this._serverInfoCollection = new EmPower.Collection.ServerInformation(null, {session: this._session});
	    this._databaseInfoCollection = new EmPower.Collection.DatabaseInformation(null, {session: this._session});
	    this._daemonInfoCollection = new EmPower.Collection.DaemonInformation(null, {session: this._session});
	    this._daemonErrorCollection = new EmPower.Collection.DaemonError(null, {session: this._session});
	},
	
	_setupListeners: function() {
	    
	    var self = this;
	    
	    self.listenTo(self._serverInfoCollection, 'update reset', function() {
		self.renderDebounced();
	    });
	    self.listenTo(self._databaseInfoCollection, 'update reset', function() {
		self.renderDebounced();
	    });
	    self.listenTo(self._daemonInfoCollection, 'update reset', function() {
		self.renderDebounced();
	    });
	    self.listenTo(self._daemonErrorCollection, 'update reset', function() {
		self.renderDebounced();
	    });
	    self.listenTo(self._session, 'reset', function() {
		this._serverInfoCollection.reset();
		this._databaseInfoCollection.reset();
		this._daemonInfoCollection.reset();
		this._daemonErrorCollection.reset();
	    });
		
	},
	
	_htmlTemplate: function() {
	    return EmPower.Templates['about/about'];
	},
	
	_htmlData: function() {
	    return {
		hasServerInfo: this._serverInfoCollection.length > 0,
		hasDatabaseInfo: this._databaseInfoCollection.length > 0,
		hasDaemonInfo: this._daemonInfoCollection.length > 0,
		hasDaemonError: this._daemonErrorCollection.length > 0
	    };
	},
	
	_render: function($html) {
	
	    var $serverInformation = $html.find("div[data-section='server-information']");
	    if (this._serverInfoCollection.length > 0) {
		if (!this._serverInformation) {

		    this._serverInformation = new bbGrid.View({
			rows: 6,
			container: $serverInformation,
			collection: this._serverInfoCollection,
			colModel: [{
				title: 'Description',
				name: 'description',
				sorttype: 'string'
			    },{
				title: 'Value',
				name: 'value',
				sorttype: 'number'
			    }]
		    });
		} else {
		    $serverInformation.html(this._serverInformation.$el);
		}
	    }
	    
	    var $databaseInformation = $html.find("div[data-section='database-information']");
	    if (this._databaseInfoCollection.length > 0) {
		if (!this._databaseInformation) {

		    this._databaseInformation = new bbGrid.View({
			rows: 5,
			container: $databaseInformation,
			collection: this._databaseInfoCollection,
			colModel: [{
				title: 'Version',
				name: 'version.version',
				sorttype: 'string'
			    }, {
				title: 'Description',
				name: 'description',
				sorttype: 'string'
			    }, {
				title: 'Install Date',
				name: 'installedOn',
				sorttype: 'number'
			    }, {
				title: 'Script',
				name: 'script',
				sorttype: 'string'
			    }, {
				title: 'State',
				name: 'state',
				sorttype: 'string'
			    }, {
				title: 'Checksum',
				name: 'checksum',
				sorttype: 'number'
			    }]
		    });
		} else {
		    $databaseInformation.html(this._databaseInformation.$el);
		}
	    }
	    
	    var $daemonInformation = $html.find("div[data-section='daemon-information']");
	    if (this._daemonInfoCollection.length > 0) {
		if (!this._daemonInformation) {

		    this._daemonInformation = new bbGrid.View({
			rows: 5,
			container: $daemonInformation,
			collection: this._daemonInfoCollection,
			colModel: [{
				title: 'Description',
				name: 'description',
				sorttype: 'string'
			    },{
				title: 'Value',
				name: 'value',
				sorttype: 'number'
			    }]
		    });
		} else {
		    $daemonInformation.html(this._daemonInformation.$el);
		}
	    }
	    
	    var $daemonError = $html.find("div[data-section='daemon-error']");
	    if (this._daemonErrorCollection.length > 0) {
		if (!this._daemonError) {

		    this._daemonError = new bbGrid.View({
			rows: 5,
			container: $daemonError,
			collection: this._daemonErrorCollection,
			colModel: [{
				title: 'Notes',
				name: 'errornotes',
				sorttype: 'number'
			    }, {
				title: 'Warning ID',
				name: 'errorid',
				sorttype: 'string'
			    }, {
				title: 'Severity',
				name: 'errorseverity',
				sorttype: 'number'
			    }, {
				title: 'State',
				name: 'errorstate',
				sorttype: 'number'
			    }, {
				title: 'Transformer ID',
				name: 'transformersTransformerid',
				sorttype: 'number'
			    }, {
				title: 'Change Reason',
				name: 'errorchangereason',
				sorttype: 'number'
			    }, {
				title: 'Timestamp',
				name: 'errordatetime',
				sorttype: 'number'
			    }]
		    });
		} else {
		    $daemonError.html(this._daemonError.$el);
		}
	    }
	},
	
	_show: function() {
	},
	
	_hide: function() {
	},
	
	_remove: function() {
	    if (this._serverInformation) {
		this._serverInformation.remove();
	    }
	    if (this._databaseInformation) {
		this._databaseInformation.remove();
	    }
	    if (this._daemonInformation) {
		this._daemonInformation.remove();
	    }
	    if (this._daemonError) {
		this._daemonError.remove();
	    }
	}
    });
    
})(jQuery);
