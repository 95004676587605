EmPower.View.AccessPanelRow = (function($, undefined) {

    return EmPower.View.AbstractView.extend({
	viewName: "Access Panel Row",
	
	_events: function() {
	},
	
	_initialize: function() {
	    this._accessPanelModel = this._getOption("accessPanelModel", true);
	},
	
	_setupListeners: function() {
	    
	    var self = this;
	    
	    self.listenTo(self.getAccessPanelModel(), 'change', function() {
		self.renderDebounced();
	    });
	},
	
	getAccessPanelModel: function() {
	    return this._accessPanelModel;
	},
	
	_htmlTemplate: function() {
	    return EmPower.Templates['transformer/security/access-panel/access_panel_row'];
	},
	
	_htmlData: function() {
            
            var cssClass = "";
            var icon = null;
            
            if (this.getAccessPanelModel().hasEvent()) {
                if (this.getAccessPanelModel().accessGranted() !== null) {
                    if (this.getAccessPanelModel().accessGranted()) {
                        cssClass = "granted";
                        icon = "fa-check-circle";
                    } else {
                        cssClass = "denied";
                        icon = "fa-exclamation-circle";
                    }
                }
            } else {
                cssClass = "disabled";
            }
            
	    return {
		accessPanelModel: this.getAccessPanelModel(),
                cssClass: cssClass,
                icon: icon
	    };
	},
	
	_render: function() {
	},
	
	_show: function() {
	},
	
	_hide: function() {
	},
	
	_remove: function() {
	}
	
    });
    
})(jQuery);
