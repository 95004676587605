EmPower.View.AccessPanelTable = (function($, undefined) {

    return EmPower.View.AbstractEventTableView.extend({
	viewName: "Access Panel Table",
	
	_getEventCollection: function() {
	    return new EmPower.Collection.AccessPanelEvent(null, {
		session: this._session,
		selectedObject: this._selectedTransformer,
		dateRange: this._session.dateRange()
	    });
	},
	
	_createTableView: function($container, eventCollection) {
	    return new bbGrid.View({
		rows: 7,
		container: $container,
		collection: eventCollection,
		colModel: [{
			title: 'Description',
			index: true,
			name: 'description',
			sorttype: 'string'
		    }, { 
                        title: "First Name",
                        index: true,
                        name: "firstName",
                        sorttype: "string"
                    }, { 
                        title: "Last Name",
                        index: true,
                        name: "lastName",
                        sorttype: "string"
                    }, {
                        title: 'Facility ID',
                        index: true,
                        name: 'facilityNumber',
                        sorttype: 'string'
                    }, {
                        title: 'Card Number',
                        index: true,
                        name: 'cardNumber',
                        sorttype: 'string'
                    }, {
                        title: 'Access',
                        index: true,
                        name: 'accessGranted',
                        sorttype: 'string'
                    }, {
			title: 'Timestamp',
			index: true,
			name: 'timestamp',
			sorttype: 'number'
		    }]
	    });
	}
    });
    
})(jQuery);
