EmPower.View.Bushing = (function($, undefined) {

    return EmPower.View.AbstractView.extend({
	viewName: "Transformer Bushing",
	
	_events: function() {
	},
	
	_initialize: function() {
	    this._selectedTransformer = this._session.transformerSession().selectedTransformer();
	    
	    this._bushingCollectionProxy = this._session.transformerSession().bushingCollectionProxy();
	},
	
	_setupListeners: function() {
	},
	
	_htmlTemplate: function() {
	    return EmPower.Templates['transformer/bushing/transformer_bushing'];
	},
	
	_htmlData: function() {
	},
	
	_render: function($html) {
	    
	    var $bushingList = $html.filter("div[data-section='transformer-bushing-list']");
	    if (!this._bushingList) {

		this._bushingList = new EmPower.View.BushingList({
		    session: this._session,
		    collection: this._bushingCollectionProxy,
		    selectedModel: null
		});
		this._bushingList.render($bushingList);
	    } else {
		$bushingList.replaceWith(this._bushingList.$el);
	    }
	},
	
	_show: function() {
	    if (this._bushingList) {
		this._bushingList.show();
	    }
	},
	
	_hide: function() {
	    if (this._bushingList) {
		this._bushingList.hide();
	    }
	},
	
	_remove: function() {
	    if (this._bushingList) {
		this._bushingList.remove();
	    }
	}
    });
    
})(jQuery);
