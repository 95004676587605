EmPower.Collection.DaemonInformation = (function($, undefined) {
    
    return EmPower.Collection.AbstractCollection.extend({
	
	url: EmPower.Util.Url.apiBuilder('serverinfo', 'daemon'),

	model: EmPower.Model.DaemonInformation,
	
	collectionName: 'Daemon Information',
	
	_initialize: function() {
	},
	
	_parse: function(response) {
	    
	    var models = [];
	    if (response) {
		_.forEach(_.keys(response), function(key, index) {
		    models.push(new EmPower.Model.DaemonInformation({
			id: index,
			description: key,
			value: response[key]
		    }));
		});
	    }
	    
	    return models;
	}
    });
    
})(jQuery);
