(function(TransformerSsh, $, undefined) {
    
    // private namespace
    var that = {};
    
    that.sshPort = {};
    
    that.sshPort.html = [
	'<div>',
	'  RCTU SSH Port:',
	'  <span><%-port%></span>',
	'<div>'
    ];
    
    that.sshPort.open = function(transformer, port, dialog) {
	
	dialog = dialog || new BootstrapDialog({
	    title: 'SSH Port for <strong>Transformer ' + transformer.get("transformerId") + "</strong>"
	});
	
	if (!that.sshPort.htmlCompiled) {
	    that.sshPort.htmlCompiled = _.template(that.sshPort.html.join(''));
	}
	 
	var data = {
	    port: port
	};
	
	var $html = $(that.sshPort.htmlCompiled(data));
	
	dialog.setMessage($html);
	
	var onCancel = function(dialog) {
	    dialog.close();
	};
	
	var cancelButton = {
	    label: 'Cancel',
	    cssClass: 'btn-default left',
	    action: onCancel
	};
	
	dialog.setButtons([cancelButton]);
	
	return dialog.open();
    };
    
    TransformerSsh.start = function(transformer) {
        
        if (transformer && transformer.id) {
            
            EmPower.Dialog.SimpleDialog.Loading.open('Starting RCTU SSH session...');
            
            var url = EmPower.Util.Url.apiBuilder("transformers", transformer.id, "ssh", "start");
            
            $.ajax({
		url: url,
		type: 'GET',
		success: function(data) {
		    EmPower.Dialog.SimpleDialog.Loading.close();
                    if (data.port !== undefined && data.port !== null) {
                        that.sshPort.open(transformer, data.port);
                    } else {
                        $.notify({
			    message: "Sorry, a SSH session could not be opened for <strong> Transformer ID " + transformer.id + "</strong>."
			}, {
			    type: 'danger',
			    placement: {
				from: 'top',
				align: 'center'
			    }
			});
                    }
		},
		error: function() {
		    EmPower.Dialog.SimpleDialog.Loading.close();
		}
	    });
        }
    };
    
    TransformerSsh.stop = function(transformer) {
        
        if (transformer && transformer.id) {
            
            EmPower.Dialog.SimpleDialog.Loading.open('Stopping RCTU SSH session...');
            
            var url = EmPower.Util.Url.apiBuilder("transformers", transformer.id, "ssh", "stop");
            
            $.ajax({
		url: url,
		type: 'GET',
		success: function(data) {
		    EmPower.Dialog.SimpleDialog.Loading.close();
                    $.notify({
                        message: "Stopped SSH sessions for <strong> Transformer ID " + transformer.id + "</strong>."
                    }, {
                        type: 'success',
                        placement: {
                            from: 'top',
                            align: 'center'
                        }
                    });
		},
		error: function() {
		    EmPower.Dialog.SimpleDialog.Loading.close();
		}
	    });
        }
    };
    
})(EmPower.Dialog.TransformerSsh = EmPower.Dialog.TransformerSsh || {}, jQuery);
