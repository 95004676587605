(function (SimpleChart, $, undefined) {

    /* Chart defaults */
    Chart.defaults.global.defaultFontFamily = 'Open Sans';
    Chart.defaults.global.defaultFontSize = 13;
    Chart.defaults.global.defaultFontColor = '#424242';

    // private namespace
    var that = {};

    that.logger = new EmPower.Util.Logger("Simple Chart", true);

    that.chartPlugins = {
        beforeDraw: function (chartInstance, easing) {
            var ctx = chartInstance.chart.ctx;
            ctx.fillStyle = "white";
            ctx.fillRect(0, 0, chartInstance.chart.width, chartInstance.chart.height);
        }
    };
    Chart.pluginService.register(that.chartPlugins);

//    that.defaultColors = [
//        '20, 20, 20',       // Almost Black
//        
//        '0, 230, 0',        // Green
//        '175, 0, 0',        // Dark Red
//        '232, 44, 44',      // Light Red
//        '132, 125, 13',     // Orange kinda (???)
//        '0, 109, 219',      // Purple
//        '132, 12, 80',      // Magenta
//        '146, 73, 0',       // Brown
//        '255, 109, 182',    // Pink
//        '109, 182, 255',    // Light Blue
//        '73, 0, 146',       // Blue
//        '255, 182, 119',    // Peach
//        '115, 139, 163',    // Teal
//        '0, 91, 91'         // Dark Grey Blue
//    ];

    that.defaultColors = [
        '20, 20, 20',       // Almost Black
        '175, 0, 0',        // Dark Red
        '0, 230, 0',        // Green
        '232, 44, 44',      // Light Red
        '0, 109, 219',      // Purple
        '146, 73, 0',       // Brown
        '255, 109, 182',    // Pink
        '109, 182, 255',    // Light Blue
        '132, 12, 80',      // Magenta
        '73, 0, 146',       // Blue
        '255, 182, 119',    // Peach
        '115, 139, 163',    // Teal
        '132, 125, 13',     // Orange kinda (???)
        '0, 91, 91'         // Dark Grey Blue
    ];


    SimpleChart.defaultColors = function () {
        return _.cloneDeep(that.defaultColors);
    };
    that.shadeRGBColor = function (color, percent) {
        if (percent !== 0) {
            var f = color.split(","),
                    t = percent < 0 ? 0 : 255,
                    p = percent < 0 ? percent * -1 : percent,
                    R = parseInt(f[0]),
                    G = parseInt(f[1]),
                    B = parseInt(f[2]);
            return (Math.round((t - R) * p) + R) + ", " + (Math.round((t - G) * p) + G) + ", " + (Math.round((t - B) * p) + B);
        }
        return color;
    };
    SimpleChart.getColor = function (position) {
        var defaultColors = SimpleChart.defaultColors();
        var color = defaultColors[position];
        return color;
        // return that.shadeRGBColor(color, -0.2 * (Math.floor((position + 1) / defaultColors.length)));
    };
    SimpleChart.getBackgroundColor = function(color) {
        return 'rgba(' + color + ', 0.95)';
    };
    SimpleChart.getBorderColor = function(color){
        return 'rgba(' + color + ', 1)';
    };
           
    
    SimpleChart.modifyIdenticalColors = function(datasets){
        var colorsToDatasets = {};
        _.forOwn(datasets, function(dataset){
            var color = dataset.color;
            if( !colorsToDatasets[color] ){
                colorsToDatasets[color]=[dataset];
            }else{
                colorsToDatasets[color].push(dataset);
            }
        });  
        
        _.forOwn( colorsToDatasets, function(datasets){
            // Return if there is only one dataset with this color - this will probably happen most of the time
            if( datasets.length <= 1){return;}
            
            
            var shading = [0.0, -0.50, 0.50];
            var color = datasets[0].color;
            for( var i=1; i<datasets.length; i++){
                var dataset = datasets[i];
                dataset.color = that.shadeRGBColor(color, shading[i%shading.length]);
                dataset.backgroundColor = SimpleChart.getBackgroundColor(dataset.color);
                dataset.borderColor = SimpleChart.getBorderColor(dataset.color);
            }
            
        });
                
//            var color = dataset.color;
//            var originalColor = dataset.color;
//            var i = 0;
//            while( colorsUsed[color] && i < 5 ){
//                var newColor = that.shadeRGBColor(originalColor, 0.31*(-1^i));
//                color = newColor;
//                i++;
//            }
//            colorsUsed[color] = true;
//            dataset.color = color;
//            dataset.backgroundColor = SimpleChart.getBackgroundColor(color);
//            dataset.borderColor = SimpleChart.getBorderColor(color);
        
        var a = 1;
        a = a + 1;
    };

    // using an array to get a sorting value for determining place in legend and assigned color
    that.orderLegend = function (label, chartType) {
        var arr = [];

        // when the chart is a CI chart use these orders
        if (chartType !== null) {
            switch (chartType) {
                case 'DGA':
                    arr = ['Dissolved Gas Analysis CI', 'Hydrogen', 'Acetylene', 'Ethylene', 'Ethane', 'Methane', 'Carbon Monoxide', 'Carbon Dioxide', 'Oxygen', 'Nitrogen', 'Water in Oil', 'Water Activity', 'Generic', 'Load', 'Winding Hot Spot Temperature - Calculated'];
                    break;
                case 'BUBB':
                    arr = ['Bubbling Temperature CI', 'Top Oil Temperature', 'Winding Hot Spot Temperature - Calculated', 'Winding Hot Spot Temperature', 'Load', 'Generic'];
                    break;
                case 'BUSH':
                    arr = ['Bushings CI', 'Generic', 'Load', 'Ambient Temperature', 'Winding Hot Spot Temperature', 'Winding Hot Spot Temperature - Calculated', 'Top Oil Temperature'];
                    break;
                case 'BOARD':
                    arr = ['Board Breakdown Probability CI', 'Top Oil Temperature', 'Generic', 'Winding Hot Spot Temperature - Calculated', 'Winding Hot Spot Temperature', 'Load'];
                    break;
                case 'HST':
                    arr = ['Hot Spot CI', 'Winding Hot Spot Temperature', 'Top Oil Temperature', 'Load', 'Winding Hot Spot Temperature - Calculated', 'Ambient Temperature', 'Generic'];
                    break;
                case 'LOAD':
                    arr = ['Load CI', 'Load', 'Winding Hot Spot Temperature - Calculated', 'Winding Hot Spot Temperature', 'Top Oil Temperature', 'Generic'];
                    break;
                case "LOL":
                    arr = ['Loss of Life CI', 'Top Oil Temperature', 'Winding Hot Spot Temperature - Calculated', 'Winding Hot Spot Temperature', 'Load', 'Generic'];
                    break;
                case 'OIL':
                    arr = ['Oil Breakdown Probability CI', 'Generic', 'Load', 'Top Oil Temperature', 'Winding Hot Spot Temperature'];
                    break;
                case 'TOT':
                    arr = ['Top Oil Temperature CI', 'Top Oil Temperature', 'Winding Hot Spot Temperature - Calculated', 'Winding Hot Spot Temperature', 'Load', 'Ambient Temperature', 'Generic'];
                    break;
            }
        // all other charts on the site
        } else {
            arr = [
                'Load',
                'Available Load',
                'Loss of Life',
                'Loss of Life Day',
                'Accelerated Aging Factor',
                'Accelerated Aging Factor Total',
                'EQ Aging Factor',
                'EQ Aging Factor Lfie',
                'Top Oil Temperature',
                'Top Oil Temperature Ultimate',
                'Winding Hotspot Temperature',
                'Winding Hotspot Temperature Ultimate',
                'Ambient Temperature',
                'Oil Temperature by Moisture Sensor',
                'Radiator Temp RTD',
                'Cabinet Temp',
                'Water in Oil',
                'Water Activity',
                'Hydrogen',
                'Voltage Phase A',
                'Voltage Phase B',
                'Voltage Phase C',
                'Current Phase A',
                'Current Phase B',
                'Current Phase C',
                'Current Neutral',
                'Generic Temperature',
                'Acetylene',
                'Ethylene',
                'Ethane',
                'Methane',
                'Carbon Monoxide',
                'Carbon Dioxide',
                'Oxygen',
                'Nitrogen',
                'Water Content in Oil',
                'Water Content in Paper',
                'Water Activity in Oil',
                'Water Activity in Paper',
                'Water Activity',
                'Water in Oil',
                'Water in Paper',
                
                'Board Breakdown Probability CI',
                'Bubbling Temperature CI',
                'Dissolved Gas Analysis CI',
                'Health CI',
                'Hot Spot CI',
                'Loss of Life CI',
                'Oil Breakdown Probability CI',
                'Load CI',
                'Top Oil CI',
                
                'primary',
                'secondary',
                'tertiary',
                
                'Generic'
            ];
        }

        var index = arr.indexOf(label);
        return index > -1 ? index : arr.indexOf('Generic');
    };

    that.sortLegend = function (dataset) {
        return dataset.sort(function (a, b) {
            return a.position > b.position;
        });
    };

//    function tooltipUnit(type) {
//        
//    }

    that.defaultChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        responsiveAnimationDuration: 250,
        stacked: false,
        hasDownload: true,
        title: {
            display: false,
            text: '',
            fontSize: 20
        },
        legendCallback: function (chart) {
            var dataset = that.sortLegend(chart.data.datasets);
            return $(EmPower.Templates['templates/graph_legend']({
                hasDownload: chart.options.hasDownload ? true : false,
                datasets: dataset
            }));
        },
        legend: {
            display: false,
            position: 'top'
        },
        layout: {
            padding: {
                left: 5,
                right: 5,
                bottom: 5,
                top: 5
            }
        },
        tooltips: {
            enabled: true,
            mode: 'index',
            intersect: false,
            caretSize: 0,
            position: 'average',
            titleMarginBottom: 8,
            bodySpacing: 5,

            callbacks: {
//                label: function (tooltipItems, data) {
//                    return data.datasets[tooltipItems.datasetIndex].label + ': ' + tooltipItems.yLabel + ' units';
//                }
            }
        }
    };
    SimpleChart.defaultChartOptions = function () {
        return _.merge({}, that.defaultChartOptions, {
            scales: {
                yAxes: [EmPower.Util.SimpleChartAxis.value()],
                xAxes: [EmPower.Util.SimpleChartAxis.time()]
            },
        });
    };


    that._buildDataset = function (collection, model, datasets, datasetConfig/*, assignedColor*/) {

        if ((_.isFunction(datasetConfig.collection) ? (datasetConfig.collection() === collection) : true) &&
                (_.isFunction(datasetConfig.isGraphed) ? datasetConfig.isGraphed(model) : true)) {

            var datasetKey = _.isFunction(datasetConfig.datasetId) ? datasetConfig.datasetId(model) : model.modelName + model.transformerId() + model.deviceId();

            var dataset = datasets[datasetKey];
            if (_.isEmpty(dataset)) {

//                if (model.attributes.gasTypeId !== undefined) {
//                    assignedColor = model.attributes.gasTypeId - 2;
//                }
//                else if (model.attributes.ciTypeId !== undefined) {
//                    assignedColor = model.attributes.ciTypeId - 2;
//                }
                var chartType = typeof datasetConfig.chartType === 'function' ? datasetConfig.chartType() : null;
                var label     = _.isFunction(datasetConfig.label) ? datasetConfig.label(model) : model.description();
                var position  = that.orderLegend(label, chartType);
                var color;
                // weak check to see if this is the temperature graph
                if (model.attributes.hasOwnProperty("temperatureTypeName")) {
                    color = SimpleChart.getColor(model.deviceId() % 14);
                } else if (model.attributes.hasOwnProperty("winding")) {
                    color = SimpleChart.getColor(that.orderLegend(model.winding(), null) % 14);
                } else {
                    color = SimpleChart.getColor(position % 14);
                }
                
                dataset = {
                    label: label,
                    position: position,
                    color: color,
//                    backgroundColor: 'rgba(' + color + ', 0.95)',
//                    borderColor: 'rgba(' + color + ', 1)',
                    backgroundColor: SimpleChart.getBackgroundColor(color),
                    borderColor: SimpleChart.getBorderColor(color),
                    fill: false,
                    borderWidth: 2,
                    data: [{
                            x: _.isFunction(datasetConfig.xValue) ? datasetConfig.xValue(model) : model.timestampUnix(),
                            y: _.isFunction(datasetConfig.yValue) ? datasetConfig.yValue(model) : model.value()
                        }],
                    cubicInterpolationMode: "monotone",
                    pointRadius: 0,
                    pointHoverRadius: 4,
                };

                if (datasetConfig.datasetOptions) {

                    var datasetOptions = _.isFunction(datasetConfig.datasetOptions) ? datasetConfig.datasetOptions(model) : datasetConfig.datasetOptions;

                    dataset = _.merge({}, dataset, datasetOptions);
                }

                datasets[datasetKey] = dataset;
//                assignedColor++;

            } else {

                dataset.data.push({
                    x: _.isFunction(datasetConfig.xValue) ? datasetConfig.xValue(model) : model.timestampUnix(),
                    y: _.isFunction(datasetConfig.yValue) ? datasetConfig.yValue(model) : model.value()
                });
            }
        }
        // return assignedColor;
    };

    SimpleChart.datasetGenerator = function (collections, datasetConfig) {

        var datasets = {};
//        var assignedColor = 0;

        if (!_.isArray(collections)) {
            collections = [collections];
        }

        _.forEach(collections, function (collection) {

            _.forEach(collection.models, function (model) {

                if (_.isArray(datasetConfig)) {
                    _.forEach(datasetConfig, function (datasetConfig) {
                        that._buildDataset(collection, model, datasets, datasetConfig/*, assignedColor*/);
                    });
                } else {
                    that._buildDataset(collection, model, datasets, datasetConfig || {}/*, assignedColor*/);
                }
            });
        });

        _.forOwn(datasets, function (dataset, key) {

            var datasetData = dataset.data;
            var datasetLength = datasetData.length;

            that.logger.info("Original dataset: ", datasetLength);
            if (datasetLength >= (EmPower.Options.graphPoints * 1.5)) {

                // find standard deviation of Y
                var simplifyE = Math.abs(math.std(_.map(dataset.data, 'y')));

                if (!isFinite(simplifyE)) {
                    simplifyE = EmPower.Options.simplifyE;
                }

                that.logger.info("Standard Deviation: ", simplifyE);

                var maxSimplifyE, minSimplifyE;

                var attempt = 1;
                while ((datasetLength < EmPower.Options.graphPoints) || (datasetLength > (EmPower.Options.graphPoints * 1.5))) {

                    if (attempt >= (EmPower.Options.graphSimplifyAttempts + 1)) {

                        datasetData = simplify(dataset.data, maxSimplifyE, true);
                        datasetLength = datasetData.length;
                        that.logger.warn("Failed: Attempt MaxSimplifyE DatasetLength: ", attempt, maxSimplifyE, datasetLength);
                        break;
                    }

                    datasetData = simplify(dataset.data, simplifyE, true);
                    datasetLength = datasetData.length;

                    that.logger.info("Attempt SimplifyE DatasetLength: ", attempt, simplifyE, datasetLength);
                    if (datasetLength > (EmPower.Options.graphPoints * 1.5)) {
                        // dataset is larger...
                        minSimplifyE = simplifyE;
                        if (maxSimplifyE && minSimplifyE) {
                            simplifyE += ((maxSimplifyE - minSimplifyE) / 2);
                        } else {
                            simplifyE += simplifyE * 0.5;
                        }
                    } else if (datasetLength < EmPower.Options.graphPoints) {
                        // dataset is smaller...
                        maxSimplifyE = simplifyE;
                        if (maxSimplifyE && minSimplifyE) {
                            simplifyE -= ((maxSimplifyE - minSimplifyE) / 2);
                        } else {
                            simplifyE -= simplifyE * 0.5;
                        }
                    }

                    attempt++;
                }
            }

            dataset.data = datasetData;
            that.logger.info("Simplified dataset: ", dataset.data.length);
        });
        
        // Modify colors that turn out to be identical
        SimpleChart.modifyIdenticalColors(datasets);

        return _.values(datasets);
    };

    SimpleChart.lineChart = function ($graphContainer, chartOptions, defaultChartOptions) {

        defaultChartOptions = defaultChartOptions || SimpleChart.defaultChartOptions();

        return new Chart($graphContainer, {
            type: 'line',
            data: {},
            options: _.merge({}, defaultChartOptions, chartOptions)
        });
    };

})(EmPower.Util.SimpleChart = EmPower.Util.SimpleChart || {}, jQuery);
