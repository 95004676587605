EmPower.Model.Dga = (function($, undefined) {
    
    return EmPower.Model.AbstractDeviceEventModel.extend({
	    
	modelName: 'DGA',

	_initialize: function() {
	},

	_parse: function(response, options) {
	    return response;
	},
	
	value: function(value) {
	    return this._field('value', value);
	},
	
	valueDisplay: function() {
            var valueDisplay = "No value";
            if (this.value() !== undefined && this.value() !== null) {
                
                if (_.startsWith(this.gasTypeAbbrev(), "H2OOil") ||
                        _.startsWith(this.gasTypeAbbrev(), "H2OPaper") ||
                        _.startsWith(this.gasTypeAbbrev(), "H2OActivityOil") ||
                        _.startsWith(this.gasTypeAbbrev(), "H2OActivityPaper")) {
                    valueDisplay = this.value().toFixedNumber(2);
                    valueDisplay += " (0-1)";
                } else if (_.startsWith(this.gasTypeAbbrev(), "H2OContentPaper")) {
                    valueDisplay = this.value().toFixedNumber(2);
                    valueDisplay += " %";
                } else {
                    valueDisplay = this.value().toFixedNumber(1);
                    valueDisplay += " ppm";
                }
            }
	    return valueDisplay;
	},
	
	gasTypeId: function(value) {
	    return this._field('gasTypeId', value);
	},
	
	gasTypeName: function(value) {
	    return this._field('gasTypeName', value);
	},
	
	gasTypeAbbrev: function(value) {
	    return this._field('gasTypeAbbrev', value);
	},
	
	gasTypeWeightFactor: function(value) {
	    return this._field('gasTypeWeightFactor', value);
	}
    });
    
})(jQuery);
