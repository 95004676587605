EmPower.Collection.AbstractCollection = (function(Collection, $, undefined) {
    
    return Collection.extend({
	
	constructor: function(models, options) {

	    var collectionName = "Collection [" + (this.collectionName || "Abstract") + "]";
	    
	    var loggerEnabled = this.loggerEnabled !== undefined ? this.loggerEnabled : true;
	    
	    this._logger = new EmPower.Util.Logger(collectionName, loggerEnabled);
	    
	    if (!this.collectionName) {
		
		this._logger.throwError("Collection was not extended with a specific Collection Name.");
	    }
	    
	    if (!this.model) {
		
		this._logger.throwError("Collection was not extended with a specific Model.");
	    }
	    
	    if (!this.url) {
		
		this._logger.throwError("Collection was not extended with a specific URL.");
	    }
	    
	    this._hasError = false;
	    
	    this._isFetched = false;
	    
	    this._options = options;
	    
	    Collection.apply(this, arguments);
	},

	_initialize: function() {

	    this._logger.throwError("Collection was not extended with a specific Initialize function.");
	},
	
	initialize: function() {
	    
	    var self = this;
	    
	    self._session = self._getOption('session', true);
	    
	    self.listenTo(self._session, 'reset', function() {
		self.reset();
	    });
	    
	    self.listenTo(self, 'sync', function() {
		self.isFetched(true);
		self.hasError(false);
	    });
	    
	    self.listenTo(self, 'error', function() {
		self.hasError(true);
	    });
	    
	    this._initialize.apply(this, arguments);
	},
	
	_getOptions: function(required) {

	    if (required && (!this._options && _.isEmpty(this._options))) {

		this._logger.throwError("Collection was not initilized with Options.");
	    }

	    return this._options;
	},

	_getOption: function(field, required) {

	    if (!field) {

		return null;
	    }

	    var option;
	    try {

		var options = this._getOptions(required);

		if (options) {
		    if (required && !_.has(options, field)) {
			throw new Error();
		    }
		    option = options[field];
		} else if (required) {
		    throw new Error();
		}

	    } catch (error) {

		this._logger.throwError("Required field [" + field + "] was not found on collection Options.");
	    }

	    return option;
	},
	
	_parse: function(response) {
	    
	    this._logger.throwError("Collection was not extended with a specific Parse function.");
	},

	parse: function (response) { 
	    
	    var models = response;
	    if (response.results && _.isArray(response.results)) {
		models = response.results;
	    }
	    
	    models = this._parse(response) || models;

	    return models;
	},
	
	fetchDebounced: function() {
	    
	    var self = this;
	    
	    var debounce = _.debounce(function() {
		return self.fetch();
	    }, 1);
	    
	    self.fetchDebounced = debounce;
	    
	    return self.fetchDebounced();
	},
	
	isFetched: function(isFetched) {
	
	    if (isFetched !== undefined) {
		this._isFetched = isFetched;
	    }

	    return this._isFetched;
	},
	
	hasError: function(hasError) {
	    
	    if (hasError !== undefined) {
		this._hasError = hasError;
	    }

	    return this._hasError;
	},
	
	reset: function() {
	    
	    var retVal = Collection.prototype.reset.apply(this, arguments);
	    
	    this.isFetched(false);
	    this.hasError(false);
	    
	    if (_.isFunction(this._resetState)) { 
		this._resetState(arguments);
	    } else {
		this._initialize();
	    }
	    
	    return retVal;
	}
    });
    
})(Backbone.Collection, jQuery);
