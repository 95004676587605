EmPower.Route.Router = (function(Router, $, undefined) {
    
    return Router.extend({
		whatAmI: function(){return "EmPower.Route.Router - Also see routeName";},
	
	routeName: "Application",
	
	_paths: function() {
	    return {
		login: function() {
		    return EmPower.Util.Url.builder('');
		},
		map: function(subRoute) {
		    return EmPower.Util.Url.builder('map', subRoute);
		},
		transformers: function(subRoute) {
		    return EmPower.Util.Url.builder('transformers', subRoute);
		},
		settings: function(subRoute) {
		    return EmPower.Util.Url.builder('settings', subRoute);
		},
		about: function(subRoute) {
		    return EmPower.Util.Url.builder('about', subRoute);
		}
	    };
	},
	
	_routes: function(paths) {
	    
	    var routes = {};
	    
	    routes[paths.login()] = this._showLogin;
	    routes[paths.map('(/*subroute)')] = this._invokeMapModule;
	    routes[paths.transformers('(/*subroute)')] = this._invokeTransformersModule;
	    routes[paths.settings('(/*subroute)')] = this._invokeSettingsModule;
	    routes[paths.about('(/*subroute)')] = this._invokeAboutModule;
	    
	    return routes;
	},

	_initialize: function() {
	    
	    var self = this;
	    
	    self._$container = self._getOption('container', true);
	    
	    self._redirect = null;
	    
	    self._session = new EmPower.Model.Session({
		applicationRouter: this
	    });
	    
	    self._contentFrame = new EmPower.View.ContentFrame({
		container: self._$container,
		session: self._session
	    });
	    
	    self._contentFrame.render();
	},
	
	_beforeExecute: function(callback, args, name, section) {

	    if (!Synexxus.Auth.assureSession()) {
		this._session.selectedSection().selected(null);
		
		if (callback !== this._showLogin) {
		    this._redirect = Backbone.history.getFragment();
		    this.showLogin();
		    return false;
		}
	    } else {
		this._session.selectedSection().selected(section !== undefined ? section : this);
	    }
	},
	
	_showLogin: function() {
	    
	    if(!Synexxus.Auth.assureSession()) {

		if (!this._login) {
		    this._login = new EmPower.View.Login({
			session: this._session
		    });
		}
		
		this._session.reset({
		    selectedSection: null,
		    selectedSectionView: this._login
		});
	    } else {
		
		if (this._redirect) {
		    this.navigate(this._redirect, {trigger: true});
		    this._redirect = null;
		} else {
		    this.getMapModule().showMap();
		}
	    }
	},
	showLogin: function() {
	    this.navigate(this.paths.login(), {trigger: true});
	},
	
	redirect: function() {
	    this._showLogin();
	},
	
	_invokeMapModule: function() {

	    if (!this._mapSubRoute) {
		this._mapSubRoute = new EmPower.Route.Map(this.paths.map(), {
		    session: this._session
		});
	    }
	},
	getMapModule: function() {
	    this._invokeMapModule();
	    return this._mapSubRoute;
	},

	_invokeTransformersModule: function() {

	    if (!this._transformerSubRoute) {
		this._transformerSubRoute = new EmPower.Route.Transformers(this.paths.transformers(), {
		    session: this._session
		});
	    }
	},
	getTransformersModule: function() {
	    this._invokeTransformersModule();
	    return this._transformerSubRoute;
	},
	
	_invokeSettingsModule: function() {

	    if (!this._settingsSubRoute) {
		this._settingsSubRoute = new EmPower.Route.Settings(this.paths.settings(), {
		    session: this._session
		});
	    }
	},
	getSettingsModule: function() {
	    this._invokeSettingsModule();
	    return this._settingsSubRoute;
	},

	_invokeAboutModule: function() {

	    if (!this._aboutSubRoute) {
		this._aboutSubRoute = new EmPower.Route.About(this.paths.about(), {
		    session: this._session
		});
	    }
	},
	getAboutModule: function() {
	    this._invokeAboutModule();
	    return this._aboutSubRoute;
	},

	showPageNotFound: function() {
	    
	    this._session.selectedSection().selected(this);

	    if (!this._pageNotFoundView) {
		this._pageNotFoundView = new EmPower.View.PageNotFound({
		    session: this._session
		});
	    }
	    
	    this._session.selectedSectionView().selected(this._pageNotFoundView);
	},
	
	_show: function() {
	    this._contentFrame.show();
	},
	
	_hide: function() {
	    this._contentFrame.hide();
	}
    });
    
})(EmPower.Route.AbstractRouter, jQuery);
