EmPower.View.BushingRow = (function($, undefined) {

    return EmPower.View.AbstractView.extend({
	viewName: "Bushing Row",
	
	_events: function() {
	},
	
	_initialize: function() {
	    this._bushingModel = this._getOption("bushingModel", true);
	},
	
	_setupListeners: function() {
	    
	    var self = this;
	    
	    self.listenTo(self.getBushingModel(), 'change', function() {
		self.renderDebounced();
	    });
	},
	
	getBushingModel: function() {
	    return this._bushingModel;
	},
	
	_htmlTemplate: function() {
	    return EmPower.Templates['transformer/bushing/bushing_row'];
	},
	
	_htmlData: function() {
            
            var busingsModel = this.getBushingModel();
            
            // phase color, signal icon/color, condition text/icon.
            var phaseColor;
            if ((busingsModel && busingsModel.phase())) {
                
                switch(busingsModel.phase().toLowerCase()) {
                    case "a":
                        phaseColor = "red";
                        break;
                    case "b":
                        phaseColor = "gray";
                        break;
                    case "c":
                        phaseColor = "blue";
                        break;
                    default:
                        phaseColor = "disabled";
                }
            } else {
                phaseColor = "disabled";
            }
            
            var signalQualityIcon, signalQualityColor;
            if ((busingsModel && busingsModel.signalQuality())) {
                
                switch(busingsModel.signalQuality().toLowerCase()) {
                    case "signal_down":
                        signalQualityIcon = "fa-times";
                        signalQualityColor = "red";
                        break;
                    case "signal_ok":
                        signalQualityIcon = "fa-check";
                        signalQualityColor = "green";
                        break;
                    case "signal_na":
                        signalQualityIcon = "fa-times";
                        signalQualityColor = "red";
                        break;
                    case "signal_unknown":
                        signalQualityIcon = "fa-minus";
                        signalQualityColor = "yellow";
                        break;
                    default:
                        signalQualityIcon = "fa-circle";
                        signalQualityColor = "disabled";
                        break;
                }
            } else {
                signalQualityIcon = "fa-circle";
                signalQualityColor = "disabled";
            }
            
            var conditionText, conditionIcon, conditionColor;
            if ((busingsModel && (busingsModel.condition() !== undefined && busingsModel.condition() !== null))) {
                
                var condition = busingsModel.condition();
                
                if (condition < 70) {
                    conditionText = "Normal";
                    conditionIcon = "fa-check";
                    conditionColor = "green";
                } else if (condition < 100) {
                    conditionText = "Warning";
                    conditionIcon = "fa-minus";
                    conditionColor = "yellow";
                } else if (condition >= 100) {
                    conditionText = "Alarm";
                    conditionIcon = "fa-times";
                    conditionColor = "red";
                }
            } else {
                conditionText = "No events";
                conditionIcon = "fa-circle";
                conditionColor = "disabled";
            }
            
	    return {
		bushingModel: busingsModel,
                phaseColor: phaseColor,
                signalQualityIcon: signalQualityIcon,
                signalQualityColor: signalQualityColor,
                conditionText: conditionText,
                conditionIcon: conditionIcon,
                conditionColor: conditionColor
	    };
	},
	
	_render: function($html) {
            
            var self = this;
            
            var $g1Inner = $html.find("#dl_gauge_1");
            setTimeout(function() {
                if (true || (self.getBushingModel() !== undefined && self.getBushingModel() !== null)) {
                    if (!self.g1Inner) {
                        self.g1Inner = EmPower.Util.SimpleGauge.smallGauge($g1Inner, {
                            minValue: 0,
                            maxValue: 110,
                            staticZones: [{
                                    strokeStyle: "#2ecc71",
                                    min: 0,
                                    max: 70
                                }, {
                                    strokeStyle: "#f1c40f",
                                    min: 70,
                                    max: 100
                                }, {
                                    strokeStyle: "#e74c3c",
                                    min: 100,
                                    max: 110
                            }],
                            staticLabels: {
                                labels: [0, 70, 100, 110],
                                fractionDigits: 0
                            }
                        });
                    } else {
                        $g1Inner.replaceWith($(self.g1Inner.canvas));
                    }
                    self.g1Inner.set(self.getBushingModel().condition());
                } else {
                    $g1Inner.replaceWith(EmPower.Templates['templates/page_warning']({
                        message: "No Events"
                    }));
                }
            }, 1);
            
	},
	
	_show: function() {
	},
	
	_hide: function() {
	},
	
	_remove: function() {
	}
	
    });
    
})(jQuery);
