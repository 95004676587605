EmPower.Model.PastConections = (function($, undefined) {
    
    return EmPower.Model.AbstractModel.extend({
	
	idAttribute: 'id',
	    
	modelName: 'Past Connections',

	_initialize: function() {
	},

	_parse: function() {
    },

    connectionStartTime: function(value){
        return this._field('connectionStartTime', value);
    },
    
    connectionEndTime: function(value){
        return this._field('connectionEndTime', value);
    },

    eventsNotSynced: function(value){
        return this._field('eventsNotSynced', value);
    },

    eventsSynced: function(value){
        return this._field('eventsSynced', value);
    }

    });
    
})(jQuery);
