EmPower.Model.Annunciator = (function($, undefined) {
    
    return EmPower.Model.AbstractDeviceEventModel.extend({
	    
	modelName: 'Annunciator',

	_initialize: function() {
	},

	_parse: function(response, options) {
	    return response;
	},
	
	value: function(value) {
	    return this._field('value', value);
	},
        
        hidden: function(hidden) {
            return this._field('hidden', hidden);
        }
    });
    
})(jQuery);
