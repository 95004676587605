EmPower.Model.Harmonics = (function($, undefined) {
    
    return EmPower.Model.AbstractModel.extend({
	
	idAttribute: 'id',
	    
	modelName: 'Harmonics',

	_initialize: function() {
	},

	_parse: function() {
	},
        
        VA: function(value) {
            return this._field("VA", value * 1000);
        },
        
        VB: function(value) {
            return this._field("VB", value * 1000);
        },
        
        VC: function(value) {
            return this._field("VC", value * 1000);
        }
    });
    
})(jQuery);
