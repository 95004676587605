EmPower.Model.DynamicLoading = (function($, undefined) {
    
    return EmPower.Model.AbstractDeviceEventModel.extend({
	    
	modelName: 'Dynamic Loading',

	_initialize: function() {
	},

	_parse: function(response, options) {
	    return response;
	},
        
        acceleratedAgingFactor: function(value) {
            return this._field('acceleratedAgingFactor', value);
        },
        
        acceleratedAgingFactorTotal: function(value) {
            return this._field('acceleratedAgingFactorTotal', value);
        },
        
	ambientTemp: function(value) {
	    return this._field('ambientTemp', value);
	},
	
	ambientTempDisplay: function() {
	    return this.ambientTemp() ? this.ambientTemp() + "&#8451;" : "No Ambient Temperature";
	},
        
        eqAgingFactor: function(value) {
            return this._field('eqAgingFactor', value);
        },
        
        eqAgingFactorLife: function(value) {
            return this._field('eqAgingFactorLife', value);
        },
        
        lifeLoss: function(value) {
            return this._field('lifeLoss', value);
        },
        
        lifeLossDay: function(value) {
            return this._field('lifeLossDay', value);
        },
        
        load: function(value) {
            return this._field('load', value);
        },
        
        minimumLoad: function(value) {
            return this._field('minimumLoad', value);
        },
        
        tempDuration: function(value) {
            return this._field('tempDuration', value);
        },
        
        timeOverTemp: function(value) {
            return this._field('timeOverTemp', value);
        },
        
        timeOverTempDisplay: function() {
            
            var timeOverTemp = this.timeOverTemp();
            if (timeOverTemp >= 1000) {
                timeOverTemp = "infinity";
            }
            if (timeOverTemp <= -1000) {
                timeOverTemp = "infinity";
            }
            
            return timeOverTemp;
        },
        
        timeOverTempTopOil: function(value) {
            return this._field('timeOverTempTopOil', value);
        },
        
        timeOverTempWindingHs: function(value) {
            return this._field('timeOverTempWindingHs', value);
        },
        
        topOilTempInit: function(value) {
            return this._field('topOilTempInit', value);
        },
        
        topOilTempRiseInit: function(value) {
            return this._field('topOilTempRiseInit', value);
        },
        
        topOilTempRiseUlt: function(value) {
            return this._field('topOilTempRiseUlt', value);
        },
        
        topOilTempUlt: function(value) {
            return this._field('topOilTempUlt', value);
        },
        
        topOilTimeConst: function(value) {
            return this._field('topOilTimeConst', value);
        },
        
        ultLoad: function(value) {
            return this._field('ultLoad', value);
        },
        
        ultLoadDiff: function(value) {
            return this._field('ultLoadDiff', value);
        },
        
        ultLoadTopOil: function(value) {
            return this._field('ultLoadTopOil', value);
        },
        
        ultLoadWindingHs: function(value) {
            return this._field('ultLoadWindingHs', value);
        },
        
        windingHsTempInit: function(value) {
            return this._field('windingHsTempInit', value);
        },
        
        windingHsTempRiseInit: function(value) {
            return this._field('windingHsTempRiseInit', value);
        },
        
        windingHsTempRiseUlt: function(value) {
            return this._field('windingHsTempRiseUlt', value);
        },
        
        windingHsTempUlt: function(value) {
            return this._field('windingHsTempUlt', value);
        },
        currentLimit: function(value) {
            return this._field('currentLimit', value);
        },
        topOilMaxSetPoint: function(value) {
            return this._field('topOilMaxSetPoint', value);
        },
        windingMaxSetPoint: function(value) {
            return this._field('windingMaxSetPoint', value);
        },
        agingEnabled: function(value) {
            return this._field('agingEnabled', value);
        },
        insulationType: function(value) {
            return this._field('insulationType', value);
        }
    });
    
})(jQuery);
