EmPower.Model.Cooling = (function($, undefined) {
    
    return EmPower.Model.AbstractDeviceEventModel.extend({
	    
	modelName: 'Cooling',

	_initialize: function() {
	},

	_parse: function(response, options) {
	    return response;
	},
	
	value: function(value) {
	    return this._field('value', value);
	},
	
	valueDisplay: function() {
	    var value = this.value();
	    if (value === 1) {
		return "Active";
	    } else if (value === 0) {
		return "Inactive";
	    }  else {
		return "No Value";
	    }
	},
	
	totalHours: function(value) {
	    return this._field('totalHours', value);
	},
	
	currentDraw: function(value) {
	    return this._field('currentDraw', value);
	},
	
	currentDrawDisplay: function(value) {
	    return (this.currentDraw() !== undefined && this.currentDraw() !== null) ? (this.currentDraw() / 1000).toFixedNumber(1) + " A" : "No Current";
	},
	
	coolingStageId: function(value) {
	    return this._field('coolingStageId', value);
	}
    });
    
})(jQuery);
