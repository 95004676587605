EmPower.Route.AbstractRouterObject = (function($, undefined) {
    
    // keep track of last url for router...
    /// on show if null go to 'homescreen' or if not go to last url...
    
    return function(Router, routerType) {
	
	routerType = routerType || "Router";
	
	return Router.extend({
		whatAmI: function(){return "EmPower.Route.AbstractRouterObject";},
	
	    _routes: function() {
		this._logger.throwError(routerType + " was not extended with specific routes.");
	    },

	    constructor: function(rootPath, options) {

		var routeName = routerType + " [" + (this.routeName || "Abstract") + "]";

		var loggerEnabled = this.loggerEnabled !== undefined ? this.loggerEnabled : true;

		this._logger = new EmPower.Util.Logger(routeName, loggerEnabled);

		if (!this.routeName) {

		    this._logger.throwError(routerType + " was not extended with a specific " + routerType + " Name.");
		}

		var paths = this._paths();
		this.paths = paths;

		var routes = this._routes(paths);
		routes["*path"] = "showPageNotFound";
		this.routes = routes;

		this._options = options;

		this._rootPath = _.isString(rootPath) ? rootPath
			: this._logger.throwError(routerType + " was not constructed with a specific RootPath.");

		Router.apply(this, arguments);
	    },

	    _initialize: function() {
		this._logger.throwError(routerType + " was not extended with specific Initialization.");
	    },

	    initialize: function() {
		
		this._initialize();
		
		this._logger.info("Initialized " + routerType, this);

		return this;
	    },

	    getRootPath: function() {
		return this._rootPath;
	    },
	    
	    _getOptions: function(required) {

		if (required && (!this._options && _.isEmpty(this._options))) {

		    this._logger.throwError(routerType + " was not initilized with Options.");
		}

		return this._options;
	    },

	    _getOption: function(field, required) {

		if (!field) {

		    return null;
		}

		var option;
		try {

		    var options = this._getOptions(required);

		    if (options) {
			if (required && !_.has(options, field)) {
			    throw new Error();
			}
			option = options[field];
		    } else if (required) {
			throw new Error();
		    }

		} catch (error) {

		    this._logger.throwError("Required field [" + field + "] was not found on " + routerType + " Options.");
		}

		return option;
	    },
	    
	    _beforeExecute: function(callback, args, name) {
		this._logger.throwError(routerType + " was not extended with specific BeforeExecute.");
	    },

	    showPageNotFound: function() {
		return this._logger.throwError(routerType + " was not extend with ShowPageNotFound function.");
	    },

	    showLogin: function() {
		return this._logger.throwError(routerType + " was not extend with ShowLogin function.");
	    },
	    
	    execute: function(callback, args, name) {

		if (this._beforeExecute(callback, args, name) !== false) {
		    Router.prototype.execute.apply(this, arguments);
		} else {
		    this._logger.info(routerType + " stopped route execution.", callback, args, name);
		}
	    },
	    
	    _show: function() {
		return this._logger.throwError(routerType + " was not extend with a show function.");
	    },
	    show: function() {
		this._show();
	    },
	    
	    _hide: function() {
	    },
	    hide: function() {
		this._hide();
	    }
	});
    };
    
})(jQuery);
