EmPower.View.CameraInformation = (function ($, undefined) {

    return EmPower.View.AbstractView.extend({
        viewName: "Camera Information",

        _events: function () {
            return {
                "touchstart #pan-up": "onActionClick", "touchend #pan-up": "onActionClick",
                "mousedown #pan-up": "onActionClick", "mouseup #pan-up": "onActionClick",
                "touchstart #pan-down": "onActionClick", "touchend #pan-down": "onActionClick",
                "mousedown #pan-down": "onActionClick", "mouseup #pan-down": "onActionClick",
                "touchstart #pan-left": "onActionClick", "touchend #pan-left": "onActionClick",
                "mousedown #pan-left": "onActionClick", "mouseup #pan-left": "onActionClick",
                "touchstart #pan-right": "onActionClick", "touchend #pan-right": "onActionClick",
                "mousedown #pan-right": "onActionClick", "mouseup #pan-right": "onActionClick",
                "touchstart #zoom-in": "onActionClick", "touchend #zoom-in": "onActionClick",
                "mousedown #zoom-in": "onActionClick", "mouseup #zoom-in": "onActionClick",
                "touchstart #zoom-out": "onActionClick", "touchend #zoom-out": "onActionClick",
                "mousedown #zoom-out": "onActionClick", "mouseup #zoom-out": "onActionClick",
                "change #preset-select": "onPresetClicked",
                "change #res-select": "onProfileClicked",
                "change #player-select": function () {
                    // remove old player, disposes dom element too unfortunately, has to be appended after
                    videojs('videocamera').dispose();
                    $('#camera-video-container').append('<video-js id="videocamera" class="video-js vjs-default-skin vjs-fluid" lang="en"><p class="vjs-no-js">Loading video...</a></p></video-js>');
                    this._streamCamera(this._selectedCamera.selected());
                },
                "click [data-action=end-stream]": function () {
                    this._stopStream(this._selectedCamera.selected());
                },
                "click [data-action=edit-preset]": function () {
                    var el = document.getElementById('preset-select');
                    var name = el.options[el.selectedIndex].text;
                    var num = $("#preset-select").val();
                    var camera = this._cameraModel().transformerDeviceId();
                    EmPower.Dialog.Preset.updateCreatePreset(this._session, this._selectedTransformer.selectedId(), camera, num, name, 'edit');
                },
                "click [data-action=create-preset]": function () {
                    var el = document.getElementById('preset-select');
                    var name = el.options[el.selectedIndex].text;
                    var camera = this._cameraModel().transformerDeviceId();
                    EmPower.Dialog.Preset.updateCreatePreset(this._session, this._selectedTransformer.selectedId(), camera, -1, name, 'create');
                },
                "click [data-action=delete-preset]": function () {
                    var el = document.getElementById('preset-select');
                    var name = el.options[el.selectedIndex].text;
                    var num = $("#preset-select").val();
                    var camera = this._cameraModel().transformerDeviceId();
                    EmPower.Dialog.Preset.deletePreset(this._session, this._selectedTransformer.selectedId(), camera, num, name);
                }
            };
        },

        _initialize: function () {
            this._lastCameraID = -1;
            this._selectedCamera = this._getOption("selectedCamera", true);
            this._cameraModel(this._selectedCamera.selected());
            this._selectedTransformer = this._session.transformerSession().selectedTransformer();

            this._cameraCollection = new EmPower.Collection.Camera(null, {
                session: this._session,
                selectedObject: this._selectedTransformer
            });
        },

        _setupListeners: function () {

            var self = this;

            self.listenTo(this._selectedTransformer, 'change', function () {
                EmPower.Dialog.SimpleDialog.Loading.close();
            });

            self.listenTo(self._selectedCamera, 'change', function () {
                self._cameraModel(self._selectedCamera.selected());
                self._streamCamera(self._selectedCamera.selected());
                self.renderDebounced();

            });
        },

        _cameraModel: function (selectedCameraModel) {

            var self = this;

            if (selectedCameraModel !== undefined) {

                if (self._selectedCameraModel && selectedCameraModel) {

                    if (self._selectedCameraModel.attributes.deviceId !== selectedCameraModel.attributes.deviceId) {

                        if (self._selectedCameraModel) {
                            self.stopListening(self._selectedCameraModel);
                        }

                        if (selectedCameraModel) {
                            self.listenTo(selectedCameraModel, 'change', function () {
                                self.renderDebounced();
                            });
                        }

                    }
                }

                self._selectedCameraModel = selectedCameraModel;
            }

            return self._selectedCameraModel;
        },

        _stopStream: function (camera) {

            var url = EmPower.Util.Url.apiBuilder('transformers', this._session.transformerSession().selectedTransformer().selectedId(), 'camera', camera.transformerDeviceId(), 'stopstream');
            $.ajax({
                url: url,
                type: 'POST',
                success: function (data) {
                    videojs('videocamera').dispose();
                    $('#camera-video-container').append('<video-js id="videocamera" class="video-js vjs-default-skin vjs-fluid" lang="en"><p class="vjs-no-js">Loading video...</a></p></video-js>');
                },
                error: function (err) {
                    console.log(err);
                }
            });

            this._selectedCamera.selected(null);

        },

        _streamCamera: function (camera) {
            if (camera) {

                EmPower.Dialog.SimpleDialog.Loading.open("Setting up video stream...");

                var url = EmPower.Util.Url.apiBuilder(
                        'transformers',
                        this._session.transformerSession().selectedTransformer().selectedId(),
                        'camera',
                        camera.transformerDeviceId(),
                        'startstream'
                        );

                var self = this;
                $.ajax({
                    url: url,
                    type: 'POST',
                    success: function (data) {

                        setTimeout(function () {

                            if (data.cameraUrl) {

                                EmPower.Dialog.SimpleDialog.Loading.open("Connecting to video stream...");

                                var rtmpUrl = data.cameraUrl.replace("http://", "rtmps://");
                                var httpsUrl = data.cameraUrl.replace("http://", "https://");
                                var hlsUrl = httpsUrl + "/playlist.m3u8";

                                var playVideo = function () {

                                    // for now we will have the user select the player protocol since videojs fallback has issues, defaults flash
                                    var protocol = $('#player-select').val();
                                    if (protocol === 'flash') {
                                        return videojs('#videocamera', {
                                            fluid: true,
                                            preload: 'none',
                                            controls: true,
                                            autoplay: true,
                                            techOrder: ["flash"],
                                            sources: [
                                                {src: rtmpUrl, type: "rtmp/mp4"}
                                            ]
                                        }, function () {
                                            EmPower.Dialog.SimpleDialog.Loading.close();
                                            this.play();
                                        });

                                    } else {
                                        return videojs('#videocamera', {
                                            fluid: true,
                                            preload: 'none',
                                            controls: true,
                                            autoplay: true,
                                            techOrder: ["html5"],
                                            sources: [
                                                {src: hlsUrl, type: "application/x-mpegURL"}
                                            ]
                                        }, function () {
                                            EmPower.Dialog.SimpleDialog.Loading.close();
                                            this.play();
                                        });

                                    }

                                };

                                var checkVideo = function (counter) {
                                    $.ajax({
                                        url: httpsUrl,
                                        type: 'GET',
                                        defaultError: false,
                                        success: function () {
                                            playVideo();
                                        },
                                        error: function () {
                                            if (counter < 10) {
                                                counter++;
                                                setTimeout(checkVideo, 5000, counter);
                                            } else {
                                                EmPower.Dialog.SimpleDialog.Loading.close();
                                                $.notify({
                                                    message: "Sorry, unable to connect to video stream."
                                                }, {
                                                    type: 'danger',
                                                    placement: {
                                                        from: 'top',
                                                        align: 'center'
                                                    },
                                                    z_index: 9999
                                                });
                                            }
                                        }
                                    });
                                };

                                checkVideo(0);

                            } else {
                                EmPower.Dialog.SimpleDialog.Loading.close();
                                $.notify({
                                    message: "Sorry, a video stream was unable to be setup."
                                }, {
                                    type: 'danger',
                                    placement: {
                                        from: 'top',
                                        align: 'center'
                                    },
                                    z_index: 9999
                                });
                            }
                        }, 15000);
                    },
                    error: function () {
                        EmPower.Dialog.SimpleDialog.Loading.close();
                    }
                });
            }
        },

        onPresetClicked: function (e) {
            document.getElementById('delete-preset').disabled = false;
            document.getElementById('edit-preset').disabled = false;
            var presetNumber = $("#preset-select").val();

            var url = EmPower.Util.Url.apiBuilder(
                    'transformers',
                    this._session.transformerSession().selectedTransformer().selectedId(),
                    'camera',
                    this._cameraModel().transformerDeviceId(),
                    'action',
                    'preset',
                    presetNumber
                    );

            $.ajax({
                url: url,
                type: 'POST',
                success: function (data) {
                    // console.log(data);
                },
                error: function () {
                    console.log("Could not set preset");
                }
            });
        },

        onProfileClicked: function (e) {
            var self = this;
            var profileName = $("#res-select").val();
            var url = EmPower.Util.Url.apiBuilder("transformers", this._selectedTransformer.selectedId(), "camera", this._cameraModel().transformerDeviceId(), "media-profile", "/" + profileName);
            $.ajax({
                url: url,
                type: 'POST',
                success: function (data) {
                    videojs('videocamera').dispose();
                    $('#camera-video-container').append('<video-js id="videocamera" class="video-js vjs-default-skin vjs-fluid" lang="en"><p class="vjs-no-js">Loading video...</a></p></video-js>');
                    self._streamCamera(self._selectedCamera.selected());
                },
                error: function (err) {
                    console.log(err);
                }
            });

        },

        onActionClick: function (e) {
            if (e.type === 'touchstart' || e.type === 'touchend') {
                e.preventDefault();
            }

            var action = e.currentTarget.id;
            if (e.type === "mousedown" || e.type === 'touchstart') {
                switch (action) {
                    case "pan-up":
                        this.ptzMove("up");
                        break;
                    case "pan-down":
                        this.ptzMove("down");
                        break;
                    case "pan-left":
                        this.ptzMove("left");
                        break;
                    case "pan-right":
                        this.ptzMove("right");
                        break;
                    case "zoom-in":
                        this.ptzMove("zoomin");
                        break;
                    case "zoom-out":
                        this.ptzMove("zoomout");
                        break;
                }
            } else if (e.type === "mouseup" || e.type === 'touchend') {
                this.ptzMove("stop");
            }


        },

        ptzMove: function (action) {

            var url = EmPower.Util.Url.apiBuilder(
                    'transformers',
                    this._session.transformerSession().selectedTransformer().selectedId(),
                    'camera',
                    this._cameraModel().transformerDeviceId(),
                    'action',
                    action
                    );
            $.ajax({
                type: "POST",
                url: url,
                success: function () {
                },
                error: function (err) {
                    console.log(err);
                }
            });

        },

        _htmlTemplate: function () {
            return EmPower.Templates['transformer/camera/camera_information'];
        },

        _htmlData: function () {
            var self = this;

            var isMobile = false;
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                isMobile = true;
            }

            var cameraModel = this._cameraModel();

            // retrieve the timer information
            if (self._selectedCamera.selected() !== null) {
                var interval = 30000;

                setInterval(function () {
                    // refresh the collection for fresh timer information
                    self._cameraCollection.fetch();

                    setTimeout(function () {
                        var selectedCamera = self._cameraCollection.models.find(function (x) {
                            return x.attributes.deviceId === self._selectedCamera.selected().attributes.deviceId;
                        });

                        if (selectedCamera.attributes.streamingInformation) {
                            var timeRemaining = selectedCamera.attributes.streamingInformation.timeRemainingMS;
                            var currentTime = moment().valueOf();
                            var endTime = currentTime + timeRemaining;
                            var diff = endTime - currentTime;
                            var duration = moment.duration(diff, 'milliseconds');

                            $('#timeRemaining').text(duration.minutes() + ' minutes remaining');

                            if (duration < 1) {
                                $('#timeRemaining').text('Stream ended');
                            } else if (duration < 60001) {
                                // prompt user to continue watching
                                EmPower.Dialog.Camera.addTimeToCamera(self._session, self._selectedTransformer.selectedId(), cameraModel.transformerDeviceId());
                            }
                            
                            $('#timeRemaining').text('No Stream Duration');
                        }

                    }, 1000);

                }, interval);
            }

            return {
                rows: [],
                cameraModel: cameraModel,
                isMobile: isMobile
            };
        },

        _render: function ($html) {

        },

        _show: function () {
        },

        _hide: function () {
            // if streaming, stop...
        },

        _remove: function () {
            // if streaming, stop...
        }
    });

})(jQuery);
