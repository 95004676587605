EmPower.View.MetrologyRow = (function($, undefined) {

    return EmPower.View.AbstractView.extend({
	viewName: "Metrology Row",
	
	_events: function() {
	},
	
	_initialize: function() {
	    this._metrologyModel = this._getOption("metrologyModel", true);
	},
	
	_setupListeners: function() {
	    
	    var self = this;
	    
	    self.listenTo(self.getMetrologyModel(), 'change', function() {
		self.renderDebounced();
	    });
	},
	
	getMetrologyModel: function() {
	    return this._metrologyModel;
	},
	
	_htmlTemplate: function() {
	    return EmPower.Templates['transformer/power-metering/metrology_row'];
	},
	
	_htmlData: function() {
            // by default show all the power fields, unless the monitor type is CT only, then hide them
            var hasPT = true;
            if (this._metrologyModel.metrologyMonitorType() === "THREE_PHASE_CT" || this._metrologyModel.metrologyMonitorType() === "SINGLE_PHASE_A_CT") {
                hasPT = false;
            }
            
            var lineLabels = {};
            if (this._metrologyModel.ptType() === "WYE_PS") {
                lineLabels.lineA = "Line A";
                lineLabels.lineB = "Line B";
                lineLabels.lineC = "Line C";
            }
            else if (this._metrologyModel.ptType() === "DELTA_PS"
                     && this._metrologyModel.ctType() === "LINE"
                     && this._metrologyModel.metrologyMonitorType() === "THREE_PHASE") {
                lineLabels.lineA = "Phase AB/I A";
                lineLabels.lineB = "Phase BC/I B";
                lineLabels.lineC = "Phase CA/I C";
            }
            else if (this._metrologyModel.ptType() === "DELTA_PS"
                     && this._metrologyModel.ctType() === "PHASE"
                     && this._metrologyModel.metrologyMonitorType() === "SINGLE_PHASE_A") {
                lineLabels.lineA = "Phase AB/I A";
                lineLabels.lineB = "Phase BC/I B";
                lineLabels.lineC = "Phase CA/I C";
            }
            else if (this._metrologyModel.ptType() === "DELTA_PS" && this._metrologyModel.ctType() === "PHASE") {
                lineLabels.lineA = "Phase AB";
                lineLabels.lineB = "Phase BC";
                lineLabels.lineC = "Phase CA";
            }
            else {
                lineLabels.lineA = "Line A";
                lineLabels.lineB = "Line B";
                lineLabels.lineC = "Line C";
            }
            
	    return {
		metrologyModel: this.getMetrologyModel(),
                hasPT: hasPT,
                lineLabels: lineLabels
	    };
	},
	
	_render: function() {
	},
	
	_show: function() {
	},
	
	_hide: function() {
	},
	
	_remove: function() {
	}
	
    });
    
})(jQuery);
