EmPower.Collection.Document = (function($, undefined) {
    
    return EmPower.Collection.AbstractSelectedObjectCollection.extend({
	collectionName: 'Document',

	model: EmPower.Model.Document,
	
	url: function() {
	    return EmPower.Util.Url.apiBuilder('transformers', this._selectedObject.selectedId(), 'documents');
	},
	
	_initialize: function() {
	},
	
	_parse: function() {
	},
        
        downloadUrl: function() {
            return this.url() + "/download";
        }
    });
    
})(jQuery);
