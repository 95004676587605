EmPower.View.AccessGateTable = (function($, undefined) {

    return EmPower.View.AbstractEventTableView.extend({
	viewName: "Access Gate Table",
	
	_getEventCollection: function() {
	    return new EmPower.Collection.AccessGateEvent(null, {
		session: this._session,
		selectedObject: this._selectedTransformer,
		dateRange: this._session.dateRange()
	    });
	},
	
	_createTableView: function($container, eventCollection) {
	    return new bbGrid.View({
		rows: 7,
		container: $container,
		collection: eventCollection,
		colModel: [{
			title: 'Description',
			index: true,
			name: 'description',
			sorttype: 'string'
		    }, {
			title: 'Value',
			index: true,
			name: 'value',
			sorttype: 'number'
		    }, {
			title: 'Timestamp',
			index: true,
			name: 'timestamp',
			sorttype: 'number'
		    }]
	    });
	}
    });
    
})(jQuery);
