EmPower.View.DgaRow = (function($, undefined) {

    return EmPower.View.AbstractView.extend({
	viewName: "Dga Row",
	
	_events: function() {
	},
	
	_initialize: function() {
	    this._dgaModel = this._getOption("dgaModel", true);
	},
	
	_setupListeners: function() {
	    
	    var self = this;
	    
	    self.listenTo(self.getDgaModel(), 'change', function() {
		self.renderDebounced();
	    });
	},
	
	getDgaModel: function() {
	    return this._dgaModel;
	},
	
	_htmlTemplate: function() {
	    return EmPower.Templates['transformer/dga/dga_row'];
	},
	
	_htmlData: function() {
            
            var gasTypeAbbrev = this.getDgaModel().gasTypeAbbrev();
            if (_.startsWith(gasTypeAbbrev, "H2OOil")) {
                gasTypeAbbrev = "H2O in oil";
            } else if (_.startsWith(gasTypeAbbrev, "H2OPaper")) {
                gasTypeAbbrev = "H2O in paper";
            } else if (_.startsWith(gasTypeAbbrev, "H2OContentOil")) {
                gasTypeAbbrev = "H2O in oil";
            } else if (_.startsWith(gasTypeAbbrev, "H2OContentPaper")) {
                gasTypeAbbrev = "H2O in paper";
            } else if (_.startsWith(gasTypeAbbrev, "H2OActivityOil")) {
                gasTypeAbbrev = "H2O in oil";
            } else if (_.startsWith(gasTypeAbbrev, "H2OActivityPaper")) {
                gasTypeAbbrev = "H2O in paper";
            }
            
	    return {
		dgaModel: this.getDgaModel(),
                gasTypeAbbrev: gasTypeAbbrev
	    };
	},
	
	_render: function() {
	},
	
	_show: function() {
	},
	
	_hide: function() {
	},
	
	_remove: function() {
	}
	
    });
    
})(jQuery);
