EmPower.View.TemperatureList = (function ($, undefined) {

    return EmPower.View.AbstractCollectionView.extend({
        viewName: "Temperature List",

        _events: function () {
        },

        _initialize: function () {
            this._applicationRouter = this._session.applicationRouter();
            this._temperatureUnit = this._getOption("temperatureUnit", true);
        },

        _setupListeners: function () {
        },

        _htmlTemplate: function () {
            return EmPower.Templates['transformer/temperature/temperature_list'];
        },

        _htmlData: function () {
        },

        _getCollectionContainer: function ($html) {
            return $html.filter("div[data-section='temperature-list']");
        },
        
        _createModelView: function (model) {
            return new EmPower.View.TemperatureRow({
                session: this._session,
                temperatureModel: model,
                temperatureUnit: this._temperatureUnit
            });
        },

        _getLoadingCollectionMessage: function () {
            return "Loading temperatures, please wait...";
        },

        _getEmptyCollectionMessage: function () {
            return "Sorry, no temperatures have been configured.";
        },

        _render: function () {
        },

        _show: function () {
        },

        _hide: function () {
        },

        _remove: function () {
        }
    });

})(jQuery);
