EmPower.View.Dga = (function($, undefined) {

    return EmPower.View.AbstractView.extend({
	viewName: "Transformer DGA",
	
	_events: function() {
	},
	
	_initialize: function() {
	    this._selectedTransformer = this._session.transformerSession().selectedTransformer();
	    
	    this._dgaCollection = this._session.transformerSession().dgaCollectionProxy();
	},
	
	_setupListeners: function() {
	},
	
	_htmlTemplate: function() {
	    return EmPower.Templates['transformer/dga/transformer_dga'];
	},
	
	_htmlData: function() {
	},
	
	_render: function($html) {
	    
	    var $dgaList = $html.filter("div[data-section='transformer-dga-list']");
	    if (!this._dgaList) {

		this._dgaList = new EmPower.View.DgaList({
		    session: this._session,
		    collection: this._dgaCollection,
		    selectedModel: null
		});
		this._dgaList.render($dgaList);
	    } else {
		$dgaList.replaceWith(this._dgaList.$el);
	    }
	},
	
	_show: function() {
	    if (this._dgaList) {
		this._dgaList.show();
	    }
	},
	
	_hide: function() {
	    if (this._dgaList) {
		this._dgaList.hide();
	    }
	},
	
	_remove: function() {
	    if (this._dgaList) {
		this._dgaList.remove();
	    }
	}
    });
    
})(jQuery);
