EmPower.View.DynamicLoadingTable = (function($, undefined) {

    return EmPower.View.AbstractEventTableView.extend({
	viewName: "Transformer Annunciator Table",
	
	_getEventCollection: function() {
	    return this._session.transformerSession().dynamicLoadingEventCollection();
	},
	
	_createTableView: function($container, eventCollection) {
	    return new bbGrid.View({
		rows: 7,
		container: $container,
		collection: eventCollection,
		colModel: [{
			title: 'Available Load',
			index: true,
			name: 'ultLoad',
			sorttype: 'number'
		    }, {
			title: 'Ambient Temperature',
			index: true,
			name: 'ambientTemp',
			sorttype: 'number'
		    }, {
			title: 'Time to Over Temperature',
			index: true,
			name: 'timeOverTemp',
			sorttype: 'number'
		    }, {
			title: 'Temperature Duration',
			index: true,
			name: 'tempDuration',
			sorttype: 'number'
		    }, {
			title: 'Timestamp',
			index: true,
			name: 'timestamp',
			sorttype: 'number'
		    }]
	    });
	}
    });
    
})(jQuery);
