EmPower.Route.Settings = (function(SubRoute, $, undefined) {
    
    return SubRoute.extend({
		whatAmI: function(){return "EmPower.Route.Settings - Also see routeName";},
	
	routeName: "Settings",
	
	_paths: function() {
	    return {
		accountManagement: function(userId) {
		    return EmPower.Util.Url.builder(userId);
		}
	    };
	},
	
	_routes: function(paths) {
	    
	    var routes = {};
	    
	    routes[paths.accountManagement()] = this._showAccountManagement;
	    routes[paths.accountManagement('(:userId)')] = this._showAccountManagement;
	    
	    return routes;
	},
	
	_initialize: function() {
	    this._accountManagementView = new EmPower.View.AccountManagement({
		session: this._session
	    });
	},
	
	
	_showAccountManagement: function(userId) {
	    this._session.selectedSectionView().selected(this._accountManagementView);
	    this._session.settingSession().selectedUser().selectedId(userId ? userId : null);
	},
	showAccountManagement: function() {
	    this.navigate(this.paths.accountManagement(this._session.settingSession().selectedUser().selectedId()), {trigger: true});
	},
	
	_show: function() {
	    this._session.selectedSectionView().selected(this._accountManagementView);
	}
    });
    
})(EmPower.Route.AbstractSubRoute, jQuery);
