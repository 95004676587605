EmPower.View.Login = (function($, undefined) {
    
    return EmPower.View.AbstractView.extend({
	viewName: "Login",
	
	_events: function() {
	    return {
		'click button[data-action="empower-login"]': function(event) {
		    event.preventDefault();
		    this._onLogin();
		}
	    };
	},
	
	_initialize: function() {
	    this._applicationRouter = this._session.applicationRouter();
	},
	
	_setupListeners: function() {
	},
	
	_htmlTemplate: function() {
	    
	    return EmPower.Templates['login/login'];
	},
	
	_htmlData: function() {
	},
	
	_render: function($html) {
	},
	
	_onLogin: function() {
	    
	    var self = this;

	    if (self._spinIntervalId) {
		clearInterval(self._spinIntervalId);
	    }

	    var degrees = 0;
	    var turnNum = 0;
	    var direction = 1; // 1: clockwise, -1: counterclockwise
	    var lockElement = document.getElementById("synexxus-lock");

	    var rotateImg = function() {

		if(navigator.userAgent.match("Chrome")){
		    
			lockElement.style.WebkitTransform = "rotate(" + degrees + "deg)";
		} else if(navigator.userAgent.match("Firefox")){
		    
			lockElement.style.MozTransform = "rotate(" + degrees + "deg)";
		} else if(navigator.userAgent.match("MSIE")){
		    
			lockElement.style.msTransform = "rotate("+ degrees + "deg)";
		} else if(navigator.userAgent.match("Opera")){
		    
			lockElement.style.OTransform = "rotate(" + degrees + "deg)";
		} else {
		    
			lockElement.style.transform = "rotate(" + degrees + "deg)";
		}

		degrees += (10 * direction);

		if (turnNum === 0 && degrees >= 75) {

		    turnNum++;
		    direction = -1;
		} else if (turnNum === 1 && degrees <= -135) {

		    turnNum++;
		    direction = 1;
		} else if (turnNum === 2 && degrees >= 270) {

		    turnNum++;
		    direction = -1;
		} else if (turnNum >= 3 && degrees <= 0) {

		    turnNum = 0;
		    direction = 1;
		}
	    };

	    self.$el.find("div[data-section='empower-login-error']").hide();

	    self._spinIntervalId = setInterval(rotateImg, 35);
	    var username = self.$el.find('input[name=username]').val();
	    var password = self.$el.find('input[name=password]').val();

	    var login = Synexxus.Auth.login(username, password);

	    login = login.then(function(session) {
		EmPower.app.currentUser = new EmPower.Model.User({username: Synexxus.Auth.session.username});
		return $.when(EmPower.app.currentUser.fetch());
	    });

	    login.fail(function(object) {

		var message = "Sorry, unable to process login at this time.";

                var jqxhr = object.jqXHR;
                if (jqxhr) {
                    var response = jqxhr.responseJSON;
                    if (response && response.message && _.isString(response.message)) {
                        message = response.message + (_.endsWith(response.message, ".") ? "" : ".");
                    }
                }
		
		self.$el.find("div[data-section='empower-login-error']").removeClass("hide");
		self.$el.find("div[data-section='empower-login-error']").html(message).show();  
	    });

	    login.done(function(resp) {
		self._applicationRouter.redirect();
	    });

	    login.always(function() {
		clearInterval(self._spinIntervalId);
	    });
	},
	
	_show: function() {
	},
	
	_hide: function() {
	},
	
	_remove: function() {
	}
    });

})(jQuery);
