EmPower.Model.SelectedCollectionObject = (function(Model, $, undefined) {
    
    return Model.extend({
	
	modelName: 'Selected Collection Object',

	_initialize: function() {
	    
	    var self = this;
	    
	    Model.prototype._initialize.apply(self, arguments);
	    
	    self._selectedId = undefined;
            
            self.foundOnFilteredCollection(null);
	    
	    if (!self._session()) {
		self._logger.throwError("Selected collection object was not created with a session instance.");
	    }
	    
	    if (!self._comparableAttribute() || !_.isFunction(self._comparableAttribute())) {
		self._logger.throwError("Selected collection object was not created with a specific comparable attribute function.");
	    }
	    
	    if (!self.collection()) {
		self._logger.throwError("Selected collection object was not created with a specific collection instance.");
	    } else {
                if (self.collection() instanceof Backbone.Obscura) {
                    self.collectionProxy(self.collection());
                    self.collection(self.collectionProxy().superset());
                }
            }

	    self.listenTo(self.collectionProxy(), 'add remove update reset', function() {
                self._updateSelectedObjectByIdDebounced();
	    });
	},
	
	selected: function() {
            
	    var selected = Model.prototype.selected.apply(this, arguments);
	    
	    if (selected !== undefined) {
                if (this.collection().isFetched()) {
                    var selectedId = this._comparableAttribute()(selected);
                    if (selectedId !== this._selectedId) {
                        this._selectedId = selectedId;
                        this._updateSelectedObjectById();
                    }
                }
	    }
	    
	    return selected;
	},
	
	selectedId: function(value) {
	    
	    if (value !== undefined && this._selectedId !== value) {
                this._selectedId = value;
                this._updateSelectedObjectById();
	    }
	    
	    return this._selectedId;
	},

        foundOnFilteredCollection: function(value) {
            return this._field('foundOnFilteredCollection', value);
        },

	_session: function(value) {
	    return this._field('session', value);
	},

        _notFoundRedirect: function(selectedId) {
            var redirect = this._field('notFoundRedirect');
            
            if (_.isFunction(redirect)) {
                redirect(selectedId);
            }
        },

	collection: function(value) {
	    
	    var collection = this._field('collection');
	    
	    if (value !== undefined && collection !== value) {
		collection = this._field('collection', value);
		this._updateSelectedObjectById();
	    }
	    
	    return collection;
	},
        
        collectionProxy: function(value) {
	    
	    var collectionProxy = this._field('collectionProxy');
	    
	    if (value !== undefined && collectionProxy !== value) {
		collectionProxy = this._field('collectionProxy', value);
		this._updateSelectedObjectById();
	    }
            
            if (!collectionProxy) {
                return this.collection();
            }
	    
	    return collectionProxy;
	},
	
	_attributeParser: function(value) {
	    return this._field('attributeParser', value);
	},
	
	_comparableAttribute: function(value) {
	    var comparableAttribute = this._field('comparableAttribute');
	    
	    if (value !== undefined && (comparableAttribute && comparableAttribute !== value)) {
		comparableAttribute = this._field('comparableAttribute', value);
		this._updateSelectedObjectById();
	    }
	    
	    return comparableAttribute;
	},
	
	_updateSelectedObjectById: function() {
	    
	    var self = this;
	    
	    var found;
	    
	    var collection = self.collection();
            var collectionProxy = self.collectionProxy();
	    var selectedId = self.selectedId();
            
	    if ((selectedId !== undefined && selectedId !== null) && collection.isFetched()) {
		
                var findModel = function(model) {
		    
		    var attributeParser = self._attributeParser();
		    if (_.isFunction(attributeParser)) {
			selectedId = attributeParser(selectedId);
		    }
		    return (self._comparableAttribute()(model) === selectedId);
		};
                
		found = collectionProxy.find(findModel);
                // diff between collection and proxy collection...
		if (found === undefined) {
                    if (collectionProxy !== collection) {
                        found = collection.find(findModel);
                    }
                    if (found === undefined) {
                        this.foundOnFilteredCollection(null);
                        self.selected(null);
                        self._logger.warn("Selected collection object could not find an object with matching ID.", selectedId, self._comparableAttribute());
                        this._notFoundRedirect(selectedId);
                    } else {
                        this.foundOnFilteredCollection(false);
                        self.selected(found);
                    }
		} else {
                    this.foundOnFilteredCollection(true);
		    self.selected(found);
		}
	    } else if (selectedId === null) {
                this.foundOnFilteredCollection(null);
		self.selected(null);
	    }
	    
	    return found;
	},
	
	_updateSelectedObjectByIdDebounced: function() {
	    
	    if (!this._debouncedUpdateSelectedObjectById) {
		this._debouncedUpdateSelectedObjectById = _.debounce(this._updateSelectedObjectById, 1);
	    }
	    
	    return this._debouncedUpdateSelectedObjectById();
	}
    });
    
})(EmPower.Model.SelectedObject, jQuery);
