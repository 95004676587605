EmPower.View.UserList = (function($, undefined) {
    
    return EmPower.View.AbstractCollectionView.extend({
	viewName: "User List",
	
	_events: function() {
	    return {
		'click button[data-action="user-add"]': function() {
		    EmPower.Dialog.User.information(this._session);
		}
	    };
	},
	
	_initialize: function() {
	},
	
	_setupListeners: function() {
	},
	
	_htmlTemplate: function() {
	    return EmPower.Templates['transformer/user/user_list'];
	},
	
	_htmlData: function() {
	    
	    var data = {
		canCreateUser: false
	    };
	    
	    if (this.getCollection()) {
		data.canCreateUser = false;//this.getCollection().getCanCreateUser();
	    }
	    
	    return data;
	},
	
	_getCollectionContainer: function($html) {
	    return $html.find("div[data-section='user-list']");
	},
	
	_createModelView: function(model) {
	    return new EmPower.View.UserRow({
		session: this._session,
		userModel: model
	    });
	},
	
	_render: function() {
	},
	
	_show: function() {
	},
	
	_hide: function() {
	},
	
	_remove: function() {
	}
    });
    
})(jQuery);
