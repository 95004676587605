EmPower.Collection.Battery = (function($, undefined) {
    
    return EmPower.Collection.AbstractSelectedObjectCollection.extend({
	collectionName: 'Battery',

	model: EmPower.Model.Battery,
	
	url: function() {
	    return EmPower.Util.Url.apiBuilder('transformers', this._selectedObject.selectedId(), 'battery');
	},
	
	_initialize: function() {
	},
	
	_parse: function() {
	}
        
    });
    
})(jQuery);