EmPower.Route.Transformers = (function(SubRoute, $, undefined) {
    
    return SubRoute.extend({
		whatAmI: function(){return "EmPower.Route.Transformers - Also see routeName";},
	
	routeName: "Transformers",
	
	_paths: function() {
	    return {
		transformerManagement: function(transformerId) {
		    return EmPower.Util.Url.builder(transformerId);
		},
		health: function(transformerId) {
		    return EmPower.Util.Url.builder(transformerId, 'conditiondashboard');
		},
		dynamicLoading: function(transformerId) {
		    return EmPower.Util.Url.builder(transformerId, 'dynamicloading');
		},
                bushing: function(transformerId) {
                    return EmPower.Util.Url.builder(transformerId, 'bushing');
                },
		temperature: function(transformerId) {
		    return EmPower.Util.Url.builder(transformerId, 'temperature');
		},
		pressure: function(transformerId) {
		    return EmPower.Util.Url.builder(transformerId, 'pressure');
		},
		powerMetering: function(transformerId) {
		    return EmPower.Util.Url.builder(transformerId, 'powermetering');
		},
		annunciator: function(transformerId) {
		    return EmPower.Util.Url.builder(transformerId, 'annunciator');
		},
		cooling: function(transformerId) {
		    return EmPower.Util.Url.builder(transformerId, 'cooling');
		},
		ltc: function(transformerId) {
		    return EmPower.Util.Url.builder(transformerId, 'ltc');
		},
		dga: function(transformerId) {
		    return EmPower.Util.Url.builder(transformerId, 'dga');
		},
                moisture: function(transformerId) {
                    return EmPower.Util.Url.builder(transformerId, 'moisture');
                },
		documents: function(transformerId) {
		    return EmPower.Util.Url.builder(transformerId, 'documents');
		},
		security: function(transformerId) {
		    return EmPower.Util.Url.builder(transformerId, 'security');
		},
		video: function(transformerId) {
		    return EmPower.Util.Url.builder(transformerId, 'video');
		},
		users: function(transformerId) {
		    return EmPower.Util.Url.builder(transformerId, 'users');
		},
		rctu: function(transformerId) {
		    return EmPower.Util.Url.builder(transformerId, 'rctu');
		},
                rctuUpdate: function(transformerId) {
                    return EmPower.Util.Url.builder(transformerId, 'rctu-update');
                },
                opm: function(transformerId) {
                    return EmPower.Util.Url.builder(transformerId, 'opm');
                },
                recorded: function(transformerId) {
                    return EmPower.Util.Url.builder(transformerId, 'recorded');
                },
                battery: function(transformerId) {
                    return EmPower.Util.Url.builder(transformerId, 'battery');
                },
                systemHealth: function(transformerId) {
                    return EmPower.Util.Url.builder(transformerId, 'system-health');
                },
                metrology: function(transformerId) {
                    return EmPower.Util.Url.builder(transformerId, 'metrology');
                },
                firmware: function(transformerId) {
                    return EmPower.Util.Url.builder(transformerId, 'firmware');
                }
	    };
	},
	
	_routes: function(paths) {
	    
	    var routes = {};
	    
	    routes[paths.transformerManagement()] = this._showTransformerManagement;
	    routes[paths.transformerManagement('(:transformerId)')] = this._showTransformerManagement;
	    routes[paths.health(':transformerId')] = this._showHealth;
	    routes[paths.dynamicLoading(':transformerId')] = this._showDynamicLoading;
            routes[paths.bushing(':transformerId')] = this._showBushing;
	    routes[paths.temperature(':transformerId')] = this._showTemperature;
	    routes[paths.pressure(':transformerId')] = this._showPressure;
	    routes[paths.powerMetering(':transformerId')] = this._showPowerMetering;
	    routes[paths.annunciator(':transformerId')] = this._showAnnunciator;
	    routes[paths.cooling(':transformerId')] = this._showCooling;
	    routes[paths.ltc(':transformerId')] = this._showLtc;
            routes[paths.moisture(':transformerId')] = this._showMoisture;
	    routes[paths.dga(':transformerId')] = this._showDga;
	    routes[paths.documents(':transformerId')] = this._showDocuments;
	    routes[paths.security(':transformerId')] = this._showSecurity;
	    routes[paths.video(':transformerId')] = this._showVideo;
	    routes[paths.users(':transformerId')] = this._showUsers;
	    routes[paths.rctu(':transformerId')] = this._showRctu;
            routes[paths.rctuUpdate(':transformerId')] = this._showRctuUpdate;
            routes[paths.opm(':transformerId')] = this._showOpm;
            routes[paths.recorded(':transformerId')] = this._showRecorded;
            routes[paths.battery(':transformerId')] = this._showBattery;
            routes[paths.systemHealth(':transformerId')] = this._showSystemHealth;
            routes[paths.metrology(':transformerId')] = this._showMetrology;
            routes[paths.firmware(':transformerId')] = this._showFirmware;
	    
	    return routes;
	},
	
	_initialize: function() {
	    this._transformerContentFrame = new EmPower.View.TransformerContentFrame({
		session: this._session
	    });
	},
	
        _setTransformerSession: function(subview, transformerId) {
            this._session.selectedSectionView().selected(this._transformerContentFrame);
	    this._session.transformerSession().selectedTransformerSubview().selected(subview);
	    this._session.transformerSession().selectedTransformer().selectedId(transformerId);
        },
        
	_showTransformerManagement: function(transformerId) {
	    
	    if (!this._transformerManagementSubview) {
		this._transformerManagementSubview =
                        EmPower.View.TransformerSubviews.transformerManagement(this._session, this.paths.transformerManagement);
	    }
	    
            this._setTransformerSession(this._transformerManagementSubview, transformerId);
	},
	showTransformerManagement: function() {
	    this.navigate(this.paths.transformerManagement(this._session.transformerSession().selectedTransformer().selectedId()), {trigger: true});
	},
	
	
	_showHealth: function(transformerId) {
	    
	    if (!this._healthSubview) {
		this._healthSubview =
                        EmPower.View.TransformerSubviews.health(this._session, this.paths.health);
	    }
	    
            this._setTransformerSession(this._healthSubview, transformerId);
	},
	showHealth: function() {
	    this.navigate(this.paths.health(this._session.transformerSession().selectedTransformer().selectedId()), {trigger: true});
	},
	
	_showDynamicLoading: function(transformerId) {
	    
	    if (!this._dynamicLoadingSubview) {
		this._dynamicLoadingSubview =
                        EmPower.View.TransformerSubviews.dynamicLoading(this._session, this.paths.dynamicLoading);
	    }
	    
            this._setTransformerSession(this._dynamicLoadingSubview, transformerId);
	},
	showDynamicLoading: function() {
	    this.navigate(this.paths.dynamicLoading(this._session.transformerSession().selectedTransformer().selectedId()), {trigger: true});
	},
        
        _showBushing: function(transformerId) {
	    
	    if (!this._bushingSubview) {
		this._bushingSubview =
                        EmPower.View.TransformerSubviews.bushing(this._session, this.paths.bushing);
	    }
	    
            this._setTransformerSession(this._bushingSubview, transformerId);
	},
	showBushing: function() {
	    this.navigate(this.paths.bushing(this._session.transformerSession().selectedTransformer().selectedId()), {trigger: true});
	},
	
	_showAnnunciator: function(transformerId) {
	    
	    if (!this._annunciatorSubview) {
		this._annunciatorSubview =
                        EmPower.View.TransformerSubviews.annunciator(this._session, this.paths.annunciator);
	    }
	    
            this._setTransformerSession(this._annunciatorSubview, transformerId);
	},
	showAnnunciator: function() {
	    this.navigate(this.paths.annunciator(this._session.transformerSession().selectedTransformer().selectedId()), {trigger: true});
	},
	
	_showPowerMetering: function(transformerId) {
	    
	    if (!this._powerMeteringSubview) {
		this._powerMeteringSubview =
                        EmPower.View.TransformerSubviews.powerMetering(this._session, this.paths.powerMetering);
	    }
	    
            this._setTransformerSession(this._powerMeteringSubview, transformerId);
	},
	showPowerMetering: function() {
	    this.navigate(this.paths.powerMetering(this._session.transformerSession().selectedTransformer().selectedId()), {trigger: true});
	},
	
	_showTemperature: function(transformerId) {
	    
	    if (!this._temperatureSubview) {
		this._temperatureSubview =
                        EmPower.View.TransformerSubviews.temperature(this._session, this.paths.temperature);
	    }
	    
            this._setTransformerSession(this._temperatureSubview, transformerId);
	},
	showTemperature: function() {
	    this.navigate(this.paths.temperature(this._session.transformerSession().selectedTransformer().selectedId()), {trigger: true});
	},
	
	_showPressure: function(transformerId) {
	    
	    if (!this._pressureSubview) {
		this._pressureSubview =
                        EmPower.View.TransformerSubviews.pressure(this._session, this.paths.pressure);
	    }
	    
            this._setTransformerSession(this._pressureSubview, transformerId);
	},
	showPressure: function() {
	    this.navigate(this.paths.pressure(this._session.transformerSession().selectedTransformer().selectedId()), {trigger: true});
	},
	
	_showCooling: function(transformerId) {
	    
	    if (!this._coolingSubview) {
		this._coolingSubview =
                        EmPower.View.TransformerSubviews.cooling(this._session, this.paths.cooling);
	    }
	    
            this._setTransformerSession(this._coolingSubview, transformerId);
	},
	showCooling: function() {
	    this.navigate(this.paths.cooling(this._session.transformerSession().selectedTransformer().selectedId()), {trigger: true});
	},
	
	_showLtc: function(transformerId) {
	    
	    if (!this._ltcSubview) {
		this._ltcSubview =
                        EmPower.View.TransformerSubviews.ltc(this._session, this.paths.ltc);
	    }
	    
            this._setTransformerSession(this._ltcSubview, transformerId);
	},
	showLtc: function() {
	    this.navigate(this.paths.ltc(this._session.transformerSession().selectedTransformer().selectedId()), {trigger: true});
	},
	
        _showMoisture: function(transformerId) {
	    
	    if (!this._moistureSubview) {
		this._moistureSubview =
                        EmPower.View.TransformerSubviews.moisture(this._session, this.paths.moisture);
	    }
	    
            this._setTransformerSession(this._moistureSubview, transformerId);
	},
	showMoisture: function() {
	    this.navigate(this.paths.moisture(this._session.transformerSession().selectedTransformer().selectedId()), {trigger: true});
	},
        
	_showDga: function(transformerId) {
	    
	    if (!this._dgaSubview) {
		this._dgaSubview =
                        EmPower.View.TransformerSubviews.dga(this._session, this.paths.dga);
	    }
	    
            this._setTransformerSession(this._dgaSubview, transformerId);
	},
	showDga: function() {
	    this.navigate(this.paths.dga(this._session.transformerSession().selectedTransformer().selectedId()), {trigger: true});
	},
	
	_showDocuments: function(transformerId) {
	    
	    if (!this._documentsSubview) {
		this._documentsSubview =
                        EmPower.View.TransformerSubviews.documents(this._session, this.paths.documents);
	    }
	    
            this._setTransformerSession(this._documentsSubview, transformerId);
	},
	showDocuments: function() {
	    this.navigate(this.paths.documents(this._session.transformerSession().selectedTransformer().selectedId()), {trigger: true});
	},
	
	_showSecurity: function(transformerId) {
	    
	    if (!this._securitySubview) {
		this._securitySubview =
                        EmPower.View.TransformerSubviews.security(this._session, this.paths.security);
	    }
	    
            this._setTransformerSession(this._securitySubview, transformerId);
	},
	showSecurity: function() {
	    this.navigate(this.paths.security(this._session.transformerSession().selectedTransformer().selectedId()), {trigger: true});
	},
	
	_showVideo: function(transformerId) {
	    
	    if (!this._videoSubview) {
		this._videoSubview =
                        EmPower.View.TransformerSubviews.video(this._session, this.paths.video);
	    }
	    
            this._setTransformerSession(this._videoSubview, transformerId);
	},
	showVideo: function() {
	    this.navigate(this.paths.video(this._session.transformerSession().selectedTransformer().selectedId()), {trigger: true});
	},
        
        _showUsers: function(transformerId) {
	    
	    if (!this._userSubview) {
		this._userSubview =
                        EmPower.View.TransformerSubviews.users(this._session, this.paths.users);
	    }
	    
            this._setTransformerSession(this._userSubview, transformerId);
	},
	showUsers: function() {
	    this.navigate(this.paths.users(this._session.transformerSession().selectedTransformer().selectedId()), {trigger: true});
	},
        
	_showRctu: function(transformerId) {
	    
	    if (!this._rctuSubview) {
		this._rctuSubview = 
                        EmPower.View.TransformerSubviews.rctu(this._session, this.paths.rctu);
	    }
	    
            this._setTransformerSession(this._rctuSubview, transformerId);
	},
	showRctu: function() {
	    this.navigate(this.paths.rctu(this._session.transformerSession().selectedTransformer().selectedId()), {trigger: true});
	},
        
        _showRctuUpdate: function(transformerId) {
	    
	    if (!this._rctuUpdateSubview) {
		this._rctuUpdateSubview = 
                        EmPower.View.TransformerSubviews.rctuUpdate(this._session, this.paths.rctuUpdate);
	    }
	    
            this._setTransformerSession(this._rctuUpdateSubview, transformerId);
	},
	showRctuUpdate: function() {
	    this.navigate(this.paths.rctuUpdate(this._session.transformerSession().selectedTransformer().selectedId()), {trigger: true});
	},
        
        _showOpm: function(transformerId) {
			var that=this;
			

			//this._setTransformerSession(this._opmSubview, transformerId);

			//setTimeout(function(){

			if( true ){
			
				if (!that._opmSubview) {
					that._opmSubview = EmPower.View.TransformerSubviews.opm(that._session, that.paths.opm);
				}

			}
			
			if( true ) {

				that._setTransformerSession(that._opmSubview, transformerId);

			}
			
		
            

			
			//}, 5000);
        },
        
        showOpm: function() {
            this.navigate(this.paths.opm(this._session.transformerSession().selectedTransformer().selectedId()), {trigger: true});
        },
        
        _showRecorded: function(transformerId) {
            if (!this._recordedSubview) {
                this._recordedSubview = EmPower.View.TransformerSubviews.recorded(this._session, this.paths.recorded);
            }
            
            this._setTransformerSession(this._recordedSubview, transformerId);
        },
        
        showRecorded: function() {
            this.navigate(this.paths.recorded(this._session.transformerSession().selectedTransformer().selectedId()), {trigger: true});
        },
        
        _showBattery: function(transformerId) {
            if (!this._batterySubview) {
                this._batterySubview = EmPower.View.TransformerSubviews.battery(this._session, this.paths.battery);
            }
            
            this._setTransformerSession(this._batterySubview, transformerId);
        },
        
        showBattery: function() {
            this.navigate(this.paths.battery(this._session.transformerSession().selectedTransformer().selectedId()), {trigger: true});
        },
        
        _showSystemHealth: function(transformerId) {
            if (!this._systemHealthSubview) {
                this._systemHealthSubview = EmPower.View.TransformerSubviews.systemHealth(this._session, this.paths.systemHealth);
            }
            
            this._setTransformerSession(this._systemHealthSubview, transformerId);
        },
        
        showSystemHealth: function() {
            this.navigate(this.paths.systemHealth(this._session.transformerSession().selectedTransformer().selectedId()), {trigger: true});
        },
        
        _showMetrology: function(transformerId) {
            if (!this._metrologySubview) {
                this._metrologySubview = EmPower.View.TransformerSubviews.metrology(this._session, this.paths.metrology);
            }
            
            this._setTransformerSession(this._metrologySubview, transformerId);
        },
        
        showMetrology: function() {
            this.navigate(this.paths.metrology(this._session.transformerSession().selectedTransformer().selectedId()), {trigger: true});
        },
        
        _showFirmware: function(transformerId) {
            if (!this._firmwareSubview) {
                this._firmwareSubview = EmPower.View.TransformerSubviews.firmware(this._session, this.paths.firmware);
            }
            
            this._setTransformerSession(this._firmwareSubview, transformerId);
        },
        
        showFirmware: function() {
            this.navigate(this.paths.firmware(this._session.transformerSession().selectedTransformer().selectedId()), {trigger: true});
        },
	
	_show: function() {
	    if (!this._session.transformerSession().selectedTransformerSubview().selected()) {
		this.showTransformerManagement();
	    } else {
		// set path show content frame
		this._session.selectedSectionView().selected(this._transformerContentFrame);
	    }
	}
    });
    
})(EmPower.Route.AbstractSubRoute, jQuery);
