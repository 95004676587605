EmPower.View.FloorPlan = (function($, undefined) {

    return EmPower.View.AbstractView.extend({
	viewName: "Transformer Floor Plan",
	
	_events: function() {
            var self = this;
            return {
                "click [data-action='transformer-add-floor-plan']": function() {
                    EmPower.Dialog.TransformerFloorPlan.add(this._selectedTransformer.selected())
                            .done(function() {
                                self.renderDebounced();
                    });
                },
                "click [data-action='transformer-update-floor-plan']": function() {
                    EmPower.Dialog.TransformerFloorPlan.update(this._selectedTransformer.selected())
                            .done(function() {
                                self.renderDebounced();
                    });
                }
            };
	},
	
	_initialize: function() {
	    this._selectedTransformer = this._session.transformerSession().selectedTransformer();
	},
	
	_setupListeners: function() {
            
            this.listenTo(this._selectedTransformer, "change", function() {
                this.renderDebounced(); 
            });
	},
	
	_htmlTemplate: function() {
	    return EmPower.Templates['transformer/security/floor-plan/floor_plan'];
	},
	
	_htmlData: function() {
            return {
                selectedTransformer: this._selectedTransformer.selected()
            };
	},
	
	_render: function($html) {
            
            var selectedTransformer = this._selectedTransformer.selected();

            if (selectedTransformer) {
                
                var $transformerFloorPlanImg = $("<img>")
                    .on('load', function() {
                        $html.filter("div[data-content='transformer-floor-plan-image']").removeClass("hide");
                        $html.filter("div[data-content='loading-floor-plan']").hide();
                    })
                    .on('error', function() {
                        $html.filter("div[data-content='add-transformer-floor-plan']").removeClass("hide");
                        $html.filter("div[data-content='loading-floor-plan']").hide();
                    })
                    .attr("src", ["./api/1/transformers/", selectedTransformer.transformerId(), "/floorplan"].join(""));
            
                var $lightboxed = $("<a>")
                        .attr("href", ["./api/1/transformers/", selectedTransformer.transformerId(), "/floorplan"].join(""))
                        .attr("data-lightbox", "floorplan-img")
                        .attr("class", "lightbox-link")
                        .prepend($transformerFloorPlanImg);
            
                $html.filter("div[data-content='transformer-floor-plan-image']").prepend($lightboxed);
            }
	},
	
	_show: function() {
	},
	
	_hide: function() {
            this._reRender = true;
	},
	
	_remove: function() {
	}
    });
    
})(jQuery);
