EmPower.View.TransformerManagement = (function($, undefined) {

    return EmPower.View.AbstractView.extend({
	viewName: "Transformer Management",
	
	_events: function() {
	    return {
		'click div[data-action="transformer-information-toggle"] > a': this._toggleTransformerInformation
	    };
	},
	
	_initialize: function() {
	    this._transformerCollectionProxy = this._session.transformerSession().transformerCollectionProxy();
	    this._selectedTransfromer = this._session.transformerSession().selectedTransformer();
	    this._quickInfoVisible = false;
	    
	    var self = this;
	    self.listenTo(self._session, 'reset', function() {
		self._quickInfoVisible = false;
		self.renderDebounced();
	    });
	},
	
	_setupListeners: function() {
	},
	
	_htmlTemplate: function() {
	    return EmPower.Templates['transformer/transformer-management/transformer_management'];
	},
	
	_htmlData: function() {
	    return {
		quickInfoVisible: this._quickInfoVisible
	    };
	},
	
	_render: function($html) {
	
	    var $transformerInformation = $html.find("div[data-section='transformer-information']");
	    if (!this._transformerInformation) {
		
		this._transformerInformation = new EmPower.View.TransformerInformation({
		    session: this._session
		});
		this._transformerInformation.render($transformerInformation);
	    } else {
		$transformerInformation.replaceWith(this._transformerInformation.$el);
	    }
	    if (!this._quickInfoVisible) {
		this._transformerInformation.hide();
	    }
		
	    var $transformerList = $html.find("div[data-section='transformer-list']");
	    if (!this._transformerList) {

		this._transformerList = new EmPower.View.TransformerList({
		    session: this._session,
		    collection: this._transformerCollectionProxy,
		    selectedModel: this._selectedTransfromer
		});
		this._transformerList.render($transformerList);
	    } else {
		$transformerList.replaceWith(this._transformerList.$el);
	    }
	},
	
	_toggleTransformerInformation: function(event) {
	    
	    var $button = $(event.currentTarget);

	    if ($button.attr('data-action') === "collapse") {
		$button.parent().addClass("collapsed");
		this._quickInfoVisible = false;
		if (this._transformerInformation) {
		    this._transformerInformation.hide("blind", {
			direction: "right"
		    }, 250);
		}
	    } else {
		$button.parent().removeClass("collapsed");
		this._quickInfoVisible = true;
		if (this._transformerInformation) {
		    this._transformerInformation.show("blind", {
			direction: "right"
		    }, 250);
		}
	    }
	},
	
	_show: function() {
	    if (this._transformerInformation && this._quickInfoVisible) {
		this._transformerInformation.show();
	    }
	    if (this._transformerList) {
		this._transformerList.show();
	    }
	},
	
	_hide: function() {
	    if (this._transformerInformation) {
		this._transformerInformation.hide();
	    }
	    if (this._transformerList) {
		this._transformerList.hide();
	    }
	},
	
	_remove: function() {
	    if (this._transformerInformation) {
		this._transformerInformation.remove();
	    }
	    if (this._transformerList) {
		this._transformerList.remove();
	    }
	}
    });
    
})(jQuery);
