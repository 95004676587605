EmPower.Model.ServerInformation = (function($, undefined) {
    
    return EmPower.Model.AbstractModel.extend({
	
	idAttribute: 'id',
	    
	modelName: 'Server Information',
	
	urlRoot: EmPower.Util.Url.apiBuilder('serverinfo'),

	_initialize: function() {
	},

	_parse: function() {
	}
    });
    
})(jQuery);
