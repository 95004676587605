EmPower.Collection.RctuInformation = (function($, undefined) {
    
    return EmPower.Collection.AbstractSelectedObjectCollection.extend({
	collectionName: 'Rctu Information',

	model: EmPower.Model.RctuInformation,
	
	url: function() {
	    return EmPower.Util.Url.apiBuilder('transformers', this._selectedObject.selectedId(), 'rctu', 'info');
	},
	
	_initialize: function(response) {
	},
	
	_parse: function(response) {
            
            var models = [];
	    if (response) {
                _.forEach(response, function(object) {
                    _.forEach(_.keys(object), function(key, index) {
                        models.push(new EmPower.Model.DaemonInformation({
                            id: index,
                            description: key,
                            value: object[key]
                        }));
                    });
                });
	    }
	    
	    return models;
	}
    });
    
})(jQuery);
