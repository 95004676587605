EmPower.View.DgaConditionIndexGraph = (function(View, $, undefined) {
    
    return View.extend({
        viewName: "DGA Condition Index Graph",
	
	_initialize: function() {
            
            var self = this;
            
            this.collections([
                self._session.transformerSession().conditionIndexEventCollection(),
                self._session.transformerSession().dgaEventCollection(),
                self._session.transformerSession().dynamicLoadingEventCollection(),
                self._session.transformerSession().temperatureEventCollection()
            ]);
            this.graphOptions({
                options: {
                    scales: {
                        yAxes: [EmPower.Util.SimpleChartAxis.conditionIndex({
                            id: "y-axis-ci"
                        }), EmPower.Util.SimpleChartAxis.temperature({
                            id: "y-axis-temperature",
                            position: "right"
                        }), EmPower.Util.SimpleChartAxis.concentration({
                            id: "y-axis-concentration",
                            position: "right"
                        }), EmPower.Util.SimpleChartAxis.load({
                            id: "y-axis-load"
                        })]
                    }
                },
                datasetConfig: [{
                    collection: function() {
                        return self._session.transformerSession().conditionIndexEventCollection();
                    },
                    datasetId: function(model) {
                        return model.ciTypeId();
                    },
                    label: function(model) {
                        return model.ciTypeName() + " CI";
                    },
                    yValue: function(model) {
                        return (model.value() !== undefined && model.value() !== null) ? model.value().toFixedNumber(2) : null;
                    },
                    isGraphed: function(model) {
                        return _.startsWith(model.ciTypeAbbrev(), "DGA");
                    },
                    datasetOptions: function(model) {
                        return {
                            yAxisID: "y-axis-ci"
                        };
                    },
                    chartType: function() {
                        return 'DGA';
                    }
                }, {
                    collection: function() {
                        return self._session.transformerSession().dgaEventCollection();
                    },
                    label: function(model) {
                        var label = model.description();
                        if (model.gasTypeAbbrev()
                                && !(_.startsWith(model.gasTypeAbbrev(), "UNDEF"))) {
                            label = model.gasTypeName();
                        }
                        return label;
                    },
                    yValue: function(model) {
                        return (model.value() !== undefined && model.value() !== null) ? model.value().toFixedNumber(2) : null;
                    },
                    datasetOptions: function(model) {
                        return {
                            yAxisID: "y-axis-concentration"
                        };
                    },
                    chartType: function() {
                        return 'DGA';
                    }
                }, {
                    collection: function() {
                        return self._session.transformerSession().dynamicLoadingEventCollection();
                    },
                    datasetId: function(model) {
                        return "dl-load";
                    },
                    label: function(model) {
                        return "Load";
                    },
                    yValue: function(model) {
                        return (model.load() !== undefined && model.load() !== null) ? (model.load()).toFixedNumber(2) : null;
                    },
                    datasetOptions: function(model) {
                        return {
                            yAxisID: "y-axis-load"
                        };
                    },
                    chartType: function() {
                        return 'DGA';
                    }
                }, {
                    collection: function() {
                        return self._session.transformerSession().dynamicLoadingEventCollection();
                    },
                    datasetId: function(model) {
                        return "dl-windingHSInit";
                    },
                    label: function(model) {
                        return "Winding Hot Spot Temperature - Calculated";
                    },
                    yValue: function(model) {
                        return (model.windingHsTempInit() !== undefined && model.windingHsTempInit() !== null) ? (model.windingHsTempInit()).toFixedNumber(2) : null;
                    },
                    datasetOptions: function(model) {
                        return {
                            yAxisID: "y-axis-temperature"
                        };
                    },
                    chartType: function() {
                        return 'DGA';
                    }
                }, {
                    collection: function() {
                        return self._session.transformerSession().temperatureEventCollection();
                    },
                    label: function(model) {
                        var label = model.description();
                        if (model.temperatureTypeAbbrev()
                                && !(_.startsWith(model.temperatureTypeAbbrev(), "UNDEF") || _.startsWith(model.temperatureTypeAbbrev(), "GEN"))) {
                            label = model.temperatureTypeName();
                        }
                        return label;
                    },
                    isGraphed: function(model) {
                        return _.startsWith(model.temperatureTypeAbbrev(), "HST");
                    },
                    yValue: function(model) {
                        return (model.value() !== undefined && model.value() !== null) ? (model.value()).toFixedNumber(2) : null;
                    },
                    datasetOptions: function(model) {
                        return {
                            yAxisID: "y-axis-temperature"
                        };
                    },
                    chartType: function() {
                        return 'DGA';
                    }
                }]
            });
	}
    });
    
})(EmPower.View.AbstractGraphView, jQuery);
