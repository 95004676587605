EmPower.View.DocumentList = (function($, undefined) {

    return EmPower.View.AbstractCollectionView.extend({
	viewName: "Document List",
	
	_events: function() {
            return {
                "click a[data-action='delete-document']": function(e) {
                    var $element = $(e.currentTarget);
                    var cid = $element.attr("data-document-cid");
                    if (cid) {
                        EmPower.Dialog.TransformerDocument.delete(this._collection.get({cid: cid}));
                    }
                },
                "click a[data-action='download-document']": function(e) {
                    var $element = $(e.currentTarget);
                    var cid = $element.attr("data-document-cid");
                    if (cid) {
                        EmPower.Util.Download.hiddenDownload(this._collection.get({cid: cid}).downloadUrl());
                    }
                },
                "click button[data-action='download-documents']": function(e) {
                    EmPower.Util.Download.hiddenDownload(this._collection.downloadUrl());
                }
            };
	},
	
	_initialize: function() {
	    this._applicationRouter = this._session.applicationRouter();
	
	    this._selectedTransformer = this._session.transformerSession().selectedTransformer();
	},
	
	_setupListeners: function() {
	    
	    var self = this;
	    
	    self.listenTo(self._selectedTransformer, 'change', function() {
		self.renderDebounced();
	    });
	},
	
	_htmlTemplate: function() {
	    return EmPower.Templates['transformer/document/document_list'];
	},
	
	_htmlData: function() {
	    return {
		transformerId: this._selectedTransformer.selectedId()
	    };
	},
	
	_getCollectionContainer: function($html) {
	    return $html.find("div[data-section='document-list']");
	},
	
	_createModelView: function(model) {
	    return new EmPower.View.DocumentRow({
		session: this._session,
		documentModel: model
	    });
	},
	
	_getLoadingCollectionMessage: function() {
	    return "Loading documents, please wait...";
	},
	
	_getEmptyCollectionMessage: function() {
	    return "Sorry, no documents have been uploaded.";
	},
	
	_render: function($html) {
	    
	    var $dropzone = $html.find('div[data-section="dropzone"]');
	    if (!this._dropzone) {
		
		var self = this;
		
		this._dropzone = new Dropzone($dropzone.get(0), {
		    url: "/api",
		    addRemoveLinks: true,
		    dictCancelUpload: "cancel upload",
		    dictCancelUploadConfirmation: "upload canceled",
		    dictRemoveFile: "remove file"
		});

		this._dropzone.on("complete", function(file) {
		    this.removeFile(file);
		    self._session.collectionFetcher().fetchAll();
		});
		
		this._dropzone.on("processing", function() {
		    this.options.url = ["/api/1/transformers/", self._selectedTransformer.selectedId(), "/documents"].join("");
		});

		Dropzone.options.technicalDocumentsDropzone = {
		    paramName: "file",
		    maxFilesize: 20,
		    accept: function(file, done) {
			done();
		    }
		};
		
	    } else {

		$dropzone.replaceWith(this._dropzone.element);
	    }
	},
	
	_show: function() {
	},
	
	_hide: function() {
	},
	
	_remove: function() {
	}
    });
    
})(jQuery);
