(function(TransformerFloorPlan, $, undefined) {
    
    // private namespace
    var that = {};
    
    that.add = {};
    
    that.add.html = [
	'<div>',
        '  <div data-action="errorMessage"></div>',
	'  <label>Upload floor plan image:</label>',
	'  <div data-action="dropzone" class="dropzone" empower-section="myAwesomeDropzone">',
	'    <div class="dz-message">',
	'      <span>Drop floor plan image here or click to upload.</span>',
	'    </div>',
	'  </div>',
	'<div>'
    ];
    
    that.add.open = function(transformer, dialog, deferred) {
	
	dialog = dialog || new BootstrapDialog({
	    title: 'Floor plan for  <strong>' + transformer.transformerName() + "</strong>"
	});
	
	if (!that.add.htmlCompiled) {
	    that.add.htmlCompiled = _.template(that.add.html.join(''));
	}
	 
	var data = {};
	
	var $html = $(that.add.htmlCompiled(data));
	
	dialog.setMessage($html);
	
        var transformerId = transformer.transformerId();
        var dropzone = that.createDropzone($html.find("div[data-action='dropzone']"), transformerId);
	
	dropzone.on("addedfile", function(file) {
	    
	    EmPower.Dialog.SimpleDialog.Loading.open('Uploading floor plan...');

	    dialog.getMessage().find("div[data-action='errorMessage']").empty();
	});

	dropzone.on("error", function(file, error) {
	    
	    dropzone.removeFile(file);
	    
	    var failHtml = [
		'<div class="alert alert-danger">',
		'  <%-errorMessage%>',
		'</div>'
	    ];
	    
	    var errorMessage;
	    if (_.has(error, "message") && _.isString(error.message)) {
		var responseMessage = error.message.charAt(0).toLowerCase()
			+ error.message.slice(1);
                
                if (responseMessage && _.isString(responseMessage)) {
                    errorMessage = "Sorry, " + responseMessage + (_.endsWith(responseMessage, ".") ? "" : ".");
                }
	    } else if (_.isString(error)) {
		errorMessage = error;
	    } else {
		errorMessage = "Failed to upload floor plan, please try again.";
	    }
	    
	    var data = {
		errorMessage: errorMessage
	    };
	    
	    var htmlCompiled = _.template(failHtml.join(''));
	    
	    $html = $(htmlCompiled(data));
	    
	    dialog.getMessage().find("div[data-action='errorMessage']").html($html);
	    
	    EmPower.Dialog.SimpleDialog.Loading.close();
            
            deferred.reject();
	});

	dropzone.on("success", function(file) {
	    
	    dropzone.removeFile(file);
            
            that.add.showSuccess(transformer, null, dialog);
	    
	    EmPower.Dialog.SimpleDialog.Loading.close();
            
            deferred.resolve();
	});
	
	var onCancel = function(dialog) {
	    dialog.close();
	};
	
	var cancelButton = {
	    label: 'Cancel',
	    cssClass: 'btn-default left',
	    action: onCancel
	};
	
	dialog.setButtons([cancelButton]);
	
	return dialog.open();
    };
    
    that.add.showSuccess = function(transformer, data, dialog) {
	
	$.notify({
	    message: "Uploaded floor plan for <strong>" + transformer.transformerName() + "</strong>."
	}, {
	    type: 'success',
	    placement: {
		from: 'top',
		align: 'center'
	    }
	});
	
	dialog.close();

	return dialog;
    };
    
    that.createDropzone = function($element, transformerId, url) {
	
	var self = this;
	
	url = (url || ["./api/1/transformers/", transformerId, "/floorplan"].join(""));
	
	var dropzone = new Dropzone($element.get(0), {
	    url: url,
	    acceptedFiles: "image/*"
	});

	return dropzone;
    };
    
    TransformerFloorPlan.add = function(transformer) {
        
        var deferred = $.Deferred();
        
	if (transformer && transformer.id) {
            that.add.open(transformer, null, deferred);
	} else {
            deferred.reject();
        }
        
        return deferred.promise();
    };
    
    TransformerFloorPlan.update = function(transformer) {
        
        var deferred = $.Deferred();
        
	if (transformer && transformer.id) {
            that.add.open(transformer, null, deferred);
	} else {
            deferred.reject();
        }
        
        return deferred.promise();
    };
    
})(EmPower.Dialog.TransformerFloorPlan = EmPower.Dialog.TransformerFloorPlan || {}, jQuery);
