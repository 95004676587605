EmPower.Model.BubblingTemperature = (function($, undefined) {
    
    return EmPower.Model.AbstractDeviceEventModel.extend({
	    
	modelName: 'Bubbling Temperature',

	_initialize: function() {
	},

	_parse: function(response, options) {
	    return response;
	},
        
        bubblingTemp: function(value) {
            return this._field('bubblingTemp', value);
        },
        
        timestamp: function(value) {
            return this._field('timestamp', value);
        }
        
    });
    
})(jQuery);
