EmPower.View.SystemHealth = (function ($, undefined) {

    return EmPower.View.AbstractView.extend({

        viewName: "System Health",

        _events: function () {
        },

        _initialize: function () {
            this._selectedTransformer = this._session.transformerSession().selectedTransformer();
            this._transformerCollection = this._session.transformerSession().transformerCollection();
            this._systemHealthCollection = new EmPower.Collection.SystemHealth(null, {session: this._session, selectedObject: this._selectedTransformer});
        },

        _setupListeners: function () {

            var self = this;

            self.listenTo(self._selectedTransformer, 'change', function () {
                this.renderDebounced();
            });
            
            self.listenTo(self._systemHealthCollection, 'update reset', function() {
                this.renderDebounced();
	    });
	    self.listenTo(self._session, 'reset', function() {
		this._systemHealthCollection.reset();
	    });

        },

        _htmlTemplate: function () {
            return EmPower.Templates['transformer/system-health/system_health'];
        },

        _htmlData: function () {
            return {
                healthData: this._systemHealthCollection,
                colorMap: {
                    'UNKNOWN': 'grey',
                    'GOOD': 'green',
                    'BAD': 'red',
                    "DEGRADED": 'darkorange',
                    "NOT_USED": 'blue',
                    "INITIALIZATION_FAILED": 'darkblue'
                }
            };
        },

        _render: function ($html) {

        },

        _show: function () {
//            if (this.hasRendered()) {
//                this.render();
//            }
        },

        _hide: function () {
        },

        _remove: function () {
        }
    });

})(jQuery);
