(function (Transformer, $, undefined) {

    var that = {};

    that.dialogHtml = [
        '<form onsubmit="return false;">',
        '  <div data-action="errorMessage"></div>',
        '  <div class="form-group">',
        '    <label>The stream will automatically timeout soon. Would you like to continue watching?</label>',
        '  </div>',
        '</form>'
    ];

    that.cameraDialog = function (session, transformerId, dialog, deviceId) {

        dialog = dialog || new BootstrapDialog({
            title: 'Stream Ending Soon'
        });

        if (!that.dialogHtmlCompiled) {
            that.dialogHtmlCompiled = _.template(that.dialogHtml.join(''));
        }

        var $html = $(that.dialogHtmlCompiled({
        }));

        dialog.setMessage($html);

        var onCancel = function (dialog) {
            dialog.close();
        };

        var cancelButton = {
            label: 'Cancel',
            cssClass: 'btn-default left',
            action: onCancel
        };

        var onSubmit = function (dialog) {
            var url = EmPower.Util.Url.apiBuilder("transformers", transformerId, "camera", deviceId, "addtime");
            $.ajax({
                url: url,
                type: "POST",
                success: function (data) {
                    console.log("success");
                },
                error: function (err) {
                    console.log(err);
                }
            });

            dialog.close();
        };

        var submitButton = {
            label: 'Submit',
            cssClass: 'btn-default right',
            action: onSubmit
        };

        dialog.setButtons([cancelButton, submitButton]);

        return dialog.open();
    };

    Transformer.addTimeToCamera = function (session, transformerId, deviceId) {

        if (transformerId !== undefined && transformerId !== null) {

            that.cameraDialog(session, transformerId, null, deviceId);

        }
    };

})(EmPower.Dialog.Camera = EmPower.Dialog.Camera || {}, jQuery);