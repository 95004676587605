EmPower.View.TransformerInformation = (function($, undefined) {

    return EmPower.View.AbstractView.extend({
	viewName: "Transformer Information",
	
	_events: function() {
	},
	
	_initialize: function() {
	    this._selectedTransformer = this._session.transformerSession().selectedTransformer();
            this._conditionIndexCollectionProxy = this._session.transformerSession().conditionIndexCollectionProxy();
	},
	
	_setupListeners: function() {
	    
	    var self = this;
	    
	    self.listenTo(self._selectedTransformer, 'change', function() {
                self.renderDebounced();
            });
	},
	
	_htmlTemplate: function() {
	    return EmPower.Templates['transformer/transformer-management/transformer_information'];
	},
	
	_htmlData: function() {
	    return {
		transformerModel: this._selectedTransformer.selected(),
                foundOnFilteredCollection: this._selectedTransformer.foundOnFilteredCollection()
	    };
	},
	
	_render: function($html) {
            
            if (this._selectedTransformer.selected() && this._selectedTransformer.foundOnFilteredCollection()) {
                
		var $conditionIndexList = $html.find("div[data-section='condition-index-list']");
		if (!this._conditionIndexList) {
		    this._conditionIndexList = new EmPower.View.ConditionIndexList({
			session: this._session,
			collection: this._conditionIndexCollectionProxy,
			selectedModel: null
		    });
		    this._conditionIndexList.render($conditionIndexList);
		} else {
                    $conditionIndexList.replaceWith(this._conditionIndexList.$el);
		}
                
                var $transformerInfoTableContainer = $html.find("div[data-section='transformer-info-table']");
                if (!this._transformerInfoTable) {
                    this._transformerInfoTable = new EmPower.View.TransformerInformationTable({
			session: this._session
		    });
                    this._transformerInfoTable.render($transformerInfoTableContainer);
                } else {
                    $transformerInfoTableContainer.replaceWith(this._transformerInfoTable.$el);
                }
	    }
            
	},
	
	_show: function() {
	    if (this._conditionIndexList) {
		this._conditionIndexList.show();
	    }
            if (this._transformerInfoTable) {
		this._transformerInfoTable.show();
	    }
	},
	
	_hide: function() {
	    if (this._conditionIndexList) {
		this._conditionIndexList.hide();
	    }
            if (this._transformerInfoTable) {
		this._transformerInfoTable.hide();
	    }
	},
	
	_remove: function() {
	    if (this._conditionIndexList) {
		this._conditionIndexList.remove();
	    }
            if (this._transformerInfoTable) {
		this._transformerInfoTable.remove();
	    }
	}
    });
    
})(jQuery);
