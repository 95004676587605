EmPower.View.AccountManagement = (function($, undefined) {
    
    return EmPower.View.AbstractView.extend({
	
	viewName: "Account Management",
	
	_events: function() {
	},
	
	_initialize: function() {
	    this._userCollectionProxy = this._session.settingSession().userCollectionProxy();
	    this._selectedUser = this._session.settingSession().selectedUser();
	},
	
	_setupListeners: function() {
	},
	
	_htmlTemplate: function() {
	    return EmPower.Templates['settings/account-management/account_management'];
	},
	
	_htmlData: function() {
	},
	
	_render: function($html) {
	
	    var $accountInformation = $html.find("div[data-section='user-information']");
	    if (!this._accountInformation) {
		
		this._accountInformation = new EmPower.View.AccountInformation({
		    session: this._session
		});
		this._accountInformation.render($accountInformation);
	    } else {
		$accountInformation.replaceWith(this._userInformation.$el);
	    }
		
	    var $userList = $html.find("div[data-section='user-list']");
	    if (!this._accountListContainer) {

		this._accountList = new EmPower.View.AccountList({
		    session: this._session,
		    collection: this._userCollectionProxy,
		    selectedModel: this._selectedUser
		});
		this._accountList.render($userList);
	    } else {
		$userList.replaceWith(this._accountList.$el);
	    }
	},
	
	_show: function() {
	    if (this._accountInformation) {
		this._accountInformation.show();
	    }
	    if (this._accountList) {
		this._accountList.show();
	    }
	},
	
	_hide: function() {
	    if (this._accountInformation) {
		this._accountInformation.hide();
	    }
	    if (this._accountList) {
		this._accountList.hide();
	    }
	},
	
	_remove: function() {
	    if (this._accountInformation) {
		this._accountInformation.remove();
	    }
	    if (this._accountList) {
		this._accountList.remove();
	    }
	}
    });
    
})(jQuery);
