EmPower.Model.AbstractModelObject = (function($, undefined) {
    
    return function(Model) {
	
	return Model.extend({
	
	    constructor: function(options) {

		var modelName = "Model [" + (this.modelName || "Abstract") + "]";

		var loggerEnabled = this.loggerEnabled !== undefined ? this.loggerEnabled : true;

		this._logger = new EmPower.Util.Logger(modelName, loggerEnabled);

		if (!this.modelName) {

		    this._logger.throwError("Model was not extended with a specific Model Name.");
		}
		
		this._options = options;

		Model.apply(this, arguments);
	    },

	    _initialize: function() {

		this._logger.throwError("Model was not extended with a specific Initialize function.");
	    },

	    initialize: function() {

		this._initialize();
	    },

	    _parse: function(response) {

		this._logger.throwError("Model was not extended with a specific Parse function.");
	    },

	    parse: function(response) {

		if (response.results && _.isArray(response.results)) {

		    if (response.results.length !== 1) { 
			this._logger.error("Response was returned with incorrect results count.", response);
		    }

		    response = response.results[0];
		}

		response = this._parse(response) || response;

		return response;
	    },

	    _field: function(field, value) {

		if (value !== undefined) {
                    
                    if (this.get(field) !== value) {
                        var keyValue = {};
                        keyValue[field] = value;

						this._logger.debugConsoleLog("Setting " + field + ":" + value + " on " + this.modelName);
                        this.set(keyValue);
                    }
		}

		return this.get(field);
	    },

	    _subModel: function(field, values) {

		if (values !== undefined) {

		    this.get(field).set(values);
		}

		return this.get(field);
	    },

	    _setupSubModel: function(field, Model, response) {

		if (!this._field(field)) {
		    this._field(field, new Model());
		}

		if (response && response[field]) {
		    this._subModel(field, response[field]);

		    delete response[field];
		}
	    },
	    
	    _getOptions: function(required) {

		if (required && (!this._options && _.isEmpty(this._options))) {

		    this._logger.throwError("Model was not initilized with Options.");
		}

		return this._options;
	    },

	    _getOption: function(field, required) {

		if (!field) {

		    return null;
		}

		var option;
		try {

		    var options = this._getOptions(required);

		    if (options) {
			if (required && !_.has(options, field)) {
			    throw new Error();
			}
			option = options[field];
		    } else if (required) {
			throw new Error();
		    }

		} catch (error) {

		    this._logger.throwError("Required field [" + field + "] was not found on model Options.");
		}

		return option;
	    }
	});
    };
    
})(jQuery);

