(function(AjexSetup, $, undefined) {
    
    // private namespace
    var that = {};
    
    AjexSetup.initialize = function() {
	
	if (!that._logger) {
	    that._logger = new EmPower.Util.Logger("Ajax Setup", true);
	}
	
	$.ajaxSetup({
	    timeout: EmPower.Options.timeout
	});

	$(document).ajaxError(function(event, jqxhr, options, thrownError) {
	    
	    if (jqxhr.status === 401) {
                
		window.setTimeout(function() {
                    Synexxus.Auth.session = null;
		    document.location.hash = "#";
		}, 1);
	    } else if (_.isEmpty(options) || (!_.has(options, "defaultError") || options.defaultError) ) {
		
		that._logger.error("Ajax Call Error [Event, JQXHR, Settings, ThrownError]",
			event, jqxhr, options, thrownError);
		
		var response = jqxhr.responseJSON;
		var message = "Sorry, the request could not be processed at this time.";
		
		if (jqxhr.readyState === 0) {
		    
		    message = "Sorry, could not connect to the server at this time.";
		} else if (response && response.message && _.isString(response.message)) {
                    
		    var responseMessage = response.message.charAt(0).toLowerCase()
			    + response.message.slice(1);
                    
                    if (responseMessage && _.isString(responseMessage)) {
                        message = "Sorry, " + responseMessage + (_.endsWith(responseMessage, ".") ? "" : ".");
                    }
		}
	
		EmPower.Dialog.SimpleDialog.Loading.close();
		$.notify({
		    message: message
		}, {
		    type: 'danger',
		    placement: {
			from: 'top',
			align: 'center'
		    },
		    z_index: 9999
		});
	    }
	});
    };
    
})(EmPower.Util.AjaxSetup = EmPower.Util.AjaxSetup || {}, jQuery);