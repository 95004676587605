EmPower.Model.Metrology = (function($, undefined) {
    
    return EmPower.Model.AbstractDeviceEventModel.extend({
	    
	modelName: 'Metrology',

	_initialize: function() {
	},

	_parse: function(response, options) {
	    return response;
	},
	
	phases: function(value) {
	    return this._field('phases', value);
	},
        
        metrologyMonitorType: function(value) {
            return this._field('type', value);
        },
        
        voltagePhaseA: function(value) {
            return this._field('voltagePhaseA', value);
        },
        voltagePhaseADisplay: function() {
	    return (this.voltagePhaseA() !== undefined && this.voltagePhaseA() !== null) ? (this.voltagePhaseA() / 1000).toFixed(3) + " kV" : "-";
	},
        
        voltagePhaseB: function(value) {
            return this._field('voltagePhaseB', value);
        },
        voltagePhaseBDisplay: function() {
	    return (this.voltagePhaseB() !== undefined && this.voltagePhaseB() !== null) ? (this.voltagePhaseB() / 1000).toFixed(3) + " kV" : "-";
	},
        
        voltagePhaseC: function(value) {
            return this._field('voltagePhaseC', value);
        },
        voltagePhaseCDisplay: function() {
	    return (this.voltagePhaseC() !== undefined && this.voltagePhaseC() !== null) ? (this.voltagePhaseC() / 1000).toFixed(3) + " kV" : "-";
	},
        
        currentPhaseA: function(value) {
            return this._field('currentPhaseA', value);
        },
        currentPhaseADisplay: function() {
	    return (this.currentPhaseA() !== undefined && this.currentPhaseA() !== null) ? this.currentPhaseA().toFixed(3) + " A" : "-";
	},
        
        currentPhaseB: function(value) {
            return this._field('currentPhaseB', value);
        },
        currentPhaseBDisplay: function() {
	    return (this.currentPhaseB() !== undefined && this.currentPhaseB() !== null) ? this.currentPhaseB().toFixed(3) + " A" : "-";
	},
        
        currentPhaseC: function(value) {
            return this._field('currentPhaseC', value);
        },
        currentPhaseCDisplay: function() {
	    return (this.currentPhaseC() !== undefined && this.currentPhaseC() !== null) ? this.currentPhaseC().toFixed(3) + " A" : "-";
	},
        
        averageCurrent: function() {
            var averageCurrent = this.currentPhaseA() + this.currentPhaseB() + this.currentPhaseC();
            if (averageCurrent !== undefined && averageCurrent !== null) {
                return (averageCurrent / 3);
            }
            return null;
        },
        averageCurrentDisplay: function() {
            return (this.averageCurrent() !== undefined && this.averageCurrent() !== null) ? this.averageCurrent().toFixed(3) + " A" : "-";
        },
        
        activePower: function(value) {
            return this._field('activePower', value);
        },
        activePowerDisplay: function() {
	    return (this.activePower() !== undefined && this.activePower() !== null) ? ((this.activePower())/1000).toFixed(3) + " kW" : "-";
	},
        
        activePowerPhaseA: function(value) {
            return this._field('activePowerPhaseA', value);
        },
        activePowerPhaseADisplay: function() {
	    return (this.activePowerPhaseA() !== undefined && this.activePowerPhaseA() !== null) ? ((this.activePowerPhaseA())/1000).toFixed(3) + " kW" : "-";
	},
        
        activePowerPhaseB: function(value) {
            return this._field('activePowerPhaseB', value);
        },
        activePowerPhaseBDisplay: function() {
	    return (this.activePowerPhaseB() !== undefined && this.activePowerPhaseB() !== null) ? ((this.activePowerPhaseB())/1000).toFixed(3) + " kW" : "-";
	},
        
        activePowerPhaseC: function(value) {
            return this._field('activePowerPhaseC', value);
        },
        activePowerPhaseCDisplay: function() {
	    return (this.activePowerPhaseC() !== undefined && this.activePowerPhaseC() !== null) ? ((this.activePowerPhaseC())/1000).toFixed(3) + " kW" : "-";
	},
        
        reactivePower: function(value) {
            return this._field('reactivePower', value);
        },
        reactivePowerDisplay: function() {
	    return (this.reactivePower() !== undefined && this.reactivePower() !== null) ? ((this.reactivePower())/1000).toFixed(3) + " kVAR" : "-";
	},
        
        reactivePowerPhaseA: function(value) {
            return this._field('reactivePowerPhaseA', value);
        },
        reactivePowerPhaseADisplay: function() {
	    return (this.reactivePowerPhaseA() !== undefined && this.reactivePowerPhaseA() !== null) ? ((this.reactivePowerPhaseA())/1000).toFixed(3) + " kVAR" : "-";
	},
        
        reactivePowerPhaseB: function(value) {
            return this._field('reactivePowerPhaseB', value);
        },
        reactivePowerPhaseBDisplay: function() {
	    return (this.reactivePowerPhaseB() !== undefined && this.reactivePowerPhaseB() !== null) ? ((this.reactivePowerPhaseB())/1000).toFixed(3) + " kVAR" : "-";
	},
        
        reactivePowerPhaseC: function(value) {
            return this._field('reactivePowerPhaseC', value);
        },
        reactivePowerPhaseCDisplay: function() {
	    return (this.reactivePowerPhaseC() !== undefined && this.reactivePowerPhaseC() !== null) ? ((this.reactivePowerPhaseC())/1000).toFixed(3) + " kVAR" : "-";
	},
        
        apparentPower: function(value) {
            return this._field('apparentPower', value);
        },
        apparentPowerDisplay: function() {
	    return (this.apparentPower() !== undefined && this.apparentPower() !== null) ? ((this.apparentPower())/1000).toFixed(3) + " kVA" : "-";
	},
        
        apparentPowerPhaseA: function(value) {
            return this._field('apparentPowerPhaseA', value);
        },
        apparentPowerPhaseADisplay: function() {
	    return (this.apparentPowerPhaseA() !== undefined && this.apparentPowerPhaseA() !== null) ? ((this.apparentPowerPhaseA())/1000).toFixed(3) + " kVA" : "-";
	},
        
        apparentPowerPhaseB: function(value) {
            return this._field('apparentPowerPhaseB', value);
        },
        apparentPowerPhaseBDisplay: function() {
	    return (this.apparentPowerPhaseB() !== undefined && this.apparentPowerPhaseB() !== null) ? ((this.apparentPowerPhaseB())/1000).toFixed(3) + " kVA" : "-";
	},
        
        apparentPowerPhaseC: function(value) {
            return this._field('apparentPowerPhaseC', value);
        },
        apparentPowerPhaseCDisplay: function() {
	    return (this.apparentPowerPhaseC() !== undefined && this.apparentPowerPhaseC() !== null) ? ((this.apparentPowerPhaseC())/1000).toFixed(3) + " kVA" : "-";
	},
        
        frequency: function(value) {
            return this._field('frequency', value);
        },
        frequencyDisplay: function() {
	    return (this.frequency() !== undefined && this.frequency() !== null) ? this.frequency().toFixed(3) + " Hz" : "-";
	},
        
        phaseAngleA: function(value) {
            return this._field('phaseAngleA', value);
        },
        phaseAngleADisplay: function() {
	    return (this.phaseAngleA() !== undefined && this.phaseAngleA() !== null) ? this.phaseAngleA().toFixed(1) + " &deg;" : "-";
	},
        
        phaseAngleB: function(value) {
            return this._field('phaseAngleB', value);
        },
        phaseAngleBDisplay: function() {
	    return (this.phaseAngleB() !== undefined && this.phaseAngleB() !== null) ? this.phaseAngleB().toFixed(1) + " &deg;" : "-";
	},
        
        phaseAngleC: function(value) {
            return this._field('phaseAngleC', value);
        },
        phaseAngleCDisplay: function() {
	    return (this.phaseAngleC() !== undefined && this.phaseAngleC() !== null) ? this.phaseAngleC().toFixed(1) + " &deg;" : "-";
	},
        
        currentNeutral: function(value) {
            return this._field('currentNeutral', value);
        },
        currentNeutralDisplay: function() {
	    return (this.currentNeutral() !== undefined && this.currentNeutral() !== null) ? this.currentNeutral().toFixed(3) + " A" : "-";
	},
	
        powerFactorPhaseADisplay: function() {
            var value = "-";
            if (this.activePowerPhaseA() !== null && this.apparentPowerPhaseA() !== null && this.apparentPowerPhaseA() !== 0) {
                value = (this.activePowerPhaseA() / this.apparentPowerPhaseA()).toPrecision(4);
            }
            return value;
        },
        
        powerFactorPhaseBDisplay: function() {
            var value = "-";
            if (this.activePowerPhaseB() !== null && this.apparentPowerPhaseB() !== null && this.apparentPowerPhaseB() !== 0) {
                value = (this.activePowerPhaseB() / this.apparentPowerPhaseB()).toPrecision(4);
            }
            return value;
        },
        
        powerFactorPhaseCDisplay: function() {
            var value = "-";
            if (this.activePowerPhaseC() !== null && this.apparentPowerPhaseC() !== null && this.apparentPowerPhaseC() !== 0) {
                value = (this.activePowerPhaseC() / this.apparentPowerPhaseC()).toPrecision(4);
            }
            return value;
        },
        
	deviceEventId: function(value) {
	    return this._field('deviceEventId', value);
	},
	
	metrologyTypeId: function(value) {
	    return this._field('metrologyTypeId', value);
	},
        
        ctType: function(value) {
	    return this._field('ctType', value);
	},
        
        ptType: function(value) {
	    return this._field('ptType', value);
	}
    });
    
})(jQuery);
