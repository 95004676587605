(function(User, $, undefined) {
    
    // private namespace
    var that = {};
    
    that.logger = new EmPower.Util.Logger("User Wizards", true);
    
    that.userDialog = {};
    
    that.userDialog.html = [
	'<form>',
	'  <div class="form-group has-feedback">',
	'    <label>Username:</label>',
	'    <input name="username" type="text" class="form-control" placeholder="Enter username" value="" required/>',
	'    <span class="glyphicon form-control-feedback"></span>',
	'  </div>',
	'  <div class="form-group">',
	'    <label>Password:</label>',
	'    <input name="password" type="password" class="form-control" placeholder="Enter password" value="" required/>',
	'  </div>',
	'  <div class="form-group">',
	'    <label>Verify Password:</label>',
	'    <input name="passwordVerify" type="password" class="form-control" placeholder="Re-enter password" value="" required/>',
	'  </div>',
	'</form>'
    ];
    
    that.userDialog.open = function(session, dialog) {
	
	dialog = dialog || new BootstrapDialog({
	    title: 'Create new user'
	});
	
	if (!that.userDialog.htmlCompiled) {
	    that.userDialog.htmlCompiled = _.template(that.userDialog.html.join(''));
	}
	    
	var $html = $(that.userDialog.htmlCompiled());
	
	dialog.setMessage($html);
	
	var $form = $html,
		$username = $form.find('input[name="username"]'),
		$password = $form.find('input[name="password"]');
	
	var userModel = new EmPower.Model.User();
	
	$form.validate({
	    rules: {
		username: {
		    required: true,
		    remote: {
			url: EmPower.Util.Url.apiBuilder("user"),
			type: "GET",
			data: {
			    checkUser: true
			}
		    }
		},
		password: {
		    required: true
		},
		passwordVerify: {
		    required: true,
		    equalTo: $password
		}
	    },
	    messages: {
		username: {
		    required: "Sorry, please enter username.",
		    remote: "Sorry, entered username is taken."
		},
		password: {
		    required: "Sorry, please enter password."
		},
		passwordVerify: {
		    required: "Sorry, please re-enter password.",
		    equalTo: "Sorry, the passwords do not match."
		}
	    },
	    tooltip_options: {
		_all_: {
		    placement: 'bottom'
		}
	    }
	});
	
	var onNextStep = function(dialog) {
		
	    if ($form.valid()) {

		userModel.username($username.val());
		userModel.password($password.val());
		userModel.isEnabled(1);

		that.userInformationDialog.open(session, userModel, dialog, true);
	    }
	};
	
	var nextButton = {
	    label: 'Next',
	    cssClass: 'btn-primary',
	    action: onNextStep
	};
	
	var onCancel = function(dialog) {
	    dialog.close();
	};
	
	var cancelButton = {
	    label: 'Cancel',
	    cssClass: 'btn-default left',
	    action: onCancel
	};
	
	dialog.setButtons([cancelButton, nextButton]);
	
	return dialog.open();
    };
    
    that.userInformationDialog = {};
    
    that.userInformationDialog.html = [
	'<form autocomplete="off">',
	'  <div class="form-group">',
	'    <label>First Name:</label>',
	'    <input name="firstName" type="text" class="form-control" value="<%-userModel.firstName()%>" placeholder="Enter a first name" required/>',
	'  </div>',
	'  <div class="form-group">',
	'    <label>Last Name:</label>',
	'    <input name="lastName" type="text" class="form-control" value="<%-userModel.lastName()%>" placeholder="Enter a last name" required/>',
	'  </div>',
	'  <div class="form-group">',
	'    <label>Phone Number:</label>',
	'    <input name="phoneNumber" type="text" class="form-control" value="<%-userModel.phoneNumber()%>" placeholder="Enter a phone number"/>',
	'  </div>',
	'  <div class="form-group">',
	'    <label>Email:</label>',
	'    <input name="email" type="text" class="form-control" value="<%-userModel.email()%>" placeholder="Enter an email" required/>',
	'  </div>',
	'  <div class="form-group">',
	'    <label>Company:</label>',
	'    <input name="company" type="text" class="form-control" value="<%-userModel.company()%>" placeholder="Enter a company"/>',
	'  </div>',
	'  <div class="form-group">',
	'    <label>Title:</label>',
	'    <input name="title" type="text" class="form-control" value="<%-userModel.title()%>" placeholder="Enter a title"/>',
	'  </div>',
	'  <div class="form-group">',
	'    <label>Access Card Number:</label>',
	'    <input name="accessCardNumber" type="text" class="form-control" value="<%-userModel.accessCardNumber()%>" placeholder="Enter access card number"/>',
	'  </div>',
	'  <div class="form-group">',
	'    <label>Access Card Facility Id:</label>',
	'    <input name="accessCardFacilityId" type="text" class="form-control" value="<%-userModel.accessCardFacilityId()%>" placeholder="Enter a access card facility data"/>',
	'  </div>',
	'  <div class="form-group">',
	'    <label>Access Card Id:</label>',
	'    <input name="accessCardId" type="text" class="form-control" value="<%-userModel.accessCardId()%>" placeholder="Enter raw access card data"/>',
	'  </div>',
	'  <div class="form-group">',
	'    <label>User Role:</label>',
	'    <select class="form-control" name="userRole" <%- userModel.getCanChangePermission() || isNew ? "" : "disabled" %> required>',
        '      <option value="User" <%- userModel.roleName() === "User" ? "selected" : "" %>>User</option>',
        '      <option value="Technician" <%- userModel.roleName() === "Technician" ? "selected" : "" %>>Technician</option>',
        '      <option value="Administrator" <%- userModel.roleName() === "Administrator" ? "selected" : "" %>>Administrator</option>',
	'    </select>',
	'  </div>',
	'</form>'
    ];
    
    that.userInformationDialog.open = function(session, userModel, dialog, isUser) {
	
	var isNew = userModel.id === undefined;
	
	dialog = dialog || new BootstrapDialog();
	
	dialog.setTitle((isNew ? 'Create' : 'Update') + ' user information for <strong>' + userModel.username() + '</strong>');
	
	if (!that.userInformationDialog.htmlCompiled) {
	    that.userInformationDialog.htmlCompiled = _.template(that.userInformationDialog.html.join(''));
	}
	
	var data = {
	    userModel: userModel,
	    isNew: isNew
	};
	
	var $html = $(that.userInformationDialog.htmlCompiled(data));
	
	dialog.setMessage($html);
	
	var $form = $html,
		username = userModel.username(),
		$firstName = $form.find('input[name="firstName"]'),
		$lastName = $form.find('input[name="lastName"]'),
		$phoneNumber = $form.find('input[name="phoneNumber"]'),
		$email = $form.find('input[name="email"]'),
		$company = $form.find('input[name="company"]'),
		$title = $form.find('input[name="title"]'),
		$accessCardNumber = $form.find('input[name="accessCardNumber"]'),
		$accessCardFacilityId = $form.find('input[name="accessCardFacilityId"]'),
		$accessCardId = $form.find('input[name="accessCardId"]'),
		$roleName = $form.find('select[name="userRole"]');
	
	$form.validate({
	    rules: {
		firstName: {
		    required: true
		},
		lastName: {
		    required: true
		},
		email: {
		    required: true,
		    email: true
		},
		userRole: {
		    required: true
		}
	    },
	    messages: {
		firstName: {
		    required: "Sorry, please enter first name."
		},
		lastName: {
		    required: "Sorry, please enter last name."
		},
		email: {
		    required: "Sorry, please enter email.",
		    email: "Sorry, please enter a valid email."
		},
		userRole: {
		    retuired: "Sorry, please select a user role."
		}
	    },
	    tooltip_options: {
		_all_: {
		    placement: 'bottom'
		}
	    }
	});
	
	var onNextStep = function(dialog) {
	    
	    if ($form.valid()) {
		
		userModel.username(username);
		userModel.firstName($.trim($firstName.val()));
		userModel.lastName($.trim($lastName.val()));
		userModel.phoneNumber($.trim($phoneNumber.val()));
		userModel.email($.trim($email.val()));
		userModel.company($.trim($company.val()));
		userModel.title($.trim($title.val()));
		userModel.roleName($.trim($roleName.val()));
		userModel.accessCardId($accessCardId.val() ? $.trim($accessCardId.val()) : null );
		userModel.accessCardFacilityId($accessCardFacilityId.val() ? $.trim($accessCardFacilityId.val()) : null );
		userModel.accessCardNumber($accessCardNumber.val() ? $.trim($accessCardNumber.val()) : null );


		EmPower.Dialog.SimpleDialog.Loading.open('Processing user...');

		var apiUser = userModel.save();

		apiUser.done(function(apiData) {

		    if (isUser) {
                        userModel.password(null);
                        userModel.oldPassword(null);
                        session.settingSession().userCollection().add(userModel);
		    } else {
			that.logger.warn("Cannot save user information from User Wizard.");
		    }

		    EmPower.Dialog.SimpleDialog.Loading.close();

		    that.userInformationDialog.showSuccess(userModel, dialog, isNew, isUser);
		});
	    }
	};
	
	var nextButton = {
	    label: 'Save',
	    cssClass: 'btn-primary',
	    action: onNextStep
	};
	
	var onClose = function(dialog) {
	    dialog.close();
	};
	
	var closeButton = {
	    label: 'Close',
	    cssClass: 'btn-default left',
	    action: onClose
	};
	
	dialog.setButtons([closeButton, nextButton]);
	
	return dialog.open();
    };
    
    that.userInformationDialog.showSuccess = function(userModel, dialog, isNew, isUser) {
	
	$.notify({
	    message: (isUser ? "User" : "User information for") + " <strong>" + userModel.username() + "</strong> was " + (isNew ? 'created' : 'updated') + "."
	}, {
	    type: 'success',
	    placement: {
		from: 'top',
		align: 'center'
	    }
	});
	
	dialog.close();

	return dialog;
    };
    
    that.userPasswordDialog = {};
    
    that.userPasswordDialog.html = [
	'<form>',
	'  <div class="form-group">',
	'    <label>Current Password:</label>',
	'    <input name="currentPassword" type="password" class="form-control" placeholder="Enter old password (Not required for admins editing non-admins)"/>',
	'  </div>',
	'  <div class="form-group">',
	'    <label>New Password:</label>',
	'    <input name="password" type="password" class="form-control" placeholder="Enter a new password" required/>',
	'  </div>',
	'  <div class="form-group">',
	'    <label>Verify New Password:</label>',
	'    <input name="passwordVerify" type="password" class="form-control" placeholder="Re-enter new password" required/>',
	'  </div>',
	'</form>'
    ];
    
    that.userPasswordDialog.open = function(userModel, dialog) {
	
	dialog = dialog || new BootstrapDialog({
	    title: 'Change password for <strong>' + userModel.username() + "</strong>"
	});
	
	if (!that.userPasswordDialog.htmlCompiled) {
	    that.userPasswordDialog.htmlCompiled = _.template(that.userPasswordDialog.html.join(''));
	}
	    
	var $html = $(that.userPasswordDialog.htmlCompiled());
	
	dialog.setMessage($html);
	
	var $form = $html,
		$currentPassword = $form.find('input[name="currentPassword"]'),
		$password = $form.find('input[name="password"]');
	
	$form.validate({
	    rules: {
		currentPassword: {
		    required: false
		},
		password: {
		    required: true
		},
		passwordVerify: {
		    required: true,
		    equalTo: $password
		}
	    },
	    messages: {
		currentPassword: {
		    required: "Sorry, please enter current password."
		},
		password: {
		    required: "Sorry, please enter new password."
		},
		passwordVerify: {
		    required: "Sorry, please re-enter new password.",
		    equalTo: "Sorry, the new passwords do not match."
		}
	    },
	    tooltip_options: {
		_all_: {
		    placement: 'bottom'
		}
	    }
	});
	
	var onNextStep = function(dialog) {
	    
	    if ($form.valid()) {
		
		EmPower.Dialog.SimpleDialog.Loading.open('Processing password...');
		
		userModel.password($.trim($password.val()));
		userModel.oldPassword($.trim($currentPassword.val()));
		
		var apiUser = userModel.save();
		
		apiUser.done(function(apiData) {
		    
                    userModel.password(null);
                    userModel.oldPassword(null);
                    
		    EmPower.Dialog.SimpleDialog.Loading.close();
		    
		    that.userPasswordDialog.showSuccess(userModel, dialog);
		});
	    }
	};
	
	var nextButton = {
	    label: 'Next',
	    cssClass: 'btn-primary',
	    action: onNextStep
	};
	
	var onCancel = function(dialog) {
	    dialog.close();
	};
	
	var cancelButton = {
	    label: 'Cancel',
	    cssClass: 'btn-default left',
	    action: onCancel
	};
	
	dialog.setButtons([cancelButton, nextButton]);
	
	return dialog.open();
    };
    
    that.userPasswordDialog.showSuccess = function(userModel, dialog) {
	
	$.notify({
	    message: "Password for <strong>" + userModel.username() + "</strong> was updated."
	}, {
	    type: 'success',
	    placement: {
		from: 'top',
		align: 'center'
	    }
	});
	
	dialog.close();

	return dialog;
    };
    
    that.userDisableDialog = {};
    
    that.userDisableDialog.html = [
	'<div>',
	'  Are you sure you wish to disable <strong><%-username()%></strong>?',
	'<div>'
    ];
    
    that.userDisableDialog.open = function(userModel, dialog) {
	
	dialog = dialog || new BootstrapDialog({
	    title: 'Disable <strong>' + userModel.username() + "</strong>",
	    size: BootstrapDialog.SIZE_SMALL
	});
	
	if (!that.userDisableDialog.htmlCompiled) {
	    that.userDisableDialog.htmlCompiled = _.template(that.userDisableDialog.html.join(''));
	}
	    
	var $html = $(that.userDisableDialog.htmlCompiled(userModel));
	
	dialog.setMessage($html);
	
	var onNextStep = function(dialog) {
	    
	    EmPower.Dialog.SimpleDialog.Loading.open('Disabling User...');
	    
	    userModel.isEnabled(0);
	    
	    var apiUser = userModel.save();
		
	    apiUser.done(function(apiData) {

		EmPower.Dialog.SimpleDialog.Loading.close();

		that.userDisableDialog.showSuccess(userModel, dialog);
	    });
	};
	
	var nextButton = {
	    label: 'Disable',
	    cssClass: 'btn-danger',
	    action: onNextStep
	};
	
	var onCancel = function(dialog) {
	    dialog.close();
	};
	
	var cancelButton = {
	    label: 'Cancel',
	    cssClass: 'btn-default left',
	    action: onCancel
	};
	
	dialog.setButtons([cancelButton, nextButton]);
	dialog.setType(BootstrapDialog.TYPE_DANGER);
	dialog.setSize(BootstrapDialog.SIZE_SMALL);
	
	return dialog.open();
    };
    
    that.userDisableDialog.showSuccess = function(userModel, dialog) {
	
	$.notify({
	    message: "User <strong>" + userModel.username() + "</strong> was disabled."
	}, {
	    type: 'success',
	    placement: {
		from: 'top',
		align: 'center'
	    }
	});
	
	dialog.close();

	return dialog;
    };
    
    that.userEnableDialog = {};
    
    that.userEnableDialog.html = [
	'<div>',
	'  Are you sure you wish to enable <strong><%-username()%></strong>?',
	'<div>'
    ];
    
    that.userEnableDialog.open = function(userModel, dialog) {
	
	dialog = dialog || new BootstrapDialog({
	    title: 'Enable <strong>' + userModel.username() + "</strong>",
	    size: BootstrapDialog.SIZE_SMALL
	});
	
	if (!that.userEnableDialog.htmlCompiled) {
	    that.userEnableDialog.htmlCompiled = _.template(that.userEnableDialog.html.join(''));
	}
	    
	var $html = $(that.userEnableDialog.htmlCompiled(userModel));
	
	dialog.setMessage($html);
	
	var onNextStep = function(dialog) {
	    
	    EmPower.Dialog.SimpleDialog.Loading.open('Enabling User...');
	    
	    userModel.isEnabled(1);
	    
	    var apiUser = userModel.save();
		
	    apiUser.done(function(apiData) {

		EmPower.Dialog.SimpleDialog.Loading.close();

		that.userEnableDialog.showSuccess(userModel, dialog);
	    });
	};
	
	var nextButton = {
	    label: 'Enable',
	    cssClass: 'btn-success',
	    action: onNextStep
	};
	
	var onCancel = function(dialog) {
	    dialog.close();
	};
	
	var cancelButton = {
	    label: 'Cancel',
	    cssClass: 'btn-default left',
	    action: onCancel
	};
	
	dialog.setButtons([cancelButton, nextButton]);
	dialog.setType(BootstrapDialog.TYPE_SUCCESS);
	dialog.setSize(BootstrapDialog.SIZE_SMALL);
	
	return dialog.open();
    };
    
    that.userEnableDialog.showSuccess = function(userModel, dialog) {
	
	$.notify({
	    message: "User <strong>" + userModel.username() + "</strong> was enabled."
	}, {
	    type: 'success',
	    placement: {
		from: 'top',
		align: 'center'
	    }
	});
	
	dialog.close();

	return dialog;
    };
    
    that.userHideDialog = {};
    
    that.userHideDialog.html = [
	'<div>',
	'  Are you sure you wish to hide <strong><%-username()%></strong>?',
	'<div>'
    ];
    
    that.userHideDialog.open = function(userModel, dialog) {
	
	dialog = dialog || new BootstrapDialog({
	    title: 'Hide <strong>' + userModel.username() + "</strong>",
	    size: BootstrapDialog.SIZE_SMALL
	});
	
	if (!that.userHideDialog.htmlCompiled) {
	    that.userHideDialog.htmlCompiled = _.template(that.userHideDialog.html.join(''));
	}
	    
	var $html = $(that.userHideDialog.htmlCompiled(userModel));
	
	dialog.setMessage($html);
	
	var onNextStep = function(dialog) {
	    
	    EmPower.Dialog.SimpleDialog.Loading.open('Making user hidden...');
	    
	    userModel.isHidden(1);
	    
	    var apiUser = userModel.save();
		
	    apiUser.done(function(apiData) {

		EmPower.Dialog.SimpleDialog.Loading.close();

		that.userHideDialog.showSuccess(userModel, dialog);
	    });
	};
	
	var nextButton = {
	    label: 'Hide',
	    cssClass: 'btn-warning',
	    action: onNextStep
	};
	
	var onCancel = function(dialog) {
	    dialog.close();
	};
	
	var cancelButton = {
	    label: 'Cancel',
	    cssClass: 'btn-default left',
	    action: onCancel
	};
	
	dialog.setButtons([cancelButton, nextButton]);
	dialog.setType(BootstrapDialog.TYPE_WARNING);
	dialog.setSize(BootstrapDialog.SIZE_SMALL);
	
	return dialog.open();
    };
    
    that.userHideDialog.showSuccess = function(userModel, dialog) {
	
	$.notify({
	    message: "User <strong>" + userModel.username() + "</strong> was set to hidden."
	}, {
	    type: 'success',
	    placement: {
		from: 'top',
		align: 'center'
	    }
	});
	
	dialog.close();

	return dialog;
    };
    
    that.userShowDialog = {};
    
    that.userShowDialog.html = [
	'<div>',
	'  Are you sure you wish to show <strong><%-username()%></strong>?',
	'<div>'
    ];
    
    that.userShowDialog.open = function(userModel, dialog) {
	
	dialog = dialog || new BootstrapDialog({
	    title: 'Show <strong>' + userModel.username() + "</strong>",
	    size: BootstrapDialog.SIZE_SMALL
	});
	
	if (!that.userShowDialog.htmlCompiled) {
	    that.userShowDialog.htmlCompiled = _.template(that.userShowDialog.html.join(''));
	}
	    
	var $html = $(that.userShowDialog.htmlCompiled(userModel));
	
	dialog.setMessage($html);
	
	var onNextStep = function(dialog) {
	    
	    EmPower.Dialog.SimpleDialog.Loading.open('Making user visable...');
	    
	    userModel.isHidden(0);
	    
	    var apiUser = userModel.save();
		
	    apiUser.done(function(apiData) {

		EmPower.Dialog.SimpleDialog.Loading.close();

		that.userShowDialog.showSuccess(userModel, dialog);
	    });
	};
	
	var nextButton = {
	    label: 'Show',
	    cssClass: 'btn-success',
	    action: onNextStep
	};
	
	var onCancel = function(dialog) {
	    dialog.close();
	};
	
	var cancelButton = {
	    label: 'Cancel',
	    cssClass: 'btn-default left',
	    action: onCancel
	};
	
	dialog.setButtons([cancelButton, nextButton]);
	dialog.setType(BootstrapDialog.TYPE_SUCCESS);
	dialog.setSize(BootstrapDialog.SIZE_SMALL);
	
	return dialog.open();
    };
    
    that.userShowDialog.showSuccess = function(userModel, dialog) {
	
	$.notify({
	    message: "User <strong>" + userModel.username() + "</strong> was set to visable."
	}, {
	    type: 'success',
	    placement: {
		from: 'top',
		align: 'center'
	    }
	});
	
	dialog.close();

	return dialog;
    };
    
    User.information = function(session, userModel) {
	
	if (userModel) {
	    
	    that.userInformationDialog.open(session, userModel);
	} else {
	    
	    that.userDialog.open(session);
	}
    };
    
    User.password = function(userModel) {
	
	if (userModel) {
	    
	    that.userPasswordDialog.open(userModel);
	}
    };
    
    User.disable = function(userModel) {
	
	if (userModel && userModel.id) {
	    
	    that.userDisableDialog.open(userModel);
	}
    };
    
    User.enable = function(userModel) {
	
	if (userModel && userModel.id) {
	    
	    that.userEnableDialog.open(userModel);
	}
    };
    
    User.hide = function(userModel) {
	
	if (userModel && userModel.id) {
	    
	    that.userHideDialog.open(userModel);
	}
    };
    
    User.show = function(userModel) {
	
	if (userModel && userModel.id) {
	    
	    that.userShowDialog.open(userModel);
	}
    };
    
})(EmPower.Dialog.User = EmPower.Dialog.User || {}, jQuery);
