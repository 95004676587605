EmPower.Model.Document = (function($, undefined) {
    
    return EmPower.Model.AbstractModel.extend({
	
	idAttribute: 'fileName',
	    
	modelName: 'Document',

	_initialize: function() {
	},

	_parse: function() {
	},
	
	description: function(value) {
	    return this._field('description', value);
	},

	fileName: function(value) {
	    return this._field('fileName', value);
	},
        
        previewUrl: function() {
            return this.url();
        },
        
        downloadUrl: function() {
            return this.url() + "/download";
        }
    });
    
})(jQuery);
