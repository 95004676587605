/* jshint bitwise: false */

EmPower.Model.SettingSession = (function($, undefined) {
    
    return EmPower.Model.AbstractSubSession.extend({
        whatAmI: function(){return "EmPower.Model.SettingSession - Also see modelName";},
	    
	modelName: 'Setting Session',

	_initialize: function() {
            
            this.userCollection(EmPower.Collection.User);
	    
            this.userCollectionProxy(Backbone.Obscura);
            
	    this.selectedUser(EmPower.Model.SelectedCollectionObject);
        },
        
        _reset: function(options) {
            
            this.selectedUser().selected(options.selectedUser ? options.selectedUser : null);
        },
	
        userCollection: function(value) {
	    return this._instanceFactoryField('userCollection', value, [null, {session: this._session()}]);
	},
        userCollectionProxy: function(value) {
            var args;
            if (value === undefined) {
                args = [this.userCollection()];
            }
            var proxy = this._instanceFactoryField('userCollectionProxy', value, args);
            if (_.isFunction(proxy.filterBy) && !this._userCollectionProxyFilter) {
                proxy.filterBy('Enabled', function(model) {
                    return model.isEnabled() ? true : false;
                });
                proxy.filterBy('Hidden', function(model) {
                    if (proxy.superset().getCanCreateUser()) {
                        return true;
                    } else {
                        return model.isHidden() ? false : true;
                    }
                });
                this._userCollectionProxyFilter = true;
            }
            return proxy;
        },
	selectedUser: function(value) {
            
	    var self = this;
            
	    var args;
	    if (value === undefined) {
		args = [{
		    session: this._session(),
		    collection: this.userCollectionProxy(),
		    attributeParser: function(selectedId) {
			return isNaN(selectedId) ? undefined : ~~selectedId;
		    },
		    comparableAttribute: function(model) {
			return model ? ~~model.userId() : undefined;
		    },
                    notFoundRedirect: function(selectedId) {
                        var message = "Sorry, <strong>User ID " + selectedId + "</strong> was not found.";
                        if (selectedId === undefined || selectedId === null) {
                            message = "Sorry, User ID must be a number.";
                        }
                        
                        $.notify({
                            message: message
                        }, {
                            type: 'danger',
                            placement: {
                                from: 'top',
                                align: 'center'
                            }
                        });
                        self._session().applicationRouter().getSettingsModule().showAccountManagement();
                    }
		}];
	    }
	    
	    return this._instanceFactoryField('selectedUser', value, args);
	}
    });
    
})(jQuery);
