(function(TransformerUpdate, $, undefined) {
    
    // private namespace
    var that = {};
    
    that.update = {};
    
    that.update.html = [
	'<div>',
	'  Available Updates:',
	'  <pre><%-updateMessage%></pre>',
	'<div>'
    ];
    
    that.update.open = function(transformer, availableUpdates, dialog) {
	
	dialog = dialog || new BootstrapDialog({
	    title: 'Available updates for  <strong>Transformer ' + transformer.get("transformerId") + "</strong>"
	});
	
	if (!that.update.htmlCompiled) {
	    that.update.htmlCompiled = _.template(that.update.html.join(''));
	}
	 
	var data = {
	    updateMessage: availableUpdates
	};
	
	var $html = $(that.update.htmlCompiled(data));
	
	dialog.setMessage($html);
	
	var onNextStep = function(dialog) {
	    
	    EmPower.Dialog.SimpleDialog.Loading.open('Updating transformer...');
	    
	    var url = EmPower.Util.Url.apiBuilder("transformers", transformer.id, "updatesoftware");
	    
	    $.ajax({
		url: url,
		type: 'post',
		success: function(data) {
		    EmPower.Dialog.SimpleDialog.Loading.close();
		    if (data && data.status) {
			that.update.showSuccess(transformer, data, dialog);
		    } else {
			$.notify({
			    message: "Sorry, <strong> Transformer ID " + transformer.id + "</strong> was not able to be updated."
			}, {
			    type: 'danger',
			    placement: {
				from: 'top',
				align: 'center'
			    }
			});
		    }
		},
		error: function() {
		    EmPower.Dialog.SimpleDialog.Loading.close();
		}
	    });
	};
	
	var nextButton = {
	    label: 'Apply Update',
	    cssClass: 'btn-primary',
	    action: onNextStep
	};
	
	var onCancel = function(dialog) {
	    dialog.close();
	};
	
	var cancelButton = {
	    label: 'Cancel',
	    cssClass: 'btn-default left',
	    action: onCancel
	};
	
	dialog.setButtons([cancelButton, nextButton]);
	
	return dialog.open();
    };
    
    that.update.showSuccess = function(transformer, data, dialog) {
	
	$.notify({
	    message: "<strong>Transformer " + transformer.get("transformerId") + "</strong> was updated."
	}, {
	    type: 'success',
	    placement: {
		from: 'top',
		align: 'center'
	    }
	});
	
	dialog.close();

	return dialog;
    };
    
    TransformerUpdate.open = function(transformer) {
	
	if (transformer && transformer.id) {
	    // api call to do check...
	    EmPower.Dialog.SimpleDialog.Loading.open('Checking transformer for updates...');
	    
	    var url = EmPower.Util.Url.apiBuilder("transformers", transformer.id, "checkupdates");
	    
	    $.ajax({
		url: url,
		type: 'GET',
		success: function(data) {
		    EmPower.Dialog.SimpleDialog.Loading.close();
		    if (data && data.versions) {
			that.update.open(transformer, data.versions);
		    } else {
			$.notify({
			    message: "<strong> Transformer ID " + transformer.id + "</strong> is currently up to date."
			}, {
			    type: 'info',
			    placement: {
				from: 'top',
				align: 'center'
			    }
			});
		    }
		},
		error: function() {
		    EmPower.Dialog.SimpleDialog.Loading.close();
		}
	    });
	}
    };
    
})(EmPower.Dialog.TransformerUpdate = EmPower.Dialog.TransformerUpdate || {}, jQuery);
