EmPower.View.Rctu = (function ($, undefined) {

    return EmPower.View.AbstractView.extend({

        viewName: "RCTU",

        _events: function () {
            var self = this;
            return {
                "click [data-action=update-configuration]": function (event) {
                    event.preventDefault();
                    EmPower.Dialog.Transformer.uploadConfiguration(this._session, this._selectedTransformer.selectedId(), this._selectedTransformer.selected().phoneNumber(), this._selectedTransformer.selected().transformerName());

                },
                "click [data-action=update-transformer]": function (event) {
                    event.preventDefault();
                    EmPower.Dialog.TransformerUpdate.open(this._selectedTransformer.selected());
                },
                "click [data-action=download-rctu-config]": function (event) {
                    event.preventDefault();
                    EmPower.Util.Download.hiddenDownload("api/1/transformers/" + this._selectedTransformer.selectedId() + "/config/download");
                },
                "click [data-action=start-ssh]": function (event) {
                    event.preventDefault();
                    EmPower.Dialog.TransformerSsh.start(this._selectedTransformer.selected());
                },
                "click [data-action=stop-ssh]": function (event) {
                    event.preventDefault();
                    EmPower.Dialog.TransformerSsh.stop(this._selectedTransformer.selected());
                },
                "click [data-action=force-disconnect]": function (event) {
                    event.preventDefault();
                    EmPower.Dialog.TransformerConnection.disconnect(this._selectedTransformer.selected());
                },
                "click [data-action=update-phone-number]": function (event) {
                    event.preventDefault();
                    EmPower.Dialog.Transformer.updatePhone(this._session, this._selectedTransformer.selectedId());
                },
                "click [data-action=reparse-configuration]": function (event) {
                    event.preventDefault();
                    self._reparseConfiguration();
                }
            };
        },

        _initialize: function () {
            this._selectedTransformer = this._session.transformerSession().selectedTransformer();
            this._transformerCollection = this._session.transformerSession().transformerCollection();
            this._rctuInfoCollection = new EmPower.Collection.RctuInformation(null, {session: this._session, selectedObject: this._selectedTransformer});
            this._rctuWarningCollection = new EmPower.Collection.RctuWarning(null, {session: this._session, selectedObject: this._selectedTransformer});
            this._baseDisplayWarningCollection = new EmPower.Collection.BaseDisplayWarning(null, {session: this._session, selectedObject: this._selectedTransformer});
        },

        _setupListeners: function () {

            var self = this;

            self.listenTo(self._transformerCollection, 'update reset', function () {
                this.renderDebounced();
            });
            self.listenTo(self._selectedTransformer, 'change', function () {
                this.renderDebounced();
            });
            self.listenTo(self._rctuInfoCollection, 'update reset', function () {
            });
            self.listenTo(self._rctuWarningCollection, 'update reset', function () {
            });
            self.listenTo(self._baseDisplayWarningCollection, 'update reset', function () {
            });
            self.listenTo(self._session, 'reset', function () {
                this._rctuInfoCollection.reset();
                this._rctuWarningCollection.reset();
                this._baseDisplayWarningCollection.reset();
            });
        },

        _htmlTemplate: function () {
            return EmPower.Templates['transformer/rctu/transformer_rctu'];
        },

        _htmlData: function () {
            return {
                transformerId: this._selectedTransformer.selectedId(),
                canCreate: this._transformerCollection.canCreateTransformer(),
                canEdit: this._selectedTransformer.selected() ? this._selectedTransformer.selected().getCanEdit() : false,
                activeConnection: this._selectedTransformer.selected() ? this._selectedTransformer.selected().connected() : false
            };
        },
        
        _reparseConfiguration: function () {
            var transformerId = this._selectedTransformer.selectedId();
            var reparseUrl = EmPower.Util.Url.apiBuilder("transformers", "config", "reparse", transformerId);
            $.ajax({
                type: "POST",
                url: reparseUrl,
                success: function (data) {
                    $.notify({
                        message: 'Configuration was successfully processed for <strong>Transformer ID ' + transformerId + '</strong>.'
                    }, {
                        type: 'success',
                        placement: {
                            from: 'top',
                            align: 'center'
                        }
                    });

                    $('#reparse-warning').css("display", "none");
                }
            });
        },

        _render: function ($html) {
            var parsedInfoUrl = EmPower.Util.Url.apiBuilder("transformers", this._selectedTransformer.selectedId(), "/config/information");
            $.ajax({
                url: parsedInfoUrl,
                type: 'GET',
                success: function (data) {
                    var time = moment(data.parsedTime).format("dddd, MMMM Do YYYY, h:mm:ss a");
                    $html.find('#parsedTime').text(time);

                    if (data.checksum !== data.currentFileChecksum) {
                        $html.find('#reparse-warning').css("display", "inline-block");
                    }
                }
            });

            if (this._selectedTransformer.selected()) {

                var selectedTransformerId = this._selectedTransformer.selected().transformerId();

                $.ajax({
                    url: ['./api/1/transformers/', selectedTransformerId, '/config/download'].join(""),
                    success: function (data) {
                        $html.find("div[data-section='rctu-xml']").empty().append($("<script src='https://cdn.rawgit.com/google/code-prettify/master/loader/run_prettify.js?skin=sons-of-obsidian'></script><pre class='prettyprint linenums:1' style='height:250px'>").html("<xmp style='margin: 0'>" + data + "</xmp>"));
                    },
                    error: function () {
                        $html.find("div[data-section='rctu-xml']").empty().append($("<pre style='height:250px'>").text("Sorry, configuration file not found."));
                    },
                    type: 'GET'
                });
            }

            var $rctuInformation = $html.find("div[data-section='rctu-information']").empty();
            if (!this._rctuInformation) {

                this._rctuInformation = new bbGrid.View({
                    rows: 25,
                    container: $rctuInformation,
                    collection: this._rctuInfoCollection,
                    colModel: [{
                            title: 'Description',
                            name: 'description',
                            sorttype: 'string'
                        }, {
                            title: 'Value',
                            name: 'value',
                            sorttype: 'number'
                        }]
                });
            } else {
                $rctuInformation.html(this._rctuInformation.$el);
            }

            var $rctuWarning = $html.find("div[data-section='rctu-warnings']").empty();
            if (!this._rctuWarning) {

                this._rctuWarning = new bbGrid.View({
                    rows: 25,
                    container: $rctuWarning,
                    collection: this._rctuWarningCollection,
                    colModel: [{
                            title: 'Warning',
                            name: 'errorNumber',
                            sorttype: 'number'
                        }, {
                            title: 'Reason',
                            name: 'errorChangeReason',
                            sorttype: 'number'
                        }, {
                            title: 'Severity',
                            name: 'errorSeverity',
                            sorttype: 'number'
                        }, {
                            title: 'State',
                            name: 'errorState',
                            sorttype: 'number'
                        }, {
                            title: 'Description',
                            name: 'errorDescription',
                            sorttype: 'string'
                        }, {
                            title: 'Notes',
                            name: 'errorNotes',
                            sorttype: 'string'
                        }, {
                            title: 'Timestamp',
                            name: 'time',
                            sorttype: 'number'
                        }]
                });
            } else {
                $rctuWarning.html(this._rctuWarning.$el);
            }

            var $baseDisplayWarning = $html.find("div[data-section='base-display-warnings']").empty();
            if (!this._baseDisplayWarning) {

                this._baseDisplayWarning = new bbGrid.View({
                    rows: 25,
                    container: $baseDisplayWarning,
                    collection: this._baseDisplayWarningCollection,
                    colModel: [{
                            title: 'Warning',
                            name: 'errorNumber',
                            sorttype: 'number'
                        }, {
                            title: 'Reason',
                            name: 'errorChangeReason',
                            sorttype: 'number'
                        }, {
                            title: 'Severity',
                            name: 'errorSeverity',
                            sorttype: 'number'
                        }, {
                            title: 'State',
                            name: 'errorState',
                            sorttype: 'number'
                        }, {
                            title: 'Notes',
                            name: 'notes',
                            sorttype: 'string'
                        }]
                });
            } else {
                $baseDisplayWarning.html(this._baseDisplayWarning.$el);
            }
        },

        _show: function () {
            if (this.hasRendered()) {
                this.render();
            }
        },

        _hide: function () {
            if (this._rctuInformation) {
                this._rctuInformation.remove();
                this._rctuInformation = null;
            }
            if (this._rctuWarning) {
                this._rctuWarning.remove();
                this._rctuWarning = null;
            }
            if (this._baseDisplayWarning) {
                this._baseDisplayWarning.remove();
                this._baseDisplayWarning = null;
            }
        },

        _remove: function () {
            if (this._rctuInformation) {
                this._rctuInformation.remove();
                this._rctuInformation = null;
            }
            if (this._rctuWarning) {
                this._rctuWarning.remove();
                this._rctuWarning = null;
            }
            if (this._baseDisplayWarning) {
                this._baseDisplayWarning.remove();
                this._baseDisplayWarning = null;
            }
        }
    });

})(jQuery);
