EmPower.View.Document = (function($, undefined) {

    return EmPower.View.AbstractView.extend({
	viewName: "Transformer Document",
	
	_events: function() {
	    return {
		'click div[data-action="transformer-document-list-toggle"] > a': this._toggleDocumentList
	    };
	},
	
	_initialize: function() {
	    
	    this._selectedTransformer = this._session.transformerSession().selectedTransformer();
	    
	    this._selectedDocument = new EmPower.Model.SelectedObject();
	    
	    this._documentCollection = new EmPower.Collection.Document(null, {
		session: this._session,
		selectedObject: this._selectedTransformer
	    });
	    
	    this._documentListVisible = true;
	},
	
	_setupListeners: function() {
            
            this.listenTo(this._selectedTransformer, "change", function() {
                this._selectedDocument.selected(null);
            });
            
            this._selectedDocument.listenTo(this._session, 'reset', function() {
		this._documentListVisible = true;
		this._selectedDocument.selected(null);
		this.renderDebounced();
	    });
	},
	
	_htmlTemplate: function() {
	    return EmPower.Templates['transformer/document/transformer_document'];
	},
	
	_htmlData: function() {
	    return {
		documentListVisible: this._documentListVisible
	    };
	},
	
	_render: function($html) {
	    
	    var $documentList = $html.find("div[data-section='transformer-document-list-container']");
	    if (!this._documentList) {

		this._documentList = new EmPower.View.DocumentList({
		    session: this._session,
		    collection: this._documentCollection,
		    selectedModel: this._selectedDocument
		});
		this._documentList.render($documentList);
	    } else {
		$documentList.replaceWith(this._documentList.$el);
	    }
	    if (!this._documentListVisible) {
		this._documentList.hide();
	    }
	    
	    var $documentPreview = $html.find("div[data-section='transformer-document-preview']");
	    if (!this._documentPreview) {

		this._documentPreview = new EmPower.View.DocumentPreview({
		    session: this._session,
		    selectedDocument: this._selectedDocument
		});
		this._documentPreview.render($documentPreview);
	    } else {
		$documentPreview.replaceWith(this._documentPreview.$el);
	    }
	},
	
	_toggleDocumentList: function(event) {
	    
	    var $button = $(event.currentTarget);

	    if ($button.attr('data-action') === "collapse") {
		$button.parent().addClass("collapsed");
		this._documentListVisible = false;
		if (this._documentList) {
		    this._documentList.hide("blind", {
			direction: "right"
		    }, 250);
		}
	    } else {
		$button.parent().removeClass("collapsed");
		this._documentListVisible = true;
		if (this._documentList) {
		    this._documentList.show("blind", {
			direction: "right"
		    }, 250);
		}
	    }
	},
	
	_show: function() {
	    if (this._documentList && this._documentListVisible) {
		this._documentList.show();
	    }
	    if (this._documentPreview) {
		this._documentPreview.show();
	    }
	},
	
	_hide: function() {
	    if (this._documentList) {
		this._documentList.hide();
	    }
	    if (this._documentPreview) {
		this._documentPreview.hide();
	    }
	},
	
	_remove: function() {
	    if (this._documentList) {
		this._documentList.remove();
	    }
	    if (this._documentPreview) {
		this._documentPreview.remove();
	    }
	}
    });
    
})(jQuery);
