EmPower.Route.Map = (function(SubRoute, $, undefined) {
    
    return SubRoute.extend({
		whatAmI: function(){return "EmPower.Route.Map - Also see routeName";},
	
	routeName: "Map",
	
	_paths: function() {
	    return {
		map: function(transformerId) {
		    return EmPower.Util.Url.builder(transformerId);
		}
	    };
	},
	
	_routes: function(paths) {
	    
	    var routes = {};
	    
	    routes[paths.map()] = this._showMap;
	    routes[paths.map('(:transformerId)')] = this._showMap;
	    
	    return routes;
	},
	
	_initialize: function() {
	    this._mapView = new EmPower.View.Map({
		session: this._session
	    });
	},
	
	
	_showMap: function(transformerId) {
	    this._session.selectedSectionView().selected(this._mapView);
	    this._session.transformerSession().selectedTransformer().selectedId(transformerId ? transformerId : null);
	},
	showMap: function() {
	    this.navigate(this.paths.map(this._session.transformerSession().selectedTransformer().selectedId()), {trigger: true});
	},

	_show: function() {
	    this._session.selectedSectionView().selected(this._mapView);
	}
    });
    
})(EmPower.Route.AbstractSubRoute, jQuery);
