EmPower.View.Rctu_software_update = (function($, undefined) {
    
    return EmPower.View.AbstractView.extend({
	
	viewName: "RCTU-software-update",
        getAllOutput: true,
	
	_events: function() {
            var self = this;
	    return {
		"click [data-action=run-apt-offline-update]": function(event) {
		    event.preventDefault();
		    self.runAptOfflineUpdate();
		},
		"click [data-action=run-apt-update]": function(event) {
		    event.preventDefault();
                    self.runAptUpdate();
		},
                "click [data-action=update-rctu]": function(event) {
                    event.preventDefault();
                    self.upgradeRCTU();
                },
                "click [data-action=update-kernel]": function(event) {
		    event.preventDefault();
                    self.upgradeKernel();
		},
                "click [data-action=update-output]": function(event){
                    //$.ajax()
                }
	    };
	},
	
	_initialize: function() {
	    this._selectedTransformer = this._session.transformerSession().selectedTransformer();
            this._transformerCollection = this._session.transformerSession().transformerCollection();
	},
	
	_setupListeners: function() {
	   
		
	},
	
	_htmlTemplate: function() {
	    return EmPower.Templates['transformer/update/transformer_update'];
	},
	
	_htmlData: function() {
	    return {
		transformerId: this._selectedTransformer.selectedId(),
		canCreate: this._transformerCollection.canCreateTransformer(),
                canEdit: this._selectedTransformer.selected() ? this._selectedTransformer.selected().getCanEdit() : false,
                activeConnection: this._selectedTransformer.selected() ? this._selectedTransformer.selected().connected() : false
	    };
	},
	
	_render: function($html) {
            $html.find("div[class='apt-terminal']").terminal( function(command){},
            {
                name: 'test',
                greeting: false
            });
	},
	
	_show: function() {
            if (this.hasRendered()) {
                this.render();
            }
	},
	
	_hide: function() {
            this._clearUpdateTimeout();
	},
	
	_remove: function() {

	},
        
        runAptOfflineUpdate: function(){
            var url = EmPower.Util.Url.apiBuilder("transformers", this._selectedTransformer.selectedId(), "update", "run-apt-offline-update" );
            var self = this;
            
            EmPower.Dialog.SimpleDialog.Loading.open("Please do not navigate away from this page until the update is complete", 0, 6000);
            
            $.ajax({
		url: url,
		type: 'POST',
		success: function(data) {
                    if (!self._timeout) {
                        self._timeout = 500;
                    }
                    self._startFetchTimeout();
		},
		error: function() {
		    
		}
	    });
        },
        
        runAptUpdate: function(){
            var url = EmPower.Util.Url.apiBuilder("transformers", this._selectedTransformer.selectedId(), "update", "run-apt-update" );
            var self = this;
            
            EmPower.Dialog.SimpleDialog.Loading.open("Please do not navigate away from this page until the update is complete", 0, 6000);
            
            $.ajax({
		url: url,
		type: 'POST',
		success: function(data) {
                    if (!self._timeout) {
                        self._timeout = 500;
                    }
	    
                    self._startFetchTimeout();
		},
		error: function() {
		    
		}
	    });
        },
        
        upgradeRCTU: function(){
            var url = EmPower.Util.Url.apiBuilder("transformers", this._selectedTransformer.selectedId(), "update", "upgrade-rctu" );
            var self = this;
            
            EmPower.Dialog.SimpleDialog.Loading.open("Please do not navigate away from this page until the update is complete", 0, 6000);
            
            $.ajax({
		url: url,
		type: 'POST',
		success: function(data) {
                    if (!self._timeout) {
                        self._timeout = 500;
                    }
	    
                    self._startFetchTimeout();
		},
		error: function() {
		    
		}
	    });
        },
        
        upgradeKernel: function(){
            var url = EmPower.Util.Url.apiBuilder("transformers", this._selectedTransformer.selectedId(), "update", "upgrade-kernel" );
            var self = this;
            
            EmPower.Dialog.SimpleDialog.Loading.open("Please do not navigate away from this page until the update is complete", 0, 6000);
            
            $.ajax({
		url: url,
		type: 'POST',
		success: function(data) {
                    if (!self._timeout) {
                        self._timeout = 500;
                    }
	    
                    self._startFetchTimeout();
		},
		error: function() {
		    
		}
	    });
        },
        
        _clearUpdateTimeout: function() {
	    if (this._updateTimeoutId) {
		clearTimeout(this._updateTimeoutId);
	    }
	},
	
	_startFetchTimeout: function() {
	    var self = this;
	    
	    self._clearUpdateTimeout();
	    
	    self._updateTimeoutId = window.setTimeout(_.bind(self._startFetchTimeout, self), self._timeout);
            self._updateTextArea();
            self._updateState();
	},
        
        _updateTextArea: function(){
            var url = EmPower.Util.Url.apiBuilder("transformers", this._selectedTransformer.selectedId(), "update", "output" );
            var self = this;
            var data;
            
            if( this.getAllOutput ){
                data = {
                    offset: 0
                };
                this.getAllOutput = false;
            }
            
            $.ajax({
		url: url,
		type: 'GET',
                data: data,
		success: function(data, status, jqXHR ) {
                    if( jqXHR.status === 204 ){
                        //no content, don't try to parse
                        return;
                    }
                    
                    data = data.replace(/(\^M\^M)/g, "\n")
                            .replace("\r", "\n")
                            .replace( /\n\n/g, "\n" );
                    var splitData = data.split( "\n" );
                   

                    for( var i =-0; i < splitData.length  ; i++ )
                    {
                        var colors = $.terminal.from_ansi( splitData[i]  );
                        $.terminal.active().echo( colors );
                    }
                    
             
		},
		error: function() {
		    //self.$html.find("div[data-section='apt-output'] pre").append( "Sorry, an error occurred when getting data" );
                    self._clearUpdateTimeout();
		}
	    });
        },
        
        _updateState: function(){
            var url = EmPower.Util.Url.apiBuilder("transformers", this._selectedTransformer.selectedId(), "update", "apt-offline-status" );
            var self = this;
            
            
            $.ajax({
		url: url,
		type: 'GET',
		success: function(data, status, jqXHR ) {
                    self.$html.find( "div[class='apt-offline-status'] div" ).text( data.status );
             
		},
		error: function() {
		    //self.$html.find("div[data-section='apt-output'] pre").append( "Sorry, an error occurred when getting data" );
                    self._clearUpdateTimeout();
		}
	    });
        }
        
    });
    
})(jQuery);
