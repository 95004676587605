
EmPower.View.ContentFrame = (function($, undefined) {
    
    return EmPower.View.AbstractView.extend({
	viewName: "Content Frame",
	
	_events: function() {
	    return {
		"click .navbar-collapse.in a": function(event){
		    if ($(event.target).is('a') && ($(event.target).attr('class') !== 'dropdown-toggle') ) {
			this.$el.find('.navbar-collapse').first().collapse('hide');
		    }
		},
		
		"click #empower-section-nav a": function(event) {
		    
		    if (event) {
			
			event.preventDefault();
			
			var dataAction = $(event.currentTarget).attr('data-action');
			
			var self = this;
			
			switch(dataAction) {
			    case 'logout':
				var authApi = Synexxus.Auth.logout();
				authApi.always(function() {
				    self._applicationRouter.showLogin();
				});
				break;
			    case this._applicationRouter.paths.map():
				this._applicationRouter.getMapModule().showMap();
				break;
			    case this._applicationRouter.paths.transformers():
				this._applicationRouter.getTransformersModule().showTransformerManagement();
				break;
			    case this._applicationRouter.paths.settings():
				this._applicationRouter.getSettingsModule().showAccountManagement();
				break;
			    case this._applicationRouter.paths.about():
				this._applicationRouter.getAboutModule().showAbout();
				break;
			}
		    }
		}
	    };
	},
	
	_initialize: function() {
	    
	    this._applicationRouter = this._session.applicationRouter();
	    
	    this._selectedSection = this._session.selectedSection();
	    
	    this._selectedSectionView = this._session.selectedSectionView();
	},
	
	_setupListeners: function() {
	    
	    var self = this;
	    
	    self.listenTo(self._selectedSectionView, 'change', function() {
		self.renderDebounced();
	    });
	    
	    self.listenTo(self._selectedSection, 'change', function() {
		self.renderDebounced();
	    });
	},

	_htmlTemplate: function() {
	    return EmPower.Templates['content-frame/content_frame'];
	},
	
	_htmlData: function() {
	    return {
		mapModule: this._applicationRouter.paths.map(),
		transformersModule: this._applicationRouter.paths.transformers(),
		settingsModule: this._applicationRouter.paths.settings(),
		aboutModule: this._applicationRouter.paths.about()
	    };
	},
	
	_render: function($html) {
	    
	    var $fetcherInfo = $html.find("li[data-section='fetcher-info']");
	    if (!this._fetcherInfo) {
		this._fetcherInfo = new EmPower.View.FetcherInfo({
		    session: this._session
		});
		this._fetcherInfo.render($fetcherInfo);
	    } else {
		$fetcherInfo.replaceWith(this._fetcherInfo.$el);
	    }
	    
	    if (!Synexxus.Auth.assureSession()) {
		$html.filter("#empower-section-nav").find("li").addClass("disabled");
		$html.filter("#empower-section-nav").find("[data-action='logout']").parent("li").hide();
	    }
	    
	    this._renderSelectedSection();
	    
	    this._renderSelectedSectionView();
	},
	
	_renderSelectedSection: function() {
	    
	    var $nav = this.$html.filter("#empower-section-nav");
	    
	    $nav.find("li.active").removeClass("active");
		
	    if (this._selectedSection.selected()) {

		var tabDataAction = this._selectedSection.selected().getRootPath();
		if (tabDataAction) {
		    $nav.find("a[data-action='" + tabDataAction + "']").closest("li").addClass("active");
		}
	    }

	    if (Synexxus.Auth.assureSession()) {
		$nav.find("li").removeClass("disabled");
		$nav.find("[data-action='logout']").parent("li").show();
	    } else {
		$nav.find("li").addClass("disabled");
		$nav.find("[data-action='logout']").parent("li").hide();
	    }
	},
	
	_renderSelectedSectionView: function() {
	    
	    var selectedView = this._selectedSectionView.selected();
	    var previousView = this._previousView;

	    this._logger.warn("Rendering selected section view and hiding previous view.", selectedView, previousView);
	    
	    if (selectedView !== previousView) {
		if (previousView && previousView.hasRendered() && previousView.isVisible()) {
		    previousView.hide();
		}
	    }

	    if (selectedView) {
		
		var $contentContainer = this.$html.filter("div#empower-section-container");
		if (this.$el.find("div#empower-section-container").length !== 0) {
		    $contentContainer.replaceWith(this.$el.find("div#empower-section-container"));
		}
		$contentContainer = this.$html.filter("div#empower-section-container");
		
		if (!selectedView.hasRendered()) {
		    var $container = $("<div></div>", {
			'id': selectedView.cid,
			'class': 'section-content-container'
		    });

		    selectedView.render($container);
		}
		
		if ($contentContainer.find("div#" + selectedView.cid).length === 0) {
		    this._logger.info("Appending selected view.");
		    $contentContainer.append(selectedView.$el);
		}
		
		if (!selectedView.isVisible()) {
		    this._logger.info("Showing selected view.");
		    selectedView.show();
		}
		
		this._previousView = selectedView;
	    }
	},
	
	_show: function() {
	    if (this._fetcherInfo) {
		this._fetcherInfo.show();
	    }
	    if (this._previousView) {
		this._previousView.show();
	    }
	},
	
	_hide: function() {
	    if (this._fetcherInfo) {
		this._fetcherInfo.hide();
	    }
	    if (this._previousView) {
		this._previousView.hide();
	    }
	},
	
	_remove: function() {
	    if (this._fetcherInfo) {
		this._fetcherInfo.remove();
	    }
	    if (this._previousView) {
		this._previousView.remove();
	    }
	}
    });
    
})(jQuery);
