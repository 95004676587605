EmPower.Model.AccessPanel = (function($, undefined) {
    
    return EmPower.Model.AbstractDeviceEventModel.extend({
	    
	modelName: 'Access Panel',

	_initialize: function() {
	},

	_parse: function(response, options) {
	    return response;
	},
	
	credentialId: function(value) {
	    return this._field('credentialId', value);
	},
	
	cardNumber: function(value) {
	    return this._field('cardNumber', value);
	},
	
	facilityNumber: function(value) {
	    return this._field('facilityNumber', value);
	},
	
	accessGranted: function(value) {
	    return this._field('accessGranted', value);
	},
	
	firstName: function(value) {
	    return this._field('firstName', value);
	},
	
	lastName: function(value) {
	    return this._field('lastName', value);
	},
	
	userId: function(value) {
	    return this._field('userId', value);
	}
    });
    
})(jQuery);
