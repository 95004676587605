EmPower.View.ConditionIndexList = (function($, undefined) {

    // private namespace
    var that = {};
    
    that.conditionIndexGraphs = {
        DGA: {
            title: "Dissolved Gas Analysis Condition Index",
            view: function() {
                return EmPower.View.DgaConditionIndexGraph;
            }
        },
        BH: {
            title: "Bushing Condition Index",
            view: function() {
                return EmPower.View.BushingConditionIndexGraph;
            }
        },
        TO: {
            title: "Top Oil Condition Index",
            view: function() {
                return EmPower.View.TopOilConditionIndexGraph;
            }
        },
        LOL: {
            title: "Loss of Life Condition Index",
            view: function() {
                return EmPower.View.LossOfLifeConditionIndexGraph;
            }
        },
        PUL: {
            title: "Load Condition Index",
            view: function() {
                return EmPower.View.LoadConditionIndexGraph;
            }
        },
        HS: {
            title: "Hot Spot Condition Index",
            view: function() {
                return EmPower.View.HotSpotConditionIndexGraph;
            }
        },
        BT: {
            title: "Bubbling Temperature Condition Index",
            view: function() {
                return EmPower.View.BubblingConditionIndexGraph;
            }
        },
        OBP: {
            title: "Oil Breakdown Probability Condition Index",
            view: function() {
                return EmPower.View.OilBreakdownConditionIndexGraph;
            }
        },
        BBP: {
            title: "Board Breakdown Probability Condition Index",
            view: function() {
                return EmPower.View.BoardBreakdownConditionIndexGraph;
            }
        } 
        
    };

    return EmPower.View.AbstractCollectionView.extend({
	viewName: "Condition Index List",
	
	_events: function() {
            return {
                'click a[data-action="ci-chart"]': function(event) {
                    
                    var graph = _.get(that.conditionIndexGraphs, $(event.currentTarget).attr('data-ci-id'));
                    
                    EmPower.Dialog.Graph.open(this._session, graph.view(), graph.title);
                }
            };
	},
	
	_initialize: function() {
	    this._applicationRouter = this._session.applicationRouter();
            this._selectedTransformer = this._session.transformerSession().selectedTransformer();
	},
	
	_setupListeners: function() {
            
            var self = this;
            
            self.listenTo(self._selectedTransformer, 'change', function() {
                self.renderDebounced();
            });
	},
	
	_htmlTemplate: function() {
	    return EmPower.Templates['transformer/health/condition_index_list'];
	},
	
	_htmlData: function() {
	},
	
	_getCollectionContainer: function($html) {
	    return $html.filter("div[data-section='condition-index-list']");
	},
	
	_createModelView: function(model) {
	    return new EmPower.View.ConditionIndexRow({
		session: this._session,
		conditionIndexModel: model,
                conditionIndexGraphs: that.conditionIndexGraphs
	    });
	},
	
	_getLoadingCollectionMessage: function() {
	    return "Loading condition indexes, please wait...";
	},
	
	_getEmptyCollectionMessage: function() {
	    return "No condition indexes have data.";
	},
	
	_render: function() {
            if (!this.getCollection().isFetched()) {
                this.getCollection().fetch();
            }
	},
	
	_show: function() {
	},
	
	_hide: function() {
	},
	
	_remove: function() {
	}
    });
    
})(jQuery);
