(function(DateRange, $, undefined) {
    
    // private namespace
    var that = {};
    
    that.html = [
	'<div class="input-daterange input-group" data-action="datepicker">',
	'  <input type="text" class="input-sm form-control" name="start" placeholder="YYYY-MM-DD" value="<%= fromDateFormatted %>"/>',
	'  <span class="input-group-addon">to</span>',
	'  <input type="text" class="input-sm form-control" name="end" placeholder="YYYY-MM-DD" value="<%= toDateFormatted %>"/>',
	'</div>'
    ];
    
    DateRange.open = function(dateRange, dialog) {
	
	dialog = dialog || new BootstrapDialog({
	    title: 'Event date range'
	});
	
	if (!that.htmlCompiled) {
	    that.htmlCompiled = _.template(that.html.join(''));
	}
	
	var data = {
	    fromDateFormatted: dateRange ? dateRange.fromDateFormattedLocal() : null,
	    toDateFormatted: dateRange ? dateRange.toDateFormattedLocal() : null
	};
	
	var $html = $(that.htmlCompiled(data));
	
	$html.filter("div[data-action='datepicker']").datepicker({
	    format: "yyyy-mm-dd",
	    todayBtn: "linked",
	    orientation: "bottom auto",
	    autoclose: true,
	    todayHighlight: true
	});
	
	dialog.setMessage($html);
	
	var onApply = function(dialog) {
	    
	    var fromDate = $html.find('input[name="start"]').val();
	    if (dateRange && _.isFunction(dateRange.fromDate)) {
		dateRange.fromDate(fromDate);
	    }
	    
	    var toDate = $html.find('input[name="end"]').val();
	    if (dateRange && _.isFunction(dateRange.toDate)) {
		dateRange.toDate(toDate);
	    }
	    
	    dialog.close();
	};
	
	var applyButton = {
	    label: 'Apply',
	    cssClass: 'btn-primary',
	    action: onApply
	};
	
	var onCancel = function(dialog) {
	    dialog.close();
	};
	
	var cancelButton = {
	    label: 'Cancel',
	    cssClass: 'btn-default left',
	    action: onCancel
	};
	
	dialog.setButtons([cancelButton, applyButton]);
	
	return dialog.open();
    };
    
})(EmPower.Dialog.DateRange = EmPower.Dialog.DateRange || {}, jQuery);
