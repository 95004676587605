(function (Transformer, $, undefined) {

    // private namespace
    var that = {};

    that.enterTransformerIdHtml = [
        '<form onsubmit="return false;">',
        '  <div class="form-group">',
        '    <label>Transformer ID:</label>',
        '    <input name="transformerId" class="form-control" placeholder="Enter transformer ID" required/>',
        '  </div>',
        '</form>'
    ];

    that.transformerIdDialog = function (session, dialog) {

        dialog = dialog || new BootstrapDialog({
            title: 'Add Transformer'
        });

        if (!that.enterTransformerIdHtmlCompiled) {
            that.enterTransformerIdHtmlCompiled = _.template(that.enterTransformerIdHtml.join(''));
        }

        var $html = $(that.enterTransformerIdHtmlCompiled());

        dialog.setMessage($html);

        var $form = $html,
                $transformerId = $form.find('input[name="transformerId"]');

        $form.validate({
            rules: {
                transformerId: {
                    required: true,
                    digits: true
                }
            },
            messages: {
                transformerId: {
                    required: "Sorry, please enter transformer ID.",
                    digits: "Sorry, transformer ID must be a number."
                }
            },
            tooltip_options: {
                _all_: {
                    placement: 'bottom'
                }
            }
        });

        var onNextStep = function (dialog) {

            if ($form.valid()) {

                that.uploadConfigurationDialog(session, $.trim($transformerId.val()), dialog, true);
            }
        };

        var nextButton = {
            label: 'Next',
            cssClass: 'btn-primary',
            action: onNextStep
        };

        var onCancel = function (dialog) {
            dialog.close();
        };

        var cancelButton = {
            label: 'Cancel',
            cssClass: 'btn-default left',
            action: onCancel
        };

        dialog.setButtons([cancelButton, nextButton]);

        return dialog.open();
    };

    that.uploadConfigurationHtml = [
        '<form onsubmit="return false;">',
        '  <div data-action="errorMessage"></div>',
        '  <div class="form-group">',
        '    <label>Transformer name:</label>',
        '    <input name="transformerName" class="form-control" placeholder="Enter transformer\'s name" value="<%-name%>" required/>',
        '  </div>',
        '  <div class="form-group">',
        '    <label>Transformer phone number:</label>',
        '    <input name="transformerPhoneNumber" class="form-control" placeholder="x-xxx-xxx-xxxx" value="<%-phoneNumber%>" required/>',
        '  </div>',
        '  <label>Upload the transformer configuration file:</label>',
        '  <div data-action="dropzone" class="dropzone" empower-section="myAwesomeDropzone">',
        '    <div class="dz-message">',
        '      <span>Drop configuration here or click to upload.</span>',
        '    </div>',
        '  </div>',
        '</form>'
    ];

    /*
     '  <div id="allowOverwriteSection" style="display: none">',
     '    <input type="checkbox" name="allowOverwrite" id="allowOverwrite" style="margin-right: 5px;"><label> Overwrite the existing transformer with this RCTU serial number</label>',
     '  </div>',
     */

    that.uploadConfigurationDialog = function (session, transformerId, dialog, isNew, phoneNumber, name) {

        dialog = dialog || new BootstrapDialog({
            title: 'Upload Transformer Configuration'
        });

        if (!that.uploadConfigurationHtmlCompiled) {
            that.uploadConfigurationHtmlCompiled = _.template(that.uploadConfigurationHtml.join(''));
        }

        var $html = $(that.uploadConfigurationHtmlCompiled({
            name: name,
            phoneNumber: phoneNumber
        }));

        dialog.setMessage($html);

        var dropzone = that.createDropzone($html.find("div[data-action='dropzone']"), transformerId, null, false);

        dropzone.on("sending", function (file, xhr, data) {

            data.append("isNew", isNew ? true : false);

            data.append("phoneNumber", $html.find("input[name=transformerPhoneNumber]").val());

            data.append("transformerName", $html.find("input[name=transformerName]").val());

            EmPower.Dialog.SimpleDialog.Loading.open('Processing configuration...');

            dialog.getMessage().find("div[data-action='errorMessage']").empty();
        });

        dropzone.on("error", function (file, error) {

            dropzone.removeFile(file);

            var failHtml = [
                '<div class="alert alert-danger">',
                '  <%-errorMessage%>',
                '</div>'
            ];

            var errorMessage;
            if (_.has(error, "message") && _.isString(error.message)) {
                var responseMessage = error.message.charAt(0).toLowerCase()
                        + error.message.slice(1);

                if (responseMessage && _.isString(responseMessage)) {
                    errorMessage = "Sorry, " + responseMessage + (_.endsWith(responseMessage, ".") ? "" : ".");

                    if (errorMessage.includes("Sorry, the supplied RCTU serial number is already in use on Transformer ID:")) {
                        errorMessage += "\n If you wish to overwrite the existing transformer, please resubmit the configuration file";
                        dropzone.destroy();
                        dropzone = that.createDropzone($html.find("div[data-action='dropzone']"), transformerId, null, true);
                    }
                }
            } else if (_.isString(error)) {
                errorMessage = error;
            } else {
                errorMessage = "Failed to upload configuration file, please try again.";
            }

            var data = {
                errorMessage: errorMessage
            };

            var htmlCompiled = _.template(failHtml.join(''));

            var $errorHtml = $(htmlCompiled(data));

            dialog.getMessage().find("div[data-action='errorMessage']").html($errorHtml);

            session.collectionFetcher().fetchAllDebounce();

            EmPower.Dialog.SimpleDialog.Loading.close();

//            if (errorMessage.includes("Sorry, the supplied RCTU serial number is already in use on Transformer ID:")) {
//                document.getElementById("allowOverwriteSection").style.display = "block";
//                
//            }

        });

        dropzone.on("success", function (file) {

            dropzone.removeFile(file);

            session.collectionFetcher().fetchAllDebounce();

            $.notify({
                message: 'Configuration was successfully processed for <strong>Transformer ID ' + transformerId + '</strong>.'
            }, {
                type: 'success',
                placement: {
                    from: 'top',
                    align: 'center'
                }
            });

            EmPower.Dialog.SimpleDialog.Loading.close();

            dialog.close();
        });

        var onCancel = function (dialog) {
            dialog.close();
        };

        var cancelButton = {
            label: 'Cancel',
            cssClass: 'btn-default left',
            action: onCancel
        };

        dialog.setButtons([cancelButton]);

        return dialog.open();
    };

    that.createDropzone = function ($element, transformerId, url, allowOverwrite) {

        url = (url || ["./api/1/transformers/config/upload/", transformerId, "?allowOverwrite=", allowOverwrite].join(""));

        var dropzone = new Dropzone($element.get(0), {
            url: url,
            acceptedFiles: "text/xml"
        });

        return dropzone;
    };

    that.transformerDisableDialog = {};

    that.transformerDisableDialog.html = [
        '<div>',
        '  Are you sure you wish to disable <strong><%-transformerName()%></strong>?',
        '<div>'
    ];

    that.transformerDisableDialog.open = function (transformerModel, dialog) {

        dialog = dialog || new BootstrapDialog({
            title: 'Disable <strong>' + transformerModel.transformerName() + "</strong>",
            size: BootstrapDialog.SIZE_SMALL
        });

        if (!that.transformerDisableDialog.htmlCompiled) {
            that.transformerDisableDialog.htmlCompiled = _.template(that.transformerDisableDialog.html.join(''));
        }

        var $html = $(that.transformerDisableDialog.htmlCompiled(transformerModel));

        dialog.setMessage($html);

        var onNextStep = function (dialog) {

            EmPower.Dialog.SimpleDialog.Loading.open('Disabling Transformer...');

            transformerModel.isEnabled(0);

            var apiTransformer = transformerModel.save();

            apiTransformer.done(function (apiData) {

                EmPower.Dialog.SimpleDialog.Loading.close();

                that.transformerDisableDialog.showSuccess(transformerModel, dialog);
            });
        };

        var nextButton = {
            label: 'Disable',
            cssClass: 'btn-danger',
            action: onNextStep
        };

        var onCancel = function (dialog) {
            dialog.close();
        };

        var cancelButton = {
            label: 'Cancel',
            cssClass: 'btn-default left',
            action: onCancel
        };

        dialog.setButtons([cancelButton, nextButton]);
        dialog.setType(BootstrapDialog.TYPE_DANGER);
        dialog.setSize(BootstrapDialog.SIZE_SMALL);

        return dialog.open();
    };

    that.transformerDisableDialog.showSuccess = function (transformerModel, dialog) {

        $.notify({
            message: "Transformer <strong>" + transformerModel.transformerName() + "</strong> was disabled."
        }, {
            type: 'success',
            placement: {
                from: 'top',
                align: 'center'
            }
        });

        dialog.close();

        return dialog;
    };

    that.transformerEnableDialog = {};

    that.transformerEnableDialog.html = [
        '<div>',
        '  Are you sure you wish to enable <strong><%-transformerName()%></strong>?',
        '<div>'
    ];

    that.transformerEnableDialog.open = function (transformerModel, dialog) {

        dialog = dialog || new BootstrapDialog({
            title: 'Enable <strong>' + transformerModel.transformerName() + "</strong>",
            size: BootstrapDialog.SIZE_SMALL
        });

        if (!that.transformerEnableDialog.htmlCompiled) {
            that.transformerEnableDialog.htmlCompiled = _.template(that.transformerEnableDialog.html.join(''));
        }

        var $html = $(that.transformerEnableDialog.htmlCompiled(transformerModel));

        dialog.setMessage($html);

        var onNextStep = function (dialog) {

            EmPower.Dialog.SimpleDialog.Loading.open('Enabling Transformer...');

            transformerModel.isEnabled(1);

            var apiTransformer = transformerModel.save();

            apiTransformer.done(function (apiData) {

                EmPower.Dialog.SimpleDialog.Loading.close();

                that.transformerEnableDialog.showSuccess(transformerModel, dialog);
            });
        };

        var nextButton = {
            label: 'Enable',
            cssClass: 'btn-success',
            action: onNextStep
        };

        var onCancel = function (dialog) {
            dialog.close();
        };

        var cancelButton = {
            label: 'Cancel',
            cssClass: 'btn-default left',
            action: onCancel
        };

        dialog.setButtons([cancelButton, nextButton]);
        dialog.setType(BootstrapDialog.TYPE_SUCCESS);
        dialog.setSize(BootstrapDialog.SIZE_SMALL);

        return dialog.open();
    };

    that.transformerEnableDialog.showSuccess = function (transformerModel, dialog) {

        $.notify({
            message: "Transformer <strong>" + transformerModel.transformerName() + "</strong> was enabled."
        }, {
            type: 'success',
            placement: {
                from: 'top',
                align: 'center'
            }
        });

        dialog.close();

        return dialog;
    };

    Transformer.uploadConfiguration = function (session, transformerId, transformerPhoneNumber, transformerName) {

        var self = this;

        if (transformerId !== undefined && transformerId !== null) {

            that.uploadConfigurationDialog(session, transformerId, null, false, transformerPhoneNumber, transformerName);
        } else {

            that.transformerIdDialog(session);
        }
    };

    Transformer.disable = function (transformerModel) {

        if (transformerModel && transformerModel.id) {

            that.transformerDisableDialog.open(transformerModel);
        }
    };

    Transformer.enable = function (transformerModel) {

        if (transformerModel && transformerModel.id) {

            that.transformerEnableDialog.open(transformerModel);
        }
    };

    that.updatePhoneHtml = [
        '<form onsubmit="return false;">',
        '  <div data-action="errorMessage"></div>',
        '  <div class="form-group">',
        '    <label>Phone Number:</label>',
        '    <input name="phoneNumber" class="form-control" id="phone-input" placeholder="Enter a valid phone number" value="<%-phoneNumber%>"/>',
        '  </div>',
        '</form>',
        '<form onsubmit="return false;">',
        '  <div data-action="errorMessage"></div>',
        '  <div class="form-group">',
        '    <label>ICCID:</label>',
        '    <input name="iccid" class="form-control" id="iccid-input" placeholder="Enter a valid ICCID" value="<%-iccid%>"/>',
        '  </div>',
        '</form>'
    ];

    that.updatePhoneDialog = function (session, transformerId, dialog, phoneNumber, iccid) {

        dialog = dialog || new BootstrapDialog({
            title: 'Update Phone Number & ICCID'
        });

        if (!that.updatePhoneHtmlCompiled) {
            that.updatePhoneHtmlCompiled = _.template(that.updatePhoneHtml.join(''));
        }

        var $html = $(that.updatePhoneHtmlCompiled({
            phoneNumber: phoneNumber,
            iccid: iccid
        }));

        dialog.setMessage($html);

        var onCancel = function (dialog) {
            dialog.close();
        };

        var cancelButton = {
            label: 'Cancel',
            cssClass: 'btn-default left',
            action: onCancel
        };

        var onSubmit = function (dialog) {
            var phoneNumber = document.getElementById('phone-input').value;
            var iccid = document.getElementById('iccid-input').value;

            if (phoneNumber && phoneNumber !== "") {
                var phoneData = {
                    phoneNumber: phoneNumber
                };

                var phoneUrl = EmPower.Util.Url.apiBuilder("transformers", transformerId, "rctu", "update-phone");
                $.ajax({
                    url: phoneUrl,
                    type: 'POST',
                    data: JSON.stringify(phoneData),
                    contentType: "application/json",
                    success: function (data) {
                        $.notify({
                            message: 'Successfully updated phone number'
                        }, {
                            type: 'success',
                            placement: {
                                from: 'top',
                                align: 'center'
                            }
                        });

                    },
                    error: function () {
                        console.log('err');
                    }
                });

            }

            if (iccid && iccid !== "") {
                var iccidData = {
                    iccid: iccid
                };

                var iccidUrl = EmPower.Util.Url.apiBuilder("transformers", transformerId, "rctu", "update-iccid");
                $.ajax({
                    url: iccidUrl,
                    type: 'POST',
                    data: JSON.stringify(iccidData),
                    contentType: "application/json",
                    success: function (data) {
                        $.notify({
                            message: 'Successfully updated ICCID'
                        }, {
                            type: 'success',
                            placement: {
                                from: 'top',
                                align: 'center'
                            }
                        });

                    },
                    error: function () {
                        console.log('err');
                    }
                });

            }

            dialog.close();
        };

        var submitButton = {
            label: 'Submit',
            cssClass: 'btn-default right',
            action: onSubmit
        };

        dialog.setButtons([cancelButton, submitButton]);

        return dialog.open();
    };

    Transformer.updatePhone = function (session, transformerId) {

        if (transformerId !== undefined && transformerId !== null) {

            that.updatePhoneDialog(session, transformerId, null, '', '');

        }
    };

})(EmPower.Dialog.Transformer = EmPower.Dialog.Transformer || {}, jQuery);