/* jshint bitwise: false */

EmPower.Model.Session = (function($, undefined) {
    
    return EmPower.Model.AbstractSession.extend({
		whatAmI: function(){return "EmPower.Model.Session - Also see modelName";},
	    
	modelName: 'Session',
	
	urlRoot: EmPower.Util.Url.apiBuilder('session'),

	_initialize: function() {
	    
	    if (!this.applicationRouter()) {
		this._logger.throwError("Session was not created with a specific Application Router instance.");
	    }
	    
            this.transformerSession(EmPower.Model.TransformerSession);
            
            this.settingSession(EmPower.Model.SettingSession);
            
            
	    this.selectedSection(EmPower.Model.SelectedObject);
	    
	    this.selectedSectionView(EmPower.Model.SelectedObject);
	    
	    
	    this.collectionFetcher(EmPower.Util.instanceFactory(EmPower.Collection.Fetcher));

	    
	    this.dateRange(EmPower.Util.instanceFactory(EmPower.Model.DateRange));
	},
	
	applicationRouter: function(value) {
	    return this._field('applicationRouter', value);
	},
	
	_reset: function(options) {
	    
	    this.selectedSection().selected(options.selectedSection ? options.selectedSection : null);
	    this.selectedSectionView().selected(options.selectedSectionView ? options.selectedSectionView : null);
	},
	
        transformerSession: function(value) {
            return this._instanceFactoryField('transformerSession', value, [{
                session: this
            }]);
        },
        
        settingSession: function(value) {
            return this._instanceFactoryField('settingSession', value, [{
                session: this
            }]);
        },
        
	selectedSection: function(value) {
	    return this._instanceFactoryField('selectedSection', value);
	},
	selectedSectionView: function(value) {
	    return this._instanceFactoryField('selectedSectionView', value);
	},
	
	collectionFetcher: function(value) {
	    return this._instanceFactoryField('collectionFetcher', value, [45000]);
	},
	
	dateRange: function(value) {
	    return this._instanceFactoryField('dateRange', value, [{session: this}]);
	}
    });
    
})(jQuery);
