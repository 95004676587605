EmPower.View.Opm = (function ($, undefined) {

    return EmPower.View.AbstractView.extend({

        viewName: "Opm",

        _events: function () {
            return {
                "click [data-action=check-files]": function (event) {
                    event.preventDefault();
                    this._checkForFiles();
                }
//                "click [data-action=bubbtest]": function (event) {
//                    event.preventDefault();
//                    this._testBubbPost();
//                }
            };
        },

        _initialize: function () { 
            this._selectedTransformer = this._session.transformerSession().selectedTransformer();
            this._transformerCollection = this._session.transformerSession().transformerCollection();
            this._opmLogCollection = new EmPower.Collection.Opm(null, {session: this._session, selectedObject: this._selectedTransformer});
        },

        _setupListeners: function () {
            var self = this;

            self.listenTo(self._opmLogCollection, 'update reset', function () {
            });
            self.listenTo(self._session, 'reset', function () {
                this._opmLogCollection.reset();
            });
            self.listenTo(self._transformerCollection, 'update reset', function() {
                this.renderDebounced();
            });
            self.listenTo(self._selectedTransformer, 'change', function() {
                this.renderDebounced();
            });
        },

        _htmlTemplate: function () {
            return EmPower.Templates['transformer/opm/transformer_opm'];
        },

        _htmlData: function () {
            return {
                transformerId: this._selectedTransformer.selectedId()
            };

        },

        _render: function ($html) {

            if (this._selectedTransformer.selected()) {
            
                var selectedTransformerId = this._selectedTransformer.selected().transformerId();
                var $opmStatus = $html.find("div[data-section='opm-status']").empty();
                $.ajax({
                    url: ['./api/1/transformers/', selectedTransformerId, '/opm/status'].join(""),
                    success: function (data) {
                        $opmStatus.prepend("<ul class='opm-status'>");
                        var $statusUl = $html.find("ul[class='opm-status']").empty();
                        $statusUl.append("<li><p>Event ID</p><span>" + (data.eventId === null ? 'No Data' : data.eventId)  + "</span></li>");
                        $statusUl.append("<li><p>OPM Event ID</p><span>" + (data.opmEventID === null ? 'No Data' : data.opmEventID) + "</span></li>");
                        $statusUl.append("<li><p>Message</p><span>" + (data.message === null ? 'No Data' : data.message) + "</span></li>");
                        $statusUl.append("<li><p>Status Level Name</p><span>" + (data.statusLevelName === null ? 'No Data' : data.statusLevelName) + "</span></li>");
                        $statusUl.append("<li><p>Timestamp</p><span>" + (data.timestamp === null ? 'No Data' : data.timestamp) + "</span></li>");
                        $opmStatus.append("</ul>");
                    },
                    error: function () {
                        console.log("error");
                    },
                    type: 'GET'
                });
            }

            var $opmLog = $html.find("div[data-section='opm-log']").empty();
            if (!this._opmLog) {

                this._opmLog = new bbGrid.View({
                    rows: 15,
                    container: $opmLog,
                    collection: this._opmLogCollection,
                    colModel: [{
                            title: 'Timestamp',
                            name: 'timestamp',
                            index: true,
                            sorttype: 'string'
                        }, {
                            title: 'OPM Event ID',
                            name: 'opmEventID',
                            index: true,
                            sorttype: 'number'
                        }, {
                            title: 'Message',
                            name: 'message',
                            index: true,
                            sorttype: 'string'
                        }, {
                            title: 'Log Level Name',
                            name: 'logLevelName',
                            index: true,
                            sorttype: 'string'
                        }, {
                            title: 'Time Recorded',
                            name: 'timeRecorded',
                            index: true,
                            sorttype: 'string'
                        }, {
                            title: 'Transformer ID',
                            name: 'transformerId',
                            index: true,
                            sorttype: 'number'
                        }]
                });
            } else {
                $opmLog.html(this._opmLog.$el);
            }

        },
        
//        _testBubbPost: function() {
//            var obj = {"acceleratedAgingFactor":1.0,"acceleratedAgingFactorTotal":1.0,"ambientTemp":1.0,"bubblingTemp":1.0,"description":null,"deviceId":null,"eqAgingFactor":1.0,"eqAgingFactorLife":1.0,"id":3,"isEnabled":null,"lifeLoss":1.0,"lifeLossDay":1.0,"load":1.0,"minimumLoad":1.0,"tempDuration":1.0,"timeOverTemp":1.0,"timeOverTempTopOil":1.0,"timeOverTempWindingHs":1.0,"timeRecorded":"2017-12-22T16:25:53Z","timestamp":"2017-12-13T07:45:49Z","topOilTempInit":1.0,"topOilTempRiseInit":1.0,"topOilTempRiseUlt":1.0,"topOilTempUlt":1.0,"topOilTimeConst":1.0,"transformerDeviceId":null,"transformerId":30,"ultLoad":1.0,"ultLoadDiff":1.0,"ultLoadTopOil":1.0,"ultLoadWindingHs":1.0,"windingHsTempInit":1.0,"windingHsTempRiseInit":1.0,"windingHsTempRiseUlt":1.0,"windingHsTempUlt":1.0};
//            var obj = { "bubblingTemp": 11 };
//            var selectedTransformerId = this._selectedTransformer.selected().transformerId();
//            $.ajax({
//                url: ['./api/1/transformers/', selectedTransformerId, "/opm/bubblingtemp"].join(""),
//                type: 'POST',
//                dataType: 'json',
//                contentType: 'application/json; charset=utf-8',
//                data: JSON.stringify(obj),
//                success: function(data) {
//                    
//                },
//                error: function() {
//                    console.log("Error");
//                }
//            });
//        },
        
        // finds files for download and creates the split dropdown for downloading them
        _checkForFiles: function () {
            var selectedTransformerId = this._selectedTransformer.selected().transformerId();
            var $opmLogFiles = $("#file-dropdown");
            $.ajax({
                url: ['./api/1/transformers/', selectedTransformerId, '/opm/log/file'].join(""),
                success: function (data) {
                    if (data.length !== 0) {
                        data.forEach(function (file) {
                            var fileName = file.fileName;
                            var path = file.url;
                            // check if the current item has already been added to the list
                            var itemFound = false;
                            $('#file-dropdown li').each(function () {
                                if ($(this).text() === fileName) {
                                    itemFound = true;
                                }
                            });
                            // if the item is not already added to the list, create the item with a download link and add it
                            if (!itemFound) {
//                                var link = $.ajax({
//                                    url:['./api/1/transformers/', selectedTransformerId, '/opm/log/file/', fileName].join(""),
//                                    success: function (data) {
//                                        return data;
//                                    },
//                                    error: function() {
//                                        console.log('link error');
//                                    }
//                                });
                                console.log(path + " : " + file.url);
                                $opmLogFiles.append('<li><a href="' + path + '" download>' + fileName + '</a></li>');
                            }
                        });
                    } else {
                        var itemFound = false;
                        $('#file-dropdown li').each(function () {
                            if ($(this).text() === 'No files found') {
                                itemFound = true;
                            }
                        });
                        if(!itemFound) {
                            $opmLogFiles.append('<li><a>No files found</a></li>');
                        }
                    }
                },
                error: function () {
                    console.log("error");
                },
                type: 'GET'
            });

        },

        _show: function () {
            if (this._Opm) {
                this._Opm.show();
            }
        },

        _hide: function () {
            if (this._Opm) {
                this._Opm.hide();
            }
        },

        _remove: function () {
            if (this._Opm) {
                this._Opm.remove();
            }
        }

    });
})(jQuery); 