EmPower.View.CameraRow = (function($, undefined) {

    return EmPower.View.AbstractView.extend({
	viewName: "Camera Row",
	
	_events: function() {
	},
	
	_initialize: function() {
	    this._cameraModel = this._getOption("cameraModel", true);
	},
	
	_setupListeners: function() {
	    
	    var self = this;
	    
	    self.listenTo(self.getCameraModel(), 'change', function() {
		self.renderDebounced();
	    });
	},
	
	getCameraModel: function() {
	    return this._cameraModel;
	},
	
	_htmlTemplate: function() {
	    return EmPower.Templates['transformer/camera/camera_row'];
	},
	
	_htmlData: function() {
	    return {
		cameraModel: this.getCameraModel()
	    };
	},
	
	_render: function() {
	    // process circle ui thing...
	},
	
	_show: function() {
	},
	
	_hide: function() {
	},
	
	_remove: function() {
	}
	
    });
    
})(jQuery);
