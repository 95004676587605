EmPower.View.BatteryTable = (function($, undefined) {

    return EmPower.View.AbstractEventTableView.extend({
	viewName: "Transformer Battery Table",
	
	_getEventCollection: function() {
	    return new EmPower.Collection.BatteryEvents(null, {
                session: this._session,
                selectedObject: this._selectedTransformer,
                dateRange: this._session.dateRange()
            });
	},
	
	_createTableView: function($container, eventCollection) {
	    return new bbGrid.View({
		rows: 7,
		container: $container,
		collection: eventCollection,
		colModel: [
                        {
                            title: 'Timestamp',
                            name: 'timestamp',
                            index: true,
                            sorttype: 'number'
                        }, {
                            title: 'Charge Mode',
                            name: 'chargeMode',
                            index: true,
                            sorttype: 'number'
                        }, {
                            title: 'Charge Voltage',
                            name: 'chargeVoltage',
                            index: true,
                            sorttype: 'number'
                        }, {
                            title: 'Charge Current',
                            name: 'chargeCurrent',
                            index: true,
                            sorttype: 'number'
                        }, {
                            title: 'Battery Temp',
                            name: 'batteryTemp',
                            index: true,
                            sorttype: 'number'
                        }, {
                            title: 'Compartment Temp',
                            name: 'compartmentTemp',
                            index: true,
                            sorttype: 'number'
                        }, {
                            title: 'Message',
                            name: 'logMessage',
                            index: true,
                            sorttype: 'string'
                        }]
	    });
	}
    });
    
})(jQuery);
