EmPower.Collection.Transformer = (function($, undefined) {
    
    return EmPower.Collection.AbstractCollection.extend({
	
	url: EmPower.Util.Url.apiBuilder('transformers'),

	model: EmPower.Model.Transformer,
	
	collectionName: 'Transformer',
	
	_initialize: function() {
	    this._canCreateTransformer = false;
	},
	
	_parse: function(response) {
	    
	    this._canCreateTransformer = response.canCreate ? true : false;
	},
	
	comparator: function(transformerOne, transformerTwo) {
	    var sort = 0;
	    if (transformerOne.transformerName()) {
		sort = transformerOne.transformerName().localeCompare(transformerTwo.transformerName());
	    }
	    if (sort === 0) {
		if (transformerOne.transformerId() >= transformerTwo.transformerId()) {
		    sort = 1;
		} else if (transformerOne.transformerId() < transformerTwo.transformerId()) {
		    sort = -1;
		}
	    }
	    
	    return sort;
	},
	
	canCreateTransformer: function() {
	    return this._canCreateTransformer ? true : false;
	}
    });
    
})(jQuery);
