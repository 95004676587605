EmPower.View.Recorded = (function ($, undefined) {

    return EmPower.View.AbstractView.extend({
        viewName: "Transformer Recorded",

        _events: function () {
            return {
                "click [data-action=select-video]": function (event) {
                    event.preventDefault();
                    this._selectVideo(event.target.id);
                }
            };
        },

        _initialize: function () {
            this._selectedTransformer = this._session.transformerSession().selectedTransformer();


            this._videoCollection = new EmPower.Collection.Recorded(null, {
                session: this._session,
                selectedObject: this._selectedTransformer
            });

        },

        _setupListeners: function () {
            var self = this;

            this.listenTo(this._selectedTransformer, "change", function () {
            });

        },

        _htmlTemplate: function () {
            return EmPower.Templates['transformer/recorded/transformer_recorded'];
        },

        _htmlData: function () {
        },

        _render: function ($html) {
            var self = this;
            var videosrc = '';
            var playVideo = function () {

                return videojs($html.find('video').get(0), {
                    fluid: true,
                    id: 'recordedPlayer',
                    preload: 'none',
                    controls: true,
                    autoplay: false,
                    sources: [{
                            type: "video/mp4",
                            src: videosrc
                        }]
                }, function () {
                    this.play();
                });
            };
            playVideo();
            var url = EmPower.Util.Url.apiBuilder('transformers',
                    this._session.transformerSession().selectedTransformer().selectedId(),
                    'videos'
                    );
            var $videoList = $html.find("div[data-section='playlist']");
            $.ajax({
                url: url,
                type: 'GET',
                defaultError: false,
                success: function (data) {
                    var playlist = data.sort(function (a, b) {
                        return new Date(b).getDate() - new Date(a).getDate();
                    });
                    playlist.forEach(function (el) {
                        var dlurl = self._downloadVideoUrl(el.fileName);
                        var date = new Date(el.created).toString().split(" ");
                        var dmy = [date[1], date[2], date[3]].join(" ");
                        var timestamp = [date[4], date[5]].join(" ");
                        
                        var videoList = "<div class='playcard'><div class='timestamp' id='" + el.fileName + "' data-action='select-video'><p>" + dmy + "</p><p>" + timestamp + "</p></div><div class='download'><a href='" + dlurl + "' download><i class='fa fa-download'></i><span>Download</span></a></div></div>";
                        $videoList.append(videoList);
                    });
                },
                error: function () {
                }
            });

        },
        
        _downloadVideoUrl: function(filename) {
            var dlurl = EmPower.Util.Url.apiBuilder('transformers',this._session.transformerSession().selectedTransformer().selectedId(),'videos',filename);
            return dlurl;
        },

        _selectVideo: function (filename) {
            var url = EmPower.Util.Url.apiBuilder('transformers',
                    this._session.transformerSession().selectedTransformer().selectedId(),
                    'videos',
                    filename
                    );
            var videoPlayer = videojs('recordedPlayer');

            videoPlayer.src({type: 'video/mp4', src: url});

        },

        _show: function () {
        },

        _hide: function () {
        },

        _remove: function () {
        }
    });

})(jQuery);
