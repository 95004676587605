EmPower.View.Ltc = (function ($, undefined) {

    return EmPower.View.AbstractView.extend({
        viewName: "LTC",

        _events: function () {
            var self = this;
            return {
                "click [data-action=position-tab]": function (event) {
                    event.preventDefault();
                    $('#position-tab-container').css("display", "inline-block");
                    $('#motor-tab-container').css("display", "none");

                    this._tabHandler('position');

                    $('#position-tab-button').addClass("active-tab");
                    $('#motor-tab-button').removeClass("active-tab");
                },
                "click [data-action=motor-current-tab]": function (event) {
                    event.preventDefault();
                    $('#position-tab-container').css("display", "none");
                    $('#motor-tab-container').css("display", "inline-block");

                    this._tabHandler("motor");

                    $('#position-tab-button').removeClass("active-tab");
                    $('#motor-tab-button').addClass("active-tab");

                },
                "click [data-action=acknowledge-sticky]": function (event) {
                    event.preventDefault();
                    var id = event.currentTarget.dataset.annuncid;
                    self.acknowledgeAnnunciator(id);
                }
            };
        },

        _initialize: function () {
//            this._applicationRouter = this._session.applicationRouter();
            this._selectedTransformer = this._session.transformerSession().selectedTransformer();
            this._transformerCollection = this._session.transformerSession().transformerCollection();

            this._ltcCollection = new EmPower.Collection.Ltc(null, {
                session: this._session,
                selectedObject: this._selectedTransformer
            });

            this._ltcMotorCurrentsCollection = new EmPower.Collection.LtcMotorCurrentsEvent(null, {
                session: this._session,
                selectedObject: this._selectedTransformer
            });

            this._ltcEventCollection = new EmPower.Collection.LtcEvent(null, {
                session: this._session,
                selectedObject: this._selectedTransformer,
                dateRange: this._session.dateRange()
            });

            this._dateRange = this._session.dateRange();

        },

        _setupListeners: function () {
            var self = this;
            self.listenTo(self._transformerCollection, 'update reset', function () {
                this.renderDebounced();
            });
            self.listenTo(self._selectedTransformer, 'change', function () {
                this.renderDebounced();
            });
            self.listenTo(self._ltcMotorCurrentsCollection, 'update reset', function () {
            });
            self.listenTo(self._ltcCollection, 'update reset', function () {
                this.renderDebounced();
            });
            self.listenTo(self._ltcEventCollection, 'update reset', function () {
                this.renderDebounced();
            });

            this.listenTo(self._dateRange, 'change', function () {
                this.renderDebounced();
//                self._tabHandler("motor");
            });
        },

        _htmlTemplate: function () {
            return EmPower.Templates['transformer/ltc/transformer_ltc'];
        },

        _htmlData: function () {

        },

        acknowledgeAnnunciator: function (annunciatorId) {
            var url = EmPower.Util.Url.apiBuilder("transformers", this._selectedTransformer.selectedId(), "annunciator", annunciatorId, "acknowledge");
            $.ajax({
                type: "POST",
                url: url,
                success: function () {
                    $.notify({
                        message: "<strong>Success:</strong>  Annunciator " + annunciatorId + " acknowledged. Status will update pending RCTU response."
                    }, {
                        type: 'success',
                        placement: {
                            from: 'top',
                            align: 'center'
                        }
                    });
                },
                error: function (err) {
                    $.notify({
                        message: "<strong>Failure:</strong> Could not acknowledge annunciator " + annunciatorId
                    }, {
                        type: 'danger',
                        placement: {
                            from: 'top',
                            align: 'center'
                        }
                    });
                }
            });

        },

        _tabHandler: function (tab) {
            var self = this;
            if (tab === 'position') {
                this.renderDebounced();
            } else if (tab === 'motor') {
                var from = moment(self._dateRange.fromDate()).format().toString();
                var to = moment(self._dateRange.toDate()).format().toString();
                var motorCurrentsUrl = EmPower.Util.Url.apiBuilder("transformers", this._selectedTransformer.selectedId(), "ltc", "/motor-current-events?from=" + from + "&to=" + to);

                /*
                 * Create the motor currents chart
                 */
                $.ajax({
                    url: motorCurrentsUrl,
                    type: "GET",
                    success: function (data) {
                        $("#motorCurrent-loading").hide();

                        // create position histogram chart
                        var mccContext = $("#motorCurrentsChart");

                        if (data.length > 0) {

                            var timeToChangePoints = [];
                            var meanCurrentPoints = [];
                            var peakCurrentPoints = [];

                            data.forEach(function (event) {
                                var eventTime = moment(event.time).format();
                                timeToChangePoints.push({x: eventTime, y: event.inRushCurrentDuration});
                                meanCurrentPoints.push({x: eventTime, y: event.meanCurrent});
                                peakCurrentPoints.push({x: eventTime, y: event.peakCurrent});
                            });

                            var motorCurrents = new Chart(mccContext, {
                                type: 'line',
                                data: {
                                    datasets: [
                                        {
                                            data: timeToChangePoints,
                                            yAxisID: 'timeToChange',
                                            backgroundColor: '#337ab7',
                                            borderColor: '#337ab7',
                                            label: 'Time To Change',
                                            fill: false
//                                            pointRadius: 0
                                        },
                                        {
                                            data: meanCurrentPoints,
                                            yAxisID: 'current',
                                            backgroundColor: '#e82c2c',
                                            borderColor: '#e82c2c',
                                            label: 'Mean Current',
                                            fill: false
//                                            pointRadius: 0
                                        },
                                        {
                                            data: peakCurrentPoints,
                                            yAxisID: 'current',
                                            backgroundColor: '#00e600',
                                            borderColor: '#00e600',
                                            label: 'Peak Current',
                                            fill: false
//                                            pointRadius: 0
                                        }
                                    ]
                                },
                                options: {
                                    legend: {
                                        display: true,
                                        position: 'bottom'
                                    },
                                    title: {
                                        display: true,
                                        text: 'Motor Currents'
                                    },
                                    scales: {
                                        yAxes: [
                                            {
                                                id: 'timeToChange',
                                                position: 'left',
                                                scaleLabel: {
                                                    display: true,
                                                    labelString: 'In Rush Current Duration (ms)'
                                                },
                                                ticks: {
                                                    beginAtZero: true
                                                }
                                            },
                                            {
                                                id: 'current',
                                                position: 'right',
                                                scaleLabel: {
                                                    display: true,
                                                    labelString: 'Current (mA)'
                                                },
                                                ticks: {
                                                    beginAtZero: true
                                                }
                                            }
                                        ],
                                        xAxes: [
                                            {
                                                type: 'time',
                                                ticks: {
                                                    source: 'data'
                                                },
                                                time: {
                                                    unit: 'day',
                                                    tooltipFormat: "YYYY-MM-DD T HH:mm:ssZ",
                                                    displayFormats: {
                                                        millisecond: 'll',
                                                        second: 'll',
                                                        minute: 'll',
                                                        hour: 'll',
                                                        day: 'll'
                                                    }
                                                },
                                                scaleLabel: {
                                                    labelString: 'Timestamp'
                                                }
                                            }
                                        ]
                                    }
                                }
                            });

                        } else {
                            $("#motor-section").replaceWith(EmPower.Templates['templates/page_warning']({
                                message: "No events within date range"
                            }));
                        }
                    }
                });

                // creating the motor currents table
                var $motorCurrentTable = $(document).find("#motor-current-table").empty();
//                if (!this._motorCurrentTable) {
                this._motorCurrentTable = new bbGrid.View({
                    rows: 10,
                    container: $motorCurrentTable,
                    collection: this._ltcMotorCurrentsCollection,
                    colModel: [
                        {
                            title: 'Timestamp',
                            name: 'time',
                            sorttype: 'string'
                        }, {
                            title: 'Time to Change (ms)',
                            name: 'inRushCurrentDuration',
                            sorttype: 'string'
                        }, {
                            title: 'Mean Current (mA)',
                            name: 'meanCurrent',
                            sorttype: 'number'
                        }, {
                            title: 'Peak Current (mA)',
                            name: 'peakCurrent',
                            sorttype: 'number'
                        }, {
                            title: 'In Rush Current Array',
                            name: 'inRushCurrentArray'
                        }
                    ]
                });

//                }

            }
        },

        _render: function ($html) {
            var self = this;
            /*
             * Chart js plugin to fill the area between two datasets
             */
            var fillBetweenLinesPlugin = {
                afterDatasetsDraw: function (chart) {
                    var ctx = chart.chart.ctx;
                    var xaxis = chart.scales['x-axis-0'];
                    var yaxis = chart.scales['y-axis-0'];
                    var datasets = chart.data.datasets;
                    ctx.save();

                    for (var d = 0; d < datasets.length; d++) {
                        var dataset = datasets[d];
                        if (dataset.fillBetweenSet === undefined) {
                            continue;
                        }

                        // get meta for both data sets
                        var meta1 = chart.getDatasetMeta(d);
                        var meta2 = chart.getDatasetMeta(dataset.fillBetweenSet);

                        // do not draw fill if one of the datasets is hidden
                        if (meta1.hidden || meta2.hidden) {
                            continue;
                        }
                        // create fill areas in pairs
                        for (var p = 0; p < meta1.data.length - 1; p++) {
                            // if null skip
                            if (dataset.data[p] === null || dataset.data[p + 1] === null) {
                                continue;
                            }
                            ctx.beginPath();

                            // trace line 1
                            var curr = meta1.data[p];
                            var next = meta1.data[p + 1];
                            ctx.moveTo(curr._view.x, curr._view.y);
                            ctx.lineTo(curr._view.x, curr._view.y);
                            if (curr._view.steppedLine === true) {
                                ctx.lineTo(next._view.x, curr._view.y);
                                ctx.lineTo(next._view.x, next._view.y);
                            } else if (next._view.tension === 0) {
                                ctx.lineTo(next._view.x, next._view.y);
                            } else {
                                ctx.bezierCurveTo(
                                        curr._view.controlPointNextX,
                                        curr._view.controlPointNextY,
                                        next._view.controlPointPreviousX,
                                        next._view.controlPointPreviousY,
                                        next._view.x,
                                        next._view.y
                                        );
                            }

                            // connect dataset1 to dataset2
                            curr = meta2.data[p + 1];
                            next = meta2.data[p];
                            ctx.lineTo(curr._view.x, curr._view.y);

                            // trace BACKWORDS set2 to complete the box
                            if (curr._view.steppedLine === true) {
                                ctx.lineTo(curr._view.x, next._view.y);
                                ctx.lineTo(next._view.x, next._view.y);
                            } else if (next._view.tension === 0) {
                                ctx.lineTo(next._view.x, next._view.y);
                            } else {
                                // reverse bezier
                                ctx.bezierCurveTo(
                                        curr._view.controlPointPreviousX,
                                        curr._view.controlPointPreviousY,
                                        next._view.controlPointNextX,
                                        next._view.controlPointNextY,
                                        next._view.x,
                                        next._view.y
                                        );
                            }

                            // close the loop and fill with shading
                            ctx.closePath();
                            ctx.fillStyle = dataset.fillBetweenColor || "rgba(0,0,0,0.1)";
                            ctx.fill();
                        } // end for p loop
                    }
                } // end afterDatasetsDraw
            }; // end fillBetweenLinesPlugin

            Chart.pluginService.register(fillBetweenLinesPlugin);

            /*
             * Vars to hold formatted data for the charts
             */
            var va = [];
            var ia = [];

            var topOil = [];

            var dateBins = new Map();
            var tapPositionMaxs = new Map();
            var tapPositionMins = new Map();

            var tapPositionsArr = [];

            var maxPosition;
            var minPosition;


            /*
             * Url construction for the endpoints
             */

//            var dateRange = "?from=" + moment().subtract(7, 'days').format().toString() + "&to=" + moment().format().toString();
            var fromDate = moment(this._dateRange.fromDateFormatted()).format().toString();
            var toDate = moment(this._dateRange.toDateFormatted()).format().toString();
            var dateRange = "?from=" + fromDate + "&to=" + toDate;

            var dlUrl = EmPower.Util.Url.apiBuilder("transformers", this._selectedTransformer.selectedId(), "dynamicloading", "/events", dateRange);
            var metUrl = EmPower.Util.Url.apiBuilder("transformers", this._selectedTransformer.selectedId(), "metrology", "/events", dateRange);
            var positionEventsUrl = EmPower.Util.Url.apiBuilder("transformers", this._selectedTransformer.selectedId(), "ltc", "position-change-events", dateRange);
            var tempsUrl = EmPower.Util.Url.apiBuilder("transformers", this._selectedTransformer.selectedId(), "temperature", "ltc-tank-temperature");

            var statusUrl = EmPower.Util.Url.apiBuilder("transformers", this._selectedTransformer.selectedId(), "ltc", "statuses", dateRange);
            $.ajax({
                type: "GET",
                url: statusUrl,
                success: function (data) {

                    if (data.length) {
                        // setting ltc status sidebar values
                        // setting sidebar values
                        if (data[0].health === "Good") {
                            $('#health-status-icon').addClass("fa-check-circle green-health-status-icon");
                        } else if (data[0].health === "Bad") {
                            $('#health-status-icon').addClass("fa-times-circle red-health-status-icon");
                        } else if (data[0].health === "Unknown") {
                            $('#health-status-icon').addClass("fa-question-circle grey-health-status-icon");
                        } else if (data[0].health === "Unconfigured") {
                            $('#health-status-icon').addClass("fa-exclamation-circle grey-health-status-icon");
                        }

                        $('#health-status').text(data[0].health || "NA");
                        $('#control-type').text(data[0].controlType || "NA");
                        $('#control-mode').text(data[0].controlMode || "NA");
                        $('#lockout-state').text(data[0].lockoutState || "NA");

                    }
                }
            });

            /*
             * Unified callback for Position chart endpoints
             */
            $.when(
                    /*
                     * POSITION EVENTS ENDPOINT
                     */
                    $.ajax({
                        url: positionEventsUrl,
                        type: 'GET',
                        success: function (data) {
                            if (data.length > 0) {
                                maxPosition = data[0].maxPosition;
                                minPosition = data[0].minPosition;
                                
                                if (self._ltcCollection.models[0] && self._ltcCollection.models[0].hasTapPosition === 1) {
                                    $('#current-position').text(data[0].value);
                                } else {
                                    $('#current-position').css("display", "none");
                                    $('#currentPositionLabel').css("display", "none");
                                    $('#currentPositionValue').css("display", "none");
                                }
                                
                                $('#seven-days').text(data[0].tapChangesLastSeven);
                                $('#thirty-days').text(data[0].tapChangesLastThirty);
                                $('#total-tap-count').text(data[0].totalPosChanges);

                                $('#last-tap').text(data[0].time);

                                // creates a map of position and general record events binned by day and another map of the min and max tap position by day
                                data.forEach(function (event) {
                                    //each tap position change for the position graph
                                    tapPositionsArr.push({x: moment(event.time).format(), y: event.value});

                                    event.time = moment(event.time).format("MMM DD, YYYY");
                                    if (dateBins.has(event.time)) {
                                        dateBins.set(event.time, dateBins.get(event.time) + 1);
                                    } else {
                                        dateBins.set(event.time, 1);
                                    }

                                    // if this day has a max but this value is greater, update the max
                                    if (tapPositionMaxs.has(event.time) && event.value > tapPositionMaxs.get(event.time)) {
                                        tapPositionMaxs.set(event.time, event.value);
                                    }
                                    // if this day has no max yet, this is now the max
                                    else if (!tapPositionMaxs.has(event.time)) {
                                        tapPositionMaxs.set(event.time, event.value);
                                    }

                                    // if this day has a min but this value is lesser, update the min
                                    if (tapPositionMins.has(event.time) && event.value < tapPositionMins.get(event.time)) {
                                        tapPositionMins.set(event.time, event.value);
                                    }
                                    // if this day has no min yet, this is now the min
                                    else if (!tapPositionMins.has(event.time)) {
                                        tapPositionMins.set(event.time, event.value);
                                    }

                                });

                                /*
                                 * CHART CREATION
                                 */
//                        $(function () {
//
//
//                        });

                                // if there is no position events data
                            } else {
                                $("#ltc-graphs").replaceWith(EmPower.Templates['templates/page_warning']({
                                    message: "No events within date range"
                                }));
                            }
                        }
                    }),
                    /*
                     * METROLOGY ENDPOINT
                     */
                    $.ajax({
                        url: metUrl,
                        type: 'GET',
                        success: function (data) {

//                            var today = moment().format();
//                            var startDate = moment().subtract(7, 'days').format();

                            // create datasets for the last 7 days of metrology data
                            if (data.results.length > 0) {
                                data.results.forEach(function (event) {

//                                    if (moment(event.timestamp).isBetween(startDate, today, 'days', '[]')) {
                                    va.push({x: event.timestamp, y: event.voltagePhaseA});
                                    ia.push({x: event.timestamp, y: event.currentPhaseA});
//                                    }
                                });
                            }
                        }
                    }),
                    /*
                     * DYNAMIC LOADING ENDPOIINT
                     */
                    $.ajax({
                        url: dlUrl,
                        type: 'GET',
                        success: function (data) {
                            var mainTemp;

                            // create datasets for the last 7 days of metrology data
                            if (data.results.length > 0) {
                                mainTemp = data.results[0].topOilTempInit;
                                $('#main-temp').text(mainTemp + ' C');

                                data.results.forEach(function (event) {
//                                    if (moment(event.timestamp).isBetween(startDate, today, 'days', '[]')) {
                                    topOil.push({x: event.timestamp, y: event.topOilTempInit});
//                                    }
                                });
                            }

                            // nested temps here so we can evaluate differential temp
                            $.ajax({
                                url: tempsUrl,
                                type: 'GET',
                                success: function (data) {
                                    var ltcTank;
                                    if (data.length > 0) {
                                        ltcTank = data[0].value;
                                        $('#ltc-temp').text(ltcTank + ' C');
                                    }
                                    if (ltcTank && mainTemp) {
                                        var diff = Math.abs(mainTemp - ltcTank).toFixed(1);
                                        $('#diff-temp').text(diff + ' C');
                                    }


                                }
                            });

                        }
                    })

                    ).then(function () {
                $("#position-loading").hide();
                $("#countRange-loading").hide();

                // create position chart
                var pcContext = $("#positionChart");
                if (self._ltcCollection.models[0] && self._ltcCollection.models[0].hasTapPosition === 1) {
                    var position = new Chart(pcContext, {
                        type: 'line',
                        data: {
                            datasets: [
                                {
                                    data: tapPositionsArr,
                                    yAxisID: 'tapPosition',
                                    backgroundColor: '#337ab7',
                                    borderColor: '#337ab7',
                                    label: 'Tap Position',
                                    steppedLine: true,
                                    fill: false,
                                    pointRadius: 0
                                }, {
                                    data: va,
                                    yAxisID: 'voltage',
                                    backgroundColor: '#e82c2c',
                                    borderColor: '#e82c2c',
                                    fill: false,
                                    pointRadius: 0,
                                    label: 'VA'
                                }, {
                                    data: ia,
                                    yAxisID: 'current',
                                    backgroundColor: '#00e600',
                                    borderColor: '#00e600',
                                    fill: false,
                                    pointRadius: 0,
                                    label: 'IA'
                                }, {
                                    data: topOil,
                                    yAxisID: 'temperature',
                                    backgroundColor: '#738ba3',
                                    borderColor: '#738ba3',
                                    fill: false,
                                    pointRadius: 0,
                                    label: 'Top Oil'
                                }
                            ]
                        },
                        options: {
                            legend: {
                                display: true,
                                position: 'bottom'
                            },
                            title: {
                                display: true,
                                text: 'Position'
                            },
                            scales: {
                                yAxes: [
                                    {
                                        id: 'tapPosition',
                                        position: 'left',
                                        scaleLabel: {
                                            display: true,
                                            labelString: 'Tap Position'
                                        },
                                        ticks: {
                                            beginAtZero: true,
                                            min: minPosition,
                                            max: maxPosition,
                                            stepSize: 2
                                        },
                                        gridLines: {
                                            display: false
                                        }
                                    },
                                    {
                                        id: 'temperature',
                                        position: 'left',
                                        scaleLabel: {
                                            display: true,
                                            labelString: 'Temperature (Celsius)'
                                        },
                                        ticks: {
                                            beginAtZero: true
                                        }
                                    },
                                    {
                                        id: 'voltage',
                                        position: 'right',
                                        scaleLabel: {
                                            display: true,
                                            labelString: 'Voltage (Volts)'
                                        },
                                        ticks: {
                                            beginAtZero: true
                                        }
                                    },
                                    {
                                        id: 'current',
                                        position: 'right',
                                        scaleLabel: {
                                            display: true,
                                            labelString: 'Current (Amps)'
                                        },
                                        ticks: {
                                            beginAtZero: true
                                        }
                                    }
                                ],
                                xAxes: [
                                    {
                                        type: 'time',
                                        ticks: {
                                            source: 'data'
                                        },
                                        time: {
                                            unit: 'day',
                                            tooltipFormat: "YYYY-MM-DD T HH:mm:ssZ",
                                            displayFormats: {
                                                millisecond: 'll',
                                                second: 'll',
                                                minute: 'll',
                                                hour: 'll',
                                                day: 'll'
                                            }
                                        },
                                        scaleLabel: {
                                            labelString: 'Timestamp'
                                        }
                                    }
                                ]
                            }
                        }

                    });
                } else {
                    $("#tapPositionHeader").css("display", "none");
                    $("#tapPositionContainer").css("display", "none");
                }
                // create tap count chart
                var tccContext = $("#tapCountChart");
                var tapCount = new Chart(tccContext, {
                    type: 'bar',
                    data: {
                        labels: Array.from(dateBins.keys()).reverse(),
                        datasets: [{
                                data: Array.from(dateBins.values()).reverse(),
                                backgroundColor: '#337ab7'
                            }]
                    },
                    options: {
                        legend: {
                            display: false
                        },
                        title: {
                            display: true,
                            text: 'Tap Count Per Period'
                        },
                        scales: {
                            yAxes: [
                                {
                                    ticks: {
                                        beginAtZero: true,
                                        precision: 0
                                    }
                                }
                            ]
                        }
                    }
                });

                // create position range chart
                var rbpContext = $("#rangeByPeriodChart");
                if (self._ltcCollection.models[0] && self._ltcCollection.models[0].hasTapPosition === 1) {
                    var positionRange = new Chart(rbpContext, {
                        type: 'line',
                        data: {
                            labels: Array.from(tapPositionMaxs.keys()).reverse(),
                            datasets: [
                                {
                                    data: Array.from(tapPositionMins.values()).reverse(),
                                    borderColor: '#275d8b',
//                                        steppedLine: true,
                                    lineTension: 0,
                                    fill: false,
                                    fillBetweenSet: 1,
                                    fillBetweenColor: "#337ab7"
                                },
                                {
                                    data: Array.from(tapPositionMaxs.values()).reverse(),
                                    borderColor: '#275d8b',
//                                        steppedLine: true,
                                    lineTension: 0,
                                    fill: false,
                                    fillBetweenSet: 1,
                                    fillBetweenColor: "#337ab7"
                                }
                            ]
                        },
                        options: {
                            legend: {
                                display: false
                            },
                            title: {
                                display: true,
                                text: 'Position Range By Period'
                            },
                            scales: {
                                yAxes: [
                                    {
                                        ticks: {
                                            beginAtZero: true,
                                            min: minPosition,
                                            max: maxPosition,
                                            stepSize: 2
                                        }
//                                            stacked: true
                                    }
                                ]
                            }
                        }
                    });
                } else {
                    $("#totalPositionCountHeader").css("display", "none");
                    $("#totalPositionCountContainer").css("display", "none");
                }

            });

            var motorCurrentsUrl = EmPower.Util.Url.apiBuilder("transformers", this._selectedTransformer.selectedId(), "ltc", "motor-current-events");
            $.ajax({
                url: motorCurrentsUrl,
                type: 'GET',
                success: function (data) {
                    if (data.length > 0) {
                        $('#time-to-tap').text(data[0].inRushCurrentDuration + " ms");
                        $('#mean-current').text(data[0].meanCurrent.toFixed(1) + " mA");
                        $('#peak-current').text(data[0].peakCurrent + " mA");
                    }
                }

            });


            var positionHistogramUrl = EmPower.Util.Url.apiBuilder("transformers", this._selectedTransformer.selectedId(), "ltc", "position-histogram");

            /*
             * Create the position histogram chart
             */
            var phcContext = $("#positionHistogramChart");
            if (self._ltcCollection.models[0] && self._ltcCollection.models[0].hasTapPosition === 1) {
                $.ajax({
                    url: positionHistogramUrl,
                    type: "GET",
                    success: function (data) {
                        // create position histogram chart

                        var tapPositionArr = [];
                        var tapCountArr = [];


                        if (data.length > 0) {

                            var maxPosition = data[0].maxPosition;
                            var minPosition = data[0].minPosition;

                            // check if the data is between the max and min configured position, then insert into data arrays
                            data.forEach(function (pos) {
                                if (pos.tapPosition <= maxPosition && pos.tapPosition >= minPosition) {
                                    tapPositionArr.push(pos.tapPosition);
                                    tapCountArr.push(pos.positionCount);
                                }
                            });

                            var positionHistogram = new Chart(phcContext, {
                                type: 'bar',
                                data: {
                                    labels: tapPositionArr,
                                    datasets: [
                                        {
                                            data: tapCountArr,
                                            backgroundColor: '#337ab7',
                                            borderColor: '#275d8b',
                                            borderWidth: '1'
                                        }
                                    ]
                                },
                                options: {
                                    legend: {
                                        display: false
                                    },
                                    title: {
                                        display: true,
                                        text: 'Position Histogram'
                                    },
                                    scales: {
                                        yAxes: [
                                            {
                                                ticks: {
                                                    beginAtZero: true
                                                }
                                            }
                                        ],
                                        xAxes: [
                                            {
                                                categoryPercentage: 1.0,
                                                barPercentage: 1.0
                                            }
                                        ]
                                    }
                                }
                            });
                        }
                    }
                });
            }

            var annuncUrl = EmPower.Util.Url.apiBuilder("transformers", this._selectedTransformer.selectedId(), "/annunciator/ltc");
            var $annunciatorList = $html.find("div[data-section='annunciator-list']");
            /*
             * Add LTC annunciators to the page if there are any
             */
            $.ajax({
                type: "GET",
                url: annuncUrl,
                success: function (data) {
                    $annunciatorList.empty();

                    data.forEach(function (annunciator) {

                        var timestamp = annunciator.timestamp ? annunciator.timestamp : 'No Date';
                        var stateClass;
                        switch (annunciator.value) {
                            case 2: // annunciator needs acknowledged, flashing orange background
                                stateClass = "label-warning flashing acknowledgeable";
                                break;
                            case 1: // annunciator is firing, orange background
                                stateClass = "label-warning not-acknowledgeable";
                                break;
                            case 0: // annunciator is not firing, gray background
                                stateClass = "label-default not-acknowledgeable";
                                break;
                            default: // no events, grey background with grey text
                                stateClass = "label-default label-disabled not-acknowledgeable";
                                break;
                        }

                        $annunciatorList.append("<div class='annunciator-card " + stateClass + "' data-annuncId='" + annunciator.transformerAnnunciatorId + "' data-action='acknowledge-sticky'><p>" + annunciator.description + "</p><span>" + timestamp + "</div>");
                    });

                },
                error: function (err) {
                    console.log(err);
                }
            });

        },

        _show: function () {
        },

        _hide: function () {
        },

        _remove: function () {
        }
    });

})(jQuery);
