EmPower.Collection.LtcMotorCurrentsEvent = (function($, undefined) {
    
    return EmPower.Collection.AbstractSelectedObjectCollection.extend({
	collectionName: 'Ltc Motor Currents Event',
	
	model: EmPower.Model.Ltc,
	
	url: function() {
	    return EmPower.Util.Url.apiBuilder('transformers', this._selectedObject.selectedId(), 'ltc', 'motor-current-events');
	},
	
	_initialize: function() {
	},
	
	_parse: function() {
	}
    });
    
})(jQuery);
