EmPower.Collection.MetrologyDetectedEvent = (function($, undefined) {
    
    return EmPower.Collection.AbstractDeviceEventCollection.extend({
	collectionName: 'Metrology Detected Event',

	model: EmPower.Model.MetrologyDetectedEvent,
        
	_url: function() {
	    return EmPower.Util.Url.apiBuilder('transformers', this._selectedObject.selectedId(), 'metrology-detected-event');
	},
        
//        getDownloadUrl: function() {
//            return EmPower.Util.Url.apiBuilder('transformers', this._selectedObject.selectedId(), 'metrology-detected-event', 'csv');
//        },
	
	_initialize: function() {
	},
	
	_parse: function() {
	}
    });
    
})(jQuery);
