(function(Url, $, undefined) {
    
    // private namespace
    var that = {};
    
    that.unreservedRegExp = /^[\w*:.~-]+$/;
    
    Url.builder = function() {
	
	var url = [];
	for (var arg = 0; arg < arguments.length; ++arg) {
	    
	    var argument = arguments[arg];
	    
	    if (_.isNumber(argument)) {
		argument = argument.toString();
	    }
	    
	    if (_.isString(argument)) {
		
		if (that.unreservedRegExp.test(argument)) {
		    if (url.length !== 0) {
			url.push('/');
		    }
		}
		
		url.push(argument);
	    }
	}
	return url.join('');
    };
    
    Url.apiBuilder = function() {
	
	var args = ['api', EmPower.apiVersion];
	args.push.apply(args, Array.prototype.slice.call(arguments));
	
	return Url.builder.apply(this, args);
    };
    
    Url.authBuilder = function() {
	
        var args = ['auth', EmPower.apiVersion];
	args.push.apply(args, Array.prototype.slice.call(arguments));
	
	return Url.builder.apply(this, args);
    };

})(EmPower.Util.Url = EmPower.Util.Url || {}, jQuery);
