EmPower.Collection.Opm = (function($, undefined) {
   
    return EmPower.Collection.AbstractSelectedObjectCollection.extend({
        
        collectionName: 'Opm',
        model: EmPower.Model.Opm,
        
        url: function() {
            return EmPower.Util.Url.apiBuilder('transformers', this._selectedObject.selectedId(), 'opm', 'log', 'events');
        },
        
        _initialize: function() {
            
        },
        
        _parse: function() {
            
        }
    });
})(jQuery);