EmPower.View.AbstractView = (function(View, $, undefined) {
	
    return View.extend({

	events: function() {
	    return this._events();
	},
	
	_events: function() {
	    this._logger.throwError("View was not extended with specific Events.");
	},

	constructor: function(options) {
	    
	    var viewName = "View [" + (this.viewName || "Abstract") + "]";
	    
	    var loggerEnabled = this.loggerEnabled !== undefined ? this.loggerEnabled : true;
	    
	    this._logger = new EmPower.Util.Logger(viewName, loggerEnabled);
	    
	    if (!this.viewName) {
		
		this._logger.throwError("View was not extended with a specific View Name.");
	    }
	    
	    this._options = options;
	    
	    this._listenToCollections = new EmPower.Util.UniqueCollection();
	    
	    View.apply(this, arguments);
	},
	
	_initialize: function() {
	    this._logger.throwError("View was not extended with specific Initialization.");
	},
	
	initialize: function() {
	    
	    this._session = this._getOption("session", true);
	    
	    this._collectionFetcher = this._session.collectionFetcher();
	    
	    this._initialize();
	    
	    var container = this._getContainer();
	    if (container) {
		this.updateContainer(container);
	    }
	    
	    this._hasRendered = false;
	    
	    this._setVisible(false);
	    
	    this._isListening = true;
	    
	    this._isListenersSetup = false;
	    
	    this._reRender = false;
	    
	    this._logger.info("Initialized View", this);
	    
	    return this;
	},
	
	updateContainer: function(container) {

	    this._container = container || this._container;
	    
	    var $container = _.isFunction(this._container) ? this._container() : this._container;
	    
	    if (!$container || !$container.length || $container.length === 0) {
		this._hasContainer = false;
		this._logger.throwError("View was given a null Container Element.");
	    }
	    
	    if (!$container.is(this.$el)) {
		this.setElement($container);
	    }
	    
	    this._hasContainer = true;
	    
	    return this;
	},
	
	_getOptions: function(required) {
	    if (required && (!this._options && _.isEmpty(this._options))) {
		this._logger.throwError("View was not initilized with Options.");
	    }
	    return this._options;
	},
    
	_getOption: function(field, required) {
	    
	    if (!field) {
		return null;
	    }

	    var option;
	    try {
		
		var options = this._getOptions(required);
		
		if (options) {
		    if (required && !_.has(options, field)) {
			throw new Error();
		    }
		    option = options[field];
		} else if (required) {
		    throw new Error();
		}
		
	    } catch (error) {
		this._logger.throwError("Required field [" + field + "] was not found on View Options.");
	    }

	    return option;
	},

	_getContainer: function(required) {
	    return this._getOption("container", required);
	},

	_htmlTemplate: function() {
	    this._logger.throwError("View was not extended with a specific HTML Template.");
	},

	_htmlData: function() {
	    this._logger.throwError("View was not extended with specific HTML Data.");
	},

	_getHtml: function(data) {

	    if (!this._htmlTemplateCompiled) {
		this._htmlTemplateCompiled = this._htmlTemplate();
		
		if (!this._htmlTemplateCompiled) {
		    
		    this._logger.throwError("View was extended with a null HTML Template Function.");
		}
	    }

	    return $(this._htmlTemplateCompiled(data));
	},
	
	_setupListeners: function() {
	    this._logger.throwError("View was not extended with a specific Setup Listeners Function.");
	},
	
	setupListeners: function() {
	    if (!this._isListenersSetup) {
		
		this._logger.warn("Setting up listeners", this);
		
		this._setupListeners();
                this._startFetching();
		this._isListenersSetup = true;
	    }
	},
	
	hasRendered: function() {
	    return this._hasRendered ? true : false;
	},
	
	_render: function($html, data) {
	    this._logger.throwError("View was not extended with a specific Render Function.");
	},

	render: function(container) { 
		this._logger.debugLog(this); // Note: to remove the console logging from this, go to Logger.js and edit the function "Logger.prototype.debugLog" so that the very first "if" statment always returns: if(true){} instead of if(false){} 

	    if (!this.hasRendered() || this.isVisible()) {
		this._logger.warn("Rendering View", this, container);

                this.setupListeners();

                if (!this._isListening && !this.hasRendered()) {
                    this.delegateEvents();
                    this._startFetching();
                }

		if (container instanceof $) {
		    this.updateContainer(container);
		}

		if (!this._hasContainer) {
		    this._logger.throwError("View was not extended with a specific Container before Render.");
		}
                
                var htmlData = this._htmlData();
		this.$html = this._getHtml(htmlData);
                
                this._render(this.$html, htmlData);
		
		if (_.isFunction(this._afterHtmlRendered)) {
		    this._afterHtmlRendered(this.$html);
		}

		this.$el.html(this.$html);

		this._hasRendered = true;

		this._setVisible(true);
		
		this._reRender = false;
	    } else {
		this._logger.info("Requested to render view but view is hidden, setting re-render flag.", this, container);
		this._reRender = true;
	    }
	    
	    return this;
	},
	
	renderDebounced: function() {
	    
	    var self = this;
	    
	    var debounce = _.debounce(function() {
		return self.render();
	    }, 1);
	    
	    self.renderDebounced = debounce;
	    
	    return self.renderDebounced();
	},

	isVisible: function() {
	    return this._isVisible ? true : false;
	},
	
	_setVisible: function(isVisible) {
	    isVisible = isVisible ? true : false;
	    
	    if (this._isVisible !== isVisible) {
		this._isVisible = isVisible;
		if (this._isVisible) {
		   this._onVisible(); 
		} else {
		    this._onHidden();
		}
		
	    }
	},
	
	_onVisible: function() {
	},
	
	_onHidden: function() {
	},
	
	_toggleDisplay: function(isDisplay, args) {
	    
	    if (!this._hasRendered) {
		var message = isDisplay ? "shown" : "hidden";
		this._logger.throwError("The view must be rendered before it can be "
			+ message + ".");
	    }
	    
	    if (isDisplay) {
		if (!this.isVisible()) {
		    this._setVisible(true);
		    this._startFetching();
		    if (this._reRender) {
			this.render();
		    }
		    this._show();
		    this.$el.show.apply(this.$el, args);
                    if (_.isFunction(this._afterShow)) {
                        this._afterShow();
                    }
		}
	    } else {
		if (this.isVisible()) {
                    if (_.isFunction(this._beforeHide)) {
                        this._beforeHide();
                    }
		    this.$el.hide.apply(this.$el, args);
		    this._hide();
		    this._stopFetching();
		    this._setVisible(false);
		}
	    }

	    return this;
	},
	
	_show: function() {
	    this._logger.throwError("View was not extended with a specific Show Function.");
	},
	
	show: function() {
	    return this._toggleDisplay(true, arguments);
	},
	
	_hide: function() {
	    this._logger.throwError("View was not extended with a specific Hide Function.");
	},

	hide: function() {
	    return this._toggleDisplay(false, arguments);
	},

	_remove: function() {
	    this._logger.throwError("View was not extended with a specific Remove Function.");
	},

	remove: function() {
	    
	    this.undelegateEvents();
	    this.$el.empty().off();
	    this.stopListening();
	    
	    this._remove();
	    
	    if (!this.isVisible()) {
		if (this.$el.is(":hidden")) {
		    this.$el.show();
		}
	    }
	    
	    this._setVisible(false);
	    this._isListening = false;
	    this._hasRendered = false;
	    
	    this._logger.info("Removed View", this);
	    
	    return this;
	},
	
	_stopFetching: function() {
	    this._collectionFetcher.removeCollection(this._listenToCollections.getCollection(), this);
	},
	
	_startFetching: function() {
            
	    var self = this;
            
	    _.forEach(self._listenToCollections.getCollection(), function(collection) {
		self._collectionFetcher.addCollection(collection, self);
	    });
	},
	
	listenTo: function(object) {
            
	    this._logger.info("Start listening", this, object);
            var listeningTo = this._collectionFetcher.addCollection(object, this, !this.isVisible());
            if (listeningTo) {
                this._listenToCollections.add(listeningTo);
            }
	    
	    View.prototype.listenTo.apply(this, arguments);
	},
	
	stopListening: function(object) {

	    this._logger.info("Stop listening", this, object);
	    if (object) {
		this._collectionFetcher.removeCollection(object, this);
                this._listenToCollections.remove(object);
	    } else {
		this._stopFetching();
	    }
	    
	    View.prototype.stopListening.apply(this, arguments);
	}
    });
    
})(Backbone.View, jQuery);
