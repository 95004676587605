EmPower.View.Camera = (function($, undefined) {

    return EmPower.View.AbstractView.extend({
	viewName: "Transformer Camera",
	
	_events: function() {
	},
	
	_initialize: function() {
	    this._selectedTransformer = this._session.transformerSession().selectedTransformer();
	    
	    this._cameraCollection = new EmPower.Collection.Camera(null, {
		session: this._session,
		selectedObject: this._selectedTransformer
	    });
            
            this._selectedCamera = new EmPower.Model.SelectedObject();
	},
	
	_setupListeners: function() {
            var self = this;
            
            self.listenTo(self._session, 'reset', function() {
                self._selectedCamera.selected(null);
            });
            
            self.listenTo(self._selectedTransformer, 'change', function() {
                self._selectedCamera.selected(null);
            });
	},
	
	_htmlTemplate: function() {
	    return EmPower.Templates['transformer/camera/transformer_camera'];
	},
	
	_htmlData: function() {
	},
	
	_render: function($html) {
	    
            var $cameraInformation = $html.find("div[data-section='transformer-camera-information']");
	    if (!this._cameraInformation) {
		
		this._cameraInformation = new EmPower.View.CameraInformation({
		    session: this._session,
                    selectedCamera: this._selectedCamera
		});
		this._cameraInformation.render($cameraInformation);
	    } else {
		$cameraInformation.replaceWith(this._cameraInformation.$el);
	    }
            
	    var $cameraList = $html.find("div[data-section='transformer-camera-list']");
	    if (!this._cameraList) {

		this._cameraList = new EmPower.View.CameraList({
		    session: this._session,
		    collection: this._cameraCollection,
		    selectedModel: this._selectedCamera
		});
		this._cameraList.render($cameraList);
	    } else {
		$cameraList.replaceWith(this._cameraList.$el);
	    }
	},
	
	_show: function() {
            if (this._cameraInformation) {
		this._cameraInformation.show();
	    }
	    if (this._cameraList) {
		this._cameraList.show();
	    }
	},
	
	_hide: function() {
            if (this._cameraInformation) {
		this._cameraInformation.hide();
	    }
	    if (this._cameraList) {
		this._cameraList.hide();
	    }
	},
	
	_remove: function() {
            if (this._cameraInformation) {
		this._cameraInformation.remove();
	    }
	    if (this._cameraList) {
		this._cameraList.remove();
	    }
	}
    });
    
})(jQuery);
