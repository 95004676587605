EmPower.Model.DaemonInformation = (function($, undefined) {
    
    return EmPower.Model.AbstractModel.extend({
	
	idAttribute: 'id',
	    
	modelName: 'Daemon Information',
	
	urlRoot: EmPower.Util.Url.apiBuilder('serverinfo', 'daemon'),

	_initialize: function() {
	},

	_parse: function() {
	}
    });
    
})(jQuery);
