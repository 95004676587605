EmPower.Model.Bushing = (function($, undefined) {
    
    return EmPower.Model.AbstractDeviceEventModel.extend({
	    
	modelName: 'Bushing',

	_initialize: function() {
	},

	_parse: function(response, options) {
	    return response;
	},
        
        condition: function(value) {
	    return this._field('condition', value);
	},
        
        phase: function(value) {
	    return this._field('phase', value);
	},
        
        signalQuality: function(value) {
	    return this._field('signalQuality', value);
	},
        
        winding: function(value) {
	    return this._field('winding', value);
	}
        
    });
    
})(jQuery);
