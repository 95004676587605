EmPower.View.AnnunciatorRow = (function ($, undefined) {

    return EmPower.View.AbstractView.extend({
        viewName: "Annunciator Row",

        _events: function () {
            var self = this;
            return {
                "click [data-action=acknowledge-sticky]": function (event) {
                    event.preventDefault();
                    var id = event.currentTarget.dataset.annuncid;
                    self.acknowledgeAnnunciator(id);
                }
            };
        },

        _initialize: function () {
            this._annunciatorModel = this._getOption("annunciatorModel", true);
            this._selectedTransformer = this._session.transformerSession().selectedTransformer();
        },

        _setupListeners: function () {

            var self = this;

            self.listenTo(self.getAnnunciatorModel(), 'change', function () {
                self.renderDebounced();
            });
            self.listenTo(self._selectedTransformer, 'change', function () {
                this.renderDebounced();
            });
        },

        getAnnunciatorModel: function () {
            return this._annunciatorModel;
        },

        acknowledgeAnnunciator: function (annunciatorId) {
            var url = EmPower.Util.Url.apiBuilder("transformers", this._selectedTransformer.selectedId(), "annunciator", annunciatorId, "acknowledge");
            $.ajax({
                type: "POST",
                url: url,
                success: function () {
                    $.notify({
                        message: "<strong>Success:</strong>  Annunciator " + annunciatorId + " acknowledged. Status will update pending RCTU response."
                    }, {
                        type: 'success',
                        placement: {
                            from: 'top',
                            align: 'center'
                        }
                    });
                },
                error: function (err) {
                    $.notify({
                        message: "<strong>Failure:</strong> Could not acknowledge annunciator " + annunciatorId
                    }, {
                        type: 'danger',
                        placement: {
                            from: 'top',
                            align: 'center'
                        }
                    });
                }
            });

        },

        _htmlTemplate: function () {
            return EmPower.Templates['transformer/annunciator/annunciator_row'];
        },

        _htmlData: function () {

            var stateClass;
            switch (this.getAnnunciatorModel().value()) {
                case 2: // annunciator needs acknowledged, flashing orange background
                    stateClass = "label-warning flashing acknowledgeable";
                    break;
                case 1: // annunciator is firing, orange background
                    stateClass = "label-warning not-acknowledgeable";
                    break;
                case 0: // annunciator is not firing, gray background
                    stateClass = "label-default not-acknowledgeable";
                    break;
                default: // no events, grey background with grey text
                    stateClass = "label-default disabled not-acknowledgeable";
                    break;
            }

            return {
                stateClass: stateClass,
                annunciatorModel: this.getAnnunciatorModel()
            };
        },

        _render: function () {
        },

        _show: function () {
        },

        _hide: function () {
        },

        _remove: function () {
        }

    });

})(jQuery);
