EmPower.Model.AbstractSubSession = (function(Model, $, undefined) {
    
    return Model.extend({
        whatAmI: function(){return "EmPower.Model.AbstractSubSession";},
        
        initialize: function() {
            
            if (!this._session()) {
		this._logger.throwError("Sub-session was not created with a specific session instance.");
	    }

            this.listenTo(this._session(), 'reset', function(options) {
                this.reset(options);
            });
            
	    Model.prototype.initialize.apply(this, arguments);
	},
        
        _session: function(value) {
            return this._field('session', value);
        }
    });
    
})(EmPower.Model.AbstractSession, jQuery);


