EmPower.Collection.AnnunciatorEvent = (function($, undefined) {
    
    return EmPower.Collection.AbstractDeviceEventCollection.extend({
	collectionName: 'Annunciator Event',
	
	model: EmPower.Model.Annunciator,
	
	_url: function() {
	    return EmPower.Util.Url.apiBuilder('transformers', this._selectedObject.selectedId(), 'annunciator', 'events', 'no-periodic');
	},
	
	_initialize: function() {
	},
	
	_parse: function() {
	}
    });
    
})(jQuery);
