EmPower.View.LtcTable = (function($, undefined) {

    return EmPower.View.AbstractEventTableView.extend({
	viewName: "Transformer LTC Table",
	
	_getEventCollection: function() {
	    return this._session.transformerSession().ltcEventCollection();
	},
	
	_createTableView: function($container, eventCollection) {
	    return new bbGrid.View({
		rows: 7,
		container: $container,
		collection: eventCollection,
		colModel: [{
			title: 'Timestamp',
			index: true,
			name: 'timestamp',
			sorttype: 'date'
		    }, {
			title: 'Tap Position',
			index: true,
			name: 'value',
			sorttype: 'number'
		    }, {
			title: 'Total Position Changes',
			index: true,
			name: 'totalPosChanges',
			sorttype: 'number'
		    }, {
			title: 'Current Draw',
			index: true,
			name: 'currentDraw',
			sorttype: 'number'
		    }]
	    });
	}
    });
    
})(jQuery);
