EmPower.Route.AbstractSubRoute = (function(SubRoute, $, undefined) {
    
    // private namespace
    var that = {};
    
    that.AbstractSubRoute = EmPower.Route.AbstractRouterObject(SubRoute, "SubRoute");
    
    return that.AbstractSubRoute.extend({
	whatAmI: function(){return "EmPower.Route.AbstractSubRoute";},
	
	initialize: function() {
	    
	    this._session = this._getOption("session", true);
	    
	    that.AbstractSubRoute.prototype.initialize.apply(this, arguments);
	    
	    return this;
	},
	
	_beforeExecute: function(callback, args, name) {
	    return this._session.applicationRouter()._beforeExecute(callback, args, name, this);
	},
	
	showPageNotFound: function() {
	    
	    var self = this;
	    
	    var showPageNotFound = function() {
		self._session.applicationRouter().showPageNotFound();
	    };
	    
	    self.showPageNotFound = showPageNotFound;
	    
	    return showPageNotFound();
	},
	
	showLogin: function() {
	    
	    var self = this;
	    
	    var showLogin = function() {
		self._session.applicationRouter().showLogin();
	    };
	    
	    self.showLogin = showLogin;
	    
	    return self.showLogin();
	},
	
	_show: function() {
		return this._logger.throwError("SubRoute was not extend with a show function.");
	},
	
	show: function() {
	    this._session.selectedSection().selected(this);
	    this._show();
	},

	_hide: function() {
	},
	
	hide: function() {
	    if (this._session.selectedSection().selected() === this) {
		this._hide();
		this._session.selectedSectionView().selected(null);
		this._session.selectedSection().selected(null);
	    }
	}
    });
    
})(Backbone.SubRoute, jQuery);
