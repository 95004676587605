EmPower.Model.Temperature = (function($, undefined) {
    
    return EmPower.Model.AbstractDeviceEventModel.extend({
	    
	modelName: 'Temperature',

	_initialize: function() {
	},

	_parse: function(response, options) {
	    return response;
	},
	
	value: function(value) {
	    return this._field('value', value);
	},
        
        hasEvent: function(value) {
            return this.time() ? true : false;
        },
	
	valueDisplay: function(temperatureUnit) {
            // give fahrenheit if specified, give celsius if specified or no unit is given
            if (temperatureUnit === "F") {
                return (this.value() !== undefined && this.value() !== null) ? ((this.value() * 9/5) + 32).toFixedNumber(1) + " &#8457;" : "No Events";
            } else {
                return (this.value() !== undefined && this.value() !== null) ? this.value().toFixedNumber(1) + " &#8451;" : "No Events";
            }
	    
	},
	
	inputNumber: function(value) {
	    return this._field('inputNumber', value);
	},
	
	temperatureTypeId: function(value) {
	    return this._field('temperatureTypeId', value);
	},
	
	temperatureTypeName: function(value) {
	    return this._field('temperatureTypeName', value);
	},
	
	temperatureTypeAbbrev: function(value) {
	    return this._field('temperatureTypeAbbrev', value);
	},
        
        time: function(value) {
            var timestamp = this._field('time', value);
            return timestamp ? moment(timestamp).format('YYYY-MM-DDTHH:mm:ssZ') : "No Date";
        }
    });
    
})(jQuery);
