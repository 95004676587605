EmPower.View.DocumentPreview = (function($, undefined) {

    return EmPower.View.AbstractView.extend({
	viewName: "Document Preview",
	
	_events: function() {
	},
	
	_initialize: function() {
	    this._selectedDocument = this._getOption('selectedDocument', true);
	    this._documentModel(this._selectedDocument.selected());
	},
	
	_setupListeners: function() {
	    
	    var self = this;
	    
	    self.listenTo(self._selectedDocument, 'change', function() {
                self._documentModel(self._selectedDocument.selected());
                self.renderDebounced();
            });
	},
	
	_documentModel: function(selectedDocumentModel) {
	    
	    var self = this;
	    
	    if (selectedDocumentModel !== undefined) {
	    
		if (self._selectedDocumentModel !== selectedDocumentModel) {

		    if (self._selectedDocumentModel) {
			self.stopListening(self._selectedDocumentModel);
		    }

		    if (selectedDocumentModel) {
			self.listenTo(selectedDocumentModel, 'change', function() {
                            self.renderDebounced();
                        });
                        
                        self.listenTo(selectedDocumentModel, 'destroy', function() {
                            self._selectedDocument.selected(null);
                        });
		    }
		}

		self._selectedDocumentModel = selectedDocumentModel;
	    }
	    
	    return self._selectedDocumentModel;
	},
	
	_htmlTemplate: function() {
	    return EmPower.Templates['transformer/document/document_preview'];
	},
	
	_htmlData: function() {
	    
	    var documentEmbeded;
	    if (this._documentModel()) {
		
		var documentUrl = this._documentModel().previewUrl();
                var downloadUrl = this._documentModel().downloadUrl();
		
		var fileType = _.last(this._documentModel().fileName().split(".")).toLowerCase();
		
		if(fileType === "pdf") {

		    documentEmbeded = [
			'<object width="100%" data="' + documentUrl + '" type="application/pdf">',
			'  <div class="alert alert-warning" style="margin:15px;">',
			'    <i class="fa fa-exclamation-circle" aria-hidden="true" style="padding-right: 5px;"></i>',
			'    It appears you don\'t have Adobe Reader or PDF support in this web browser. ', 
			'    <a href="' + downloadUrl + '">Click here to download the PDF</a> ',
			'    or <a href="https://get.adobe.com/reader/" target="_blank">click here to install Adobe Reader</a>.',
			'  </div>',
			'  <embed src="' + documentUrl + '" type="application/pdf">',
			'</object>'
		    ].join('');
		    
		} else if (fileType === "jpg" || fileType === "jpeg" || fileType === "png") {
                    //  fileType === "gif" || fileType === "bmp" || fileType === "txt"
		  
		    // Browser supported mimeType, display in iframe
		    documentEmbeded = '<a class="lightbox-link" href="' + documentUrl + '" data-lightbox="docimg"></a><img class="lightbox-image" src="' + documentUrl + '" frameborder="0"></img>';
                    
		} else if (fileType === "xml") {
                    
                    documentEmbeded = [
			'<div class="alert alert-warning" style="margin:15px;">',
			'  <i class="fa fa-exclamation-circle" aria-hidden="true" style="padding-right: 5px;"></i>',
			'  Sorry, preview for document type not supported.', 
			'  <a href="' + documentUrl + '" target="_blank">Click here to open the document in a new window</a>. ',
			'</div>'
		    ].join('');
                    
                } else {
		    
		    documentEmbeded = [
			'<div class="alert alert-warning" style="margin:15px;">',
			'  <i class="fa fa-exclamation-circle" aria-hidden="true" style="padding-right: 5px;"></i>',
			'  Sorry, preview for document type not supported.', 
			'  <a href="' + downloadUrl + '">Click here to download the document</a>. ',
			'</div>'
		    ].join('');
		}
	    }
	    
	    return {
		documentModel: this._documentModel(),
		documentEmbeded: documentEmbeded
	    };
	},
	
	_render: function() {
	},
	
	_show: function() {
	},
	
	_hide: function() {
	},
	
	_remove: function() {
	}
    });
    
})(jQuery);
