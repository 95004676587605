EmPower.Model.SelectedObject = (function($, undefined) {
    
    return EmPower.Model.AbstractModel.extend({
	
	modelName: 'Selected Object',

	_initialize: function() {
	    this.selected(undefined);
	},

	_parse: function(response) {
	    return response;
	},

	selected: function(value) {
	    return this._field('selectedObject', value);
	},
	
	getPreviouslySelected: function() {
	    return this.previous('selectedObject');
	},
	
	hasSelected: function() {
	    return this.selected() ? true : false;
	}
    });
    
})(jQuery);
