EmPower.View.LtcMotorCurrentsTable = (function($, undefined) {

    return EmPower.View.AbstractEventTableView.extend({
	viewName: "Transformer LTC Motor Currents Table",
	
	_getEventCollection: function() {
	    return this._session.transformerSession().ltcMotorCurrentsEventCollection();
	},
	
	_createTableView: function($container, eventCollection) {
	    return new bbGrid.View({
		rows: 7,
		container: $container,
		collection: eventCollection,
		colModel: [{
			title: 'Timestamp',
			index: true,
			name: 'time',
			sorttype: 'date'
		    }, {
			title: 'Time to Change',
			index: true,
			name: 'inRushCurrentDuration',
			sorttype: 'number'
		    }, {
			title: 'Mean Current',
			index: true,
			name: 'meanCurrent',
			sorttype: 'number'
		    }, {
			title: 'Peak Current',
			index: true,
			name: 'peakCurrent',
			sorttype: 'number'
		    } /*, {
			title: 'In Rush Current Array',
			index: true,
			name: 'inRushCurrentArray',
			sorttype: 'number'
		    }*/]
	    });
	}
    });
    
})(jQuery);
