EmPower.View.TemperatureRow = (function($, undefined) {
    
    return EmPower.View.AbstractView.extend({
	viewName: "Temperature Row",
	
	_events: function() {
	},
	
	_initialize: function() {
	    this._temperatureModel = this._getOption("temperatureModel", true);
            this._temperatureUnit = this._getOption("temperatureUnit", true);
	},
	
	_setupListeners: function() {
	    
	    var self = this;
	    
	    self.listenTo(self.getTemperatureModel(), 'change', function() {
		self.renderDebounced();
	    });
	},
        
        getTemperatureUnit: function() {
            return this._temperatureUnit;
        },
	
	getTemperatureModel: function() {
	    return this._temperatureModel;
	},
	
	_htmlTemplate: function() {
	    return EmPower.Templates['transformer/temperature/temperature_row'];
	},
	
	_htmlData: function() {
	    
	    var cssClass;
	    var icon;
	    
	    var value = this.getTemperatureModel().value();
	    
	    if (value === undefined || value === null) {
		cssClass = "disabled";
		icon = "fa-thermometer-empty";
	    } else if (value < 12.5) {
		cssClass = "empty";
		icon = "fa-thermometer-empty";
	    } else if (value < 37.5) {
		cssClass = "quarter";
		icon = "fa-thermometer-quarter";
	    } else if (value < 62.5) {
		cssClass = "half";
		icon = "fa-thermometer-half";
	    } else if (value < 110.0) {
		cssClass = "three-quarters";
		icon = "fa-thermometer-three-quarters";
	    } else if (110.0 <= value) {
		cssClass = "full";
		icon = "fa-thermometer-full";
	    }
	    
	    return {
		temperatureModel: this.getTemperatureModel(),
		icon: icon,
		cssClass: cssClass,
                temperatureUnit: this.getTemperatureUnit()
	    };
	},
	
	_render: function() {
	},
	
	_show: function() {
	},
	
	_hide: function() {
	},
	
	_remove: function() {
	}
	
    });
    
})(jQuery);
