EmPower.View.UserInformation = (function($, undefined) {
    
    return EmPower.View.AbstractView.extend({
	
	viewName: "User Information",
	
	_events: function() {
	    return {
		'click button[data-action="editUser"]': function() {
		    EmPower.Dialog.User.information(this._session, this._userModel());
		},
		
		'click button[data-action="changePassword"]': function() {
		    EmPower.Dialog.User.password(this._userModel());
		},
		
		'click button[data-action="disableUser"]': function() {
		    EmPower.Dialog.User.disable(this._userModel());
		},
		
		'click button[data-action="enableUser"]': function() {
		    EmPower.Dialog.User.enable(this._userModel());
		}
	    };
	},
	
	_initialize: function() {
	    this._selectedUser = this._session.transformerSession().selectedUserInformation();
	    this._userModel(this._selectedUser.selected());
	},
	
	_setupListeners: function() {
	    
	    var self = this;
	    
	    self.listenTo(
		    self._selectedUser,
		    'change',
		    function() {
			self._userModel(self._selectedUser.selected());
			self.renderDebounced();
		    }
	    );
	},
	
	_userModel: function(selectedUserModel) {
	    
	    var self = this;

	    if (selectedUserModel !== undefined) {
		
		if (self._selectedUserModel !== selectedUserModel) {

		    if (self._selectedUserModel) {
			self.stopListening(self._selectedUserModel);
		    }

		    if (selectedUserModel) {
			self.listenTo(
				selectedUserModel,
				'change',
				function() {
				    self.renderDebounced();
				}
			);
		    }
		}

		self._selectedUserModel = selectedUserModel;
	    }
	    
	    return self._selectedUserModel;
	},
	
	_htmlTemplate: function() {
	    return EmPower.Templates['transformer/user/user_information'];
	},
	
	_htmlData: function() {
	    
	    var userModel = this._userModel();
	    var data = {
		user: userModel
	    };
	    
	    if (userModel) {
		data.firstName = userModel.firstName() ? userModel.firstName() : "[First Name]";
		data.lastName = userModel.lastName() ? userModel.lastName() : "[Last Name]";
		data.isEnabled = true; //userModel.isEnabled() === 1 ? true : false;
		
		data.canChangePermission = false; //userModel.getCanChangePermission();
		data.canDisable = false; //userModel.getCanDisable();
		data.canEdit = false; //userModel.getCanEdit();

		data.rows = [
		    {key: "Company", class: "", value: userModel.company()},
		    {key: "Title", class: "", value: userModel.title()},
		    {key: "Phone Number", class: "", value: userModel.phoneNumber()},
		    {key: "E-Mail", class: "", value: userModel.email()},
		    {key: "Access Card Number", class: "", value: userModel.accessCardNumber()},
		    {key: "Access Card ID", class: "", value: userModel.accessCardId()},
		    {key: "Access Card Facility ID", class: "", value: userModel.accessCardFacilityId()}
		];
	    }
		
	    return data;
	},
	
	_render: function() {
	},
	
	_show: function() {
	},
	
	_hide: function() {
	},
	
	_remove: function() {
	}
    });
    
})(jQuery);
