EmPower.View.DocumentRow = (function($, undefined) {

    return EmPower.View.AbstractView.extend({
	viewName: "Document Row",
	
	_events: function() {
	},
	
	_initialize: function() {
	    this._documentModel = this._getOption("documentModel", true);
	},
	
	_setupListeners: function() {
	    
	    var self = this;
	    
	    self.listenTo(self.getDocumentModel(), 'change', function() {
		self.renderDebounced();
	    });
	},
	
	getDocumentModel: function() {
	    return this._documentModel;
	},
	
	_htmlTemplate: function() {
	    return EmPower.Templates['transformer/document/document_row'];
	},
	
	_htmlData: function() {
	    return {
		documentModel: this.getDocumentModel()
	    };
	},
	
	_render: function() {
	},
	
	_show: function() {
	},
	
	_hide: function() {
	},
	
	_remove: function() {
	}
	
    });
    
})(jQuery);
