(function(Model, $, undefined) {

    Model.open = function(model, title) {
        
        var $html = $(EmPower.Templates['templates/dialog_model_container']({
            model: model
        }));
        
        title = title ? "Model Attributes for  <strong>" + title + "</strong>" : "Model Attributes";
        
	var dialog = new BootstrapDialog({
	    title: title,
            draggable: true
	});
        
	dialog.setMessage($html);
	
	var onClose = function(dialog) {
	    dialog.close();
	};
	
	var cancelButton = {
	    label: 'Close',
	    cssClass: 'btn-default left',
	    action: onClose
	};
	
	dialog.setButtons([cancelButton]);
        
	return dialog.open();
    };

})(EmPower.Dialog.Model = EmPower.Dialog.Model || {}, jQuery);
