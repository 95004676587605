EmPower.Model.RctuInformation = (function($, undefined) {
    
    return EmPower.Model.AbstractModel.extend({
	
	idAttribute: 'id',
	    
	modelName: 'RCTU Information',

	_initialize: function() {
	},

	_parse: function() {
	}
    });
    
})(jQuery);
