EmPower.Model.UserAccess = (function($, undefined) {
   
    return EmPower.Model.AbstractDeviceEventModel.extend({
	    
	modelName: 'UserAccess',

	_initialize: function(value) {
	},

	_parse: function(response, options) {
	    return response;
	},
	
	user: function(value) {
	    return this._field('user', value);
	},
	
	accessPanels: function(value){
		return this._field('AccessPanels', value);
	}
	});
})(jQuery);