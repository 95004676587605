EmPower.View.AccessPanelList = (function($, undefined) {

    return EmPower.View.AbstractCollectionView.extend({
	viewName: "Access Panel List",
	
	_events: function() {
	},
	
	_initialize: function() {
	    this._applicationRouter = this._session.applicationRouter();
	},
	
	_setupListeners: function() {
	},
	
	_htmlTemplate: function() {
	    return EmPower.Templates['transformer/security/access-panel/access_panel_list'];
	},
	
	_htmlData: function() {
	},
	
	_getCollectionContainer: function($html) {
	    return $html.filter("div[data-section='access-panel-list']");
	},
	
	_createModelView: function(model) {
	    return new EmPower.View.AccessPanelRow({
		session: this._session,
		accessPanelModel: model
	    });
	},
	
	_getLoadingCollectionMessage: function() {
	    return "Loading access panels, please wait...";
	},
	
	_getEmptyCollectionMessage: function() {
	    return "Sorry, no access panels have been configured.";
	},
	
	_render: function() {
	},
	
	_show: function() {
	},
	
	_hide: function() {
	},
	
	_remove: function() {
	}
    });
    
})(jQuery);
