EmPower.Model.TransformerSubview = (function($, undefined) {
    
    return EmPower.Model.AbstractModel.extend({
	    
	modelName: 'Transformer Subview',

	_initialize: function() {
            
            if (this.title() === undefined) {
		this._logger.throwError("Transformer Subview was not created with a specific title.");
	    }
            
            if (this.path() === undefined) {
		this._logger.throwError(this.title() + " was not created with a specific path.");
	    }
            
            if (this.view() === undefined) {
		this._logger.throwError(this.title() + " was not created with a specific view instance.");
	    }
	},

	_parse: function() {
	},
        
        title: function() {
            return this._field('title');
        },

        path: function() {
            var path = this._field('path');
            if (_.isFunction(path)) {
                return path.apply(this, arguments);
            }
            return path;
        },

        view: function() {
            var view = this._field('view');
            if (_.isFunction(view)) {
                return view.apply(this, arguments);
            }
            return view;
        },

        hasCompanionView: function() {
            return this._field('companionView') ? true : false;
        },

        companionView: function() {
            var companionView = this._field('companionView');
            if (_.isFunction(companionView)) {
                return companionView.apply(this, arguments);
            }
            return companionView;
        },

        hasGraphCollection: function() {
            return this._field('graphCollection') ? true : false;
        },

        graphCollection: function() {
            var graphCollection = this._field('graphCollection');
            if (_.isFunction(graphCollection)) {
                return graphCollection.apply(this, arguments);
            }
            return graphCollection;
        },

        graphOptions: function(value) {
            
            var options = this._field('graphOptions', value);
            if (options) {
                
                var title = this.title() ? this.title() : "";
                title += " Graph";
                options = _.merge({}, {
                    options: {
                        title: {
                            text: title
                        }
                    }
                }, options);
            }
            
            return options;
        }
    });
    
})(jQuery);
