EmPower.View.AbstractContentFrame = (function(View, $, undefined) {
    
    // TODO - Extend abstract view to make an abstract content frame...
	// based off of AbstractCollectionView...
    
    return View.extend({
	
	initialize: function() { 
	    
	    this._collection = this._getOption("collection", true);
	    
	    View.prototype.initialize.apply(this, arguments);
	},
	
	setupListeners: function() {

	    var self = this;
	    
	    if (!self._isListenersSetup) {
		
		self._logger.warn("Setting up content frame listeners", this);
		
		self.listenTo(self.getCollection(), 'update reset', function() {
		    self.renderDebounced();
		});
		
		if (self.getSelectedModel()) {
		    self.listenTo(self.getSelectedModel(), 'change', function() {

			var previouslySelected = self.getSelectedModel().getPreviouslySelected();
			if (previouslySelected) {
			    self.$html.find('#' + previouslySelected.cid).removeClass("active");
			}

			var selected = self.getSelectedModel().selected();
			if (selected) {
			    self.$html.find('#' + selected.cid).addClass("active");
			}
		    });
		}
	    }
            
            View.prototype.setupListeners.apply(self, arguments);
	},
	
	_getCollectionContainer: function($html) {
	    this._logger.throwError("Collection View was not extended with specific Collection Container.");
	},
	
	_createModelView: function(model) {
	    this._logger.throwError("Collection View was not extended with specific Create Model View.", model);
	},
	
	_afterHtmlRendered: function($html) { 
	    // attach subview render...
	    var self = this;

	    var $collectionContainer = self._getCollectionContainer($html);
	    if ($collectionContainer.length === 0) {
		self._logger.throwError("Collection View was extended an empty Collection Container.");
	    }
	    
	    var $collectionList = $(self._collectionContainerTemplate());
	    $collectionContainer.append($collectionList);

	    var removed = _.clone(self._modelViews);
	    var $previousElement = null;
	    _.forEach(self.getCollection().models, function(model) {

		var modelView = self._modelViews[model.cid];
		var $modelViewContainer = null;
		if (modelView) {
		    
		    delete removed[model.cid];
		    $modelViewContainer = modelView.$el;
		} else {
		    
		    var isSelected = false;
		    if (self.getSelectedModel()) {
			var selected = self.getSelectedModel().selected();
			if ((selected && selected.cid) && (model && model.cid)) {
			    isSelected = selected.cid === model.cid;
			}
		    }
		    
		    $modelViewContainer = $(self._modelContainerTemplate({
			model: model,
			isSelected: isSelected
		    }));
		    
		    modelView = self._createModelView(model);
		    modelView.render($modelViewContainer);
		    self._modelViews[model.cid] = modelView;
		}

		if ($previousElement) {
		    $previousElement.after($modelViewContainer);
		} else {
		    $collectionList.append($modelViewContainer);
		}
		$previousElement = $modelViewContainer;
	    });
	    
	    _.forOwn(removed, function(modelView, cid, modelViews) {
		$collectionList.find('#' + cid).remove();
		modelView.remove();
		delete self._modelViews[cid];
	    });
	},
	
	_toggleDisplay: function(isDisplay) {
	    
	    var isVisible = this.isVisible();
	    
	    var retVal = View.prototype._toggleDisplay.apply(this, arguments);
	    
	    if (isDisplay) {
		if (!isVisible) {
		    _.forOwn(this._modelViews, function(modelView, cid, modelViews) {
			modelView.show();
		    });
		}
	    } else {
		if (isVisible) {
		    _.forOwn(this._modelViews, function(modelView, cid, modelViews) {
			modelView.hide();
		    });
		}
	    }

	    return retVal;
	},
	
	remove: function() {
	    
	    View.prototype.remove.apply(this, arguments);
	    
	    _.forOwn(this._modelViews, function(modelView, cid, modelViews) {
		modelView.remove();
	    });
	}
    });

})(EmPower.View.AbstractView, jQuery);
