EmPower.View.Security = (function($, undefined) {

    return EmPower.View.AbstractView.extend({
	viewName: "Transformer Security",
	
	_events: function() {
	},
	
	_initialize: function() {
	    this._selectedTransformer = this._session.transformerSession().selectedTransformer();
	    
	    this._accessGateCollection = new EmPower.Collection.AccessGate(null, {
		session: this._session,
		selectedObject: this._selectedTransformer
	    });
	    
	    this._accessPanelCollection = new EmPower.Collection.AccessPanel(null, {
		session: this._session,
		selectedObject: this._selectedTransformer
	    });
	},
	
	_setupListeners: function() {
	},
	
	_htmlTemplate: function() {
	    return EmPower.Templates['transformer/security/transformer_security'];
	},
	
	_htmlData: function() {
            return {
                selectedTransformer: this._selectedTransformer.selected()
            };
	},
	
	_render: function($html) {
	    
	    var $accessGateList = $html.find("div[data-section='transformer-access-gate-list']");
	    if (!this._accessGateList) {

		this._accessGateList = new EmPower.View.AccessGateList({
		    session: this._session,
		    collection: this._accessGateCollection,
		    selectedModel: null
		});
		this._accessGateList.render($accessGateList);
	    } else {
		$accessGateList.replaceWith(this._accessGateList.$el);
	    }
	    
	    var $accessPanelList = $html.find("div[data-section='transformer-access-panel-list']");
	    if (!this._accessPanelList) {

		this._accessPanelList = new EmPower.View.AccessPanelList({
		    session: this._session,
		    collection: this._accessPanelCollection,
		    selectedModel: null
		});
		this._accessPanelList.render($accessPanelList);
	    } else {
		$accessPanelList.replaceWith(this._accessPanelList.$el);
	    }
            
            var $floorPlan = $html.find("div[data-section='transformer-floor-plan']");
	    if (!this._floorPlan) {

		this._floorPlan = new EmPower.View.FloorPlan({
		    session: this._session
		});
		this._floorPlan.render($floorPlan);
	    } else {
		$floorPlan.replaceWith(this._floorPlan.$el);
	    }
	},
	
	_show: function() {
	    if (this._accessGateList) {
		this._accessGateList.show();
	    }
	    if (this._accessPanelList) {
		this._accessPanelList.show();
	    }
            if (this._floorPlan) {
		this._floorPlan.show();
	    }
	},
	
	_hide: function() {
	    if (this._accessGateList) {
		this._accessGateList.hide();
	    }
	    if (this._accessPanelList) {
		this._accessPanelList.hide();
	    }
            if (this._floorPlan) {
		this._floorPlan.hide();
	    }
	},
	
	_remove: function() {
	    if (this._accessGateList) {
		this._accessGateList.remove();
	    }
	    if (this._accessPanelList) {
		this._accessPanelList.remove();
	    }
            if (this._floorPlan) {
		this._floorPlan.remove();
	    }
	}
    });
    
})(jQuery);
