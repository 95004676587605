EmPower.View.DynamicLoadingTemperatureGraph = (function(View, $, undefined) {
    
    return View.extend({
        viewName: "Dynamic Loading - Temperature Graph",
	
	_initialize: function() {
            this.collections(this._session.transformerSession().dynamicLoadingEventCollection());
            this.graphOptions({
                options: {
                    scales: {
                        yAxes: [
                            EmPower.Util.SimpleChartAxis.temperature({
                                id: "y-axis-temperature"
                            })
                        ]
                    }
                },
                datasetConfig: [{
                    datasetId: function(model) {
                        return "dl-ambientTemp";
                    },
                    label: function(model) {
                        return "Ambient Temperature";
                    },
                    yValue: function(model) {
                        return (model.ambientTemp() !== undefined && model.ambientTemp() !== null) ? (model.ambientTemp()).toFixedNumber(2) : null;
                    },
                    datasetOptions: function(model) {
                        return {
                            yAxisID: "y-axis-temperature"
                        };
                    }
                }, {
                    datasetId: function(model) {
                        return "dl-topOilTempInit";
                    },
                    label: function(model) {
                        return "Top Oil Temperature";
                    },
                    yValue: function(model) {
                        return (model.topOilTempInit() !== undefined && model.topOilTempInit() !== null) ? (model.topOilTempInit()).toFixedNumber(2) : null;
                    },
                    datasetOptions: function(model) {
                        return {
                            yAxisID: "y-axis-temperature"
                        };
                    }
                }, {
                    datasetId: function(model) {
                        return "dl-topOilTempUlt";
                    },
                    label: function(model) {
                        return "Top Oil Temperature Ultimate";
                    },
                    yValue: function(model) {
                        return (model.topOilTempUlt() !== undefined && model.topOilTempUlt() !== null) ? (model.topOilTempUlt()).toFixedNumber(2) : null;
                    },
                    datasetOptions: function(model) {
                        return {
                            yAxisID: "y-axis-temperature"
                        };
                    }
                }, {
                    datasetId: function(model) {
                        return "dl-windingHsTempInit";
                    },
                    label: function(model) {
                        return "Winding Hotspot Temperature";
                    },
                    yValue: function(model) {
                        return (model.windingHsTempInit() !== undefined && model.windingHsTempInit() !== null) ? (model.windingHsTempInit()).toFixedNumber(2) : null;
                    },
                    datasetOptions: function(model) {
                        return {
                            yAxisID: "y-axis-temperature"
                        };
                    }
                }, {
                    datasetId: function(model) {
                        return "dl-windingHsTempUlt";
                    },
                    label: function(model) {
                        return "Winding Hotspot Temperature Ultimate";
                    },
                    yValue: function(model) {
                        return (model.windingHsTempUlt() !== undefined && model.windingHsTempUlt() !== null) ? (model.windingHsTempUlt()).toFixedNumber(2) : null;
                    },
                    datasetOptions: function(model) {
                        return {
                            yAxisID: "y-axis-temperature"
                        };
                    }
                }]
            });
	}
    });
    
})(EmPower.View.AbstractGraphView, jQuery);
