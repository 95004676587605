EmPower.Collection.Fetcher = (function(Collection, $, undefined) {

    var FetcherModel = EmPower.Model.AbstractModel.extend({
	
	modelName: 'Fetcher',
	
	_initialize: function() {
	    this.isFetching(false);
	    this.lastFetch(moment());
	    this.isConnected(true);
	    this.hasListeners(false);
	},

	_parse: function(response) {
	    return response;
	},

	isFetching: function(value) {
	    return this._field('isFetching', value);
	},

	lastFetch: function(value) {
	    return this._field('lastFetch', value);
	},

	isConnected: function(value) {
	    return this._field('isConnected', value);
	},
	
	hasListeners: function(value) {
	    return this._field('hasListeners', value);
	}
    });
    
    var CollectionListener = function(Collection, callee) {
	
	this._collection = Collection;
	this._activeListeners = new EmPower.Util.UniqueCollection();
	
	if (callee) {
	    this._activeListeners.add(callee);
	}
    };
    
    CollectionListener.prototype.addListener = function(callee) {

	this._activeListeners.add(callee);
    };
    
    CollectionListener.prototype.removeListener = function(callee) {

	this._activeListeners.remove(callee);
    };
    
    CollectionListener.prototype.hasListeners = function() {
	
	return !this._activeListeners.isEmpty();
    };
    
    CollectionListener.prototype.getCollection = function() {
	
	return this._collection;
    };
    
    CollectionListener.prototype.fetch = function() {
	
	// Needs to return the promise from fetch if collection fetcher view is to work correctly.
	if (false || _.isFunction(this.getCollection().fetchDebounce)) {

	    return this.getCollection().fetchDebounce();
	} else {

	    return this.getCollection().fetch();
	}
    };


    var CollectionFetcher = function(timeout) {
	
	this._logger = new EmPower.Util.Logger("Collection Fetcher", false);
	
	if (!timeout) {
	    
	    this._logger.throwError("A timeout must be set when initilizing a Collection Fetcher.");
	}

	this._timeout = timeout;
	
	this._collections = new EmPower.Util.UniqueCollection();
	
	this._collections.comparator(function(element, object) {  
	    return element.getCollection() === object;
	});
	
	this._collections.beforeDelete(function(element, callee) {
	    element.removeListener(callee);
	    return !element.hasListeners();
	});
	
	this._fetcherModel = new FetcherModel();
	
	this._startFetching();
    };
    
    CollectionFetcher.prototype.getModel = function() {
	return this._fetcherModel;
    };
    
    CollectionFetcher.prototype.setIsConnected = function(isConnected) {
	
	this._isConnected = isConnected;
    };
    
    CollectionFetcher.prototype.getTimeout = function() {
        return this._timeout;
    };
    
    CollectionFetcher.prototype.addCollection = function(collection, callee, check) {
	
	var found = null;
	if (collection && collection instanceof Collection) {
	    
            if (collection instanceof Backbone.Obscura) {
                collection = collection.superset();
            }
            
	    found = this._collections.find(collection);
	    if (!found) {
		
		found = new CollectionListener(collection, callee);
                
                if (!check) {
                    this._logger.info("Adding collection.", collection);

                    this._collections.add(found);

                    this.fetchAllDebounce(true);
                }
	    } else {
		
                if (!check) {
                    found.addListener(callee);
                }
	    }

	    this.getModel().hasListeners(!this._collections.isEmpty());
	}
	
	return found ? found.getCollection() : null;
    };
    
    CollectionFetcher.prototype.removeCollection = function(collection, callee) {
	
	if (collection && !_.isEmpty(collection)) {
	    var self = this;
            
	    if (!_.isArray(collection)) {
		collection = [collection];
	    }

	    this._logger.info("Removing collections.", collection);

	    _.forEach(collection, function(col) {
		if (col instanceof Collection) {
                    
                    if (col instanceof Backbone.Obscura) {
                        col = col.superset();
                    }

		    self._collections.remove(col, callee);
		}
	    });

	    this.getModel().hasListeners(!this._collections.isEmpty());
	}
    };
    
    CollectionFetcher.prototype.removeAllCollections = function() {
	
	this._logger.info("Removing all collections.", this._collections);
	this._collections = new EmPower.Util.UniqueCollection();
	
	this.getModel().hasListeners(!this._collections.isEmpty());
    };
    
    CollectionFetcher.prototype._timeoutId = function(timeoutId) {
	
	if (timeoutId !== undefined && this._runningTimeoutId) {
	    clearTimeout(this._runningTimeoutId);
	    this._runningTimeoutId = null;
	}
	
	if (timeoutId !== undefined) {
	    this._runningTimeoutId = timeoutId;
	}
	
	return this._runningTimeoutId;
    };
    
    CollectionFetcher.prototype._startFetching = function() {
	
	var self = this;
	
	this._timeoutId(window.setTimeout(_.bind(self.fetchAll, self), self._timeout));
    };
    
    CollectionFetcher.prototype.fetchAll = function(fetchEvents) {
	
	var self = this;
	
	self._timeoutId(null);

	self.getModel().isFetching(true);

        if (document.hasFocus()) {
            var apiPromises = [];
            _.forEach(self._collections.getCollection(), function(collection) {

                if (!(collection.getCollection() instanceof EmPower.Collection.AbstractEventCollection)) {
                    apiPromises.push(collection.fetch());
                } else if (fetchEvents) {
                    apiPromises.push(collection.fetch());
                }

            });
            
            $.when.apply($, apiPromises)
                .done(function() {
                    self.getModel().isConnected(true);
                    self.getModel().lastFetch(moment());
                })
                .fail(function() {
                    self.getModel().isConnected(false);
                })
                .always(function() {
                    self.getModel().isFetching(false);
                    self._logger.info("Setting next fetch timeout.", self);
                    self._timeoutId(window.setTimeout(_.bind(self.fetchAll, self), self._timeout));
                });
            
        } else {
            self.getModel().isFetching(false);
            self._logger.info("Setting next fetch timeout.", self);
            self._timeoutId(window.setTimeout(_.bind(self.fetchAll, self), 1500));
        }
    };
    
    CollectionFetcher.prototype.fetchAllDebounce = function(fetchEvents) {
	
	var self = this;
	    
	var debounce = _.debounce(function(fetchEvents) {
	    return self.fetchAll(fetchEvents);
	}, 100);

	self.fetchAllDebounce = debounce;

	return self.fetchAllDebounce(fetchEvents);
    };
    
    return CollectionFetcher;

})(Backbone.Collection, jQuery);
