(function(IePatch, $, undefined) {
    
    /**
     * Hack in support for Function.name for browsers that don't support it.
     * IE, I'm looking at you.
    **/
    if (Function.prototype.name === undefined && Object.defineProperty !== undefined) {
        Object.defineProperty(Function.prototype, 'name', {
            get: function() {
                var funcNameRegex = /function\s([^(]{1,})\(/;
                var results = (funcNameRegex).exec((this).toString());
                return (results && results.length > 1) ? results[1].trim() : "";
            },
            set: function(value) {}
        });
    }
    
    if (Number.prototype.toFixedNumber === undefined) {
        Number.prototype.toFixedNumber = function(x, base) {
            var pow = Math.pow(base || 10, x);
            return +(Math.round(this * pow) / pow);
        };
    }

})(EmPower.Util.IePatch = EmPower.Util.IePatch || {}, jQuery);
