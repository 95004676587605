EmPower.Collection.AbstractDeviceCollection = (function(Collection, $, undefined) {
    
    return Collection.extend({

	initialize: function() {
	    Collection.prototype.initialize.apply(this, arguments);
	},
	
	modelId: function(attr) {
	    return attr.deviceId;
	}
    });
    
})(EmPower.Collection.AbstractSelectedObjectCollection, jQuery);