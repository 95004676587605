EmPower.Collection.AbstractDeviceEventCollection = (function(Collection, $, undefined) {
    
    return Collection.extend({

	initialize: function() {
	    Collection.prototype.initialize.apply(this, arguments);
	},
	
	modelId: function(attr) {
	    return attr.id;
	},
	
	comparator: function(deviceEventTwo, deviceEventOne) {
	    var sort = 0;
	    if (deviceEventOne.timestamp() && deviceEventTwo.timestamp()) {
		sort = deviceEventOne.timestamp().localeCompare(deviceEventTwo.timestamp());
	    }
	    if (sort === 0 && deviceEventOne.deviceEventId() && deviceEventTwo.deviceEventId()) {
		if (deviceEventOne.deviceEventId() >= deviceEventTwo.deviceEventId()) {
		    sort = 1;
		} else if (deviceEventOne.deviceEventId() < deviceEventTwo.deviceEventId()) {
		    sort = -1;
		}
	    }
	    
	    return sort;
	}
    });
    
})(EmPower.Collection.AbstractEventCollection, jQuery);