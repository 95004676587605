EmPower.Model.AbstractDeviceEventModel = (function(Model, $, undefined) {
    
    return Model.extend({

	idAttribute: 'id',

	deviceEventId: function(value) {
	    return this._field('id', value);
	},
	
	description: function(value) {
	    var desc = this._field('description', value);
            var desc2 = this._field('description2', value);
            
            if (desc !== null) {
                return desc;
            } else if (desc2 !== null) {
                return desc2;
            } else {
                return null;
            }
	},
	
	transformerDeviceId: function(value) {
	    return this._field('transformerDeviceId', value);
	},
	
	deviceId: function(value) {
	    return this._field('deviceId', value);
	},
        
        isEnabled: function(value) {
            return this._field('isEnabled', value);
        }
    });
    
})(EmPower.Model.AbstractEventModel, jQuery);
