EmPower.Model.FanBank = (function($, undefined) {
    
    return EmPower.Model.AbstractDeviceEventModel.extend({
	    
	modelName: 'Fan Bank',

	_initialize: function() {
	},

	_parse: function(response, options) {
	    return response;
	}
	
    });
    
})(jQuery);
