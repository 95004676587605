EmPower.Model.DateRange = (function($, undefined) {
    
    // private namespace
    var that = {};
    
    that.dateFormat = null; // Defaults to ISO 8601
    
    that.dateFormatDisplay = "YYYY-MM-DD";
    
    return EmPower.Model.AbstractModel.extend({
	
	modelName: 'Date Range',

	_initialize: function() {
	    
	    var self = this;
	    
	    self._session = self._getOption('session', true);
	    
	    self.listenTo(self._session, 'reset', function() {
		self._initializeDateRange();
	    });
	    
	    this._initializeDateRange();
	},
	
	_initializeDateRange: function() {
	    this.fromDate(moment.utc().subtract(3, 'days').startOf('day'));
	    this.toDate(moment.utc().endOf('day'));
	},

	_parse: function() {
	},

	fromDate: function(value) {
	    if (_.isString(value)) {
		value = moment(value).startOf('day');
		value = moment.utc(value);
		if (!value.isValid()) {
		    value = undefined;
		}
	    }
	    return this._field('fromDate', value);
	},
	
	fromDateFormatted: function() {
	    return this.hasFromDate() ? this.fromDate().format(that.dateFormat) : null;
	},
	
	fromDateFormattedLocal: function() {
	    return this.hasFromDate() ? moment(this.fromDate()).local().format(that.dateFormatDisplay) : null;
	},
	
	hasFromDate: function() {
	    return this.fromDate() ? true : false;
	},
	
	toDate: function(value) {
	    if (_.isString(value)) {
		value = moment(value).endOf('day');
		value = moment.utc(value);
		if (!value.isValid()) {
		    value = undefined;
		}
	    }
	    return this._field('toDate', value);
	},
	
	toDateFormatted: function() {
	    return this.hasToDate() ? this.toDate().format(that.dateFormat) : null;
	},
	
	toDateFormattedLocal: function() {
	    return this.hasFromDate() ? moment(this.toDate()).local().format(that.dateFormatDisplay) : null;
	},
	
	hasToDate: function() {
	    return this.toDate() ? true : false;
	}
    });
    
})(jQuery);
