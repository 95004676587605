(function(EmPower, $, undefined) {
    
    // Initialize namespaces
    
    EmPower.apiVersion = 1;
    
    EmPower.Options = EmPower.Options || {};
    
    // Timeout for Ajax and Loading dialog
    EmPower.Options.timeout = 1000 * 60 * 10;
    
    // Global logger setting
    EmPower.Options.logger = false;
    
    // Global number of points to graph per dataset
    EmPower.Options.graphPoints = 500;
    
    // Global simplify attempts to make
    EmPower.Options.graphSimplifyAttempts = 30;
    
    // Global default E for simplify
    EmPower.Options.simplifyE = 1;
    
    EmPower.Util = EmPower.Util || {};
    EmPower.Collection = EmPower.Collection || {};
    EmPower.Model = EmPower.Model || {};
    EmPower.View = EmPower.View || {};
    EmPower.Route = EmPower.Route || {};
    EmPower.Dialog = EmPower.Dialog || {};
    
})(window.EmPower = window.EmPower || {}, jQuery);
