EmPower.Model.UserInformation = (function($, undefined) {
    
    return EmPower.Model.AbstractUserInformation.extend({
	
	idAttribute: 'userInformationId',
	
	modelName: 'User Information',

	urlRoot: EmPower.Util.Url.apiBuilder('userinformation'),

	_initialize: function() {
	},

	_parse: function(response) {
	    return response;
	}
    });
    
})(jQuery);
