EmPower.View.DynamicLoadingRow = (function ($, undefined) {

    return EmPower.View.AbstractView.extend({
        viewName: "Dynamic Loading Row",

        _events: function () {
        },

        _initialize: function () {
            this._selectedTransformer = this._session.transformerSession().selectedTransformer();

            this._dynamicLoadingModel = this._getOption("dynamicLoadingModel", true);

            this._metrologyCollection = this._metrologyCollection = new EmPower.Collection.Metrology(null, {
                session: this._session,
                selectedObject: this._selectedTransformer
            });

        },

        _setupListeners: function () {

            var self = this;

            self.listenTo(self.getDynamicLoadingModel(), 'change', function () {
                self.renderDebounced();
            });
            self.listenTo(self._metrologyCollection, 'update reset', function () {
                self.renderDebounced();
            });
        },

        getDynamicLoadingModel: function () {
            return this._dynamicLoadingModel;
        },

        _htmlTemplate: function () {
            return EmPower.Templates['transformer/dynamic-loading/dynamic_loading_row'];
        },

        _htmlData: function () {
            var dlModel = this.getDynamicLoadingModel();
            var isCurrentLimited = false;
            if (dlModel !== undefined && dlModel.attributes.ultLoad !== null && dlModel.attributes.currentLimit !== null) {
                isCurrentLimited = dlModel.attributes.ultLoad > dlModel.attributes.currentLimit;
            }

            var isSinglePhase = this._metrologyCollection.models[0] ? this._metrologyCollection.models[0].attributes.phases === 1 : false;
            return {
                dynamicLoadingModel: this.getDynamicLoadingModel(),
                isSinglePhase: isSinglePhase,
                isCurrentLimited: isCurrentLimited
            };
        },

        _render: function ($html) {

            var self = this;

            var $g1Inner = $html.find("#g1_inner");
            setTimeout(function () {
                if (self.getDynamicLoadingModel().load() !== undefined && self.getDynamicLoadingModel().load() !== null) {
                    if (!self.g1Inner) {
                        self.g1Inner = EmPower.Util.SimpleGauge.largeGauge($g1Inner, {
                            minValue: 0,
                            maxValue: 2,
                            staticZones: [{
                                    strokeStyle: "#2ecc71",
                                    min: 0,
                                    max: self.getDynamicLoadingModel().ultLoad() - 0.2
                                }, {
                                    strokeStyle: "#f1c40f",
                                    min: self.getDynamicLoadingModel().ultLoad() - 0.2,
                                    max: self.getDynamicLoadingModel().ultLoad()
                                }, {
                                    strokeStyle: "#e74c3c",
                                    min: self.getDynamicLoadingModel().ultLoad(),
                                    max: 2
                                }],
                            staticLabels: {
                                labels: [0, self.getDynamicLoadingModel().ultLoad() - 0.2, self.getDynamicLoadingModel().ultLoad(), 2]
                            }
                        });
                    } else {
                        $g1Inner.replaceWith($(self.g1Inner.canvas));
                        self.g1Inner.setOptions(_.merge({}, self.g1Inner.options, {
                            staticZones: [{
                                    strokeStyle: "#2ecc71",
                                    min: 0,
                                    max: self.getDynamicLoadingModel().ultLoad() - 0.2
                                }, {
                                    strokeStyle: "#f1c40f",
                                    min: self.getDynamicLoadingModel().ultLoad() - 0.2,
                                    max: self.getDynamicLoadingModel().ultLoad()
                                }, {
                                    strokeStyle: "#e74c3c",
                                    min: self.getDynamicLoadingModel().ultLoad(),
                                    max: 2
                                }],
                            staticLabels: {
                                labels: [0, self.getDynamicLoadingModel().ultLoad() - 0.2, self.getDynamicLoadingModel().ultLoad(), 2]
                            }
                        }));
                    }
                    self.g1Inner.set(self.getDynamicLoadingModel().load());
                } else {
                    $g1Inner.replaceWith(EmPower.Templates['templates/page_warning']({
                        message: "No Events"
                    }));
                }
            }, 1);

            var $g2Inner = $html.find("#g2_inner");
            setTimeout(function () {
                if (self.getDynamicLoadingModel().windingHsTempInit() !== undefined && self.getDynamicLoadingModel().windingHsTempInit() !== null) {
                    if (!self.g2Inner) {
                        self.g2Inner = EmPower.Util.SimpleGauge.smallGauge($g2Inner, {
                            minValue: 0,
                            maxValue: 180,
                            staticZones: [{
                                    strokeStyle: "#2ecc71",
                                    min: 0,
                                    max: 110
                                }, {
                                    strokeStyle: "#f1c40f",
                                    min: 110,
                                    max: 130
                                }, {
                                    strokeStyle: "#e74c3c",
                                    min: 130,
                                    max: 180
                                }],
                            staticLabels: {
                                labels: [0, 110, 130, 180],
                                fractionDigits: 0
                            }
                        });
                    } else {
                        $g2Inner.replaceWith($(self.g2Inner.canvas));
                    }
                    self.g2Inner.set(self.getDynamicLoadingModel().windingHsTempInit());
                } else {
                    $g2Inner.replaceWith(EmPower.Templates['templates/page_warning']({
                        message: "No Events"
                    }));
                }
            }, 1);

            var $g3Inner = $html.find("#g3_inner");
            setTimeout(function () {
                if (self.getDynamicLoadingModel().topOilTempInit() !== undefined && self.getDynamicLoadingModel().topOilTempInit() !== null) {
                    if (!self.g3Inner) {
                        self.g3Inner = EmPower.Util.SimpleGauge.smallGauge($g3Inner, {
                            minValue: 0,
                            maxValue: 180,
                            staticZones: [{
                                    strokeStyle: "#2ecc71",
                                    min: 0,
                                    max: 90
                                }, {
                                    strokeStyle: "#f1c40f",
                                    min: 90,
                                    max: 110
                                }, {
                                    strokeStyle: "#e74c3c",
                                    min: 110,
                                    max: 180
                                }],
                            staticLabels: {
                                labels: [0, 90, 110, 180],
                                fractionDigits: 0
                            }
                        });
                    } else {
                        $g3Inner.replaceWith($(self.g3Inner.canvas));
                    }
                    self.g3Inner.set(self.getDynamicLoadingModel().topOilTempInit());
                } else {
                    $g3Inner.replaceWith(EmPower.Templates['templates/page_warning']({
                        message: "No Events"
                    }));
                }
            }, 1);

            var $dynamicLoadingLoadGraph = $html.find("div[data-container='load-graph']");
            if (!this._loadGraph) {

                this._loadGraph = new EmPower.View.DynamicLoadingLoadGraph({
                    session: this._session
                });
                this._loadGraph.render($dynamicLoadingLoadGraph);
            } else {
                $dynamicLoadingLoadGraph.replaceWith(this._loadGraph.$el);
            }

            var $dynamicLoadingTemperatureGraph = $html.find("div[data-container='temperature-graph']");
            if (!this._temperatureGraph) {

                this._temperatureGraph = new EmPower.View.DynamicLoadingTemperatureGraph({
                    session: this._session
                });
                this._temperatureGraph.render($dynamicLoadingTemperatureGraph);
            } else {
                $dynamicLoadingTemperatureGraph.replaceWith(this._temperatureGraph.$el);
            }
            
            if (self.getDynamicLoadingModel().agingEnabled() === 1) {
                var $dynamicLoadingAssetAgeGraph = $html.find("div[data-container='asset-graph']");
                if (!this._assetGraph) {

                    this._assetGraph = new EmPower.View.DynamicLoadingAssetAgeGraph({
                        session: this._session
                    });
                    this._assetGraph.render($dynamicLoadingAssetAgeGraph);
                } else {
                    $dynamicLoadingAssetAgeGraph.replaceWith(this._assetGraph.$el);
                }
            }
        },

        _show: function () {
            if (this._loadGraph) {
                this._loadGraph.show();
            }
            if (this._temperatureGraph) {
                this._temperatureGraph.show();
            }
            if (this._assetGraph) {
                this._assetGraph.show();
            }
        },

        _hide: function () {
            if (this._loadGraph) {
                this._loadGraph.hide();
            }
            if (this._temperatureGraph) {
                this._temperatureGraph.hide();
            }
            if (this._assetGraph) {
                this._assetGraph.hide();
            }
        },

        _remove: function () {
            if (this._loadGraph) {
                this._loadGraph.remove();
            }
            if (this._temperatureGraph) {
                this._temperatureGraph.remove();
            }
            if (this._assetGraph) {
                this._assetGraph.remove();
            }
        }

    });

})(jQuery);
