(function(Download, $, undefined) {

    Download.hiddenDownload = function(url) {
	
        if (_.isString(url)) {
            $('<iframe>')
                .attr('src', url)
                .addClass("hide").appendTo('body').load(function() {
                    $(this).remove();
            });
        }
    };

})(EmPower.Util.Download = EmPower.Util.Download || {}, jQuery);
