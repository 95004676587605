EmPower.Collection.ConditionIndexEvent = (function($, undefined) {
    
    return EmPower.Collection.AbstractEventCollection.extend({
	collectionName: 'Condition Index Event',
	
	model: EmPower.Model.ConditionIndex,
	
	_url: function() {
	    return EmPower.Util.Url.apiBuilder('transformers', this._selectedObject.selectedId(), 'opm', 'conditionindex', 'events');
	},
	
	_initialize: function() {
	},
	
	_parse: function(response) {
            
            var models = [];
            
            if (response.conditionIndexes) {
                _.forOwn(response.conditionIndexes, function(value) {
                    models = models.concat(value);
                });
            }
            
            return models;
	}
    });
    
})(jQuery);
