EmPower.Model.DatabaseInformation = (function($, undefined) {
    
    return EmPower.Model.AbstractModel.extend({
	
	idAttribute: 'script',
	    
	modelName: 'Database Information',
	
	urlRoot: EmPower.Util.Url.apiBuilder('serverinfo', 'database'),

	_initialize: function() {
	},

	_parse: function() {
	}
    });
    
})(jQuery);
