EmPower.Model.AbstractSession = (function(Model, $, undefined) {
    
    return Model.extend({
		whatAmI: function(){return "EmPower.Model.AbstractSession";},

	_parse: function(response) {
	    return response;
	},
	
        _reset: function() {
            this._logger.throwError("Abstract Session was not extended with a specific reset function.");
        },
        
	reset: function(options) {
            
            options = options || {};
            
	    this._reset(options);

	    this.trigger('reset', options);
	},
	
	_instanceFactoryField: function(field, value, args) {
            
            if (value !== undefined) {
                value = EmPower.Util.instanceFactory(value);
            }
	    
	    var instanceFactory = this._field(field, value);
	    
	    if (value === undefined) {
		return instanceFactory.apply(instanceFactory, args);
	    }
	    return instanceFactory;
	}
    });
    
})(EmPower.Model.AbstractModel, jQuery);


