EmPower.View.AdvancedMetrology = (function ($, undefined) {

    return EmPower.View.AbstractView.extend({

        viewName: "Advanced Metrology",
        gChart: {},

        _events: function () {
            var self = this;
            return {
                "click [data-action=harmonics]": function (event) {
                    event.preventDefault();
                    $('#phaseDiagram').css("display", "none");
                    $('#metrologyEvents').css("display", "none");
                    $('#metrologyGraph').css("display", "none");
                    $('#harmonics').css("display", "block");

                    $('#harmonics-tab').addClass("active-tab");
                    $('#phase-tab').removeClass("active-tab");
                    $('#met-tab').removeClass("active-tab");
                    $('#graph-tab').removeClass("active-tab");
                },
                "click [data-action=phase-diagram]": function (event) {
                    event.preventDefault();
                    $('#metrologyEvents').css("display", "none");
                    $('#harmonics').css("display", "none");
                    $('#phaseDiagram').css("display", "block");
                    $('#metrologyGraph').css("display", "none");

                    this._tabHandler("PhaseDiagram");

                    $('#harmonics-tab').removeClass("active-tab");
                    $('#phase-tab').addClass("active-tab");
                    $('#met-tab').removeClass("active-tab");
                    $('#graph-tab').removeClass("active-tab");

                },
                "click [data-action=metrology-events]": function (event) {
                    event.preventDefault();
                    $('#phaseDiagram').css("display", "none");
                    $('#harmonics').css("display", "none");
                    $('#metrologyEvents').css("display", "block");
                    $('#metrologyGraph').css("display", "none");

                    this._tabHandler("MetrologyDetectedEvent");

                    $('#harmonics-tab').removeClass("active-tab");
                    $('#phase-tab').removeClass("active-tab");
                    $('#met-tab').addClass("active-tab");
                    $('#graph-tab').removeClass("active-tab");

                },
                "click [data-action=metrology-graph]": function (event) {
                    event.preventDefault();
                    $('#phaseDiagram').css("display", "none");
                    $('#harmonics').css("display", "none");
                    $('#metrologyEvents').css("display", "none");
                    $('#metrologyGraph').css("display", "block");

                    this._tabHandler("MetrologyGraph");

                    $('#harmonics-tab').removeClass("active-tab");
                    $('#phase-tab').removeClass("active-tab");
                    $('#met-tab').removeClass("active-tab");
                    $('#graph-tab').addClass("active-tab");

                },
                "click [data-action=setAxisRange]": function (event) {
                    this._setAxisRange(self.gChart, 100, 200);
                },
                "click [data-action=resetZoom]": function (event) {
                    this._resetZoom(self.gChart);
                }
            };
        },

        _initialize: function () {
            this._selectedTransformer = this._session.transformerSession().selectedTransformer();
            this._transformerCollection = this._session.transformerSession().transformerCollection();
            this._metrologyCollection = new EmPower.Collection.Metrology(null, {session: this._session, selectedObject: this._selectedTransformer});
            this._metrologyDetectedEventCollection = new EmPower.Collection.MetrologyDetectedEvent(null, {
                session: this._session,
                selectedObject: this._selectedTransformer,
                dateRange: this._session.dateRange()
            });
//            this._metrologyEventsCollection = new EmPower.Collection.MetrologyEvent(null, {session: this._session, selectedObject: this._selectedTransformer});
        },

        _setupListeners: function () {

            var self = this;

            self.listenTo(self._transformerCollection, 'update reset', function () {
                this.renderDebounced();
            });
            self.listenTo(self._selectedTransformer, 'change', function () {
                this.renderDebounced();
            });

            self.listenTo(self._metrologyCollection, 'update reset', function () {
//                this.renderDebounced();
            });

            self.listenTo(self._metrologyDetectedEventCollection, 'update reset', function () {
                this._metrologyDetectedEventCollection.fetch();
            });

//            self.listenTo(self._metrologyEventsCollection, 'update reset', function() {
//                this.renderDebounced();
//	    });

            self.listenTo(self._session, 'reset', function () {
                this._metrologyCollection.reset();
                this._metrologyDetectedEventCollection.reset();
            });

        },

        /* normalize values relative to harmonic 1 and return css class with color for cell background */
        _normalColorValue: function (base, value) {
            var normal = (value / base) * 100;
            var color = "equals0";
            if (normal > 90) {
                color = "90andup";
            } else if (normal > 80) {
                color = "80andup";
            } else if (normal > 60) {
                color = "60andup";
            } else if (normal > 40) {
                color = "40andup";
            } else if (normal > 20) {
                color = "20andup";
            } else if (normal > 15) {
                color = "15andup";
            } else if (normal > 10) {
                color = "10andup";
            } else if (normal > 5) {
                color = "5andup";
            } else if (normal > 0) {
                color = "1andup";
            }

            return color;
        },

        _htmlTemplate: function () {
            return EmPower.Templates['transformer/metrology/transformer_metrology'];
        },

        _htmlData: function () {

            return {
                metrology: this._metrologyCollection
//                metrologyEvents: this._metrologyEventsCollection
            };
        },

        _tabHandler: function (tab) {
            var self = this;

            /* PHASE DIAGRAM TAB */
            if (tab === "PhaseDiagram") {
                this._paintPhase();
            }

            /* METROLOGY DETECTED TAB */
            if (tab === "MetrologyDetectedEvent") {
                var $metrologyDetectedEvent = $(document).find("div[data-section='metrology-detected-event-table']").empty();
                if (!this._metrologyDetectedEvent) {
                    this._metrologyDetectedEvent = new bbGrid.View({
                        rows: 25,
                        container: $metrologyDetectedEvent,
                        collection: this._metrologyDetectedEventCollection,
                        colModel: [
                            {
                                title: 'Event ID',
                                name: 'id',
                                sorttype: 'number'
                            }, {
                                title: 'Timestamp',
                                name: 'timestamp',
                                sorttype: 'string'
                            }, {
                                title: 'Event Type',
                                name: 'typeString',
                                sorttype: 'string'
//                            filter: true,
//                            filterType: 'input'
                            }, {
                                title: 'Recording ID',
                                name: 'recordingId',
                                sorttype: 'number'
                            }, {
                                title: 'Count',
                                name: 'count',
                                sorttype: 'number'
                            }, {
                                title: 'Value 0',
                                name: 'value0',
                                sorttype: 'number'
                            }, {
                                title: 'Value 1',
                                name: 'value1',
                                sorttype: 'number'
                            }, {
                                title: 'Value 2',
                                name: 'value2',
                                sorttype: 'number'
                            }, {
                                title: 'Cycles Before Trigger',
                                name: 'numCyclesBeforeTrigger',
                                sorttype: 'number'
                            }, {
                                title: 'Cycles After Trigger',
                                name: 'numCyclesAfterTrigger',
                                sorttype: 'number'
                            }, {
                                title: 'Recording Sampling Rate',
                                name: 'recordingSamplingRate',
                                sorttype: 'number'
                            },
                            {
                                title: 'Filename',
                                name: 'filename'
                            }]
                    });

                    var rows = $metrologyDetectedEvent.find(".bbGrid-row");
                    // rows.find('th:last, td:last').hide();

                    rows.click(function (evt) {

                        var timestamp = $(this).find("td:nth-child(1)").text();
                        var eventType = $(this).find("td:nth-child(2)").text();
                        var filename = $(this).find("td:last").text();

                        $(this).addClass("selected").siblings().removeClass("selected");

                        self._createMetrologyDiagram(filename, timestamp, eventType);
                    });

                } else {
                    $metrologyDetectedEvent.html(this._metrologyDetectedEvent.$el);

                    var row = this._metrologyDetectedEvent.$el.find(".bbGrid-row");

                    row.click(function () {
                        var timestamp = $(this).find("td:nth-child(1)").text();
                        var eventType = $(this).find("td:nth-child(2)").text();
                        var filename = $(this).find('td:last').text();

                        $(this).addClass("selected").siblings().removeClass("selected");
                        self._createMetrologyDiagram(filename, timestamp, eventType);
                    });
                }

            }

        },

        _paintPhase: function () {
            var width = 400;
            var height = 400;
            var centerX = width / 2;
            var centerY = height / 2;
            var radius = Math.min(centerY, centerX) * 0.8;
            var textRadius = radius * 1.12;

            var canvas = document.getElementById("phase");

            var data = this._htmlData().metrology.models[0]._options;

            if (data === null) {
                return;
            }

            var phaseInfo = {
                hasPhaseA: data.voltagePhaseA > 1.0 && data.currentPhaseA > 0.1 && data.phaseAngleA !== null,
                hasPhaseB: data.voltagePhaseB > 1.0 && data.currentPhaseB > 0.1 && data.phaseAngleB !== null, //VAB / VAC / VBC return 120
                hasPhaseC: data.voltagePhaseC > 1.0 && data.currentPhaseC > 0.1 && data.phaseAngleC !== null,
                vA: data.voltagePhaseA,
                vB: data.voltagePhaseB,
                vC: data.voltagePhaseC,
                iA: data.currentPhaseA,
                iB: data.currentPhaseB,
                iC: data.currentPhaseC,
                vAtheta: 0.0,
                vBtheta: 360.0 - (data.phaseAngleVAB === null ? 120 : data.phaseAngleVAB),
                vCtheta: 0 + data.phaseAngleVCA,
                iAtheta: 360.0 - data.phaseAngleA,
                iBtheta: 360.0 - (data.phaseAngleVAB === null ? 120 : data.phaseAngleVAB) - data.phaseAngleB,
                iCtheta: 0 + (data.phaseAngleVCA === null ? 120 : data.phaseAngleVCA) - data.phaseAngleC
            };

            if (canvas.getContext) {
                var ctx = canvas.getContext('2d');

                //ctx.reset();
                ctx.moveTo(centerX, centerY);
                ctx.font = "16px roboto";
                ctx.textAlign = "center";
                ctx.textBaseline = "middle";

                // draw the background
                ctx.beginPath();
                ctx.strokeStyle = "black";
                ctx.fillStyle = "black";
                ctx.lineWidth = 2;
                ctx.arc(centerX, centerY, radius, 0, 2 * Math.PI);
                ctx.stroke();
                ctx.fillText("0" + decodeURI("%C2%B0"), centerX + textRadius, centerY);
                ctx.fillText("270" + decodeURI("%C2%B0"), centerX, centerY + textRadius);
                ctx.fillText("180" + decodeURI("%C2%B0"), centerX - textRadius, centerY);
                ctx.fillText("90" + decodeURI("%C2%B0"), centerX, centerY - textRadius);
                ctx.font = "roboto thin";
                ctx.lineWidth = 3;
                // draw each phase & current
                var sumV = Math.max(phaseInfo.vA + phaseInfo.vB + phaseInfo.vC, 0.01); // prevent value going to NaN
                var sumI = Math.max(phaseInfo.iA + phaseInfo.iB + phaseInfo.iC, 0.01);

                // assuming each phase should be balanced
                var t = true;
                if (/*PowerMonitoringModel.dataValid*/ t) {
                    var voltageScale = 2.8;
                    var currentScale = 2.0;

                    // Phase A
                    if (phaseInfo.hasPhaseA) {
                        this.drawVector(ctx, centerX, centerY, Math.max(voltageScale * (phaseInfo.vA / sumV), 0.25), radius, phaseInfo.vAtheta, "rgb(232, 44, 44)", "VA");
                        this.drawVector(ctx, centerX, centerY, Math.max(currentScale * (phaseInfo.iA / sumI), 0.25), radius, phaseInfo.iAtheta, "rgb(232, 44, 44)", "IA");
                    }

                    // Phase B
                    if (phaseInfo.hasPhaseB) {
                        this.drawVector(ctx, centerX, centerY, Math.max(voltageScale * (phaseInfo.vB / sumV), 0.25), radius, phaseInfo.vBtheta, "rgb(0, 91, 91)", "VB");
                        this.drawVector(ctx, centerX, centerY, Math.max(currentScale * (phaseInfo.iB / sumI), 0.25), radius, phaseInfo.iBtheta, "rgb(0, 91, 91)", "IB");
                    }

                    // Phase C
                    if (phaseInfo.hasPhaseC) {
                        this.drawVector(ctx, centerX, centerY, Math.max(voltageScale * (phaseInfo.vC / sumV), 0.25), radius, phaseInfo.vCtheta, "rgb(0, 230, 0)", "VC");
                        this.drawVector(ctx, centerX, centerY, Math.max(currentScale * (phaseInfo.iC / sumI), 0.25), radius, phaseInfo.iCtheta, "rgb(0, 230, 0)", "IC");
                    }
                }

            }
        },

        _setAxisRange: function (chart, min, max) {
            chart.options.scales.xAxes = [
                {
                    ticks: {
                        max: max,
                        min: min
                    }
                }
            ];

            chart.update();
        },

        _resetZoom: function (chart) {
            chart.options.scales.xAxes = [
                {
                    ticks: {
                        beginAtZero: true,
                        stepSize: 1000,
                        max: 3817 //should be the same for all of the arrays
                    }
                }
            ];

            chart.update();
        },

        _createMetrologyDiagram: function (filename, timestamp, eventType) {
            var self = this;

//            var chartZoomPlugin = EmPower.Util.ChartZoomPlugin.zoomPlugin;
//            Chart.pluginService.register(chartZoomPlugin);

            EmPower.Dialog.SimpleDialog.Loading.open('Loading Metrology Graph...');
            var line;

            if (line) {
                line.destroy();
            }
            //cleaning up after previous drawing
            var existingCanvas = $("#metrologyGraphDiv");
            if (!existingCanvas) {
                existingCanvas.empty();
                $("#metrologyGraphDiv").append('<canvas id="metrology" height="400"></canvas>');
            }



            var url = EmPower.Util.Url.apiBuilder("transformers", this._selectedTransformer.selectedId(), "metrology-detected-event") + "/" + filename.trim();
            var fReader = new FileReader();
            var oReq = new XMLHttpRequest();
            oReq.open("GET", url, true);
            oReq.responseType = "blob";

            oReq.onload = function (oEvent) {
                var blob = oReq.response;
                fReader.readAsArrayBuffer(blob);
            };

            oReq.onerror = function (event) {
                EmPower.Dialog.SimpleDialog.Loading.close();
                $('#metrologyError').show();

                $("#metrologyError").fadeTo(2000, 500).slideUp(500, function () {
                    $("#metrologyError").slideUp(500);
                });

                return;
            };

            oReq.send();

            fReader.onload = function () {
                try {
                    var data = new Float32Array(fReader.result);

                    var IA = [];
                    var labelCount = 1;
                    var IB = [];
                    var IC = [];
                    var VA = [];
                    var VB = [];
                    var VC = [];
                    var IN = [];
                    var labels = [];

                    var startTime = new Date();

                    var i = 0;
                    data.forEach(function (element) {
                        labels.push(labelCount);
                        labelCount++;
                        if (i % 6 === 0) {
                            IA.push(element);
                        } else if (i % 6 === 1) {
                            VA.push(element);
                        } else if (i % 6 === 2) {
                            IB.push(element);
                        } else if (i % 6 === 3) {
                            VB.push(element);
                        } else if (i % 6 === 4) {
                            IC.push(element);
                        } else {
                            VC.push(element);
                        }
                        i++;
                    });


                    var config = {
                        type: 'line',
                        data: {
                            labels: labels,
                            datasets: [
                                {
                                    label: "IA",
                                    backgroundColor: 'red',
                                    borderColor: 'red',
                                    borderWidth: 1,
                                    data: IA,
                                    fill: false,
                                    lineTension: 0,
                                    radius: 0
                                },
                                {
                                    label: "VA",
                                    backgroundColor: 'blue',
                                    borderColor: 'blue',
                                    borderWidth: 1,
                                    data: VA,
                                    fill: false,
                                    lineTension: 0,
                                    radius: 0
                                },
                                {
                                    label: "IB",
                                    backgroundColor: 'yellow',
                                    borderColor: 'yellow',
                                    borderWidth: 1,
                                    data: IB,
                                    fill: false,
                                    lineTension: 0,
                                    radius: 0
                                },
                                {
                                    label: "VB",
                                    backgroundColor: 'purple',
                                    borderColor: 'purple',
                                    borderWidth: 1,
                                    data: VB,
                                    fill: false,
                                    lineTension: 0,
                                    radius: 0
                                },
                                {
                                    label: "IC",
                                    backgroundColor: 'green',
                                    borderColor: 'green',
                                    borderWidth: 1,
                                    data: IC,
                                    fill: false,
                                    lineTension: 0,
                                    radius: 0
                                },
                                {
                                    label: "VC",
                                    backgroundColor: 'brown',
                                    borderColor: 'brown',
                                    borderWidth: 1,
                                    data: VC,
                                    fill: false,
                                    lineTension: 0,
                                    radius: 0
                                }
                            ]},
                        options: {
                            animation: {
                                duration: 0
                            },
                            responsive: true,
                            maintainAspectRatio: false,
                            scales: {
                                xAxes: [{
                                        display: true,
                                        ticks: {
                                            beginAtZero: true,
                                            stepSize: 1000,
                                            max: IA.length //should be the same for all of the arrays
                                        },
                                        scaleLabel: {
                                            display: true,
                                            labelString: 'Sample Rate = 4,000Hz'
                                        }
                                    }],
                                yAxes: [{
                                        display: true,
                                        type: 'linear',
                                        ticks: {
                                            steps: 9,
                                            stepValue: 10
                                        }
                                    }]
                            },
                            title: {
                                display: true,
                                text: 'Metrology'
                            },
                            tooltips: {
                                mode: 'index',
                                intersect: false
                            },
                            hover: {
                                mode: 'nearest',
                                intersect: true
                            },
                            legend: {
                                position: 'right'
                            },
                            zoom: {
                                enabled: true,
                                drag: true,
                                mode: 'x',
                                limits: {
                                    max: 4000,
                                    min: 0
                                }
                            }
                        }
                    };

                    $('[data-action=metrology-graph]').trigger('click');

                    var canvas = document.getElementById("metrology");
                    line = new Chart(canvas, config);
                    line.draw();

                    self.gChart = line;


                    var infoString = eventType.trim() + " - " + timestamp.trim();
                    $('#metrology-timestamp').text(infoString);

                    $('#metrologyWarning').hide();
                    EmPower.Dialog.SimpleDialog.Loading.close();
                } catch (error) {
                    EmPower.Dialog.SimpleDialog.Loading.close();
                    console.error(error);
                    $('#metrologyError').show();

                    $("#metrologyError").fadeTo(2000, 500).slideUp(500, function () {
                        $("#metrologyError").slideUp(500);
                    });

                    return;
                }
            };
        },

        drawVector: function (ctx, x, y, magnitude, elementRadius, angle, drawColor, label) {
            var radAngle = (angle / 360.0) * Math.PI * 2;
            var lineLength = Math.min(magnitude, 1.0);
            ctx.save();
            ctx.strokeStyle = drawColor;
            ctx.fillStyle = drawColor;
            ctx.translate(x, y);
            ctx.scale(elementRadius, elementRadius);
            ctx.rotate(-radAngle);

            // draw the line
            ctx.lineWidth = 0.01;
            ctx.beginPath();
            ctx.moveTo(0, 0);
            ctx.lineTo(lineLength - 0.1, 0.0);
            ctx.stroke();

            // draw the arrow point
            ctx.beginPath();
            ctx.moveTo(lineLength, 0.0);
            ctx.lineTo(lineLength - 0.1, 0.1);
            ctx.lineTo(lineLength - 0.1, -0.1);
            ctx.closePath();
            ctx.fill();

            // draw the label
            ctx.translate(lineLength - 0.1, -0.2);
            ctx.scale(0.007, 0.007);
            ctx.rotate(radAngle); // rotate so that the label faces upwards
            ctx.strokeStyle = drawColor;
            ctx.fillStyle = drawColor;
            ctx.fillText(label, 0.0, 0.0);

            ctx.restore();
        },

        _render: function ($html) {
            var self = this;
            var url = EmPower.Util.Url.apiBuilder("transformers", this._selectedTransformer.selectedId(), "harmonic");

            /* HARMONICS TAB */
            $.ajax({
                url: url,
                type: 'GET',
                success: function (harmonics) {
                    harmonics = harmonics.reverse();

                    if (harmonics.length > 0) {
                        
                        var baseVA = harmonics[0].VA * 1000;
                        var baseVB = harmonics[0].VB * 1000;
                        var baseVC = harmonics[0].VC * 1000;
                        var baseIA = harmonics[0].IA;
                        var baseIB = harmonics[0].IB;
                        var baseIC = harmonics[0].IC;

                        var latestTimestamp = harmonics[0].timestamp;
                        $("#harmonics-timestamp").text(latestTimestamp);

                        var voltageA = new Map();
                        var voltageB = new Map();
                        var voltageC = new Map();
                        var currentA = new Map();
                        var currentB = new Map();
                        var currentC = new Map();

                        harmonics.forEach(function (el) {
                            if (voltageA.has(el.harmonicNumber)) {
                                voltageA.delete(el.harmonicNumber);
                                voltageB.delete(el.harmonicNumber);
                                voltageC.delete(el.harmonicNumber);
                                currentA.delete(el.harmonicNumber);
                                currentB.delete(el.harmonicNumber);
                                currentC.delete(el.harmonicNumber);

                                voltageA.set(el.harmonicNumber, {color: self._normalColorValue(baseVA, el.VA), value: el.VA * 1000});
                                voltageB.set(el.harmonicNumber, {color: self._normalColorValue(baseVB, el.VB), value: el.VB * 1000});
                                voltageC.set(el.harmonicNumber, {color: self._normalColorValue(baseVC, el.VC), value: el.VC * 1000});
                                currentA.set(el.harmonicNumber, {color: self._normalColorValue(baseIA, el.IA), value: el.IA});
                                currentB.set(el.harmonicNumber, {color: self._normalColorValue(baseIB, el.IB), value: el.IB});
                                currentC.set(el.harmonicNumber, {color: self._normalColorValue(baseIC, el.IC), value: el.IC});

                            } else {
                                voltageA.set(el.harmonicNumber, {color: self._normalColorValue(baseVA, el.VA), value: el.VA * 1000});
                                voltageB.set(el.harmonicNumber, {color: self._normalColorValue(baseVB, el.VB), value: el.VB * 1000});
                                voltageC.set(el.harmonicNumber, {color: self._normalColorValue(baseVC, el.VC), value: el.VC * 1000});
                                currentA.set(el.harmonicNumber, {color: self._normalColorValue(baseIA, el.IA), value: el.IA});
                                currentB.set(el.harmonicNumber, {color: self._normalColorValue(baseIB, el.IB), value: el.IB});
                                currentC.set(el.harmonicNumber, {color: self._normalColorValue(baseIC, el.IC), value: el.IC});
                            }

                        });

                        $(document).ready(function () {
                            $("#voltageA").empty();
                            $("#voltageA").append('<th scope="row">Voltage A</th>');

                            $("#voltageB").empty();
                            $("#voltageB").append('<th scope="row">Voltage B</th>');

                            $("#voltageC").empty();
                            $("#voltageC").append('<th scope="row">Voltage C</th>');

                            $("#currentA").empty();
                            $("#currentA").append('<th scope="row">Current A</th>');

                            $("#currentB").empty();
                            $("#currentB").append('<th scope="row">Current B</th>');

                            $("#currentC").empty();
                            $("#currentC").append('<th scope="row">Current C</th>');

                            voltageA.forEach(function (el) {
                                var field = "<td data-value='" + el.color + "'>" + el.value + "</td>";
                                $('#voltageA').append(field);
                            });

                            voltageB.forEach(function (el) {
                                var field = "<td data-value='" + el.color + "'>" + el.value + "</td>";
                                $('#voltageB').append(field);
                            });

                            voltageC.forEach(function (el) {
                                var field = "<td data-value='" + el.color + "'>" + el.value + "</td>";
                                $('#voltageC').append(field);
                            });

                            currentA.forEach(function (el) {
                                var field = "<td data-value='" + el.color + "'>" + el.value + "</td>";
                                $('#currentA').append(field);
                            });

                            currentB.forEach(function (el) {
                                var field = "<td data-value='" + el.color + "'>" + el.value + "</td>";
                                $('#currentB').append(field);
                            });

                            currentC.forEach(function (el) {
                                var field = "<td data-value='" + el.color + "'>" + el.value + "</td>";
                                $('#currentC').append(field);
                            });
                        });
                    }
                },
                error: function (err) {
                    console.error(err);
                }
            });

        },

        _show: function () {
            if (this.hasRendered()) {
                this.render();
            }
        },

        _hide: function () {
        },

        _remove: function () {
        }
    });

})(jQuery);
