EmPower.Route.AbstractRouter = (function(Router, $, undefined) {
	
    // private namespace
    var that = {};
    
    that.Router = EmPower.Route.AbstractRouterObject(Router, "Router");
    
    return that.Router;
    
})(Backbone.Router, jQuery);
