EmPower.Util.instanceFactory = (function($, undefined) {
    
    var Instance = function(ObjectClass, args) {
	
	this._ObjectClass = ObjectClass;
	this._arguments = args;
	
	this._instance = null;
    };
    
    Instance.prototype.getInstance = function() {
	
	if (!this._instance) {
	    
	    Array.prototype.push.apply(this._arguments, Array.prototype.slice.call(arguments));
	    
	    var ObjectClassConstructor = this._ObjectClass.bind.apply(this._ObjectClass, this._arguments);
	
	    this._instance = new ObjectClassConstructor();
	}
	
	return this._instance;
    };
    
    return function(ObjectClass) {
	
	var instance = new Instance(ObjectClass, arguments);
	
	return function() {
	    return instance.getInstance.apply(instance, arguments);
	};
    };
    
})(jQuery);


