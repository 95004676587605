EmPower.Util.Logger = (function($, undefined) {
    
    // private namespace
    var that = {};
    
    // Only needs to check once
    that.hasConsole = window.console ? true : false;
    
    that.console = that.hasConsole ? window.console : {};

    var Logger = function(loggerPrefix, isEnabled) {
	
	loggerPrefix = loggerPrefix || "Logger";
	this._loggerPrefix = " " + loggerPrefix;
	this._isEnabled = isEnabled;
    };
    
    Logger.prototype._messageConstructor = function(type, message) {
	
	return type + this._loggerPrefix + ": " + message;
    };
    
    Logger.prototype._log = function(consoleType, type, message, args) {
	
	if (that.hasConsole && (EmPower.Options.logger && this._isEnabled)) {

	    args = Array.prototype.slice.call(args, 1);
	    args.unshift(this._messageConstructor(type, message));
	    
	    var call = Function.prototype.call;
	    try {
		if (consoleType && _.isFunction(consoleType)) {
		    
		    // fix for error calling apply directly on console.
		    call.apply(call, [consoleType, that.console].concat(args));
		    
		} else if (_.isFunction(that.console.log)) {

		    // fix for error calling apply directly on console.
		    call.apply(call, [that.console.log, that.console].concat(args));
		}
	    } catch(e) {
		// empty catch ... don't log anything on error.
	    }
	}
    };
    
    Logger.prototype.info = function(message) {
	this._log(that.console.info, "INFO", message, arguments);
    };
    
    Logger.prototype.error = function(message) {
	this._log(that.console.error, "ERROR", message, arguments);
    };
    
    Logger.prototype.trace = function(message) {
	this._log(that.console.trace, "TRACE", message, arguments);
    };
    
    Logger.prototype.warn = function(message) {
	this._log(that.console.warn, "WARN", message, arguments);
    };
    
    Logger.prototype.throwError = function(message) {
	
	message = this._messageConstructor("ERROR", message);
	throw new Error(message);
	};
	

	////////////////////////////////////////////////////////////////////////////////////////////////
	// Below are some richer tools added by Chris Dutrow tohelp getter a better visual idea of the architecture
	Logger.prototype.doDebugLog = false;			// Turn off all of the below logging with this flag
	Logger.prototype.showViewLabels = false;		// Printing of the red view labels

	Logger.prototype.debugConsoleLog = function(message){
		if( !this.doDebugLog ){ return; } 
		console.log(message);
	};

	Logger.prototype.debugLog = function(object) {
		if( !this.doDebugLog ){ return; } 


		if( object instanceof EmPower.View.AbstractView ){
			this._debugLogView(object);			
		}
		if( object instanceof EmPower.Collection.AbstractSelectedObjectCollection ){
			this._debugAbstractSelectedObjectCollection(object);
		}

		//if( object instanceof )
	};

	// Does logging specific to a view
	Logger.prototype._debugLogView = function(object){
		if( object.viewName !== "Fetcher Info" ){
			// Simply log the view name to the console:
			console.log("Rendering: " + object.viewName);

			// 
			object.$el.css('border-color', 'black');
			object.$el.css('border-style', 'solid');
			object.$el.css('border-width', '1px');

			if( this.showViewLabels ){
				object.$el.hover(function(event){
					// Get this event to just be called for the first child
					//event.stopPropagation();
					//console.log("Hovering over: " + object.viewName);

					var offset = object.$el.offset();
					var body = $('body');



					// Clear old labels
					var label = $('.debug-view-label');
					if( label.length > 0 ){
						var timeStamp = $(label[0]).attr('event-timestamp');
						if( timeStamp !== ""+event.timeStamp ){
							label.remove();
						}
					}
					//label.attr('event-timestamp', ""+event.timeStamp);

					// Create label if it does not already exist
					//if( label.length == 0 ){
						label = $('<div class="debug-view-label">SOME LABEL</div>');
						label.attr('event-timestamp', ""+event.timeStamp);
						label.css('position', 'absolute');
						label.css('z-index', '1000');
						label.css('background-color', 'red');
						label.css('color', 'white');
						//label.css('width', '100px');
						//label.css('height', '100px');
						body.prepend(label);
					//}
					// Move label to the correct spot
					label.css('top', offset.top+'px');
					label.css('left', offset.left+'px');
					label.text(object.viewName);
				});
			}
		}
	};

	Logger.prototype._debugAbstractSelectedObjectCollection = function(object){
		console.log('Syncing: ' + object.collectionName);
	};
    
    return Logger;
    
})(jQuery);
