EmPower.Collection.PumpEvent = (function($, undefined) {
    
    return EmPower.Collection.AbstractSelectedObjectCollection.extend({
	collectionName: 'Pump Event',
	
	model: EmPower.Model.PumpEvent,
	
	url: function() {
	    return EmPower.Util.Url.apiBuilder('transformers', this._selectedObject.selectedId(), 'pump', 'events');
	},
	
	_initialize: function() {
	},
	
	_parse: function() {
	}
    });
    
})(jQuery);
