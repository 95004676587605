EmPower.View.AccessGateRow = (function($, undefined) {

    return EmPower.View.AbstractView.extend({
	viewName: "Access Gate Row",
	
	_events: function() {
	},
	
	_initialize: function() {
	    this._accessGateModel = this._getOption("accessGateModel", true);
	},
	
	_setupListeners: function() {
	    
	    var self = this;
	    
	    self.listenTo(self.getAccessGateModel(), 'change', function() {
		self.renderDebounced();
	    });
	},
	
	getAccessGateModel: function() {
	    return this._accessGateModel;
	},
	
	_htmlTemplate: function() {
	    return EmPower.Templates['transformer/security/access-gate/access_gate_row'];
	},
	
	_htmlData: function() {
            
            var cssClass = "";
            
            if (this.getAccessGateModel().hasEvent()) {
                if (this.getAccessGateModel().value() !== null) {
                    if (this.getAccessGateModel().value() || this.getAccessGateModel().value() === 'open') {
                        cssClass = "opened";
                    } else {
                        cssClass = "closed";
                    }
                }
            } else {
                cssClass = "disabled";
            }
            
	    return {
		accessGateModel: this.getAccessGateModel(),
                cssClass: cssClass
	    };
	},
	
	_render: function() {
	},
	
	_show: function() {
	},
	
	_hide: function() {
	},
	
	_remove: function() {
	}
	
    });
    
})(jQuery);
