EmPower.View.Annunciator = (function($, undefined) {

    return EmPower.View.AbstractView.extend({
	viewName: "Transformer Annunciator",
	
	_events: function() {
	},
	
	_initialize: function() {
	    this._selectedTransformer = this._session.transformerSession().selectedTransformer();
	    
	    this._annunciatorCollection = this._session.transformerSession().annunciatorCollection();
	},
	
	_setupListeners: function() {
	},
	
	_htmlTemplate: function() {
	    return EmPower.Templates['transformer/annunciator/transformer_annunciator'];
	},
	
	_htmlData: function() {
	},
	
	_render: function($html) {
	    
	    var $annunciatorList = $html.filter("div[data-section='transformer-annunciator-list']");
	    if (!this._annunciatorList) {

		this._annunciatorList = new EmPower.View.AnnunciatorList({
		    session: this._session,
		    collection: this._annunciatorCollection,
		    selectedModel: null
		});
		this._annunciatorList.render($annunciatorList);
	    } else {
		$annunciatorList.replaceWith(this._annunciatorList.$el);
	    }
	},
	
	_show: function() {
	    if (this._annunciatorList) {
		this._annunciatorList.show();
	    }
	},
	
	_hide: function() {
	    if (this._annunciatorList) {
		this._annunciatorList.hide();
	    }
	},
	
	_remove: function() {
	    if (this._annunciatorList) {
		this._annunciatorList.remove();
	    }
	}
    });
    
})(jQuery);
