EmPower.View.TransformerHeader = (function($, undefined) {
    
    return EmPower.View.AbstractView.extend({
	viewName: "Transformer Header",
	
	_events: function() {
	    return {
		'click a[data-action="back-transformer-management"]': function(event) {
		    event.preventDefault();
		    this._transformerRouter.showTransformerManagement();
		}
	    };
	},
	
	_initialize: function() {
	    
	    this._transformerRouter = this._session.applicationRouter().getTransformersModule();
	    
	    this._selectedTransformer = this._session.transformerSession().selectedTransformer();
	    
	    this._selectedTransformerSubview = this._session.transformerSession().selectedTransformerSubview();
            
            this._transformerModel(this._selectedTransformer.selected());
	},
	
	_setupListeners: function() {
	    this.listenTo(this._selectedTransformerSubview, 'change', function() {
		this.renderDebounced();
	    });
	    
	    this.listenTo(this._selectedTransformer, 'change', function() {
                this._transformerModel(this._selectedTransformer.selected());
                this.renderDebounced();
	    });
	},
	
        _transformerModel: function(selectedTransformerModel) {
	    
	    var self = this;
	    
	    if (selectedTransformerModel !== undefined) {
	    
		if (self._selectedTransformerModel !== selectedTransformerModel) {

		    if (self._selectedTransformerModel) {
			self.stopListening(self._selectedTransformerModel);
		    }

		    if (selectedTransformerModel) {
			self.listenTo(selectedTransformerModel, 'change', function() {
                            self.renderDebounced();
                        });
		    }
		}

		self._selectedTransformerModel = selectedTransformerModel;
	    }
	    
	    return self._selectedTransformerModel;
	},
        
	_htmlTemplate: function() {
	    return EmPower.Templates['transformer/header/transformer_header'];
	},
	
	_htmlData: function() {
	    
	    var transformerSubview = this._selectedTransformerSubview.selected();
	    var selectedView = transformerSubview;
	    if (transformerSubview && _.isFunction(transformerSubview.view)) {
		selectedView = transformerSubview.view();
	    }
	    var isManagement = selectedView instanceof EmPower.View.TransformerManagement;
	    
	    var title = "";
            var selectedTransformer = null;
	    if (!isManagement && this._selectedTransformer.selected()) {

		title += this._selectedTransformer.selected().transformerName() || "";
		if (title) {
		    title += " - ";
		}
                
                selectedTransformer = this._transformerModel();
	    }
	    
	    if (transformerSubview && _.isFunction(transformerSubview.title) && transformerSubview.title()) {
		title += transformerSubview.title();
	    }
            
            var data = {
		title: title,
		hasBackButton: !isManagement,
                selectedTransformer: selectedTransformer
	    };
	    
	    return data;
	},
	
	_render: function() {
	},
	
	_show: function() {
	},
	
	_hide: function() {
	},
	
	_remove: function() {
	}
    });
    
})(jQuery);
