EmPower.Model.AbstractEventModel = (function(Model, $, undefined) {
    
    return Model.extend({

	idAttribute: 'eventId',

	eventId: function(value) {
	    return this._field('eventId', value);
	},
	
	timestamp: function(value) {
            var time = this._field('timestamp', value);
            var altTime = this._field("timestamp2", value);
            
            if (time !== null) {
                return time;
            } else if (altTime !== null) {
                return altTime;
            } else {
                return null;
            }
	},
	
	timeRecorded: function(value) {
	    return this._field('timeRecorded', value);
	},
	
	transformerId: function(value) {
	    return this._field('transformerId', value);
	},
	
	timestampMoment: function() {
	    return this.timestamp() ? moment(this.timestamp()) : null;
	},
	
        hasEvent: function() {
            return this.timestamp() ? true : false;
        },
        
	timestampDisplay: function() {
	    var moment = this.timestampMoment();
	    
	    return moment ? moment.format() : "No Date";
	},
        
        annuncTimestampDisplay: function() {
	    var moment = this.timestampMoment();
	    
	    return moment ? moment.format() : "No Recent Alarms";
	},
        
        timestampUnix: function() {
	    var moment = this.timestampMoment();
	    
	    return moment ? moment.valueOf() : null;
	},
	
	timeRecordedMoment: function() {
	    return this.timeRecorded() ? moment(this.timeRecorded()) : null;
	},
	
	timeRecordedDisplay: function() {
	    var moment = this.timeRecordedMoment();
	    
	    return moment ? moment.format() : "No Date";
	},
        
        timeRecordedUnix: function() {
	    var moment = this.timeRecordedMoment();
	    
	    return moment ? moment.valueOf() : null;
	},
    });
    
})(EmPower.Model.AbstractModel, jQuery);
