EmPower.View.FetcherInfo = (function($, undefined) {
    
    return EmPower.View.AbstractView.extend({
	viewName: "Fetcher Info",
	
	_events: function() {
	    return {
		'click button[data-action="forceFetch"]': function() {
		    if (!this._collectionFetcherModel.isFetching()) {
			this._collectionFetcher.fetchAll(true);
		    }
		}
	    };
	},
	
	_initialize: function() {
	    
	    this._collectionFetcher = this._session.collectionFetcher();
	    this._collectionFetcherModel = this._session.collectionFetcher().getModel();
	    
	    if (!this._timeout) {
		this._timeout = 1000;
	    }
	    
	    this._startUpdateTimeout();
	},
	
	_setupListeners: function() {
	    
	    var self = this;
	    
	    self.listenTo(self._collectionFetcherModel, 'change', function() {
		self.renderDebounced();
	    });
	},
	
	_htmlTemplate: function() {
	    return EmPower.Templates['fetcher/fetcher_info'];
	},
	
	_htmlData: function() {
	    
	    var model = this._collectionFetcherModel;
            
            var durationSinceSync = Math.abs(moment.duration(this._collectionFetcherModel.lastFetch().diff(moment())).asMilliseconds());
            
	    return {
		isFetching: model.isFetching(),
		lastFetch: model.lastFetch().format("LT"),
		isConnected: model.isConnected(),
                isOutOfSync: durationSinceSync > this._collectionFetcher.getTimeout(),
		lastUpdated: this._getLastUpdated(),
		hasListeners: model.hasListeners()
	    };
	},
	
	_getLastUpdated: function() {
	    return "Last Updated: " + this._collectionFetcherModel.lastFetch().fromNow();
	},
	
	_updateLastFetchTime: function() {
	    
	    if (this.$html) {
		var $element = this.$html.find('button[data-action="forceFetch"]');
		if ($element.length > 0) {
                    
                    var durationSinceSync = Math.abs(moment.duration(this._collectionFetcherModel.lastFetch().diff(moment())).asMilliseconds());
                    if (durationSinceSync > this._collectionFetcher.getTimeout() * 2.5) {
                        this.renderDebounced();
                    }
                    
		    $element.prop('title', this._getLastUpdated());
		}
	    }
	},
	
	_clearUpdateTimeout: function() {
	    if (this._updateTimeoutId) {
		clearTimeout(this._updateTimeoutId);
	    }
	},
	
	_startUpdateTimeout: function() {
	    
	    var self = this;
	    
	    self._clearUpdateTimeout();
	    
	    self._updateLastFetchTime();
	    
	    self._updateTimeoutId = window.setTimeout(_.bind(self._startUpdateTimeout, self), self._timeout);
	    
	},
	
	_render: function() {
	},
	
	_show: function() {
	},
	
	_hide: function() {
	},
	
	_remove: function() {
	}
    });
    
})(jQuery);
