(function(TransformerSubviews, $, undefined) {
    
    // private namespace
    var that = {};
    
    that.subviewInstance = function(subview) {
        return new EmPower.Model.TransformerSubview(subview);
    };
    
    TransformerSubviews.transformerManagement = function(session, path) {
        return that.subviewInstance({
            title: "Transformer Selection",
            path: path,
            view: EmPower.Util.instanceFactory(EmPower.View.TransformerManagement, {
                session: session
            })
        });
    };
    
    TransformerSubviews.health = function(session, path) {
        return that.subviewInstance({
            title: "Condition Dashboard",
            path: path,
            view: EmPower.Util.instanceFactory(EmPower.View.TransformerHealth, {
                session: session
            }),
            companionView: EmPower.Util.instanceFactory(EmPower.View.ConditionIndexTable, {
                session: session
            }),
            graphCollection: function() {
                return session.transformerSession().conditionIndexEventCollection();
            },
            graphOptions: {
                options: {
                    scales: {
                        yAxes: [EmPower.Util.SimpleChartAxis.conditionIndex()]
                    }
                },
                datasetConfig: {
                    datasetId: function(model) {
                        return model.ciTypeId();
                    },
                    label: function(model) {
                        return _.startsWith(model.ciTypeAbbrev(), "HL") ? model.ciTypeName() : model.ciTypeName() + " CI";
                    },
                    yValue: function(model) {
                        return (model.value() !== undefined && model.value() !== null) ? model.value().toFixedNumber(2) : null;
                    }
                }
            }
        });
    };
    
    TransformerSubviews.dynamicLoading = function(session, path) {
        return that.subviewInstance({
            title: "Dynamic Loading",
            path: path,
            view: EmPower.Util.instanceFactory(EmPower.View.DynamicLoading, {
                session: session
            }),
            companionView: EmPower.Util.instanceFactory(EmPower.View.DynamicLoadingTable, {
                session: session
            }),
            graphCollection: function() {
                return session.transformerSession().dynamicLoadingEventCollection();
            },
            graphOptions: {
                options: {
                    scales: {
                        yAxes: [
                            EmPower.Util.SimpleChartAxis.temperature({
                                id: "y-axis-temperature"
                            }),
                            EmPower.Util.SimpleChartAxis.life({
                                id: "y-axis-life",
                                position: "left"
                            }),
                            EmPower.Util.SimpleChartAxis.load({
                                id: "y-axis-load",
                                position: "right"
                            })
                        ]
                    }
                },
                datasetConfig: [{
                    datasetId: function(model) {
                        return "dl-load";
                    },
                    label: function(model) {
                        return "Load";
                    },
                    yValue: function(model) {
                        return (model.load() !== undefined && model.load() !== null) ? (model.load()).toFixedNumber(1) : null;
                    },
                    datasetOptions: function(model) {
                        return {
                            yAxisID: "y-axis-load"
                        };
                    }
                }, {
                    datasetId: function(model) {
                        return "dl-ultLoad";
                    },
                    label: function(model) {
                        return "Available Load";
                    },
                    yValue: function(model) {
                        return (model.ultLoad() !== undefined && model.ultLoad() !== null) ? (model.ultLoad()).toFixedNumber(1) : null;
                    },
                    datasetOptions: function(model) {
                        return {
                            yAxisID: "y-axis-load"
                        };
                    }
                }, {
                    datasetId: function(model) {
                        return "dl-ambientTemp";
                    },
                    label: function(model) {
                        return "Ambient Temperature";
                    },
                    yValue: function(model) {
                        return (model.ambientTemp() !== undefined && model.ambientTemp() !== null) ? (model.ambientTemp()).toFixedNumber(2) : null;
                    },
                    datasetOptions: function(model) {
                        return {
                            yAxisID: "y-axis-temperature"
                        };
                    }
                }, {
                    datasetId: function(model) {
                        return "dl-topOilTempInit";
                    },
                    label: function(model) {
                        return "Top Oil Temperature";
                    },
                    yValue: function(model) {
                        return (model.topOilTempInit() !== undefined && model.topOilTempInit() !== null) ? (model.topOilTempInit()).toFixedNumber(2) : null;
                    },
                    datasetOptions: function(model) {
                        return {
                            yAxisID: "y-axis-temperature"
                        };
                    }
                }, {
                    datasetId: function(model) {
                        return "dl-topOilTempUlt";
                    },
                    label: function(model) {
                        return "Top Oil Temperature Ultimate";
                    },
                    yValue: function(model) {
                        return (model.topOilTempUlt() !== undefined && model.topOilTempUlt() !== null) ? (model.topOilTempUlt()).toFixedNumber(2) : null;
                    },
                    datasetOptions: function(model) {
                        return {
                            yAxisID: "y-axis-temperature"
                        };
                    }
                }, {
                    datasetId: function(model) {
                        return "dl-windingHsTempInit";
                    },
                    label: function(model) {
                        return "Winding Hotspot Temperature";
                    },
                    yValue: function(model) {
                        return (model.windingHsTempInit() !== undefined && model.windingHsTempInit() !== null) ? (model.windingHsTempInit()).toFixedNumber(2) : null;
                    },
                    datasetOptions: function(model) {
                        return {
                            yAxisID: "y-axis-temperature"
                        };
                    }
                }, {
                    datasetId: function(model) {
                        return "dl-windingHsTempUlt";
                    },
                    label: function(model) {
                        return "Winding Hotspot Temperature Ultimate";
                    },
                    yValue: function(model) {
                        return (model.windingHsTempUlt() !== undefined && model.windingHsTempUlt() !== null) ? (model.windingHsTempUlt()).toFixedNumber(2) : null;
                    },
                    datasetOptions: function(model) {
                        return {
                            yAxisID: "y-axis-temperature"
                        };
                    }
                }, {
                    datasetId: function(model) {
                        return "dl-lifeLoss";
                    },
                    label: function(model) {
                        return "Loss of Life";
                    },
                    yValue: function(model) {
                        return (model.lifeLoss() !== undefined && model.lifeLoss() !== null) ? (model.lifeLoss()).toFixedNumber(2) : null;
                    },
                    datasetOptions: function(model) {
                        return {
                            yAxisID: "y-axis-life"
                        };
                    }
                }, {
                    datasetId: function(model) {
                        return "dl-lifeLossDay";
                    },
                    label: function(model) {
                        return "Loss of Life Day";
                    },
                    yValue: function(model) {
                        return (model.lifeLossDay() !== undefined && model.lifeLossDay() !== null) ? (model.lifeLossDay()).toFixedNumber(2) : null;
                    },
                    datasetOptions: function(model) {
                        return {
                            yAxisID: "y-axis-life"
                        };
                    }
                }, {
                    datasetId: function(model) {
                        return "dl-acceleratedAgingFactor";
                    },
                    label: function(model) {
                        return "Accelerated Aging Factor";
                    },
                    yValue: function(model) {
                        return (model.acceleratedAgingFactor() !== undefined && model.acceleratedAgingFactor() !== null) ? (model.acceleratedAgingFactor()).toFixedNumber(2) : null;
                    },
                    datasetOptions: function(model) {
                        return {
                            yAxisID: "y-axis-life"
                        };
                    }
                }, {
                    datasetId: function(model) {
                        return "dl-acceleratedAgingFactorTotal";
                    },
                    label: function(model) {
                        return "Accelerated Aging Factor Total";
                    },
                    yValue: function(model) {
                        return (model.acceleratedAgingFactorTotal() !== undefined && model.acceleratedAgingFactorTotal() !== null) ? (model.acceleratedAgingFactorTotal()).toFixedNumber(2) : null;
                    },
                    datasetOptions: function(model) {
                        return {
                            yAxisID: "y-axis-life"
                        };
                    }
                }, {
                    datasetId: function(model) {
                        return "dl-eqAgingFactor";
                    },
                    label: function(model) {
                        return "EQ Aging Factor";
                    },
                    yValue: function(model) {
                        return (model.eqAgingFactor() !== undefined && model.eqAgingFactor() !== null) ? (model.eqAgingFactor()).toFixedNumber(2) : null;
                    },
                    datasetOptions: function(model) {
                        return {
                            yAxisID: "y-axis-life"
                        };
                    }
                }, {
                    datasetId: function(model) {
                        return "dl-eqAgingFactorLife";
                    },
                    label: function(model) {
                        return "EQ Aging Factor Life";
                    },
                    yValue: function(model) {
                        return (model.eqAgingFactorLife() !== undefined && model.eqAgingFactorLife() !== null) ? (model.eqAgingFactorLife()).toFixedNumber(2) : null;
                    },
                    datasetOptions: function(model) {
                        return {
                            yAxisID: "y-axis-life"
                        };
                    }
                }]
            }
        });
    };
    
    TransformerSubviews.bushing = function(session, path) {
        return that.subviewInstance({
            title: "Bushing",
            path: path,
            view: EmPower.Util.instanceFactory(EmPower.View.Bushing, {
                session: session
            }),
            companionView: EmPower.Util.instanceFactory(EmPower.View.BushingTable, {
                session: session
            }),
            graphCollection: function() {
                return session.transformerSession().bushingEventCollection();
            },
            graphOptions: {
                options: {
                    scales: {
                        yAxes: [EmPower.Util.SimpleChartAxis.bushing()]
                    }
                },
                datasetConfig: {
                    yValue: function(model) {
                        return (model.condition() !== undefined && model.condition() !== null) ? model.condition().toFixedNumber(2) : null;
                    }
                }
            }
        });
    };
    
    TransformerSubviews.annunciator = function(session, path) {
        return that.subviewInstance({
            title: "Annunciator",
            path: path,
            view: EmPower.Util.instanceFactory(EmPower.View.Annunciator, {
                session: session
            }),
            companionView: EmPower.Util.instanceFactory(EmPower.View.AnnunciatorTable, {
                session: session
            })
        });
    };
    
    TransformerSubviews.powerMetering = function(session, path) {
        return that.subviewInstance({
            title: "Power",
            path: path,
            view: EmPower.Util.instanceFactory(EmPower.View.Metrology, {
                session: session
            }),
            companionView: EmPower.Util.instanceFactory(EmPower.View.MetrologyTable, {
                session: session
            }),
            graphCollection: function() {
                return session.transformerSession().metrologyEventCollection();
            },
            graphOptions: {
                options: {
                    scales: {
                        yAxes: [
                            EmPower.Util.SimpleChartAxis.voltagekV({
                                id: "y-axis-voltagekV"
                            }),
                            EmPower.Util.SimpleChartAxis.current({
                                id: "y-axis-current",
                                position: "right"
                            })
                        ]
                    }
                },
                datasetConfig: [{
                    datasetId: function(model) {
                        return "pm-voltage-a";
                    },
                    label: function(model) {
                        return "Voltage Phase A";
                    },
                    yValue: function(model) {
                        return (model.voltagePhaseA() !== undefined && model.voltagePhaseA() !== null) ? (model.voltagePhaseA() / 1000).toFixedNumber(2) : null;
                    },
                    datasetOptions: function(model) {
                        return {
                            yAxisID: "y-axis-voltagekV"
                        };
                    }
                }, {
                    datasetId: function(model) {
                        return "pm-voltage-b";
                    },
                    label: function(model) {
                        return "Voltage Phase B";
                    },
                    yValue: function(model) {
                        return (model.voltagePhaseB() !== undefined && model.voltagePhaseB() !== null) ? (model.voltagePhaseB() / 1000).toFixedNumber(2) : null;
                    },
                    datasetOptions: function(model) {
                        return {
                            yAxisID: "y-axis-voltagekV"
                        };
                    }
                }, {
                    datasetId: function(model) {
                        return "pm-voltage-c";
                    },
                    label: function(model) {
                        return "Voltage Phase C";
                    },
                    yValue: function(model) {
                        return (model.voltagePhaseC() !== undefined && model.voltagePhaseC() !== null) ? (model.voltagePhaseC() / 1000).toFixedNumber(2) : null;
                    },
                    datasetOptions: function(model) {
                        return {
                            yAxisID: "y-axis-voltagekV"
                        };
                    }
                }, {
                    datasetId: function(model) {
                        return "pm-current-a";
                    },
                    label: function(model) {
                        return "Current Phase A";
                    },
                    yValue: function(model) {
                        return (model.currentPhaseA() !== undefined && model.currentPhaseA() !== null) ? (model.currentPhaseA()).toFixedNumber(2) : null;
                    },
                    datasetOptions: function(model) {
                        return {
                            yAxisID: "y-axis-current"
                        };
                    }
                }, {
                    datasetId: function(model) {
                        return "pm-current-b";
                    },
                    label: function(model) {
                        return "Current Phase B";
                    },
                    yValue: function(model) {
                        return (model.currentPhaseB() !== undefined && model.currentPhaseB() !== null) ? (model.currentPhaseB()).toFixedNumber(2) : null;
                    },
                    datasetOptions: function(model) {
                        return {
                            yAxisID: "y-axis-current"
                        };
                    }
                }, {
                    datasetId: function(model) {
                        return "pm-current-c";
                    },
                    label: function(model) {
                        return "Current Phase C";
                    },
                    yValue: function(model) {
                        return (model.currentPhaseC() !== undefined && model.currentPhaseC() !== null) ? (model.currentPhaseC()).toFixedNumber(2) : null;
                    },
                    datasetOptions: function(model) {
                        return {
                            yAxisID: "y-axis-current"
                        };
                    }
                }, {
                    datasetId: function(model) {
                        return "pm-current-neutral";
                    },
                    label: function(model) {
                        return "Current Neutral";
                    },
                    yValue: function(model) {
                        return (model.currentNeutral() !== undefined && model.currentNeutral() !== null) ? (model.currentNeutral()).toFixedNumber(2) : null;
                    },
                    datasetOptions: function(model) {
                        return {
                            yAxisID: "y-axis-current"
                        };
                    }
                }]
            }
        });
    };
    
    TransformerSubviews.temperature = function(session, path) {
        return that.subviewInstance({
            title: "Temperature",
            path: path,
            view: EmPower.Util.instanceFactory(EmPower.View.Temperature, {
                session: session
            }),
            companionView: EmPower.Util.instanceFactory(EmPower.View.TemperatureTable, {
                session: session
            }),
            graphCollection: function() {
                return session.transformerSession().temperatureEventCollection();
            },
            graphOptions: {
                options: {
                    scales: {
                        yAxes: [EmPower.Util.SimpleChartAxis.temperature()]
                    }
                },
                datasetConfig: {
                    label: function(model) {
                        var label = model.description();
//                        if (model.temperatureTypeAbbrev()
//                                && !(_.startsWith(model.temperatureTypeAbbrev(), "UNDEF") || _.startsWith(model.temperatureTypeAbbrev(), "GEN"))) {
//                            label = model.temperatureTypeName();
//                        }
                        return label;
                    },
                    yValue: function(model) {
                        return (model.value() !== undefined && model.value() !== null) ? model.value().toFixedNumber(2) : null;
                    }
                }
            }
        });
    };
    
    TransformerSubviews.pressure = function(session, path) {
        return that.subviewInstance({
            title: "Pressure, Levels, and Auxiliary Devices",
            path: path,
            view: EmPower.Util.instanceFactory(EmPower.View.Pressure, {
                session: session
            }),
            companionView: EmPower.Util.instanceFactory(EmPower.View.PressureTable, {
                session: session
            }),
            graphCollection: function() {
                return session.transformerSession().pressureEventCollection();
            },
            graphOptions: {
                options: {
                    scales: {
                        yAxes: [EmPower.Util.SimpleChartAxis.pressure()]
                    }
                },
                datasetConfig: {
                    yValue: function(model) {
                        return (model.value() !== undefined && model.value() !== null) ? (model.value() * 0.145037738).toFixedNumber(2) : null;
                    }
                }
            }
        });
    };
    
    TransformerSubviews.cooling = function(session, path) {
        return that.subviewInstance({
            title: "Cooling",
            path:path,
            view: EmPower.Util.instanceFactory(EmPower.View.Cooling, {
                session: session
            }), 
            companionView: EmPower.Util.instanceFactory(EmPower.View.CoolingTable, {
                session: session
            })
        });
    };
    
    TransformerSubviews.ltc = function(session, path) {
        return that.subviewInstance({
            title: "Load Tap Changer",
            path: path,
            view: EmPower.Util.instanceFactory(EmPower.View.Ltc, {
                session: session
            }),
            companionView: EmPower.Util.instanceFactory(EmPower.View.LtcTable, {
                session: session
            })
        });
    };
    
    TransformerSubviews.moisture = function(session, path) {
        return that.subviewInstance({
            title: "Moisture",
            path: path,
            view: EmPower.Util.instanceFactory(EmPower.View.Moisture, {
                session: session
            }),
            companionView: EmPower.Util.instanceFactory(EmPower.View.MoistureTable, {
                session: session
            }),
            graphCollection: function() {
                return [session.transformerSession().dgaEventCollection(),
                        session.transformerSession().temperatureEventCollection()];
            },
            graphOptions: {
                options: {
                    scales: {
                        yAxes: [EmPower.Util.SimpleChartAxis.concentration({
                                id: "y-axis-concentration"
                            }),
                            EmPower.Util.SimpleChartAxis.relativeHumidity({
                                id: "y-axis-relativeHumidity",
                                position: "right"
                            }),
                            EmPower.Util.SimpleChartAxis.moisture({
                                id: "y-axis-moisture",
                                position: "right"
                            }),
                            EmPower.Util.SimpleChartAxis.temperature({
                                id: "y-axis-temperature"
                            })
                        ]
                    }
                },
                datasetConfig: [{
                    collection: function() {
                        return session.transformerSession().dgaEventCollection();
                    },
                    datasetOptions: function(model) {
                        var yAxisID;
                        if (_.startsWith(model.gasTypeAbbrev(), "H2OOil") ||
                                _.startsWith(model.gasTypeAbbrev(), "H2OPaper") ||
                                _.startsWith(model.gasTypeAbbrev(), "H2OActivityOil") ||
                                _.startsWith(model.gasTypeAbbrev(), "H2OActivityPaper")) {
                            yAxisID = "y-axis-moisture";
                        } else if (_.startsWith(model.gasTypeAbbrev(), "H2OContentPaper")) {
                            yAxisID = "y-axis-relativeHumidity";
                        } else {
                            yAxisID = "y-axis-concentration";
                        }
                        return {
                            yAxisID: yAxisID
                        };
                    },
                    label: function(model) {
                        var label = model.description();
                        if (model.gasTypeAbbrev()
                                && !(_.startsWith(model.gasTypeAbbrev(), "UNDEF"))) {
                            label = model.gasTypeName();
                        }
                        return label;
                    },
                    isGraphed: function(model) {
                        return _.startsWith(model.gasTypeAbbrev(), "H2O");
                    },
                    yValue: function(model) {
                        return (model.value() !== undefined && model.value() !== null) ? model.value().toFixedNumber(2) : null;
                    }
                }, {
                    collection: function() {
                        return session.transformerSession().temperatureEventCollection();
                    },
                    datasetOptions: function(model) {
                        return {
                            yAxisID: "y-axis-temperature"
                        };
                    },
                    label: function(model) {
                        var label = model.description();
                        if (model.temperatureTypeAbbrev()
                                && !(_.startsWith(model.temperatureTypeAbbrev(), "UNDEF") || _.startsWith(model.temperatureTypeAbbrev(), "GEN"))) {
                            label = model.temperatureTypeName();
                        }
                        return label;
                    },
                    isGraphed: function(model) {
                        return _.startsWith(model.temperatureTypeAbbrev(), "OTM");
                    },
                    yValue: function(model) {
                        return (model.value() !== undefined && model.value() !== null) ? model.value().toFixedNumber(2) : null;
                    } 
                }]
            }
        });
    };
    
    TransformerSubviews.dga = function(session, path) {
        return that.subviewInstance({
            title: "Dissolved Gas Analysis",
            path: path,
            view: EmPower.Util.instanceFactory(EmPower.View.Dga, {
                session: session
            }),
            companionView: EmPower.Util.instanceFactory(EmPower.View.DgaTable, {
                session: session
            }),
            graphCollection: function() {
                return session.transformerSession().dgaEventCollection();
            },
            graphOptions: {
                options: {
                    scales: {
                        yAxes: [EmPower.Util.SimpleChartAxis.concentration({
                                id: "y-axis-concentration"
                            }),
                            EmPower.Util.SimpleChartAxis.relativeHumidity({
                                id: "y-axis-relativeHumidity",
                                position: "right"
                            }),
                            EmPower.Util.SimpleChartAxis.moisture({
                                id: "y-axis-moisture"
                        })]
                    }
                },
                datasetConfig: {
                    datasetOptions: function(model) {
                        var yAxisID;
                        if (_.startsWith(model.gasTypeAbbrev(), "H2OOil") ||
                                _.startsWith(model.gasTypeAbbrev(), "H2OPaper") ||
                                _.startsWith(model.gasTypeAbbrev(), "H2OActivityOil") ||
                                _.startsWith(model.gasTypeAbbrev(), "H2OActivityPaper")) {
                            yAxisID = "y-axis-moisture";
                        } else if (_.startsWith(model.gasTypeAbbrev(), "H2OContentPaper")) {
                            yAxisID = "y-axis-relativeHumidity";
                        } else {
                            yAxisID = "y-axis-concentration";
                        }
                        return {
                            yAxisID: yAxisID
                        };
                    },
                    label: function(model) {
                        var label = model.description();
                        if (model.gasTypeAbbrev()
                                && !(_.startsWith(model.gasTypeAbbrev(), "UNDEF"))) {
                            label = model.gasTypeName();
                        }
                        return label;
                    },
                    yValue: function(model) {
                        return (model.value() !== undefined && model.value() !== null) ? model.value().toFixedNumber(2) : null;
                    }
                }
            }
        });
    };
    
    TransformerSubviews.documents = function(session, path) {
        return that.subviewInstance({
            title: "Documents",
            path: path,
            view: EmPower.Util.instanceFactory(EmPower.View.Document, {
                session: session
            })
        });
    };
    
    TransformerSubviews.security = function(session, path) {
        return that.subviewInstance({
            title: "Security",
            path: path,
            view: EmPower.Util.instanceFactory(EmPower.View.Security, {
                session: session
            }),
            companionView: EmPower.Util.instanceFactory(EmPower.View.AccessPanelTable, {
                session: session
            })
        });
    };
    
    TransformerSubviews.video = function(session, path) {
        return that.subviewInstance({
            title: "Video",
            path: path,
            view: EmPower.Util.instanceFactory(EmPower.View.Camera, {
                session: session
            })
        });
    };
    
    TransformerSubviews.users = function(session, path) {
        return that.subviewInstance({
            title: "Users",
            path: path,
            view: EmPower.Util.instanceFactory(EmPower.View.UserManagement, {
                session: session
            })
        });
    };
    
    TransformerSubviews.rctu = function(session, path) {
        return that.subviewInstance({
            title: "RCTU",
            path: path,
            view: EmPower.Util.instanceFactory(EmPower.View.Rctu, {
                session: session
            })
        });
    };
    
    TransformerSubviews.rctuUpdate = function(session, path){
        return that.subviewInstance({
            title: "RCTU Update",
            path: path,
            view: EmPower.Util.instanceFactory(EmPower.View.Rctu_software_update, {
                session: session
            })
        });
    };
    
    TransformerSubviews.opm = function(session, path) {
        return that.subviewInstance({
            title: "Opm",
            path: path,
            view: EmPower.Util.instanceFactory(EmPower.View.Opm, { 
                session: session 
            })
        });
    };
    
    TransformerSubviews.recorded = function(session, path) {
        return that.subviewInstance({
            title: "Recorded",
            path: path,
            view: EmPower.Util.instanceFactory(EmPower.View.Recorded, {
                session: session
            })
        });
    };
    
    TransformerSubviews.battery = function(session, path) {
        return that.subviewInstance({
            title: "Battery",
            path: path,
            view: EmPower.Util.instanceFactory(EmPower.View.Battery, {
                session: session
            }),
            companionView: EmPower.Util.instanceFactory(EmPower.View.BatteryTable, {
                session: session
            })
        });
    };
    
    TransformerSubviews.systemHealth = function(session, path) {
        return that.subviewInstance({
            title: "System Health",
            path: path,
            view: EmPower.Util.instanceFactory(EmPower.View.SystemHealth, {
                session: session
            })
        });
    };
    
    TransformerSubviews.metrology = function(session, path) {
        return that.subviewInstance({
            title: "Metrology",
            path: path,
            view: EmPower.Util.instanceFactory(EmPower.View.AdvancedMetrology, {
                session: session
            })
//            companionView: EmPower.Util.instanceFactory(EmPower.View.MetrologyDetectedEventTable, {
//                session: session
//            })
        });
    };
    
    TransformerSubviews.firmware = function(session, path) {
        return that.subviewInstance({
            title: "Firmware",
            path: path,
            view: EmPower.Util.instanceFactory(EmPower.View.Firmware, {
                session: session
            })
        });
    };

})(EmPower.View.TransformerSubviews = EmPower.View.TransformerSubviews || {}, jQuery);
