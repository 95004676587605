EmPower.View.CollectionGraph = (function(View, $, undefined) {
    
    return View.extend({
        viewName: "Collection Graph",
	
	_initialize: function() {
        this._dateRange = this._session.dateRange();
	},
        
        _getCollection: function() {
            return this._session.transformerSession().selectedTransformerSubview().selected() ?
                this._session.transformerSession().selectedTransformerSubview().selected().graphCollection() : null;
        },
        
        _getGraphOptions: function() {
            return this._session.transformerSession().selectedTransformerSubview().selected() ?
                this._session.transformerSession().selectedTransformerSubview().selected().graphOptions() || {} : {};
        },
	
	_setupListeners: function() {
            var self = this;
            
            self.listenTo(self._session.transformerSession().selectedTransformerSubview(), 'change', function() {
                self._updateTransformerSubviewDebounced(self._getCollection(), self._getGraphOptions(), true);
            });
            
            self._updateTransformerSubview(self._getCollection(), self._getGraphOptions());

            this.listenTo(self._dateRange, 'change', function() {
                this._logger.debugConsoleLog("Responding to change event on dateRange: " + this.viewName);
                    setTimeout(function() {
                        if (self.isVisible()) {
                            //self._eventCollection.fetchDebounced();
                            self._getCollection().fetchDebounced();
                        }
                    }, 1);
            });

	},
	
        _updateTransformerSubview: function(graphCollection, graphOptions) {
	    
            if (!_.isArray(graphCollection)) {
                graphCollection = [graphCollection];
            }
	    
            View.prototype._setupListeners.apply(this, [graphCollection]);

            this.collections(graphCollection);

            this.graphOptions(graphOptions);
	},
        
        _updateTransformerSubviewDebounced: function(graphCollection, graphOptions, rerender) {
            var self = this;
	    
	    var debounce = _.debounce(function(graphCollection, graphOptions, rerender) {
                
                self._updateTransformerSubview(graphCollection, graphOptions);
                
                if (rerender) {
                    self.renderDebounced();
                }
	    }, 1);
	    
	    self._updateTransformerSubviewDebounced = debounce;
	    
	    return self._updateTransformerSubviewDebounced(graphCollection, graphOptions, rerender);
        }
    });
    
})(EmPower.View.AbstractGraphView, jQuery);
